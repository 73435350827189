import { login as _login, logout as _logout, getInfo as _getInfo } from '@/api/user';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { resetRouter } from '@/router';
var getDefaultState = function getDefaultState() {
  return {
    token: getToken(),
    refreshToken: '',
    name: '',
    avatar: '',
    userId: '',
    appId: 101,
    roles: [],
    breadList: sessionStorage.getItem('breadList') ? JSON.parse(sessionStorage.getItem('breadList')) : [],
    breadActiveNum: sessionStorage.getItem('breadActiveNum') ? JSON.parse(sessionStorage.getItem('breadActiveNum')) : 0
  };
};
var state = getDefaultState();
var mutations = {
  RESET_STATE: function RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_USERID: function SET_USERID(state, userId) {
    state.userId = userId;
  },
  SET_ROLES: function SET_ROLES(state, roles) {
    state.roles = roles;
  },
  SET_BREADLIST: function SET_BREADLIST(state, breadList) {
    state.breadList = breadList;
  },
  SET_NUM: function SET_NUM(state, breadActiveNum) {
    state.breadActiveNum = String(breadActiveNum);
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      _login(userInfo).then(function (response) {
        var data = response.data;
        commit('SET_TOKEN', data.accessToken);
        setToken(data.accessToken);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref2) {
    var commit = _ref2.commit,
      state = _ref2.state;
    return new Promise(function (resolve, reject) {
      _getInfo().then(function (response) {
        var data = response.data;
        if (!data) {
          reject('Verification failed, please Login again.');
        }
        // data.roles = data.roles.map(i => {
        //   return i.code
        // })
        var icon = data.icon,
          roles = data.roles,
          username = data.username,
          userId = data.userId;
        // roles must be a non-empty array
        if (!roles || roles.length <= 0) {
          reject('getInfo: roles must be a non-null array!');
        }
        commit('SET_USERID', userId);
        commit('SET_ROLES', roles);
        commit('SET_NAME', username);
        commit('SET_AVATAR', icon);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref3) {
    var commit = _ref3.commit,
      state = _ref3.state;
    return new Promise(function (resolve, reject) {
      _logout(state.token).then(function () {
        removeToken(); // must remove  token  first
        resetRouter();
        commit('RESET_STATE');
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      removeToken(); // must remove  token  first
      commit('RESET_STATE');
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};