//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import CustomActivity from './components/CustomActivity.vue'
import officialEventBeltShare from "./components/officialEventBeltShare.vue";
// import officialEventNotShare from './components/officialEventNotShare.vue'
import CustomActivityPopup from "./components/CustomActivityPopup.vue";
// import PolymerizationActivity from './components/PolymerizationActivity.vue'
export default {
  name: 'LittleActivityCreation',
  components: {
    CustomActivityPopup: CustomActivityPopup,
    officialEventBeltShare: officialEventBeltShare
  },
  // components: { CustomActivity, CustomActivityPopup, officialEventBeltShare, officialEventNotShare, PolymerizationActivity },
  data: function data() {
    return {
      selectID: 0,
      typeName: [{
        id: 0,
        name: '自定义活动'
      }
      // { id: 1, name: '官方活动（有分享）' },
      // { id: 2, name: '官方活动（无分享）' }
      // { id: 3, name: '聚合页活动' }
      ]
    };
  },
  methods: {
    onTypeName: function onTypeName(val) {
      this.selectID = val;
    },
    onAdd: function onAdd() {
      this.$refs.customActivityPopup.open({
        type: 'add',
        name: '添加活动'
      });
    },
    onRefresh: function onRefresh() {
      // console.log('Refresh,刷新页面')

      // location.reload()
      this.$refs.officialEventBeltShare.listActivityApi();
    }
  }
};