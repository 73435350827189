import request from '@/utils/request';
export function listOrderPagedApi(params) {
  return request({
    url: '/portal/system/order/listOrderPaged',
    method: 'get',
    params: params
  });
}
export function loadOrderDetail(params) {
  return request({
    url: '/portal/system/order/loadOrderDetail',
    method: 'get',
    params: params
  });
}