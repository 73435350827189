var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "box" }, [
      _vm.type === 1
        ? _c("div", [
            _c(
              "div",
              {
                staticClass: "home-top",
                style: {
                  backgroundImage: "url(" + _vm.homeTopData.bg + ")",
                  backgroundSize: "cover",
                },
                on: {
                  mouseover: function ($event) {
                    return _vm.bothMouseOver(1)
                  },
                },
              },
              [
                _vm.boole1
                  ? _c(
                      "div",
                      {
                        staticClass: "cover",
                        on: {
                          mouseleave: function ($event) {
                            return _vm.bothMouseLeave(1)
                          },
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-edit",
                            size: "mini",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.bothClick(1)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "ipt" }, [
                  _c("img", {
                    staticStyle: {
                      width: "14px",
                      height: "14px",
                      margin: "0 10px",
                    },
                    attrs: { src: require("@/assets/search.png") },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("搜索商品关键词")]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "right-box" },
                  _vm._l(_vm.homeTopData.iconList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "both-right-box" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "small-box",
                            style: { background: item.url ? "none" : "" },
                          },
                          [
                            item.url
                              ? _c("img", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                  attrs: { src: item.url },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "text",
                            style: { color: _vm.homeTopData.textColor },
                          },
                          [_vm._v(_vm._s(item.text))]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "home-second-top",
                on: {
                  mouseover: function ($event) {
                    return _vm.bothMouseOver(2)
                  },
                },
              },
              [
                _vm.boole2
                  ? _c(
                      "div",
                      {
                        staticClass: "cover",
                        on: {
                          mouseleave: function ($event) {
                            return _vm.bothMouseLeave(2)
                          },
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-edit",
                            size: "mini",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.bothClick(2)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.homeTopSecondData.banner === 1
                  ? _c("div", { staticClass: "banner" })
                  : _vm._e(),
                _vm._v(" "),
                _vm.homeTopSecondData.smallBanner === 1
                  ? _c("div", { staticClass: "smallBanner" })
                  : _vm._e(),
                _vm._v(" "),
                _vm.homeTopSecondData.diamond === 1
                  ? _c("div", { staticClass: "diamond" }, [
                      _c(
                        "div",
                        { staticClass: "type-box" },
                        _vm._l(_vm.diamondTypeList, function (item, index) {
                          return _c("div", { key: index }, [
                            _vm._v(_vm._s(item)),
                          ])
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "diamond-box" },
                        _vm._l(10, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "diamond-son" },
                            [
                              _c("div", { staticClass: "small-box" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "text" }, [
                                _vm._v("自定义"),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "home-middle",
                on: {
                  mouseover: function ($event) {
                    return _vm.bothMouseOver(3)
                  },
                },
              },
              [
                _vm.boole3
                  ? _c(
                      "div",
                      {
                        staticClass: "cover",
                        on: {
                          mouseleave: function ($event) {
                            return _vm.bothMouseLeave(3)
                          },
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-edit",
                            size: "mini",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.bothClick(3)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.homeMiddleData.fourBatch === 1
                  ? _c(
                      "div",
                      { staticClass: "four-box" },
                      _vm._l(4, function (itme, index) {
                        return _c("div", {
                          key: index,
                          staticClass: "four-son",
                        })
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.homeMiddleData.middleBanner === 1
                  ? _c("div", { staticClass: "middle-banner" })
                  : _vm._e(),
                _vm._v(" "),
                _vm.homeMiddleData.sixBatch === 1
                  ? _c(
                      "div",
                      { staticClass: "six-big-box" },
                      _vm._l(6, function (item, index) {
                        return _c("div", { key: index, staticClass: "six-son" })
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.homeMiddleData.threeBatch === 1
                  ? _c("div", { staticClass: "three-box" }, [
                      _c("div", { staticClass: "three-left" }),
                      _vm._v(" "),
                      _vm._m(0),
                    ])
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "home-foot",
                style: {
                  background: _vm.homeFootData.tbBg
                    ? _vm.homeFootData.tbBg
                    : "",
                },
                on: {
                  mouseover: function ($event) {
                    return _vm.bothMouseOver(4)
                  },
                },
              },
              [
                _vm.boole4
                  ? _c(
                      "div",
                      {
                        staticClass: "cover",
                        on: {
                          mouseleave: function ($event) {
                            return _vm.bothMouseLeave(4)
                          },
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-edit",
                            size: "mini",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.bothClick(4)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.typeList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "text",
                      style: {
                        color: _vm.homeFootData.textColor
                          ? _vm.homeFootData.textColor
                          : "",
                      },
                    },
                    [_vm._v(_vm._s(item))]
                  )
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "foot-box",
                on: {
                  mouseover: function ($event) {
                    return _vm.bothMouseOver(5)
                  },
                },
              },
              [
                _vm.boole5
                  ? _c(
                      "div",
                      {
                        staticClass: "cover",
                        on: {
                          mouseleave: function ($event) {
                            return _vm.bothMouseLeave(5)
                          },
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-edit",
                            size: "mini",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.bothClick(5)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.tabBarData, function (item, index) {
                  return _c("div", { key: index, staticClass: "foot-son" }, [
                    _c(
                      "div",
                      {
                        staticClass: "small",
                        style: { background: item.normalUrl ? "none" : "" },
                      },
                      [
                        item.normalUrl
                          ? _c("img", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: { src: item.normalUrl },
                            })
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                  ])
                }),
              ],
              2
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.type === 2
        ? _c("div", [
            _c(
              "div",
              {
                staticClass: "home-top",
                style: {
                  background: _vm.homeTopData.bgColor
                    ? _vm.homeTopData.bgColor
                    : "",
                },
                on: {
                  mouseover: function ($event) {
                    return _vm.bothMouseOver(1)
                  },
                },
              },
              [
                _vm.boole1
                  ? _c(
                      "div",
                      {
                        staticClass: "cover",
                        on: {
                          mouseleave: function ($event) {
                            return _vm.bothMouseLeave(1)
                          },
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-edit",
                            size: "mini",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.bothClick(1)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "my-center" }, [
                  _c("img", {
                    staticStyle: { width: "60px", height: "60px" },
                    attrs: { src: require("@/assets/user.png") },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "text" }, [_vm._v("用户昵称")]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "right-box" },
                  _vm._l(_vm.homeTopData.iconList, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "both-right-box" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "small-box",
                            style: { background: item.url ? "none" : "" },
                          },
                          [
                            item.url
                              ? _c("img", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                  attrs: { src: item.url },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "text",
                            style: { color: _vm.homeTopData.textColor },
                          },
                          [_vm._v(_vm._s(item.text))]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "center-second",
                on: {
                  mouseover: function ($event) {
                    return _vm.bothMouseOver(2)
                  },
                },
              },
              [
                _vm.boole2
                  ? _c(
                      "div",
                      {
                        staticClass: "cover",
                        on: {
                          mouseleave: function ($event) {
                            return _vm.bothMouseLeave(2)
                          },
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-edit",
                            size: "mini",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.bothClick(2)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "small-box" }, [
                  _c(
                    "div",
                    {
                      staticClass: "tips",
                      style: {
                        color: _vm.centerData.explain
                          ? _vm.centerData.explain
                          : "",
                      },
                    },
                    [
                      _vm._v(
                        "\n            每月25日,结算上月确认收货的订单佣金\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "center-middle" }, [
                    _c("div", { staticClass: "middle-son" }, [
                      _c("div", { staticClass: "son-num" }, [_vm._v("100")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "son-text" }, [
                        _vm._v("累计已结算"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "son-btn",
                          style: {
                            color: _vm.centerData.detailedText
                              ? _vm.centerData.detailedText
                              : "",
                            background: _vm.centerData.detailedBtn
                              ? _vm.centerData.detailedBtn
                              : "",
                          },
                        },
                        [_vm._v("收益明细")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      staticStyle: { width: "1px" },
                      attrs: { src: require("@/assets/solid.png") },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "middle-son" }, [
                      _c("div", { staticClass: "son-num" }, [_vm._v("10011")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "son-text" }, [
                        _vm._v("账户余额"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "son-btn",
                          style: {
                            color: _vm.centerData.cashText
                              ? _vm.centerData.cashText
                              : "",
                            background: _vm.centerData.cashBtn
                              ? _vm.centerData.cashBtn
                              : "",
                          },
                        },
                        [_vm._v("去提现")]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "100%" } }, [
                    _c("img", {
                      staticStyle: { width: "100%" },
                      attrs: { src: require("@/assets/solid-water.png") },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "center-bottom" },
                    _vm._l(_vm.textList, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "bottom-son" },
                        [
                          _c("div", { staticClass: "bottom-num" }, [
                            _vm._v(_vm._s(item.num)),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "bottom-text" }, [
                            _vm._v(_vm._s(item.text)),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "three-box" },
                  _vm._l(_vm.centerData.iconList, function (item, index) {
                    return _c("div", { key: index, staticClass: "three-son" }, [
                      _c(
                        "div",
                        {
                          staticClass: "three-box-son",
                          style: { background: item.url ? "none" : "" },
                        },
                        [
                          item.url
                            ? _c("img", {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: { src: item.url },
                              })
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "text" }, [
                        _vm._v(_vm._s(item.text)),
                      ]),
                    ])
                  }),
                  0
                ),
              ]
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "three-right" }, [
      _c("div", { staticClass: "right-son" }),
      _vm._v(" "),
      _c("div", { staticClass: "right-son" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }