"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _HierarchicalAllocation = _interopRequireDefault(require("./components/HierarchicalAllocation"));
var _SubcommissionAllocation = _interopRequireDefault(require("./components/SubcommissionAllocation.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: '',
  components: {
    HierarchicalAllocation: _HierarchicalAllocation.default,
    SubcommissionAllocation: _SubcommissionAllocation.default
  },
  data: function data() {
    return {
      tabList: [{
        name: '等级配置',
        id: '0'
      }
      // { name: '区域分佣配置', id: '0' }
      ],
      appId: 0,
      activeNum: 0
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    if (this.$route.query.id) {
      console.log(this.$route.query.id);
      this.appId = this.$route.query.id * 1;
    }
  },
  mounted: function mounted() {},
  methods: {
    updateValue: function updateValue(newValue) {
      console.log(newValue, 'heheheheh');
      this.appId = newValue;
    },
    changeType: function changeType(val) {
      this.activeNum = val;
    }
  }
};