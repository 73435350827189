//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PreviewPopup',
  data: function data() {
    return {
      dialogVisible: false,
      dataList: {}
    };
  },
  methods: {
    open: function open(data) {
      this.dialogVisible = true;
      this.dataList = data;
    },
    onClose: function onClose() {
      this.dialogVisible = false;
      this.dataList = [];
    }
  }
};