var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
            title: "批量修改用户等级",
            width: "750px",
            top: "20vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.onClose,
          },
        },
        [
          _c("div", { staticClass: "content" }, [
            _c("div", [
              _c("div", [
                _c(
                  "div",
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择需要修改的等级" },
                        model: {
                          value: _vm.grade,
                          callback: function ($$v) {
                            _vm.grade = $$v
                          },
                          expression: "grade",
                        },
                      },
                      _vm._l(_vm.options, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.name, value: item.grade },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "template" }, [
                  _c("div", { staticClass: "fc cr" }, [
                    _c("img", {
                      attrs: { src: require("@/assets/xls.png"), alt: "" },
                    }),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "text",
                        attrs: {
                          download: "1111.xlsx",
                          href: "https://zf-pub-dev.oss-cn-hangzhou.aliyuncs.com/excel/%E7%94%A8%E6%88%B7%E7%AD%89%E7%BA%A7%E4%BF%AE%E6%94%B9%E6%A8%A1%E6%9D%BF.xlsx",
                        },
                      },
                      [_vm._v("点此下载EXCEL模版")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "fc",
                      staticStyle: { "margin-top": "30px" },
                    },
                    [
                      _c("div", { staticClass: "rollCall" }, [
                        _c(
                          "div",
                          { staticClass: "fc" },
                          [
                            _c(
                              "el-upload",
                              {
                                ref: "upload",
                                attrs: {
                                  limit: 1,
                                  "show-file-list": true,
                                  "auto-upload": false,
                                  "on-change": _vm.handleChangeFile,
                                  "on-remove": _vm.onRemove,
                                  "file-list": _vm.fileList,
                                  action: "#",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-top": "23px" },
                                    attrs: { type: "success" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                      点此导入模版"
                                    ),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-upload el-icon--right",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.unrecognizedList.length > 0
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-top": "16px",
                            padding: "0 10px",
                            "font-size": "14px",
                            color: "#ff4400",
                            display: "flex",
                            "word-wrap": "break-word",
                          },
                        },
                        [
                          _c(
                            "div",
                            [
                              _vm._v(
                                "\n                邀请码：\n                "
                              ),
                              _vm._l(
                                _vm.unrecognizedList,
                                function (item, index) {
                                  return _c("txt", { key: index }, [
                                    _vm._v(_vm._s(item) + "\\"),
                                  ])
                                }
                              ),
                              _vm._v(
                                "\n                有误，请重新修改后导入\n              "
                              ),
                            ],
                            2
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "fc", staticStyle: { "margin-top": "30px" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "cr",
                      staticStyle: {
                        width: "79px",
                        height: "34px",
                        background: "#4D8AFF",
                        "border-radius": "4px",
                        "line-height": "34px",
                        "text-align": "center",
                        "font-size": "14px",
                        color: "#ffffff",
                      },
                      on: { click: _vm.verify },
                    },
                    [_vm._v("\n            确认\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "cr",
                      staticStyle: {
                        "margin-left": "8px",
                        width: "79px",
                        height: "34px",
                        background: "#EEEEEE",
                        "border-radius": "4px",
                        "line-height": "34px",
                        "text-align": "center",
                        "font-size": "14px",
                        color: "#474245",
                      },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = false
                        },
                      },
                    },
                    [_vm._v("\n            关闭\n          ")]
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }