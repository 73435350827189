var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            title: _vm.popTitle,
            width: "750px",
            top: "2vh",
            "custom-class": "dialog_box",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.onClose,
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm11",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动名称", prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.ruleForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "name", $$v)
                          },
                          expression: "ruleForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "平台类型", prop: "type" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            clearable: "",
                            placeholder: "请选择平台类型",
                          },
                          model: {
                            value: _vm.ruleForm.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "type", $$v)
                            },
                            expression: "ruleForm.type",
                          },
                        },
                        _vm._l(_vm.typeOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "头图", prop: "headPhoto" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "130px",
                            height: "130px",
                            background: "#f9f9f9",
                            "border-radius": "4px",
                            border: "1px solid #eeeeee",
                          },
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                "show-file-list": false,
                                action: "",
                                "http-request": _vm.handleFileUpload,
                              },
                            },
                            [
                              _vm.ruleForm.headPhoto
                                ? _c("img", {
                                    staticClass: "avatar_img",
                                    attrs: { src: _vm.ruleForm.headPhoto },
                                  })
                                : _c("i", {
                                    staticClass:
                                      "el-icon-plus avatar-uploader-icon",
                                  }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.id
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "文件导入", prop: "fileList" } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "upload",
                              staticClass: "upload-demo",
                              attrs: {
                                action: "",
                                "on-change": _vm.change,
                                "on-remove": _vm.handleRemove,
                                "file-list": _vm.ruleForm.fileList,
                                "auto-upload": false,
                                limit: 1,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    slot: "trigger",
                                    size: "small",
                                    type: "primary",
                                  },
                                  slot: "trigger",
                                },
                                [_vm._v("选取文件")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { type: "danger", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.down()
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-download el-icon--left",
                                  }),
                                  _vm._v("下载模板"),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "列表样式", prop: "listType" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ruleForm.listType,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "listType", $$v)
                            },
                            expression: "ruleForm.listType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("竖版"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("横板"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "颜色值", prop: "color" } },
                    [
                      _c("el-color-picker", {
                        model: {
                          value: _vm.ruleForm.color,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "color", $$v)
                          },
                          expression: "ruleForm.color",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动时间", prop: "time" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          type: "datetimerange",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.timeC()
                          },
                        },
                        model: {
                          value: _vm.ruleForm.time,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "time", $$v)
                          },
                          expression: "ruleForm.time",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("ruleForm11")
                            },
                          },
                        },
                        [_vm._v("确认")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.resetForm("ruleForm11")
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }