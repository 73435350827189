"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listHistory = listHistory;
exports.loadData = loadData;
var _request = _interopRequireDefault(require("@/utils/request"));
// 数据统计查询
function loadData(params) {
  return (0, _request.default)({
    url: '/portal/system/bi/loadData',
    method: 'get',
    params: params
  });
}
// 历史数据查询
function listHistory(params) {
  return (0, _request.default)({
    url: '/portal/system/bi/listHistory',
    method: 'get',
    params: params
  });
}