var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            title: _vm.title,
            width: "1200px",
            top: "5vh",
            "custom-class": "dialog_box",
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "div",
            { ref: "dialogContent", staticClass: "from_box" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "弹出类型:", prop: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w100",
                          attrs: { placeholder: "请选择上下线" },
                          model: {
                            value: _vm.ruleForm.popType,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "popType", $$v)
                            },
                            expression: "ruleForm.popType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "普通弹窗", value: 1 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "新人注册弹框", value: 2 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "添加图片:", prop: "pic" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "130px",
                            height: "130px",
                            background: "#f9f9f9",
                            "border-radius": "4px",
                            border: "1px solid #eeeeee",
                          },
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                "show-file-list": false,
                                action: "",
                                "http-request": _vm.handleFileUpload,
                              },
                            },
                            [
                              _vm.ruleForm.pic
                                ? _c("img", {
                                    staticClass: "avatar_img",
                                    attrs: { src: _vm.ruleForm.pic },
                                  })
                                : _c("i", {
                                    staticClass:
                                      "el-icon-plus avatar-uploader-icon",
                                  }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称:", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入名称" },
                        model: {
                          value: _vm.ruleForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "name", $$v)
                          },
                          expression: "ruleForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态:", prop: "enable" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "w100",
                          attrs: { placeholder: "请选择上下线" },
                          model: {
                            value: _vm.ruleForm.enable,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "enable", $$v)
                            },
                            expression: "ruleForm.enable",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "上线", value: 1 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "下线", value: 0 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.ruleForm.popType === 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "每日弹出次数:", prop: "num" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入每日弹出次数" },
                            model: {
                              value: _vm.ruleForm.num,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "num", _vm._n($$v))
                              },
                              expression: "ruleForm.num",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序:", prop: "sort" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入排序" },
                        model: {
                          value: _vm.ruleForm.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "sort", _vm._n($$v))
                          },
                          expression: "ruleForm.sort",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.ruleForm.popType === 1
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "位置:", prop: "region" } },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: 1 },
                                  model: {
                                    value: _vm.ruleForm.region,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "region", $$v)
                                    },
                                    expression: "ruleForm.region",
                                  },
                                },
                                [_vm._v("\n              首页\n            ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: 2 },
                                  model: {
                                    value: _vm.ruleForm.region,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "region", $$v)
                                    },
                                    expression: "ruleForm.region",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              吃喝玩乐\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: 5 },
                                  model: {
                                    value: _vm.ruleForm.region,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "region", $$v)
                                    },
                                    expression: "ruleForm.region",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              个人中心\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "用户类型:", prop: "userType" } },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: null },
                                  model: {
                                    value: _vm.ruleForm.userType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "userType", $$v)
                                    },
                                    expression: "ruleForm.userType",
                                  },
                                },
                                [_c("span", [_vm._v("所有人")])]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: 1 },
                                  model: {
                                    value: _vm.ruleForm.userType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "userType", $$v)
                                    },
                                    expression: "ruleForm.userType",
                                  },
                                },
                                [
                                  _c("span", [_vm._v("新用户")]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "10px",
                                        color: "#7f7f7f",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                (7天内新注册用户)\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: 2 },
                                  model: {
                                    value: _vm.ruleForm.userType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "userType", $$v)
                                    },
                                    expression: "ruleForm.userType",
                                  },
                                },
                                [
                                  _c("span", [_vm._v("老用户激活")]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "10px",
                                        color: "#7f7f7f",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                (注册超90天且未消费)\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: 3 },
                                  model: {
                                    value: _vm.ruleForm.userType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "userType", $$v)
                                    },
                                    expression: "ruleForm.userType",
                                  },
                                },
                                [_c("span", [_vm._v("指定等级")])]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: 4 },
                                  model: {
                                    value: _vm.ruleForm.userType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "userType", $$v)
                                    },
                                    expression: "ruleForm.userType",
                                  },
                                },
                                [_c("span", [_vm._v("未登录")])]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: 5 },
                                  model: {
                                    value: _vm.ruleForm.userType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "userType", $$v)
                                    },
                                    expression: "ruleForm.userType",
                                  },
                                },
                                [_c("span", [_vm._v("无上级用户")])]
                              ),
                              _vm._v(" "),
                              _vm.ruleForm.userType === 3
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            multiple: "",
                                            placeholder: "请选择",
                                          },
                                          model: {
                                            value: _vm.classListQuery,
                                            callback: function ($$v) {
                                              _vm.classListQuery = $$v
                                            },
                                            expression: "classListQuery",
                                          },
                                        },
                                        _vm._l(
                                          _vm.classListData,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.ruleForm.userType === 3
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-size": "10px",
                                        color: "#7f7f7f",
                                      },
                                    },
                                    [_vm._v("注：等级可以多选")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动跳转:", prop: "jumpType" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: { input: _vm.onJumpTypeToggle },
                                  model: {
                                    value: _vm.ruleForm.jumpType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "jumpType", $$v)
                                    },
                                    expression: "ruleForm.jumpType",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 2 } }, [
                                    _vm._v("URL跳转"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 3 } }, [
                                    _vm._v("选择活动"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.ruleForm.jumpType === 2
                            ? _c(
                                "el-form-item",
                                { attrs: { prop: "jumpContent" } },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("el-input", {
                                        attrs: { placeholder: "请输入URL" },
                                        model: {
                                          value: _vm.ruleForm.jumpContent,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "jumpContent",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.jumpContent",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.ruleForm.jumpType === 3
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "活动选择:", prop: "" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "Official_event",
                                      on: { click: _vm.onOfficialEvent },
                                    },
                                    [_vm._v("去选择")]
                                  ),
                                  _vm._v(" "),
                                  _vm.presentationData.isActivityShow
                                    ? _c("div", [
                                        _vm.presentationData.Display_type ===
                                          1 ||
                                        _vm.presentationData.Display_type ===
                                          2 ||
                                        _vm.presentationData.Display_type ===
                                          3 ||
                                        _vm.presentationData.Display_type ===
                                          6 ||
                                        _vm.presentationData.Display_type === 7
                                          ? _c(
                                              "div",
                                              { staticClass: "movable_box" },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "活动类型:",
                                                      prop: "",
                                                      "label-width": "100px",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.presentationData
                                                            .activityType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.presentationData,
                                                            "activityType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "presentationData.activityType",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _vm.presentationData
                                                  .Display_type !== 3 &&
                                                _vm.presentationData
                                                  .Display_type !== 7
                                                  ? _c(
                                                      "el-form-item",
                                                      {
                                                        staticClass: "mt10",
                                                        attrs: {
                                                          label: "平台类型:",
                                                          prop: "",
                                                          "label-width":
                                                            "100px",
                                                        },
                                                      },
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            disabled: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .presentationData
                                                                .unionCode,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.presentationData,
                                                                "unionCode",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "presentationData.unionCode",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticClass: "mt10",
                                                    attrs: {
                                                      label: "活动名称:",
                                                      prop: "",
                                                      "label-width": "100px",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.ruleForm
                                                            .activityName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.ruleForm,
                                                            "activityName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "ruleForm.activityName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticClass: "mt10",
                                                    attrs: {
                                                      label:
                                                        _vm.presentationData
                                                          .Display_type === 6 ||
                                                        _vm.presentationData
                                                          .Display_type === 7
                                                          ? "头图:"
                                                          : "活动中转页:",
                                                      prop: "",
                                                      "label-width": "100px",
                                                    },
                                                  },
                                                  [
                                                    _c("img", {
                                                      staticStyle: {
                                                        width: "200px",
                                                        height: "100px",
                                                        "border-radius": "10px",
                                                        border:
                                                          "1px solid #ccc",
                                                      },
                                                      attrs: {
                                                        src: _vm
                                                          .presentationData
                                                          .activityImg,
                                                        alt: "",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm.presentationData
                                                  .Display_type !== 3 &&
                                                _vm.presentationData
                                                  .Display_type !== 6 &&
                                                _vm.presentationData
                                                  .Display_type !== 7
                                                  ? _c(
                                                      "el-form-item",
                                                      {
                                                        attrs: {
                                                          label: "",
                                                          prop: "",
                                                          "label-width":
                                                            "100px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-checkbox",
                                                          {
                                                            attrs: {
                                                              "true-label": 1,
                                                              "false-label": 0,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.ruleForm
                                                                  .interPageFlag,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.ruleForm,
                                                                    "interPageFlag",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "ruleForm.interPageFlag",
                                                            },
                                                          },
                                                          [_vm._v("启用中间页")]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.presentationData.Display_type ===
                                          4 ||
                                        _vm.presentationData.Display_type === 5
                                          ? _c(
                                              "div",
                                              { staticClass: "movable_box" },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      label: "活动类型:",
                                                      prop: "",
                                                      "label-width": "100px",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.presentationData
                                                            .activityType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.presentationData,
                                                            "activityType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "presentationData.activityType",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticClass: "mt10",
                                                    attrs: {
                                                      label: "活动名称:",
                                                      prop: "",
                                                      "label-width": "100px",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: { disabled: "" },
                                                      model: {
                                                        value:
                                                          _vm.ruleForm
                                                            .activityName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.ruleForm,
                                                            "activityName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "ruleForm.activityName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "投放日期:", prop: "timeArray" },
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetimerange",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  "default-time": ["00:00:00", "23:59:59"],
                                },
                                model: {
                                  value: _vm.timeArray,
                                  callback: function ($$v) {
                                    _vm.timeArray = $$v
                                  },
                                  expression: "timeArray",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "投放时间段:", prop: "" } },
                            [
                              _c("el-time-picker", {
                                attrs: {
                                  "value-format": "HH:mm:ss",
                                  "picker-options": {
                                    selectableRange: "00:00:00 - 23:59:59",
                                  },
                                  placeholder: "开始时间",
                                },
                                model: {
                                  value: _vm.ruleForm.startTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "startTime", $$v)
                                  },
                                  expression: "ruleForm.startTime",
                                },
                              }),
                              _vm._v("\n            至\n            "),
                              _c("el-time-picker", {
                                attrs: {
                                  "value-format": "HH:mm:ss",
                                  "picker-options": {
                                    selectableRange: "00:00:00 - 23:59:59",
                                  },
                                  placeholder: "结束时间",
                                },
                                model: {
                                  value: _vm.ruleForm.endTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "endTime", $$v)
                                  },
                                  expression: "ruleForm.endTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v("确认")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.resetForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("ActivitySelectionPopup", {
        ref: "activitySelectionPopup",
        on: { SelectEvents: _vm.onSelectEvents },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }