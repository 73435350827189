var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt20", staticStyle: { padding: "0 88px" } },
    [
      _c("div", { staticClass: "search_criteria" }, [
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: "粉丝类型", clearable: "" },
                model: {
                  value: _vm.queryData.fansType,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "fansType", $$v)
                  },
                  expression: "queryData.fansType",
                },
              },
              [
                _c("el-option", { attrs: { value: 0, label: "一代粉丝" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 1, label: "二代级以上" } }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: "粉丝状态", clearable: "" },
                model: {
                  value: _vm.queryData.effective,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "effective", $$v)
                  },
                  expression: "queryData.effective",
                },
              },
              [
                _c("el-option", { attrs: { value: 0, label: "无效" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 1, label: "有效" } }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: "用户类型", clearable: "" },
                model: {
                  value: _vm.queryData.grade,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "grade", $$v)
                  },
                  expression: "queryData.grade",
                },
              },
              _vm._l(_vm.options, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.name, value: item.grade },
                })
              }),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-input", {
              attrs: { placeholder: "手机号查询", clearable: "" },
              model: {
                value: _vm.queryData.phone,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "phone", $$v)
                },
                expression: "queryData.phone",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex", staticStyle: { width: "fit-content" } },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.onInquire } },
              [_vm._v("查询")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "ml10",
                attrs: { type: "info", plain: "" },
                on: { click: _vm.onReset },
              },
              [_vm._v("重置")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "flex mt10",
          staticStyle: {
            "align-items": "center",
            "margin-bottom": "15px",
            "margin-left": "5px",
          },
        },
        [
          _vm._l(_vm.statusTypeData, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "fc type_button pointer",
                class:
                  _vm.statusTypeDataIndex === item.id ? "selector_button" : "",
                on: {
                  click: function ($event) {
                    return _vm.onStatusList(item, index)
                  },
                },
              },
              [_vm._v("\n      " + _vm._s(item.name) + "\n    ")]
            )
          }),
          _vm._v(" "),
          _vm.statusTypeDataIndex === 6
            ? _c(
                "div",
                { staticStyle: { width: "360px", height: "40px" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      align: "right",
                      "unlink-panels": "",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "default-time": ["00:00:00", "23:59:59"],
                    },
                    on: { change: _vm.changeDataPicker },
                    model: {
                      value: _vm.value1,
                      callback: function ($$v) {
                        _vm.value1 = $$v
                      },
                      expression: "value1",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt10" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, border: "" },
            },
            [
              _vm.checkList.includes("头像")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "icon",
                      label: "头像",
                      width: "180",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("img", {
                                staticStyle: {
                                  width: "50px",
                                  height: "50px",
                                  "border-radius": "8px",
                                },
                                attrs: { src: scope.row.icon, alt: "" },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3960858937
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkList.includes("用户昵称")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "nickname",
                      label: "用户昵称",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "pointer",
                                  staticStyle: {
                                    "font-size": "14px",
                                    color: "#4d8aff",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onUserDetails(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.nickname))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1032891465
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkList.includes("手机号码")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "phone",
                      label: "手机号码",
                      align: "center",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkList.includes("有效用户")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "effective",
                      label: "有效用户",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    scope.row.effective ? "有效" : "无效"
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2930904572
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkList.includes("累计推广金")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "promotionAmount",
                      label: "累计推广金",
                      align: "center",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkList.includes("累计自购金")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "selfAmount",
                      label: "累计自购金",
                      align: "center",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "date",
                  label: "用户类型",
                  width: "180",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              scope.row.fansType ? "二代及以上" : "一代粉丝"
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "gradeName",
                  label: "用户等级",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "注册时间",
                  width: "180",
                  align: "center",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 page_box" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.queryData.page.page,
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.queryData.page.size,
              layout: "total, prev, pager, next, sizes,jumper",
              total: _vm.total,
              background: "",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }