var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        background: "#fff",
        padding: "30px",
        "border-radius": "20px",
      },
    },
    [
      _c("div", { staticClass: "title" }, [_vm._v("app图标配置")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c("div", { staticStyle: { color: "red" } }, [
        _vm._v("注意：图标设置后，下次打包才会生效!"),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              staticClass: "demo-ruleForm",
              staticStyle: { "margin-top": "30px" },
              attrs: { "label-width": "60px" },
            },
            [
              _c("el-form-item", [
                _c(
                  "div",
                  {
                    staticClass: "both-label",
                    attrs: { slot: "label" },
                    slot: "label",
                  },
                  [
                    _c("i", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                    _vm._v("\n          图标\n        "),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "flex-start",
                      "flex-wrap": "wrap",
                    },
                  },
                  _vm._l(_vm.list, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticStyle: {
                          width: "130px",
                          height: "130px",
                          background: "#eeeeee",
                          "border-radius": "4px",
                          border: "1px dashed #d9d9d9",
                          margin: "0 30px 60px 0",
                        },
                      },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "avatar-uploader",
                            attrs: {
                              "show-file-list": false,
                              action: "",
                              "http-request": function (value) {
                                return _vm.handleFileUpload(value, item)
                              },
                            },
                          },
                          [
                            item.url
                              ? _c("el-image", {
                                  staticClass: "avatar_img",
                                  attrs: { fit: "scale-down", src: item.url },
                                })
                              : _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon",
                                }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "12px",
                              "text-align": "center",
                            },
                          },
                          [_vm._v(_vm._s(item.info) + " png图片")]
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.save()
                        },
                      },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }