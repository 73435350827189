"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findByPhone = findByPhone;
exports.getTljChangeRecord = getTljChangeRecord;
exports.platformDeductMoney = platformDeductMoney;
exports.platformTopUp = platformTopUp;
var _request = _interopRequireDefault(require("@/utils/request"));
function findByPhone(params) {
  return (0, _request.default)({
    url: '/portal/system/member/findByPhone',
    method: 'get',
    params: params
  });
}
function getTljChangeRecord(data) {
  return (0, _request.default)({
    url: '/portal/system/tlj/getTljChangeRecord',
    method: 'post',
    data: data
  });
}
// 充值
function platformTopUp(data) {
  return (0, _request.default)({
    url: '/portal/system/tlj/platformTopUp',
    method: 'post',
    data: data
  });
}
// 扣款
function platformDeductMoney(data) {
  return (0, _request.default)({
    url: '/portal/system/tlj/platformDeductMoney',
    method: 'post',
    data: data
  });
}