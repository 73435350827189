// 通用H5界面
import request from '@/utils/request';
// 列表
export function listPageApi(data) {
  return request({
    url: '/portal/system/page/listPage',
    method: 'post',
    data: data
  });
}
// 添加页面
export function savePageApi(data) {
  return request({
    url: '/portal/system/page/savePage',
    method: 'post',
    data: data
  });
}
// 编辑
export function updatePageApi(data) {
  return request({
    url: '/portal/system/page/updatePage',
    method: 'post',
    data: data
  });
}