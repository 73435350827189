import "core-js/modules/es6.function.name";
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  token: function token(state) {
    return state.user.token;
  },
  refreshToken: function refreshToken(state) {
    return state.user.refreshToken;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  userId: function userId(state) {
    return state.user.userId;
  },
  appId: function appId(state) {
    return state.user.appId;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  breadList: function breadList(state) {
    return state.user.breadList;
  },
  breadActiveNum: function breadActiveNum(state) {
    return state.user.breadActiveNum;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  }
};
export default getters;