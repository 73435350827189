var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            title: "查看导入结果",
            width: "70%",
            top: "2vh",
            "custom-class": "dialog_box",
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    "header-cell-style": { "text-align": "center" },
                    "cell-style": { "text-align": "center" },
                    data: _vm.normalList,
                    border: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "文件名", prop: "fileName" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "任务数量", prop: "total" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "成功数量", prop: "successNum" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "创建时间", prop: "createTime" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "处理结束时间", prop: "updateTime" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-top": "30px" } },
            [
              _c("h3", [_vm._v("导入失败商品信息")]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    "header-cell-style": { "text-align": "center" },
                    "cell-style": { "text-align": "center" },
                    data: _vm.failResult,
                    border: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "类目", prop: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.rowData.goodsCategory) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "商品ID", prop: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.rowData.goodsId) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "商品标题", prop: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.rowData.goodsTitle) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "处理时间", prop: "updateTime" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "失败原因", prop: "failContent" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }