//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HomeTrim from "./components/homeTrim.vue";
import BaseConfig from "./components/baseConfig.vue";
import SubcommissionAllocation from "./components/SubcommissionAllocation";
import UserCenter from "./components/userCenter.vue";
import HierarchicalAllocation from "./components/HierarchicalAllocation";
export default {
  name: '',
  components: {
    HomeTrim: HomeTrim,
    UserCenter: UserCenter,
    SubcommissionAllocation: SubcommissionAllocation,
    BaseConfig: BaseConfig,
    HierarchicalAllocation: HierarchicalAllocation
  },
  data: function data() {
    return {
      tabList: [{
        name: '首页装修',
        id: '0'
      }, {
        name: '个人中心装修',
        id: '0'
      }, {
        name: '基础设置',
        id: '0'
      }, {
        name: '等级配置',
        id: '0'
      }, {
        name: '区域分佣配置',
        id: '0'
      }],
      appId: 0,
      activeNum: 0
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    if (this.$route.query.id) {
      console.log(this.$route.query.id);
      this.appId = this.$route.query.id * 1;
    }
  },
  mounted: function mounted() {},
  methods: {
    updateValue: function updateValue(newValue) {
      console.log(newValue, 'heheheheh');
      this.appId = newValue;
    },
    changeType: function changeType(val) {
      this.activeNum = val;
    }
  }
};