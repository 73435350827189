var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "withdrawal_management" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "上架商品", name: "first" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "下架商品", name: "second" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "search_criteria",
          staticStyle: { "margin-top": "10px" },
        },
        [
          _c(
            "div",
            { staticStyle: { "margin-left": "0" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入商品名称查询", clearable: "" },
                model: {
                  value: _vm.queryData.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "title", $$v)
                  },
                  expression: "queryData.title",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "选择所属活动",
                  },
                  model: {
                    value: _vm.queryData.activityId,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryData, "activityId", $$v)
                    },
                    expression: "queryData.activityId",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.activityName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    clearable: "",
                    filterable: "",
                    placeholder: "选择销售状态",
                  },
                  model: {
                    value: _vm.queryData.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryData, "status", $$v)
                    },
                    expression: "queryData.status",
                  },
                },
                [
                  _c("el-option", { attrs: { value: 0, label: "预售" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { value: 1, label: "现售" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { value: 2, label: "售卖结束" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex",
              staticStyle: { width: "fit-content", height: "40px" },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { height: "40px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.onInquire },
                },
                [_vm._v("查询")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml10",
                  staticStyle: { height: "40px" },
                  attrs: { type: "info", plain: "" },
                  on: { click: _vm.onInitialize },
                },
                [_vm._v("重置")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: { click: _vm.onAdd },
                },
                [_vm._v("新增+")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "danger", plain: "" },
                  on: { click: _vm.onSynchronization },
                },
                [_vm._v("同步商品信息")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                "font-size": "14px",
                color: "#e6a23c",
                width: "300px",
                "line-height": "40px",
              },
            },
            [_vm._v("\n      注：商品过期超过3个月系统自动清理\n    ")]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", [
        _c(
          "div",
          { staticClass: "mt20" },
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: {
                  border: "",
                  data: _vm.tableData,
                  "tooltip-effect": "dark",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "activityName",
                    label: "所属活动",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "goodsId", label: "商品ID", align: "center" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "商品信息", align: "center", width: "500" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "flex mt10",
                              staticStyle: {
                                border: "1px solid #dcdfe6",
                                width: "fit-content",
                                "border-radius": "10px",
                                padding: "10px",
                                "line-height": "20px",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "fc",
                                  staticStyle: { position: "relative" },
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "40px",
                                      height: "40px",
                                    },
                                    attrs: {
                                      src: scope.row.goodsDetailVo.picUrl,
                                      alt: "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  scope.row.soldOutFlag === 1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "fc",
                                          staticStyle: {
                                            width: "40px",
                                            height: "40px",
                                            position: "absolute",
                                            top: "0",
                                            left: "0",
                                            background: "rgba(0, 0, 0, 0.5)",
                                            color: "#fff",
                                            "font-size": "12px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  已抢完\n                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "ml10" }, [
                                _c(
                                  "div",
                                  { staticClass: "ellipsis-multiline" },
                                  [
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            scope.row.goodsDetailVo
                                              .platformCode === "JD",
                                          expression:
                                            "scope.row.goodsDetailVo.platformCode === 'JD'",
                                        },
                                      ],
                                      staticClass: "icon_img",
                                      attrs: {
                                        alt: "",
                                        src: require("@/assets/platformIcon/jd.png"),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            scope.row.goodsDetailVo
                                              .platformCode === "PDD",
                                          expression:
                                            "scope.row.goodsDetailVo.platformCode === 'PDD'",
                                        },
                                      ],
                                      staticClass: "icon_img",
                                      attrs: {
                                        alt: "",
                                        src: require("@/assets/platformIcon/pdd.png"),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            scope.row.goodsDetailVo
                                              .platformCode === "WPH",
                                          expression:
                                            "scope.row.goodsDetailVo.platformCode === 'WPH'",
                                        },
                                      ],
                                      staticClass: "icon_img",
                                      attrs: {
                                        alt: "",
                                        src: require("@/assets/platformIcon/wph.png"),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            scope.row.goodsDetailVo
                                              .platformCode === "TB",
                                          expression:
                                            "scope.row.goodsDetailVo.platformCode === 'TB'",
                                        },
                                      ],
                                      staticClass: "icon_img",
                                      attrs: {
                                        alt: "",
                                        src: require("@/assets/platformIcon/tb.png"),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            scope.row.goodsDetailVo
                                              .platformCode === "DY",
                                          expression:
                                            "scope.row.goodsDetailVo.platformCode === 'DY'",
                                        },
                                      ],
                                      staticClass: "icon_img",
                                      attrs: {
                                        alt: "",
                                        src: require("@/assets/platformIcon/dy.png"),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            scope.row.goodsDetailVo
                                              .platformCode === "KS",
                                          expression:
                                            "scope.row.goodsDetailVo.platformCode === 'KS'",
                                        },
                                      ],
                                      staticClass: "icon_img",
                                      attrs: {
                                        alt: "",
                                        src: require("@/assets/platformIcon/ks.png"),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            scope.row.goodsDetailVo
                                              .platformCode === "ELM",
                                          expression:
                                            "scope.row.goodsDetailVo.platformCode === 'ELM'",
                                        },
                                      ],
                                      staticClass: "icon_img",
                                      attrs: {
                                        alt: "",
                                        src: require("@/assets/platformIcon/elm.png"),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            scope.row.goodsDetailVo
                                              .platformCode === "MT",
                                          expression:
                                            "scope.row.goodsDetailVo.platformCode === 'MT'",
                                        },
                                      ],
                                      staticClass: "icon_img",
                                      attrs: {
                                        alt: "",
                                        src: require("@/assets/platformIcon/mt.png"),
                                      },
                                    }),
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(scope.row.goodsDetailVo.title) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "flex" }, [
                                  _c("div", [
                                    _vm._v("\n                    原价:"),
                                    _c(
                                      "span",
                                      { staticClass: "original-price" },
                                      [
                                        _vm._v(
                                          "￥" +
                                            _vm._s(
                                              scope.row.goodsDetailVo.orgPrice
                                            )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "ml20" }, [
                                    _vm._v("\n                    券额:"),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [
                                        _vm._v(
                                          "￥" +
                                            _vm._s(
                                              scope.row.goodsDetailVo
                                                .couponAmount
                                            )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "ml20" }, [
                                    _vm._v("\n                    券后价:"),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [
                                        _vm._v(
                                          "￥" +
                                            _vm._s(
                                              scope.row.goodsDetailVo
                                                .actualPrice
                                            )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "ml20" }, [
                                    _vm._v("\n                    返:"),
                                    _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [
                                        _vm._v(
                                          "￥" +
                                            _vm._s(
                                              scope.row.goodsDetailVo.profit
                                            )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "goodsDetailVo.profit",
                    label: "佣金",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "subsidyAmt",
                    label: "补贴金额",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "goodsTotal",
                    label: "商品上架数",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "flashSaleTotal",
                    label: "已售商品数",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "buyNum", label: "限购次数", align: "center" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "status", label: "销售状态", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", [
                            Number(scope.row.status) === 0
                              ? _c("div", [_vm._v("预售")])
                              : _vm._e(),
                            _vm._v(" "),
                            Number(scope.row.status) === 1
                              ? _c("div", [_vm._v("现售")])
                              : _vm._e(),
                            _vm._v(" "),
                            Number(scope.row.status) === 2
                              ? _c("div", [_vm._v("售卖结束")])
                              : _vm._e(),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "subsidyStartTime",
                    label: "开始时间",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "subsidyEndTime",
                    label: "结束时间",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _vm.queryData.goodsStatus === 0
                  ? _c("el-table-column", {
                      attrs: {
                        prop: "remark",
                        label: "下架原因",
                        align: "center",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "remark",
                    label: "操作",
                    align: "center",
                    width: "300",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "fc" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onEditor(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _vm._v(" "),
                              _vm.activeName === "first"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "danger" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onProductOffShelf(
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("下架")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.activeName === "second"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "danger" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onDel(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.queryData.goodsStatus === 1
                                ? _c(
                                    "div",
                                    { staticStyle: { "margin-left": "10px" } },
                                    [
                                      scope.row.pinned === 1
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: { type: "success" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onUnTop(scope.row)
                                                },
                                              },
                                            },
                                            [_vm._v("取消置顶")]
                                          )
                                        : _c(
                                            "el-button",
                                            {
                                              attrs: { type: "success" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.oNPinned(scope.row)
                                                },
                                              },
                                            },
                                            [_vm._v("置顶")]
                                          ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt20 page_box" },
          [
            _c("el-pagination", {
              attrs: {
                "current-page": _vm.queryData.page.page,
                "page-sizes": [10, 20, 30, 40],
                "page-size": _vm.queryData.page.size,
                layout: "total, prev, pager, next, sizes,jumper",
                total: _vm.total,
                background: "",
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "商品" + (_vm.eventsType === "add" ? "添加" : "编辑"),
            visible: _vm.dialogVisible,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _c("div", { staticClass: "fc" }, [
            _c("div", { staticStyle: { width: "800px" } }, [
              _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      staticClass: "demo-ruleForm",
                      attrs: {
                        model: _vm.ruleForm,
                        rules: _vm.rules,
                        "label-width": "120px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "平台类型", prop: "platformCode" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.platformCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "platformCode", $$v)
                                },
                                expression: "ruleForm.platformCode",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                { attrs: { value: "TB", label: "TB" } },
                                [_vm._v("淘宝")]
                              ),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "JD" } }, [
                                _vm._v("京东"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: "PDD" } }, [
                                _vm._v("拼多多"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品ID", prop: "goodsId" } },
                        [
                          _c(
                            "div",
                            { staticClass: "flex" },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.ruleForm.goodsId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "goodsId", $$v)
                                  },
                                  expression: "ruleForm.goodsId",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "ml10",
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-search",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onShare(
                                        _vm.ruleForm.platformCode,
                                        _vm.ruleForm.goodsId
                                      )
                                    },
                                  },
                                },
                                [_vm._v("搜索")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.ProductDetails.id
                            ? _c(
                                "div",
                                {
                                  staticClass: "flex mt10",
                                  staticStyle: {
                                    border: "1px solid #dcdfe6",
                                    width: "fit-content",
                                    "border-radius": "10px",
                                    padding: "10px",
                                    "line-height": "20px",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "fc" }, [
                                    _c("img", {
                                      staticStyle: {
                                        width: "40px",
                                        height: "40px",
                                      },
                                      attrs: {
                                        src: _vm.ProductDetails.picUrl,
                                        alt: "",
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "ml10" }, [
                                    _c(
                                      "div",
                                      { staticClass: "ellipsis-multiline" },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.ProductDetails.title) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "flex" }, [
                                      _c("div", [
                                        _vm._v("\n                      原价:"),
                                        _c(
                                          "span",
                                          { staticClass: "original-price" },
                                          [
                                            _vm._v(
                                              "￥" +
                                                _vm._s(
                                                  _vm.ProductDetails.orgPrice
                                                )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "ml20" }, [
                                        _vm._v("\n                      券额:"),
                                        _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [
                                            _vm._v(
                                              "￥" +
                                                _vm._s(
                                                  _vm.ProductDetails
                                                    .couponAmount
                                                )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "ml20" }, [
                                        _vm._v(
                                          "\n                      券后价:"
                                        ),
                                        _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [
                                            _vm._v(
                                              "￥" +
                                                _vm._s(
                                                  _vm.ProductDetails.actualPrice
                                                )
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "ml20" }, [
                                        _vm._v("\n                      返:"),
                                        _c(
                                          "span",
                                          { staticStyle: { color: "red" } },
                                          [
                                            _vm._v(
                                              "￥" +
                                                _vm._s(
                                                  _vm.ProductDetails.profit
                                                )
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              "\n                注：确认商品，点击保存后，商品ID可能会替换为平台专属商品ID，ID样式可能会发生变化\n              "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属活动", prop: "activityId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                clearable: "",
                                filterable: "",
                                placeholder: "选择所属活动",
                              },
                              on: { change: _vm.selectChange },
                              model: {
                                value: _vm.ruleForm.activityId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "activityId", $$v)
                                },
                                expression: "ruleForm.activityId",
                              },
                            },
                            _vm._l(_vm.options, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.activityName,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "商品封面图", prop: "goodsMainPic" },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("upload", {
                                attrs: {
                                  name: "goodsMainPic",
                                  "img-url": _vm.ruleForm.goodsMainPic,
                                },
                                on: {
                                  pictureAddress: function ($event) {
                                    return _vm.onPictureAddress($event)
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticStyle: { color: "red" } }, [
                                _vm._v("建议尺寸702*305，PNG格式图片"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "商品副标题",
                            prop: "goodsSubheading",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.ruleForm.goodsSubheading,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "goodsSubheading", $$v)
                              },
                              expression: "ruleForm.goodsSubheading",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "单笔补贴金额", prop: "subsidyAmt" },
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.ruleForm.subsidyAmt,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "subsidyAmt", $$v)
                              },
                              expression: "ruleForm.subsidyAmt",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticStyle: { color: "red" } }, [
                            _vm._v(
                              "\n                注：设置的补贴金额，需 ≤\n                商品券后价减去基础返佣(即分享赚)的实付金额！\n              "
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品上架数", prop: "goodsTotal" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.ruleForm.goodsTotal,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "goodsTotal", $$v)
                              },
                              expression: "ruleForm.goodsTotal",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "每人限购次数", prop: "buyNum" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.ruleForm.buyNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "buyNum", $$v)
                              },
                              expression: "ruleForm.buyNum",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品上架时间", prop: "timeArray" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetimerange",
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "default-time": ["00:00:00", "23:59:59"],
                            },
                            model: {
                              value: _vm.ruleForm.timeArray,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "timeArray", $$v)
                              },
                              expression: "ruleForm.timeArray",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品状态", prop: "goodsStatus" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.goodsStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "goodsStatus", $$v)
                                },
                                expression: "ruleForm.goodsStatus",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("上架"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("下架"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm("ruleForm")
                                },
                              },
                            },
                            [_vm._v("确认")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.resetForm("ruleForm")
                                },
                              },
                            },
                            [_vm._v("重置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }