var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "search_criteria" }, [
        _c(
          "div",
          { staticClass: "flex", staticStyle: { width: "fit-content" } },
          [
            _c(
              "el-button",
              { attrs: { type: "info", plain: "" }, on: { click: _vm.onAdd } },
              [_vm._v("新增")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt10" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", "margin-bottom": "20px" },
              attrs: {
                data: _vm.tableData,
                "row-key": "id",
                border: "",
                "default-expand-all": "",
                "tree-props": {
                  children: "children",
                  hasChildren: "hasChildren",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "sort", label: "排序", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "categoryName", label: "名称", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "type",
                  label: "素材类型",
                  width: "180",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.type === 1
                          ? _c("div", [_vm._v("自定义素材")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.type === 2
                          ? _c("div", [_vm._v("官方素材")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "address", label: "状态", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            "active-value": 1,
                            "inactive-value": 0,
                            "active-color": "#02A7F0",
                            "inactive-color": "#B9B9B9",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onSwitchChange(scope.row, $event)
                            },
                          },
                          model: {
                            value: scope.row.enable,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "enable", $$v)
                            },
                            expression: "scope.row.enable",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "fc" }, [
                          _c(
                            "div",
                            {
                              staticClass: "Operation_button fc",
                              staticStyle: { color: "#83C1FF" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEdit(scope.row)
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: { width: "14px", height: "14px" },
                                attrs: {
                                  src: require("@/assets/LittleActivityCreation/editor.png"),
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", [_vm._v("编辑")]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "Operation_button ml10 fc",
                              staticStyle: {
                                border: "1px solid #E96E7E",
                                color: "#E96E7E",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(scope.row)
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: { width: "14px", height: "14px" },
                                attrs: {
                                  src: require("@/assets/LittleActivityCreation/delete.png"),
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", [_vm._v("删除")]),
                            ]
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("classificationPopup", {
        ref: "classificationPopup",
        on: { refresh: _vm.onRefresh },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }