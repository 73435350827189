var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "both-pd-box" }, [
    _c(
      "div",
      {
        staticStyle: {
          background: "#fff",
          padding: "30px",
          "border-radius": "20px",
        },
      },
      [
        _c("h3", [_vm._v("APP基础信息配置")]),
        _vm._v(" "),
        _c("el-divider"),
        _vm._v(" "),
        _c(
          "el-form",
          {
            ref: "ruleForm11",
            staticClass: "demo-ruleForm",
            staticStyle: { width: "60%" },
            attrs: {
              model: _vm.ruleForm,
              rules: _vm.rules,
              "label-width": "150px",
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "APP名称", prop: "" } },
              [
                _c("el-input", {
                  attrs: { readonly: true },
                  model: {
                    value: _vm.ruleForm.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "name", $$v)
                    },
                    expression: "ruleForm.name",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "space-between",
                },
              },
              [
                _c(
                  "el-form-item",
                  {
                    staticStyle: { width: "50%" },
                    attrs: { label: "APP主色", prop: "" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            readonly: true,
                            type: "text",
                            autocomplete: "off",
                          },
                          model: {
                            value: _vm.ruleForm.mainColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "mainColor", $$v)
                            },
                            expression: "ruleForm.mainColor",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-color-picker", {
                          model: {
                            value: _vm.ruleForm.mainColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "mainColor", $$v)
                            },
                            expression: "ruleForm.mainColor",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticStyle: { width: "50%" },
                    attrs: { label: "APP辅色", prop: "" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { readonly: true },
                          model: {
                            value: _vm.ruleForm.salveColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "salveColor", $$v)
                            },
                            expression: "ruleForm.salveColor",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-color-picker", {
                          model: {
                            value: _vm.ruleForm.salveColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "salveColor", $$v)
                            },
                            expression: "ruleForm.salveColor",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                staticStyle: { width: "50%" },
                attrs: { label: "APP图标", prop: "icon" },
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "148px",
                      height: "148px",
                      background: "#f9f9f9",
                      "border-radius": "4px",
                      border: "1px solid #eeeeee",
                    },
                  },
                  [
                    _c(
                      "el-upload",
                      {
                        staticClass: "avatar-uploader",
                        attrs: {
                          "show-file-list": false,
                          action: "",
                          "http-request": function (value) {
                            return _vm.handleFileUpload(value, "icon")
                          },
                        },
                      },
                      [
                        _vm.ruleForm.icon
                          ? _c("img", {
                              staticClass: "avatar_img",
                              attrs: { src: _vm.ruleForm.icon },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "el-icon-plus avatar-uploader-icon",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                staticStyle: { width: "100%" },
                attrs: { label: "APP引导图", prop: "guideMaps" },
              },
              [
                _c(
                  "el-upload",
                  {
                    attrs: {
                      limit: 3,
                      action: "",
                      "list-type": "picture-card",
                      "on-remove": function (value) {
                        return _vm.handleRemove(value, 1)
                      },
                      "show-file-list": _vm.ruleForm.guideMaps.length > 0,
                      "file-list": _vm.ruleForm.guideMaps,
                      "http-request": function (value) {
                        return _vm.handleFileUpload_guideMaps(value)
                      },
                    },
                  },
                  [_c("i", { staticClass: "el-icon-plus" })]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                staticStyle: { width: "50%" },
                attrs: { label: "APP预加载图", prop: "preloadedImage" },
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "148px",
                      height: "148px",
                      background: "#f9f9f9",
                      "border-radius": "4px",
                      border: "1px solid #eeeeee",
                    },
                  },
                  [
                    _c(
                      "el-upload",
                      {
                        staticClass: "avatar-uploader",
                        attrs: {
                          "show-file-list": false,
                          action: "",
                          "http-request": function (value) {
                            return _vm.handleFileUpload(value, "preloadedImage")
                          },
                        },
                      },
                      [
                        _vm.ruleForm.preloadedImage
                          ? _c("img", {
                              staticClass: "avatar_img",
                              attrs: { src: _vm.ruleForm.preloadedImage },
                            })
                          : _c("i", {
                              staticClass: "el-icon-plus avatar-uploader-icon",
                            }),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                staticStyle: { "margin-top": "30px" },
                attrs: { label: "用户协议", prop: "license" },
              },
              [
                _c("el-input", {
                  attrs: { clearable: "" },
                  model: {
                    value: _vm.ruleForm.license,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "license", $$v)
                    },
                    expression: "ruleForm.license",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                staticStyle: { "margin-top": "30px" },
                attrs: { label: "隐私政策", prop: "privacy" },
              },
              [
                _c("el-input", {
                  attrs: { clearable: "" },
                  model: {
                    value: _vm.ruleForm.privacy,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "privacy", $$v)
                    },
                    expression: "ruleForm.privacy",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                staticStyle: { "margin-top": "30px" },
                attrs: { label: "APP全局搜索框文字", prop: "searchText" },
              },
              [
                _c("el-input", {
                  attrs: { clearable: "" },
                  model: {
                    value: _vm.ruleForm.searchText,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "searchText", $$v)
                    },
                    expression: "ruleForm.searchText",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticStyle: { "margin-top": "60px" } },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { width: "200px" },
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.submitForm("ruleForm11")
                      },
                    },
                  },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }