import request from '@/utils/request';
export function getList(params) {
  return request({
    url: '/portal/system/tenant/listTenant',
    method: 'get',
    params: params
  });
}
export function changeStatus(data) {
  return request({
    url: '/portal/system/tenant/changeStatus',
    method: 'post',
    data: data
  });
}
export function saveConfig(data) {
  return request({
    url: '/portal/system/tenant/saveTenant',
    method: 'post',
    data: data
  });
}
export function updateConfig(data) {
  return request({
    url: '/portal/system/tenant/updateTenant',
    method: 'post',
    data: data
  });
}
export function getConfig(params) {
  return request({
    url: '/portal/system/tenant/getTenant',
    method: 'get',
    params: params
  });
}
export function saveSetting(data) {
  return request({
    url: '/portal/system/tenant/saveSetting',
    method: 'post',
    data: data
  });
}
export function getConfigList(data) {
  return request({
    url: '/portal/system/tenant/listSetting',
    method: 'post',
    data: data
  });
}
export function findAll(data) {
  return request({
    url: '/portal/system/banner/model/findAll',
    method: 'post',
    data: data
  });
}
export function initBannerModel(data) {
  return request({
    url: '/portal/system/banner/model/initBannerModel',
    method: 'post',
    data: data
  });
}
export function getLocations(params) {
  return request({
    url: '/portal/system/banner/model/getLocations',
    method: 'get',
    params: params
  });
}
export function saveLocation(data) {
  return request({
    url: '/portal/system/banner/model/saveLocation',
    method: 'post',
    data: data
  });
}
export function deleteLocation(data) {
  return request({
    url: '/portal/system/banner/model/deleteLocation',
    method: 'post',
    data: data
  });
}
export function updateLocation(data) {
  return request({
    url: '/portal/system/banner/model/updateLocation',
    method: 'post',
    data: data
  });
}
export function updateBannerModelStatus(data) {
  return request({
    url: '/portal/system/banner/model/updateBannerModelStatus',
    method: 'post',
    data: data
  });
}
export function headerMsg(data) {
  return request({
    url: '/portal/system/headline/list',
    method: 'post',
    data: data
  });
}
export function horseList(data) {
  return request({
    url: '/portal/system/marquee/list',
    method: 'post',
    data: data
  });
}