import request from '@/utils/request';
// 会员粉丝列表
export function memberQueryMemberListApi(data) {
  return request({
    url: '/portal/system/member/queryMemberList',
    method: 'post',
    data: data
  });
}
// 会员关系链
export function memberQueryRelationChainApi(data) {
  return request({
    url: '/portal/system/member/queryRelationChain',
    method: 'post',
    data: data
  });
}
// 会员概况
export function memberQueryMemberDetailApi(params) {
  return request({
    url: '/portal/system/member/queryMemberDetail',
    method: 'get',
    params: params
  });
}
// 会员等级列表查询
export function memberQueryMemberGradeApi(params) {
  return request({
    url: '/portal/system/member/queryMemberGrade',
    method: 'get',
    params: params
  });
}
// 修改自购等级或邀请码
export function memberUpdateMemberApi(data) {
  return request({
    url: '/portal/system/member/updateMember',
    method: 'post',
    data: data
  });
}
// 解除平台授权
export function memberUnbindPlatformAuthApi(data) {
  return request({
    url: '/portal/system/member/unbindPlatformAuth',
    method: 'post',
    data: data
  });
}
// ********************************************************************************************
// 查询区域列表
export function queryRegionListApi(data) {
  return request({
    url: '/portal/system/region/queryRegionList',
    method: 'post',
    data: data
  });
}
// 查询用户代理区域
export function queryMemberAgentRegionApi(data) {
  return request({
    url: '/portal/system/member/queryMemberAgentRegion',
    method: 'post',
    data: data
  });
}
// 修改用户代理区域
export function updateMemberRegionApi(data) {
  return request({
    url: '/portal/system/member/updateMemberRegion',
    method: 'post',
    data: data
  });
}

// ***********************************************************************************************
// 失效用户
export function memberDisableApi(data) {
  return request({
    url: '/portal/system/member/memberDisable',
    method: 'post',
    data: data
  });
}
// 修改用户状态[锁定、解锁用户]
export function updateMemberStatusApi(data) {
  return request({
    url: '/portal/system/member/updateAccountStatus',
    method: 'post',
    data: data
  });
}
// 加入、取消黑名单
export function addOrCancelBlacklistApi(data) {
  return request({
    url: '/portal/system/member/addOrCancelBlacklist',
    method: 'post',
    data: data
  });
}
// *************************************************************
// 下载批量设置会员等级excel模板
export function downloadMemberGradeTemplateApi(params) {
  return request({
    url: '/portal/system/file/downloadMemberGradeTemplate',
    method: 'get',
    params: params
  });
}
// 导入批量设置会员等级excel模板
export function importMemberGradeTemplate(data) {
  return request({
    url: '/portal/system/member/importMemberGradeTemplate',
    method: 'post',
    data: data
  });
}
// 设置是否为推广客
export function updateMemberPromotion(data) {
  return request({
    url: '/portal/system/member/updateMemberPromotion',
    method: 'post',
    data: data
  });
}