var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex frb" }, [
    _c("div", { staticClass: "activity" }, [
      _c(
        "div",
        { staticClass: "img", staticStyle: { "border-radius": "10px" } },
        [
          _vm.item.activityList[0].activityPic
            ? _c("img", {
                staticStyle: {
                  width: "290px",
                  height: "100px",
                  "border-radius": "10px",
                },
                attrs: { src: _vm.item.activityList[0].activityPic, alt: "" },
              })
            : _c("img", {
                staticStyle: {
                  width: "280px",
                  height: "100px",
                  "border-radius": "10px",
                },
                attrs: {
                  src: require("@/assets/LittleActivityCreation/acquiesce_icon.png"),
                  alt: "",
                },
              }),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "text mt10" }, [
        _c("div", [
          _c("div", { staticClass: "beyond_hiding title" }, [
            _vm._v(_vm._s(_vm.item.name)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "frb" },
            [_c("div"), _vm._v(" "), _vm._t("default")],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "preview fc",
          on: {
            click: function ($event) {
              return _vm.onPreview(_vm.item)
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "12px", height: "12px" },
            attrs: { src: require("@/assets/glasses.png"), alt: "" },
          }),
          _vm._v("\n      预览活动\n    "),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }