var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "allocation_box" }, [
    _c("div", { staticClass: "allocation_name" }, [_vm._v("等级配置")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "allocation_head", staticStyle: { "margin-top": "20px" } },
      [
        _c(
          "el-button",
          {
            staticStyle: { background: "#02c58d", border: "none" },
            attrs: { type: "primary", icon: "el-icon-plus" },
            on: { click: _vm.onAddAllocation },
          },
          [_vm._v("添加")]
        ),
        _vm._v(" "),
        _c("img", {
          staticStyle: { width: "16px", height: "16px", "margin-left": "10px" },
          attrs: { src: require("@/assets/icon/mark.png"), alt: "" },
        }),
        _vm._v(" "),
        _vm._m(0),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "my_table_name", staticStyle: { "margin-top": "20px" } },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.tableLoading,
                expression: "tableLoading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              "header-row-class-name": "tab_box",
              data: _vm.tableData,
              "row-key": "name",
              border: "",
              "highlight-current-row": true,
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "name",
                label: "等级名称",
                align: "center",
                height: "40",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "grade",
                label: "等级权重",
                align: "center",
                height: "40",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "icon",
                label: "等级图标",
                align: "center",
                height: "40",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("img", {
                        staticStyle: { width: "60px", height: "25px" },
                        attrs: { src: scope.row.icon, alt: "" },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "",
                label: "升级模式",
                align: "center",
                height: "40",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.upgradeModel === 1
                        ? _c("div", [_vm._v("条件升级")])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.upgradeModel === 0
                        ? _c("div", [_vm._v("注册即满足")])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "selfRate",
                label: "自购分佣",
                align: "center",
                height: "40",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "subRate",
                label: "平台补贴",
                align: "center",
                height: "40",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "directRate",
                label: "直属上级分佣",
                align: "center",
                height: "40",
              },
            }),
            _vm._v(" "),
            _c(
              "el-table-column",
              {
                attrs: {
                  prop: "",
                  label: "团队分佣配置1",
                  align: "center",
                  height: "40",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "teamRate",
                    label: "团队分佣",
                    align: "center",
                    height: "40",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "lateralRateList[0].rate",
                    label: "平1级奖励",
                    align: "center",
                    height: "40",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "lateralRateList[1].rate",
                    label: "平2级奖励",
                    align: "center",
                    height: "40",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "lateralRateList[2].rate",
                    label: "平3级奖励",
                    align: "center",
                    height: "40",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table-column",
              {
                attrs: {
                  prop: "",
                  label: "团队分佣配置2",
                  align: "center",
                  height: "40",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "teamPlusRate",
                    label: "团队分佣",
                    align: "center",
                    height: "40",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "lateralPlusRateList[0].rate",
                    label: "平1级奖励",
                    align: "center",
                    height: "40",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "lateralPlusRateList[1].rate",
                    label: "平2级奖励",
                    align: "center",
                    height: "40",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "lateralPlusRateList[2].rate",
                    label: "平3级奖励",
                    align: "center",
                    height: "40",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "createTime",
                label: "创建时间",
                align: "center",
                height: "40",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "status",
                label: "状态",
                align: "center",
                height: "40",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.status === 1
                        ? _c("div", [_vm._v("禁用")])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.status === 0
                        ? _c("div", [_vm._v("正常")])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "name",
                label: "操作",
                align: "center",
                width: "200",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _c("img", {
                          staticClass: "icon_box pointer handle",
                          attrs: {
                            src: require("@/assets/icon/mark1.png"),
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "icon_box ml20 pointer",
                          attrs: {
                            src: require("@/assets/personnelManage/editor.png"),
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onEditor(scope.row)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "icon_box ml20 pointer",
                          attrs: {
                            src: require("@/assets/personnelManage/delete.png"),
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onDelete(scope.row)
                            },
                          },
                        }),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "el-dialog",
          {
            attrs: {
              "close-on-click-modal": false,
              visible: _vm.dialogVisible,
              width: "1000px",
            },
            on: { close: _vm.close },
          },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-ruleForm",
                attrs: {
                  model: _vm.ruleForm,
                  "status-icon": "",
                  rules: _vm.rules,
                  "label-width": "150px",
                },
              },
              [
                _c("div", { staticClass: "title" }, [
                  _c("div", { staticClass: "bock_box" }, [
                    _c("div", { staticClass: "block" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [_vm._v("等级状态")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "fc ml20",
                      staticStyle: { "margin-top": "20px" },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { "label-width": "30", prop: "status" } },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 0 },
                              model: {
                                value: _vm.ruleForm.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "status", $$v)
                                },
                                expression: "ruleForm.status",
                              },
                            },
                            [_vm._v("启用")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              model: {
                                value: _vm.ruleForm.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "status", $$v)
                                },
                                expression: "ruleForm.status",
                              },
                            },
                            [_vm._v("禁用")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "title" }, [
                  _c("div", { staticClass: "bock_box" }, [
                    _c("div", { staticClass: "block" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [_vm._v("基础配置")]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "mt30",
                    attrs: { label: "等级名称：", prop: "name" },
                  },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.ruleForm.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "name", _vm._n($$v))
                        },
                        expression: "ruleForm.name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "mt30",
                    attrs: { label: "等级权重", prop: "grade" },
                  },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.ruleForm.grade,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "grade", _vm._n($$v))
                        },
                        expression: "ruleForm.grade",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticStyle: { color: "#f59a23" } }, [
                      _vm._v(
                        '\n            注：输入 "数字"\n            设置等级权重，数字越大，权重越高（已创建的等级权重无法重复创建）\n          '
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "mt30",
                    attrs: { label: "等级图标：", prop: "icon" },
                  },
                  [
                    _c("div", { staticClass: "upload_box flex" }, [
                      _c("div", { staticClass: "img" }, [
                        _c("div", [
                          _vm.ruleForm.icon
                            ? _c("img", {
                                staticStyle: { width: "100%", height: "38px" },
                                attrs: { src: _vm.ruleForm.icon, alt: "" },
                              })
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "fc",
                            staticStyle: { color: "#f59a23" },
                          },
                          [
                            _vm._v(
                              "\n                建议尺寸200×50,PNG格式\n              "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "upload_btn fc ml10" },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                "http-request": _vm.onRequest,
                                "show-file-list": false,
                                action: "",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "medium", type: "primary" } },
                                [_vm._v("点击上传")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "mt30",
                    attrs: { label: "等级权益图：", prop: "benefitImage" },
                  },
                  [
                    _c("div", { staticClass: "upload_box flex" }, [
                      _c(
                        "div",
                        {
                          staticClass: "img",
                          staticStyle: { width: "170px", height: "170px" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: { width: "170px", height: "120px" },
                            },
                            [
                              _vm.ruleForm.benefitImage
                                ? _c("img", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "120px",
                                    },
                                    attrs: {
                                      src: _vm.ruleForm.benefitImage,
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "fc",
                              staticStyle: { color: "#f59a23" },
                            },
                            [
                              _vm._v(
                                "\n                建议尺寸200×50,PNG格式\n              "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "upload_btn1 fc ml10" },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                "http-request": _vm.onRequest1,
                                "show-file-list": false,
                                action: "",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "medium", type: "primary" } },
                                [_vm._v("点击上传")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "title" }, [
                  _c("div", { staticClass: "bock_box" }, [
                    _c("div", { staticClass: "block" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v("\n              升级配置\n              "),
                      _c(
                        "span",
                        {
                          staticClass: "ml20",
                          staticStyle: {
                            "font-size": "14px",
                            color: "red",
                            "font-weight": "400",
                          },
                        },
                        [_vm._v("以下升级条件，设置为0则不启用")]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "mt30",
                    attrs: { label: "条件模式：", prop: "upgradeModel" },
                  },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: { disabled: _vm.ruleForm.grade === 1, label: 1 },
                        model: {
                          value: _vm.ruleForm.upgradeModel,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "upgradeModel", $$v)
                          },
                          expression: "ruleForm.upgradeModel",
                        },
                      },
                      [_vm._v("条件升级")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-radio",
                      {
                        attrs: { disabled: "", label: 0 },
                        model: {
                          value: _vm.ruleForm.upgradeModel,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "upgradeModel", $$v)
                          },
                          expression: "ruleForm.upgradeModel",
                        },
                      },
                      [_vm._v("注册即满足")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.ruleForm.upgradeModel === 1
                  ? _c(
                      "el-form-item",
                      {
                        staticClass: "mt30",
                        attrs: {
                          label: "升级条件说明文字：",
                          prop: "upgradeTips",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            placeholder:
                              " 请输入，C端升级中心-升级途径上的介绍说明文字",
                          },
                          model: {
                            value: _vm.ruleForm.upgradeTips,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "upgradeTips", $$v)
                            },
                            expression: "ruleForm.upgradeTips",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.ruleForm.upgradeModel === 1
                  ? _c(
                      "div",
                      _vm._l(_vm.ruleForm.conditionList, function (i, k) {
                        return _c(
                          "div",
                          { key: k },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { "label-width": "0px" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex" },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        attrs: {
                                          "true-label": 1,
                                          "false-label": 0,
                                        },
                                        model: {
                                          value: i.enable,
                                          callback: function ($$v) {
                                            _vm.$set(i, "enable", $$v)
                                          },
                                          expression: "i.enable",
                                        },
                                      },
                                      [_vm._v("升级途径" + _vm._s(k + 1) + ":")]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "fc" }, [
                                      _c(
                                        "div",
                                        { staticClass: "flex" },
                                        _vm._l(
                                          _vm.conditionsListData,
                                          function (item) {
                                            return _c(
                                              "div",
                                              {
                                                key: item.id,
                                                staticClass: "fc",
                                                staticStyle: {
                                                  height: "24px",
                                                  margin: "0px 2px",
                                                  padding: "0px 5px",
                                                  color: "#fff",
                                                  "font-size": "14px",
                                                  cursor: "pointer",
                                                  "border-radius": "4px",
                                                },
                                                style: {
                                                  background: item.color,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onAddConditions(
                                                      k,
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(item.name) +
                                                    "\n                    "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "ml20" },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              "label-width": "0px",
                                              prop:
                                                "conditionList." + k + ".model",
                                              rules: _vm.modelRules,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    条件模式:\n                    "
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-radio",
                                              {
                                                attrs: {
                                                  label: 0,
                                                  disabled:
                                                    _vm.conditionTrueIsNoList[
                                                      i.index
                                                    ],
                                                },
                                                model: {
                                                  value: i.model,
                                                  callback: function ($$v) {
                                                    _vm.$set(i, "model", $$v)
                                                  },
                                                  expression: "i.model",
                                                },
                                              },
                                              [_vm._v("同时满足")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-radio",
                                              {
                                                attrs: { label: 1 },
                                                model: {
                                                  value: i.model,
                                                  callback: function ($$v) {
                                                    _vm.$set(i, "model", $$v)
                                                  },
                                                  expression: "i.model",
                                                },
                                              },
                                              [_vm._v("任意条件")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    k === 0
                                      ? _c(
                                          "div",
                                          { staticClass: "ml30" },
                                          [
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  placement: "top",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { slot: "content" },
                                                    slot: "content",
                                                  },
                                                  [
                                                    _c("div", [
                                                      _vm._v(
                                                        "\n                        【任意满足】：只要会员满足所选途径内的其中一个条件就可以升级。\n                      "
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      _vm._v(
                                                        "\n                        【同时满足】：设置的升级条件必须同时满足才可以升级。如果设置的有充值和其他条件同时满足，会员必须先满足条件再去付费升级。\n                      "
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      _vm._v(
                                                        "\n                        注意：单一途径内设置超过两条充值条件时，无法设置为同时满足。\n                      "
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("img", {
                                                  staticStyle: {
                                                    width: "12px",
                                                    height: "12px",
                                                  },
                                                  attrs: {
                                                    src: require("@/assets/warning_reminder.png"),
                                                    alt: "",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "mt20" }, [
                                  _c(
                                    "div",
                                    { staticStyle: { width: "100%" } },
                                    _vm._l(i.termList, function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticStyle: { width: "100%" },
                                        },
                                        [
                                          _c("div", { staticClass: "flex" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mb10 scalation_Condition flex fc",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "fc checkbox",
                                                    staticStyle: {
                                                      width: "100px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          _vm.UnitType[
                                                            item.type
                                                          ].title
                                                        ) +
                                                        "\n                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                item.type !== 4
                                                  ? _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "180px",
                                                        },
                                                        attrs: {
                                                          "popper-class":
                                                            "select",
                                                          placeholder:
                                                            "请选择时间",
                                                          clearable: "",
                                                        },
                                                        model: {
                                                          value: item.time,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "time",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.time",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.selectData[
                                                          _vm.UnitType[
                                                            item.type
                                                          ].selectDataName
                                                        ].timeLimit,
                                                        function (i) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: i.key,
                                                              attrs: {
                                                                label: i.value,
                                                                value: i.key,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                item.type === 1
                                                  ? _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "180px",
                                                        },
                                                        attrs: {
                                                          "popper-class":
                                                            "select",
                                                          placeholder:
                                                            "选择用户关系",
                                                          clearable: "",
                                                        },
                                                        model: {
                                                          value: item.rela,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "rela",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.rela",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.selectData[
                                                          _vm.UnitType[
                                                            item.type
                                                          ].selectDataName
                                                        ].relationLimit,
                                                        function (i) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: i.key,
                                                              attrs: {
                                                                label: i.value,
                                                                value: i.key,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                item.type === 1
                                                  ? _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "180px",
                                                        },
                                                        attrs: {
                                                          "popper-class":
                                                            "select",
                                                          placeholder:
                                                            "选择用户类型",
                                                          clearable: "",
                                                        },
                                                        model: {
                                                          value: item.item,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "item",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.item",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.selectData[
                                                          _vm.UnitType[
                                                            item.type
                                                          ].selectDataName
                                                        ].itemLimit,
                                                        function (i) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: i.key,
                                                              attrs: {
                                                                label: i.value,
                                                                value: i.key,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                item.type === 2
                                                  ? _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "180px",
                                                        },
                                                        attrs: {
                                                          "popper-class":
                                                            "select",
                                                          placeholder:
                                                            "选择订单类型",
                                                          clearable: "",
                                                        },
                                                        model: {
                                                          value: item.item,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "item",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.item",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.selectData[
                                                          _vm.UnitType[
                                                            item.type
                                                          ].selectDataName
                                                        ].itemLimit,
                                                        function (i) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: i.key,
                                                              attrs: {
                                                                label: i.value,
                                                                value: i.key,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                item.type === 3
                                                  ? _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "180px",
                                                        },
                                                        attrs: {
                                                          "popper-class":
                                                            "select",
                                                          placeholder:
                                                            "选择收益归属",
                                                          clearable: "",
                                                        },
                                                        model: {
                                                          value: item.rela,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "rela",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.rela",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.selectData[
                                                          _vm.UnitType[
                                                            item.type
                                                          ].selectDataName
                                                        ].relationLimit,
                                                        function (i) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: i.key,
                                                              attrs: {
                                                                label: i.value,
                                                                value: i.key,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                item.type === 3
                                                  ? _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "180px",
                                                        },
                                                        attrs: {
                                                          "popper-class":
                                                            "select",
                                                          placeholder:
                                                            "选择收益类型",
                                                          clearable: "",
                                                        },
                                                        model: {
                                                          value: item.item,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "item",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.item",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.selectData[
                                                          _vm.UnitType[
                                                            item.type
                                                          ].selectDataName
                                                        ].itemLimit,
                                                        function (i) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: i.key,
                                                              attrs: {
                                                                label: i.value,
                                                                value: i.key,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                item.type === 4
                                                  ? _c(
                                                      "el-select",
                                                      {
                                                        staticStyle: {
                                                          width: "180px",
                                                        },
                                                        attrs: {
                                                          "popper-class":
                                                            "select",
                                                          placeholder:
                                                            "充值类型",
                                                          clearable: "",
                                                        },
                                                        model: {
                                                          value: item.item,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "item",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.item",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.selectData[
                                                          _vm.UnitType[
                                                            item.type
                                                          ].selectDataName
                                                        ].itemLimit,
                                                        function (i) {
                                                          return _c(
                                                            "el-option",
                                                            {
                                                              key: i.key,
                                                              attrs: {
                                                                label: i.value,
                                                                value: i.key,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "el-input",
                                                  {
                                                    staticStyle: {
                                                      height: "38px",
                                                    },
                                                    style: {
                                                      width:
                                                        _vm.UnitType[item.type]
                                                          .dimension,
                                                    },
                                                    attrs: {
                                                      max: 100,
                                                      min: 0,
                                                      type: "number",
                                                      placeholder: "请输入内容",
                                                    },
                                                    model: {
                                                      value: item.value,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "value",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.value",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      {
                                                        staticStyle: {
                                                          height: "38px",
                                                          background: "#cacaca",
                                                        },
                                                        slot: "append",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.UnitType[
                                                              item.type
                                                            ].unit
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("div", { staticClass: "fc" }, [
                                              _c("img", {
                                                staticClass:
                                                  "icon_box ml20 pointer",
                                                attrs: {
                                                  src: require("@/assets/personnelManage/delete.png"),
                                                  alt: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onDeleteConditions(
                                                      k,
                                                      index
                                                    )
                                                  },
                                                },
                                              }),
                                            ]),
                                          ]),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "title" }, [
                  _c("div", { staticClass: "bock_box" }, [
                    _c("div", { staticClass: "block" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v("\n              自购分佣配置\n              "),
                      _c(
                        "span",
                        {
                          staticClass: "ml20",
                          staticStyle: {
                            "font-size": "14px",
                            color: "#969696",
                            "font-weight": "400",
                          },
                        },
                        [_vm._v("以下升级条件，设置为0则不启用")]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt30", attrs: { label: "自购分佣：" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: { placeholder: "请输入内容" },
                        model: {
                          value: _vm.ruleForm.selfRate,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "selfRate", $$v)
                          },
                          expression: "ruleForm.selfRate",
                        },
                      },
                      [_c("template", { slot: "append" }, [_vm._v("%")])],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "acquiesce_tips" }, [
                      _vm._v(
                        "\n            自购/分享佣金是，为联盟扣除服务费后的佣金百分比\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isExceed,
                            expression: "isExceed",
                          },
                        ],
                        staticClass: "title_tips",
                      },
                      [
                        _vm._v(
                          "\n            佣金百分比不能超过100\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "title" }, [
                  _c("div", { staticClass: "bock_box" }, [
                    _c("div", { staticClass: "block" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v("\n              平台补贴配置\n              "),
                      _c(
                        "span",
                        {
                          staticClass: "ml20",
                          staticStyle: {
                            "font-size": "14px",
                            color: "#969696",
                            "font-weight": "400",
                          },
                        },
                        [_vm._v("以下升级条件，设置为0则不启用")]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt30", attrs: { label: "平台补贴:" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: { placeholder: "请输入内容" },
                        model: {
                          value: _vm.ruleForm.subRate,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "subRate", $$v)
                          },
                          expression: "ruleForm.subRate",
                        },
                      },
                      [_c("template", { slot: "append" }, [_vm._v("%")])],
                      2
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "title" }, [
                  _c("div", { staticClass: "bock_box" }, [
                    _c("div", { staticClass: "block" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        "\n              直属上级分佣配置\n              "
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "ml20",
                          staticStyle: {
                            "font-size": "14px",
                            color: "#969696",
                            "font-weight": "400",
                          },
                        },
                        [_vm._v("以下升级条件，设置为0则不启用")]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt30", attrs: { label: "直属上级分佣" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: { placeholder: "请输入内容" },
                        model: {
                          value: _vm.ruleForm.directRate,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "directRate", $$v)
                          },
                          expression: "ruleForm.directRate",
                        },
                      },
                      [_c("template", { slot: "append" }, [_vm._v("%")])],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "acquiesce_tips" }, [
                      _vm._v("配置直属上级的佣金分佣比例"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "title" }, [
                  _c("div", { staticClass: "bock_box" }, [
                    _c("div", { staticClass: "block" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v("\n              团队分佣配置1\n              "),
                      _c(
                        "span",
                        {
                          staticClass: "ml20",
                          staticStyle: {
                            "font-size": "14px",
                            color: "#969696",
                            "font-weight": "400",
                          },
                        },
                        [_vm._v("以下升级条件，设置为0则不启用")]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt30" }, [
                  _vm._v("平级奖模式，上一等级运营商团队分佣里扣除"),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "团队分佣：" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: { placeholder: "请输入内容" },
                        model: {
                          value: _vm.ruleForm.teamRate,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "teamRate", $$v)
                          },
                          expression: "ruleForm.teamRate",
                        },
                      },
                      [_c("template", { slot: "append" }, [_vm._v("%")])],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "acquiesce_tips" }, [
                      _vm._v(
                        "\n            团队分佣比，为联盟扣除服务费后的佣金，再减去自购/分享佣金部分的百分比\n          "
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._l(_vm.ruleForm.lateralRateList, function (i, k) {
                  return _c(
                    "el-form-item",
                    {
                      key: i.id,
                      attrs: { label: "平" + i.order + "级奖励：" },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: "" },
                          model: {
                            value: i.rate,
                            callback: function ($$v) {
                              _vm.$set(i, "rate", $$v)
                            },
                            expression: "i.rate",
                          },
                        },
                        [_c("template", { slot: "append" }, [_vm._v("%")])],
                        2
                      ),
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _c("div", { staticClass: "title" }, [
                  _c("div", { staticClass: "bock_box" }, [
                    _c("div", { staticClass: "block" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v("\n              团队分佣配置2\n              "),
                      _c(
                        "span",
                        {
                          staticClass: "ml20",
                          staticStyle: {
                            "font-size": "14px",
                            color: "#969696",
                            "font-weight": "400",
                          },
                        },
                        [_vm._v("以下升级条件，设置为0则不启用")]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "mt30" }, [
                  _vm._v("平级奖模式，上一等级运营商团队分佣里扣除"),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt20", attrs: { label: "团队分佣：" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: { placeholder: "请输入内容" },
                        model: {
                          value: _vm.ruleForm.teamPlusRate,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "teamPlusRate", $$v)
                          },
                          expression: "ruleForm.teamPlusRate",
                        },
                      },
                      [_c("template", { slot: "append" }, [_vm._v("%")])],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "acquiesce_tips" }, [
                      _vm._v(
                        "\n            团队分佣比，为联盟扣除服务费后的佣金，再减去自购/分享佣金部分的百分比\n          "
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._l(_vm.ruleForm.lateralPlusRateList, function (i, k) {
                  return _c(
                    "el-form-item",
                    {
                      key: i.id,
                      attrs: { label: "平" + i.order + "级奖励：" },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: "" },
                          model: {
                            value: i.rate,
                            callback: function ($$v) {
                              _vm.$set(i, "rate", $$v)
                            },
                            expression: "i.rate",
                          },
                        },
                        [_c("template", { slot: "append" }, [_vm._v("%")])],
                        2
                      ),
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _c("div", { staticClass: "title" }, [
                  _c("div", { staticClass: "bock_box" }, [
                    _c("div", { staticClass: "block" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        "\n              团队分佣配置选择\n              "
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "ml20",
                          staticStyle: {
                            "font-size": "14px",
                            color: "#969696",
                            "font-weight": "400",
                          },
                        },
                        [_vm._v("当前等级用户自购时，走的团队分佣配置")]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt30", attrs: { label: "选择分佣模式：" } },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: 0 },
                        model: {
                          value: _vm.ruleForm.plusModel,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "plusModel", $$v)
                          },
                          expression: "ruleForm.plusModel",
                        },
                      },
                      [_vm._v("团队分佣配置1")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: 1 },
                        model: {
                          value: _vm.ruleForm.plusModel,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "plusModel", $$v)
                          },
                          expression: "ruleForm.plusModel",
                        },
                      },
                      [_vm._v("团队分佣配置2")]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "fc" },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: {
                      width: "172px",
                      height: "52px",
                      background: "#4d8aff",
                      "border-radius": "4px",
                    },
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.submitForm("ruleForm")
                      },
                    },
                  },
                  [_vm._v("确认")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: { visible: _vm.delDialogVisible, width: "400px" },
            on: {
              "update:visible": function ($event) {
                _vm.delDialogVisible = $event
              },
              close: _vm.delClose,
            },
          },
          [
            _c("div", [_vm._v("会员等级状态是启用情况下，不可直接删除")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "fc mt30" },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: {
                      width: "172px",
                      height: "52px",
                      background: "#4d8aff",
                      "border-radius": "4px",
                    },
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.delDialogVisible = false
                      },
                    },
                  },
                  [_vm._v("确认")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("span", [
        _vm._v(
          "温馨提示：只可以删除禁用状态且无会员的等级；相同权重等级之间仅支持一个处于启用状态。"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }