"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.filterAsyncRoutes = filterAsyncRoutes;
exports.filterAsyncRoutes1 = filterAsyncRoutes1;
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.function.name");
var _objectSpread2 = _interopRequireDefault(require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _router = require("@/router");
/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {
      return route.meta.roles.includes(role);
    });
  } else {
    return true;
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
// roles
function filterAsyncRoutes(routes, roles) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = (0, _objectSpread2.default)({}, route);
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles).filter(function (i) {
          if (i.meta.roles) {
            return i.meta.roles.includes(roles[0]);
          }
        });
      }
      res.push(tmp);
    }
  });
  return res;
}
/**
 *
 * @param {*} routes 本地路由数组
 * @param {*} treeRouters 接口返回数组
 * @returns 符合条件的数组
 */
function filterAsyncRoutes1() {
  var routes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var treeRouters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  // console.log('routes', routes, treeRouters)
  var realRouters = [];
  // routes.forEach((v, i) => {
  //   treeRouters.forEach((item, index) => {
  //     if (item.name === v.name) {
  //       v.id = item.id
  //       v.sort = item.sort
  //       if (item.children && item.children.length > 0) {
  //         v.children = filterAsyncRoutes1(v.children, item.children)
  //       }
  //       realRouters.push(v)
  //     }
  //   })
  // })
  routes.forEach(function (v, i) {
    treeRouters.forEach(function (item, index) {
      if (item.name === v.name) {
        v.id = item.id;
        v.sort = item.sort;
        v.meta.title = item.title;
        if (item.children && item.children.length > 0) {
          v.children = filterAsyncRoutes1(v.children, item.children);
        } else {
          v.children = [];
        }
        realRouters.push(v);
      }
    });
  });
  realRouters.push({
    path: '*',
    id: 999999,
    meta: {
      title: '404'
    },
    redirect: '/404',
    hidden: true
  });
  // return realRouters
  return sortRoutesRecursively(realRouters);
}
function sortRoutes(routes) {
  return routes.sort(function (a, b) {
    return a.sort - b.sort;
  });
}
/**
 * 排序
 * @param {*} routes 数组
 * @returns 按照sort排序过后的数字
 */
function sortRoutesRecursively(routes) {
  routes = sortRoutes(routes);
  routes.forEach(function (route) {
    if (route.children && route.children.length > 0) {
      route.children = sortRoutesRecursively(route.children);
    }
  });
  return routes;
}
var state = {
  routes: [],
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = _router.constantRoutes.concat(routes);
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, roles) {
    var commit = _ref.commit,
      rootState = _ref.rootState;
    return new Promise(function (resolve) {
      // const accessedRoutes = asyncRoutes // 这里先把全部页面展示出来，后续添加权限得时候在做逻辑处理(这一句的意思是拥有全部菜单权限)

      // if (roles && roles.includes('superAdmin')) {
      //   accessedRoutes = asyncRoutes || []
      // } else {
      var accessedRoutes = filterAsyncRoutes1(_router.asyncRoutes, rootState.user.treeRouters); // 这里是根据后台返回的数据进行过滤的
      // const accessedRoutes1 = filterAsyncRoutes1(asyncRoutes, rootState.user.treeRouters)
      // console.log('accessedRoutes1', accessedRoutes1)

      // accessedRoutes = filterAsyncRoutes(asyncRoutes, roles) // roles判断是否有权限
      // }
      // console.log('accessedRoutes***********', accessedRoutes)

      accessedRoutes.map(function (i) {
        if (i.path !== '*' && i.children.length === 0) {
          i['hidden'] = true;
        }
      });
      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};