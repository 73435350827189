"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/fe-ug-comprehensive-shopping-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/.jenkins/workspace/fe-ug-comprehensive-shopping-admin-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
var _activePop = _interopRequireDefault(require("./components/activePop.vue"));
var _categoryPop = _interopRequireDefault(require("./components/categoryPop.vue"));
var _resultPop = _interopRequireDefault(require("./components/resultPop.vue"));
var _goodsPop = _interopRequireDefault(require("./components/goodsPop.vue"));
var _goodsListActive = require("@/api/operationManagement/goodsListActive");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'GoodsListActive',
  components: {
    activePop: _activePop.default,
    resultPop: _resultPop.default,
    categoryPop: _categoryPop.default,
    goodsPop: _goodsPop.default
  },
  data: function data() {
    return {
      listLoading: false,
      tableData: [],
      page: 1,
      limit: 10,
      total: 0,
      name: '',
      colorObj: {
        '0': '#F8AE13',
        '1': '#52C41A',
        '2': '#BBBBBB'
      },
      textObj: {
        '0': '未开始',
        '1': '进行中',
        '2': '已结束'
      },
      status: '',
      statusOptions: [{
        value: '0',
        label: '未开始'
      }, {
        value: '1',
        label: '进行中'
      }, {
        value: '2',
        label: '已结束'
      }],
      type: '',
      typeOptions: [{
        value: 'TB',
        label: '淘宝'
      }, {
        value: 'JD',
        label: '京东'
      }, {
        value: 'PDD',
        label: '拼多多'
      }, {
        value: 'DY',
        label: '抖音'
      }, {
        value: 'WPH',
        label: '唯品会'
      }]
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    copy: function copy(val) {
      var _this = this;
      this.$copyText(val).then(function () {
        _this.$notify.success({
          title: '温馨提示',
          message: '拷贝成功'
        });
      }).catch(function () {
        _this.$notify.success({
          title: '温馨提示',
          message: '拷贝失败，请手动复制'
        });
      });
    },
    goods: function goods(val) {
      this.$refs.goodsPop.open(val);
    },
    handActivePop: function handActivePop(val) {
      if (val === '1') {
        this.page = 1;
      }
      this.getList();
    },
    look: function look(val) {
      var _this2 = this;
      console.log(val);
      (0, _goodsListActive.getImportResult)({
        taskId: val.taskId
      }).then(function (res) {
        if (res.data) {
          var obj = {
            normalList: [{
              fileName: res.data.fileName,
              total: res.data.total,
              successNum: res.data.successNum,
              createTime: res.data.createTime,
              updateTime: res.data.updateTime
            }],
            failResult: res.data.failResult === null ? [] : res.data.failResult
          };
          _this2.$refs.resultPop.open(obj);
        }
      });
    },
    beforeHandleCommand: function beforeHandleCommand(command, item) {
      return {
        'command': command,
        'item': item
      };
    },
    handleCommand: function handleCommand(command) {
      var _this3 = this;
      if (command.command === '2') {
        this.$confirm('确认删除该活动？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          (0, _goodsListActive.delAct)({
            id: command.item.id
          }).then(function (res) {
            if (res.data) {
              _this3.$message({
                type: 'success',
                message: '操作成功'
              });
              _this3.getList();
            }
          });
        }).catch(function () {
          _this3.$message({
            type: 'info',
            message: '已取消'
          });
        });
      } else if (command.command === '1') {
        this.$refs.categoryPop.open({
          id: command.item.id,
          platformCode: command.item.platformCode
        });
      }
    },
    getList: function getList() {
      var _this4 = this;
      this.listLoading = true;
      (0, _goodsListActive.queryActList)({
        platformCode: this.type,
        activityName: this.name,
        pageNo: this.page,
        limit: this.limit,
        status: this.status
      }).then(function (res) {
        _this4.total = res.data.total;
        _this4.tableData = res.data.data;
        _this4.listLoading = false;
      }).catch(function () {
        _this4.listLoading = false;
      });
    },
    // 分页
    handleSizeChange: function handleSizeChange(val) {
      this.limit = val;
      this.getList();
    },
    // 当前分页
    handleCurrentChange: function handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    search: function search() {
      this.page = 1;
      this.getList();
    },
    rest: function rest() {
      this.name = '';
      this.status = '';
      this.type = '';
      this.page = 1;
      this.getList();
    },
    onAdd: function onAdd() {
      // openType: 1-->新增；2--->编辑
      this.$refs.activePop.open({
        openType: '1'
      });
    },
    detail: function detail(val) {
      console.log(val);
      this.$refs.activePop.open((0, _objectSpread2.default)((0, _objectSpread2.default)({}, val), {}, {
        openType: '2'
      }));
    }
  }
};