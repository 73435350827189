var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "box" }, [
        _c(
          "div",
          { staticClass: "left-box" },
          [
            _c("MobileContent", {
              attrs: { type: 2, "all-center-config": _vm.allCenterConfig },
              on: { childData: _vm.handleChildData },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "right-box" }, [
          _c("div", { staticClass: "right-top-box" }, [
            _vm.activeNum === 0
              ? _c("div", { staticClass: "title" }, [_vm._v("个人中心模块")])
              : _c("div", { staticClass: "title" }, [
                  _vm._v(
                    _vm._s(
                      _vm.baseData.filter(function (i) {
                        return i.key === _vm.activeNum
                      })[0].text
                    )
                  ),
                ]),
            _vm._v(" "),
            _vm.activeNum != 0
              ? _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-right": "15px" },
                        attrs: { plain: "" },
                        on: {
                          click: function ($event) {
                            _vm.activeNum = 0
                          },
                        },
                      },
                      [_vm._v("返回")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.save()
                          },
                        },
                      },
                      [_vm._v("保存")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeNum === 0,
                  expression: "activeNum===0",
                },
              ],
              staticStyle: { "margin-top": "20px" },
            },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    "header-cell-style": { "text-align": "center" },
                    "cell-style": { "text-align": "center" },
                    data: _vm.baseData.filter(function (i) {
                      return i.key !== 4 && i.key !== 8
                    }),
                    border: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { width: "80", type: "index", label: "序号" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { width: "200", prop: "text", label: "名称" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { width: "200", prop: "", label: "上下线状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-switch", {
                              attrs: { "active-value": 1, "inactive-value": 0 },
                              on: {
                                change: function ($event) {
                                  return _vm.changeStatus(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.isShow,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "isShow", $$v)
                                },
                                expression: "scope.row.isShow",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "190", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "small",
                                  type: "primary",
                                  icon: "el-icon-edit",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.setting(scope.row)
                                  },
                                },
                              },
                              [_vm._v("管理")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.activeNum === 1
            ? _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        "header-cell-style": { "text-align": "center" },
                        "cell-style": { "text-align": "center" },
                        data: _vm.baseData.filter(function (i) {
                          return i.key === _vm.activeNum
                        })[0].tableData,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { width: "60", type: "index", label: "序号" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "150", prop: "name", label: "名称" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "150", prop: "", label: "图片" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  !(
                                    scope.row.type === "textColor" ||
                                    scope.row.type === "bgColor"
                                  )
                                    ? _c(
                                        "div",
                                        [
                                          scope.row.url
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    position: "relative",
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticStyle: {
                                                      width: "48px",
                                                      height: "48px",
                                                    },
                                                    attrs: {
                                                      src: scope.row.url,
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-delete",
                                                    staticStyle: {
                                                      position: "absolute",
                                                      right: "0",
                                                      top: "0",
                                                      cursor: "pointer",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.del(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _c(
                                                "el-upload",
                                                {
                                                  ref: "upload",
                                                  staticClass: "upload-demo",
                                                  attrs: {
                                                    action: "",
                                                    limit: 1,
                                                    "http-request": function (
                                                      value
                                                    ) {
                                                      return _vm.handleFileUpload(
                                                        value,
                                                        scope.row
                                                      )
                                                    },
                                                    "show-file-list": false,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "up",
                                                      attrs: {
                                                        slot: "trigger",
                                                      },
                                                      slot: "trigger",
                                                    },
                                                    [_vm._v("+")]
                                                  ),
                                                ]
                                              ),
                                        ],
                                        1
                                      )
                                    : _c("span", [_vm._v("-")]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2434201919
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "150", prop: "", label: "尺寸说明" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        scope.row.size ? scope.row.size : "-"
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2641519317
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "150", prop: "", label: "色值" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.type === "textColor"
                                    ? _c("el-color-picker", {
                                        model: {
                                          value: scope.row.color,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "color", $$v)
                                          },
                                          expression: "scope.row.color",
                                        },
                                      })
                                    : _c("span", [_vm._v("-")]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1624472715
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.activeNum === 2
            ? _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        "header-cell-style": { "text-align": "center" },
                        "cell-style": { "text-align": "center" },
                        data: _vm.baseData.filter(function (i) {
                          return i.key === _vm.activeNum
                        })[0].tableData,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { width: "60", type: "index", label: "序号" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "250", prop: "name", label: "名称" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.text === "firstText" ||
                                  scope.row.text === "secondText"
                                    ? _c("el-input", {
                                        attrs: {
                                          placeholder:
                                            scope.row.text === "firstText"
                                              ? "主标题"
                                              : "副标题",
                                        },
                                        model: {
                                          value: scope.row.name,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "name", $$v)
                                          },
                                          expression: "scope.row.name",
                                        },
                                      })
                                    : _c("span", [
                                        _vm._v(_vm._s(scope.row.name)),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4290422118
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "150", prop: "", label: "图片" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.type === "leftLogo" ||
                                  scope.row.type === "bgUrl"
                                    ? _c(
                                        "div",
                                        [
                                          scope.row.url
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    position: "relative",
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticStyle: {
                                                      width: "48px",
                                                      height: "48px",
                                                    },
                                                    attrs: {
                                                      src: scope.row.url,
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-delete",
                                                    staticStyle: {
                                                      position: "absolute",
                                                      right: "0",
                                                      top: "0",
                                                      cursor: "pointer",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.del(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _c(
                                                "el-upload",
                                                {
                                                  ref: "upload",
                                                  staticClass: "upload-demo",
                                                  attrs: {
                                                    action: "",
                                                    limit: 1,
                                                    "http-request": function (
                                                      value
                                                    ) {
                                                      return _vm.handleFileUpload(
                                                        value,
                                                        scope.row
                                                      )
                                                    },
                                                    "show-file-list": false,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "up",
                                                      attrs: {
                                                        slot: "trigger",
                                                      },
                                                      slot: "trigger",
                                                    },
                                                    [_vm._v("+")]
                                                  ),
                                                ]
                                              ),
                                        ],
                                        1
                                      )
                                    : _c("span", [_vm._v("-")]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          432501113
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "150", prop: "", label: "尺寸说明" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        scope.row.size ? scope.row.size : "-"
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2641519317
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "150", prop: "", label: "色值" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.type !== "leftLogo" &&
                                  scope.row.type !== "bgUrl"
                                    ? _c("el-color-picker", {
                                        model: {
                                          value: scope.row.color,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "color", $$v)
                                          },
                                          expression: "scope.row.color",
                                        },
                                      })
                                    : _c("span", [_vm._v("-")]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          508360336
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.activeNum === 3
            ? _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        "header-cell-style": { "text-align": "center" },
                        "cell-style": { "text-align": "center" },
                        data: _vm.baseData.filter(function (i) {
                          return i.key === _vm.activeNum
                        })[0].tableData,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { width: "60", type: "index", label: "序号" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "250", prop: "name", label: "名称" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.type === "explain"
                                    ? _c("el-input", {
                                        attrs: { placeholder: "请输入内容" },
                                        model: {
                                          value: scope.row.name,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "name", $$v)
                                          },
                                          expression: "scope.row.name",
                                        },
                                      })
                                    : _c("span", [
                                        _vm._v(_vm._s(scope.row.name)),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1680560677
                        ),
                      }),
                      _vm._v(" "),
                      _c(
                        "el-table-column",
                        { attrs: { width: "150", prop: "", label: "图片" } },
                        [[_c("span", [_vm._v("-")])]],
                        2
                      ),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "150", prop: "", label: "尺寸说明" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        scope.row.size ? scope.row.size : "-"
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2641519317
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "150", prop: "", label: "色值" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-color-picker", {
                                    model: {
                                      value: scope.row.color,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "color", $$v)
                                      },
                                      expression: "scope.row.color",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1118686548
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.activeNum === 6
            ? _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        "header-cell-style": { "text-align": "center" },
                        "cell-style": { "text-align": "center" },
                        data: _vm.baseData.filter(function (i) {
                          return i.key === _vm.activeNum
                        })[0].tableData,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { width: "60", type: "index", label: "序号" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "250", prop: "name", label: "名称" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [_vm._v(_vm._s(scope.row.name))]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2020036417
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "150", prop: "", label: "图片" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.url
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: { position: "relative" },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              width: "48px",
                                              height: "48px",
                                            },
                                            attrs: { src: scope.row.url },
                                          }),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass: "el-icon-delete",
                                            staticStyle: {
                                              position: "absolute",
                                              right: "0",
                                              top: "0",
                                              cursor: "pointer",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.del(scope.row)
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    : _c(
                                        "el-upload",
                                        {
                                          ref: "upload",
                                          staticClass: "upload-demo",
                                          attrs: {
                                            action: "",
                                            limit: 1,
                                            "http-request": function (value) {
                                              return _vm.handleFileUpload(
                                                value,
                                                scope.row
                                              )
                                            },
                                            "show-file-list": false,
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "up",
                                              attrs: { slot: "trigger" },
                                              slot: "trigger",
                                            },
                                            [_vm._v("+")]
                                          ),
                                        ]
                                      ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          199244684
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "150", prop: "", label: "尺寸说明" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        scope.row.size ? scope.row.size : "-"
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2641519317
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("ModulePopup", { ref: "ModulePopup" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }