"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.delAct = delAct;
exports.listPageApi = listPageApi;
exports.savePageApi = savePageApi;
exports.updatePageApi = updatePageApi;
var _request = _interopRequireDefault(require("@/utils/request"));
// 通用H5界面

// 列表
function listPageApi(data) {
  return (0, _request.default)({
    url: '/portal/system/thirdParty/list',
    method: 'post',
    data: data
  });
}
// 添加页面
function savePageApi(data) {
  return (0, _request.default)({
    url: '/portal/system/thirdParty/save',
    method: 'post',
    data: data
  });
}
// 编辑
function updatePageApi(data) {
  return (0, _request.default)({
    url: '/portal/system/thirdParty/update',
    method: 'post',
    data: data
  });
}
function delAct(data) {
  return (0, _request.default)({
    url: '/portal/system/thirdParty/delete',
    method: 'post',
    data: data
  });
}