var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            title: "违规订单管理",
            width: "1400px",
            top: "10vh",
            "custom-class": "dialog_box",
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName1,
                callback: function ($$v) {
                  _vm.activeName1 = $$v
                },
                expression: "activeName1",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "导入违规订单", name: "first" } },
                [
                  _c("div", {}, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-form",
                          {
                            ref: "ruleForm",
                            staticClass: "demo-ruleForm",
                            attrs: {
                              model: _vm.ruleForm,
                              rules: _vm.rules,
                              "label-width": "100px",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "平台类型",
                                  prop: "platformCode",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择平台类型" },
                                    model: {
                                      value: _vm.ruleForm.platformCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "platformCode",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.platformCode",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "淘宝", value: "TB" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "处理类型",
                                  prop: "handleType",
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    on: { input: _vm.changeHandleType },
                                    model: {
                                      value: _vm.ruleForm.handleType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "handleType",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.handleType",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("违规订单(订单号)"),
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: 2 } }, [
                                      _vm._v("渠道单(渠道ID)"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.ruleForm.handleType === 2
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "时间类型",
                                      prop: "dateType",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { height: "20px" } },
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            model: {
                                              value: _vm.ruleForm.dateType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.ruleForm,
                                                  "dateType",
                                                  $$v
                                                )
                                              },
                                              expression: "ruleForm.dateType",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 1 } },
                                              [_vm._v("收货时间")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "el-radio",
                                              { attrs: { label: 2 } },
                                              [_vm._v("付款时间")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "text1" }, [
                                      _vm._v(
                                        "\n                  注:联盟处罚时间规则为订单收货时间;\n                  选择收货时间时，无收货时间的订单(付款状态)不会失效!\n                "
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.ruleForm.handleType === 2
                              ? _c("el-form-item", { attrs: { label: "" } }, [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { prop: "startTime" } },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c("el-date-picker", {
                                                attrs: {
                                                  prop: "startTime",
                                                  type: "datetime",
                                                  placeholder: "开始时间",
                                                  "default-time": "00:00:00",
                                                },
                                                model: {
                                                  value: _vm.ruleForm.startTime,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.ruleForm,
                                                      "startTime",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "ruleForm.startTime",
                                                },
                                              }),
                                              _vm._v(
                                                "\n                      至\n                      "
                                              ),
                                              _c("el-date-picker", {
                                                attrs: {
                                                  type: "datetime",
                                                  placeholder: "结束时间",
                                                  "default-time": "23:59:59",
                                                },
                                                model: {
                                                  value: _vm.ruleForm.endTime,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.ruleForm,
                                                      "endTime",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "ruleForm.endTime",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "text" }, [
                                        _vm._v(
                                          "\n                    注：不设置结束时间并保存到维权渠道中，后续订单符合条件也会失效，维权渠道中已存在的渠道ID，重复上传会跳过!\n                  "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "处理原因", prop: "handleTxt" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { "padding-top": "10px" } },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: _vm.ruleForm.handleTxt,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleForm,
                                              "handleTxt",
                                              $$v
                                            )
                                          },
                                          expression: "ruleForm.handleTxt",
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "flex" }, [
                                          _c(
                                            "div",
                                            { staticStyle: { width: "200px" } },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      attrs: {
                                                        label: "违规单",
                                                      },
                                                    },
                                                    [_vm._v("违规单")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      attrs: {
                                                        label: "店铺淘宝客",
                                                      },
                                                    },
                                                    [_vm._v("店铺淘宝客")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      attrs: {
                                                        label: "站内推广",
                                                      },
                                                    },
                                                    [_vm._v("站内推广")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      attrs: {
                                                        label: "违规代购",
                                                      },
                                                    },
                                                    [_vm._v("违规代购")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      attrs: {
                                                        label: "单笔交易异常",
                                                      },
                                                    },
                                                    [_vm._v("单笔交易异常")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticStyle: { width: "200px" } },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      attrs: {
                                                        label: "虚假交易",
                                                      },
                                                    },
                                                    [_vm._v("虚假交易")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      attrs: {
                                                        label: "站内二次售卖",
                                                      },
                                                    },
                                                    [_vm._v("站内二次售卖")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      attrs: {
                                                        label: "累计超出上限",
                                                      },
                                                    },
                                                    [_vm._v("累计超出上限")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      attrs: {
                                                        label: "多笔交易异常",
                                                      },
                                                    },
                                                    [_vm._v("多笔交易异常")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticStyle: { width: "200px" } },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      attrs: {
                                                        label: "扰乱市场秩序",
                                                      },
                                                    },
                                                    [_vm._v("扰乱市场秩序")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      attrs: {
                                                        label: "结算后退款",
                                                      },
                                                    },
                                                    [_vm._v("结算后退款")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      attrs: {
                                                        label: "风险订单",
                                                      },
                                                    },
                                                    [_vm._v("风险订单")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      attrs: {
                                                        label: "处罚订单",
                                                      },
                                                    },
                                                    [_vm._v("处罚订单")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticStyle: { width: "200px" } },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      attrs: {
                                                        label: "售中退款",
                                                      },
                                                    },
                                                    [_vm._v("售中退款")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      attrs: {
                                                        label: "售后退款",
                                                      },
                                                    },
                                                    [_vm._v("售后退款")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      attrs: {
                                                        label: "其他原因",
                                                      },
                                                    },
                                                    [_vm._v("其他原因")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      attrs: {
                                                        label: "抖音搬单",
                                                      },
                                                    },
                                                    [_vm._v("抖音搬单")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "文件导入", prop: "fileList" },
                              },
                              [
                                _c(
                                  "el-upload",
                                  {
                                    ref: "upload",
                                    staticClass: "upload-demo",
                                    attrs: {
                                      action: "",
                                      "on-change": _vm.change,
                                      "on-remove": _vm.handleRemove,
                                      "file-list": _vm.ruleForm.fileList,
                                      "auto-upload": false,
                                      limit: 2,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          slot: "trigger",
                                          size: "small",
                                          type: "primary",
                                        },
                                        slot: "trigger",
                                      },
                                      [_vm._v("选取文件")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { "margin-left": "10px" },
                                        attrs: {
                                          type: "danger",
                                          size: "small",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-download el-icon--left",
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: _vm.excelUrl,
                                              download: "filename.xlsx",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.documentName))]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.submitForm("ruleForm")
                                      },
                                    },
                                  },
                                  [_vm._v("确认")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.resetForm("ruleForm")
                                      },
                                    },
                                  },
                                  [_vm._v("重置")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                { attrs: { label: "违规订单处理记录", name: "second" } },
                [
                  _c("div", { staticClass: "search_criteria" }, [
                    _c(
                      "div",
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入文件名查询",
                            clearable: "",
                          },
                          model: {
                            value: _vm.queryData.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryData, "name", $$v)
                            },
                            expression: "queryData.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "处理类型", clearable: "" },
                            model: {
                              value: _vm.queryData.handleType,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryData, "handleType", $$v)
                              },
                              expression: "queryData.handleType",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { value: 1, label: "违规单(订单号)" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { value: 2, label: "渠道单(渠道ID)" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "状态", clearable: "" },
                            model: {
                              value: _vm.queryData.importStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryData, "importStatus", $$v)
                              },
                              expression: "queryData.importStatus",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { value: 1, label: "处理中" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { value: 2, label: "已完成" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "flex",
                        staticStyle: { width: "fit-content" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.onInquire },
                          },
                          [_vm._v("查询")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "ml10",
                            attrs: { type: "info", plain: "" },
                            on: { click: _vm.onInitialize },
                          },
                          [_vm._v("重置")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          staticStyle: { width: "100%" },
                          attrs: {
                            height: "420",
                            data: _vm.tableData,
                            border: "",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "fileName",
                              label: "文件名",
                              align: "center",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "name",
                              label: "平台类型",
                              align: "center",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.platformCodeMap[
                                            JSON.parse(scope.row.ext)
                                              .platformCode
                                          ]
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "name",
                              label: "处理类型",
                              align: "center",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          JSON.parse(scope.row.ext)
                                            .handleType === 1
                                            ? "违规订单(订单号)"
                                            : "渠道单(渠道ID)"
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "name",
                              label: "处理原因",
                              align: "center",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          JSON.parse(scope.row.ext).handleTxt
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "name",
                              label: "状态",
                              align: "center",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          scope.row.importStatus === 1
                                            ? "处理中"
                                            : "已完成"
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "total",
                              label: "任务数量",
                              align: "center",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "successTotal",
                              label: "成功数量（订单数）",
                              align: "center",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "createTime",
                              label: "创建时间",
                              align: "center",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "updateTime",
                              label: "处理结束时间",
                              align: "center",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "name",
                              width: "120",
                              label: "操作",
                              align: "center",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.viewDetails(
                                              scope.row.taskId
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("查看明细")]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt20 page_box" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          "current-page": _vm.queryData.page.page,
                          "page-sizes": [10, 20, 30, 40],
                          "page-size": _vm.queryData.page.size,
                          layout: "total, prev, pager, next, sizes,jumper",
                          total: _vm.total,
                          background: "",
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.handleCurrentChange,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }