"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.queryFansListApi = queryFansListApi;
var _request = _interopRequireDefault(require("@/utils/request"));
// 会员粉丝列表
function queryFansListApi(data) {
  return (0, _request.default)({
    url: '/portal/system/member/queryFansList',
    method: 'post',
    data: data
  });
}