var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            title: "活动海报",
            width: "650px",
            top: "10vh",
            "custom-class": "dialog_box",
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "div",
            { staticClass: "from_box" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: { model: _vm.ruleForm, "label-width": "120px" },
                },
                [
                  _c("el-form-item", { attrs: { label: "海报:", prop: "" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "130px",
                          height: "130px",
                          background: "#f9f9f9",
                          "border-radius": "4px",
                          border: "1px solid #F7F7F7",
                        },
                      },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "avatar-uploader",
                            attrs: {
                              "show-file-list": false,
                              action: "",
                              "http-request": _vm.handleFileUpload,
                            },
                          },
                          [
                            _vm.ruleForm.pic
                              ? _c("img", {
                                  staticClass: "avatar",
                                  staticStyle: {
                                    width: "130px",
                                    height: "130px",
                                    "border-radius": "4px",
                                  },
                                  attrs: { src: _vm.ruleForm.pic },
                                })
                              : _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon",
                                }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态:", prop: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "活动状态", clearable: "" },
                          model: {
                            value: _vm.ruleForm.enable,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "enable", $$v)
                            },
                            expression: "ruleForm.enable",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { value: 1, label: "上线" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { value: 0, label: "下线" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "fc mt20" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.onVerify },
                        },
                        [_vm._v("确认")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }