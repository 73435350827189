"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.classListApi = classListApi;
exports.deleteByIdApi = deleteByIdApi;
exports.getPopupByIdApi = getPopupByIdApi;
exports.pageListApi = pageListApi;
exports.savePopupApi = savePopupApi;
exports.updatePopupApi = updatePopupApi;
var _request = _interopRequireDefault(require("@/utils/request"));
// 查询列表
function pageListApi(data) {
  return (0, _request.default)({
    url: '/portal/system/popup/pageList',
    method: 'post',
    data: data
  });
}
// 新增弹窗
function savePopupApi(data) {
  return (0, _request.default)({
    url: '/portal/system/popup/savePopup',
    method: 'post',
    data: data
  });
}
// 获取单个配置
function getPopupByIdApi(data) {
  return (0, _request.default)({
    url: '/portal/system/popup/getPopupById',
    method: 'post',
    data: data
  });
}
// 修改弹窗
function updatePopupApi(data) {
  return (0, _request.default)({
    url: '/portal/system/popup/updatePopup',
    method: 'post',
    data: data
  });
}
// 删除弹窗
function deleteByIdApi(data) {
  return (0, _request.default)({
    url: '/portal/system/popup/deleteById',
    method: 'post',
    data: data
  });
}
// 等级下拉框
function classListApi(data) {
  return (0, _request.default)({
    url: '/portal/system/popup/classList',
    method: 'post',
    data: data
  });
}