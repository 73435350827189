"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/fe-ug-comprehensive-shopping-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addSource = addSource;
exports.getInfo = getInfo;
exports.login = login;
exports.logout = logout;
exports.passwordChange = passwordChange;
var _request = _interopRequireDefault(require("@/utils/request"));
function login(data) {
  return (0, _request.default)({
    url: "/portal/system/auth/login",
    method: 'post',
    data: data
  });
}
function getInfo(data) {
  return (0, _request.default)({
    url: '/portal/system/auth/queryUserInfo',
    method: 'post',
    data: data
  });
}
function logout() {
  return (0, _request.default)({
    url: '/portal/system/auth/logout',
    method: 'get'
  });
}
function addSource(data) {
  return (0, _request.default)({
    url: '/admin/material/uploadMaterial',
    method: 'post',
    data: data
  });
}
function passwordChange(data) {
  return (0, _request.default)({
    url: '/portal/system/auth/passwordChange',
    method: 'post',
    data: data
  });
}