var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticStyle: { display: "flex", "justify-content": "flex-start" } },
      [
        _c(
          "el-form",
          {
            ref: "ruleForm",
            staticClass: "demo-ruleForm",
            staticStyle: { width: "50%" },
            attrs: {
              model: _vm.ruleForm,
              rules: _vm.rules,
              "label-width": "100px",
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "APP名称", prop: "appName" } },
              [
                _c("el-input", {
                  attrs: { clearable: "", type: "text", autocomplete: "off" },
                  model: {
                    value: _vm.ruleForm.appName,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "appName", $$v)
                    },
                    expression: "ruleForm.appName",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "space-between",
                  "align-items": "center",
                },
              },
              [
                _c(
                  "el-form-item",
                  {
                    staticStyle: { width: "50%" },
                    attrs: { label: "APP主色", prop: "firstColor" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            readonly: true,
                            clearable: "",
                            type: "text",
                            autocomplete: "off",
                          },
                          model: {
                            value: _vm.ruleForm.firstColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "firstColor", $$v)
                            },
                            expression: "ruleForm.firstColor",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-color-picker", {
                          model: {
                            value: _vm.ruleForm.firstColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "firstColor", $$v)
                            },
                            expression: "ruleForm.firstColor",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticStyle: { width: "50%" },
                    attrs: { label: "APP辅色", prop: "secondColor" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            readonly: true,
                            clearable: "",
                            type: "text",
                            autocomplete: "off",
                          },
                          model: {
                            value: _vm.ruleForm.secondColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "secondColor", $$v)
                            },
                            expression: "ruleForm.secondColor",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-color-picker", {
                          model: {
                            value: _vm.ruleForm.secondColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "secondColor", $$v)
                            },
                            expression: "ruleForm.secondColor",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "APP图标", prop: "appUrl" } },
              [
                _c(
                  "el-upload",
                  {
                    attrs: {
                      action: "",
                      "http-request": function (value) {
                        return _vm.handleFileUpload(value, "appUrl")
                      },
                      "on-remove": function (value) {
                        return _vm.handleRemove(value, "appUrl")
                      },
                      "list-type": "picture-card",
                      limit: 1,
                      "file-list": _vm.appUrlList,
                      "on-preview": _vm.handlePictureCardPreview,
                    },
                  },
                  [_c("i", { staticClass: "el-icon-plus" })]
                ),
                _vm._v(" "),
                _c("span", { staticStyle: { color: "#969696" } }, [
                  _vm._v(
                    "180*180PNG（图标应用的位置：登录页、首页底部登录条、发圈）"
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    attrs: { visible: _vm.dialogVisible },
                    on: {
                      "update:visible": function ($event) {
                        _vm.dialogVisible = $event
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        width: "100%",
                        src: _vm.dialogImageUrl,
                        alt: "",
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "启动页图片", prop: "startUrl" } },
              [
                _c(
                  "el-upload",
                  {
                    attrs: {
                      action: "",
                      "http-request": function (value) {
                        return _vm.handleFileUpload(value, "startUrl")
                      },
                      "on-remove": function (value) {
                        return _vm.handleRemove(value, "startUrl")
                      },
                      "list-type": "picture-card",
                      "file-list": _vm.startUrlList,
                      limit: 1,
                      "on-preview": _vm.handlePictureCardPreview,
                    },
                  },
                  [_c("i", { staticClass: "el-icon-plus" })]
                ),
                _vm._v(" "),
                _c("span", { staticStyle: { color: "#969696" } }, [
                  _vm._v("500*600（PNG）"),
                ]),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    attrs: { visible: _vm.dialogVisible },
                    on: {
                      "update:visible": function ($event) {
                        _vm.dialogVisible = $event
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        width: "100%",
                        src: _vm.dialogImageUrl,
                        alt: "",
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "用户协议", prop: "agreement" } },
              [
                _c("el-input", {
                  attrs: { clearable: "", type: "text", autocomplete: "off" },
                  model: {
                    value: _vm.ruleForm.agreement,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "agreement", $$v)
                    },
                    expression: "ruleForm.agreement",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "隐私政策", prop: "policy" } },
              [
                _c("el-input", {
                  attrs: { clearable: "", type: "text", autocomplete: "off" },
                  model: {
                    value: _vm.ruleForm.policy,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "policy", $$v)
                    },
                    expression: "ruleForm.policy",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "120px", width: "100px" },
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.submitForm("ruleForm")
                  },
                },
              },
              [_vm._v("保 存")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }