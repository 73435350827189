var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        background: "#fff",
        padding: "30px",
        "border-radius": "20px",
      },
    },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "安卓", name: "android" } },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-ruleForm",
                  staticStyle: { "margin-top": "20px" },
                  attrs: { "label-width": "120px" },
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "both-label",
                          attrs: { slot: "label" },
                          slot: "label",
                        },
                        [
                          _c("i", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v("\n            应用包名:\n          "),
                        ]
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "400px" },
                        attrs: { placeholder: "请输入内容" },
                        model: {
                          value: _vm.dataObj.androidPackageName,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataObj, "androidPackageName", $$v)
                          },
                          expression: "dataObj.androidPackageName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "both-label",
                          attrs: { slot: "label" },
                          slot: "label",
                        },
                        [
                          _c("i", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v("\n            Android证书:\n          "),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-upload",
                        {
                          staticStyle: { width: "400px" },
                          attrs: {
                            limit: 1,
                            action: "",
                            "on-remove": function (value) {
                              return _vm.handleRemove(value, 1)
                            },
                            "show-file-list":
                              _vm.dataObj.androidFileList.length > 0,
                            "file-list": _vm.dataObj.androidFileList,
                            "http-request": function (value) {
                              return _vm.handleFileUpload(
                                value,
                                "android.keystore",
                                1
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("点击上传")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "both-label",
                          attrs: { slot: "label" },
                          slot: "label",
                        },
                        [
                          _c("i", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v("\n            证书密码:\n          "),
                        ]
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "400px" },
                        attrs: {
                          type: "password",
                          "show-password": true,
                          placeholder: "请输入内容",
                        },
                        model: {
                          value: _vm.dataObj.androidPsd,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataObj, "androidPsd", $$v)
                          },
                          expression: "dataObj.androidPsd",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "both-label",
                          attrs: { slot: "label" },
                          slot: "label",
                        },
                        [
                          _c("i", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v("\n            百川安全图:\n          "),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-upload",
                        {
                          staticStyle: { width: "400px" },
                          attrs: {
                            limit: 1,
                            action: "",
                            "on-remove": function (value) {
                              return _vm.handleRemove(value, 2)
                            },
                            "show-file-list":
                              _vm.dataObj.bcPhotoList.length > 0,
                            "file-list": _vm.dataObj.bcPhotoList,
                            "http-request": function (value) {
                              return _vm.handleFileUpload(
                                value,
                                "android_baichuan_yw_1222_baichuan.jpg",
                                2
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("点击上传")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticStyle: { "margin-top": "100px" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.save },
                        },
                        [_vm._v("保存")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "苹果", name: "ios" } },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-ruleForm",
                  staticStyle: { "margin-top": "20px" },
                  attrs: { "label-width": "170px" },
                },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "both-label",
                          attrs: { slot: "label" },
                          slot: "label",
                        },
                        [
                          _c("i", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v("\n            BundleID(包名):\n          "),
                        ]
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "400px" },
                        attrs: { placeholder: "请输入内容" },
                        model: {
                          value: _vm.dataObj.iosPackageName,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataObj, "iosPackageName", $$v)
                          },
                          expression: "dataObj.iosPackageName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "both-label",
                          attrs: { slot: "label" },
                          slot: "label",
                        },
                        [
                          _c("i", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v("\n            苹果p12证书:\n          "),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-upload",
                        {
                          staticStyle: { width: "400px" },
                          attrs: {
                            limit: 1,
                            action: "",
                            "on-remove": function (value) {
                              return _vm.handleRemove(value, 3)
                            },
                            "show-file-list":
                              _vm.dataObj.iosFileList.length > 0,
                            "file-list": _vm.dataObj.iosFileList,
                            "http-request": function (value) {
                              return _vm.handleFileUpload(value, "ios.p12", 3)
                            },
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("点击上传")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "both-label",
                          attrs: { slot: "label" },
                          slot: "label",
                        },
                        [
                          _c("i", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v(
                            "\n            苹果p12证书描述文件:\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-upload",
                        {
                          staticStyle: { width: "400px" },
                          attrs: {
                            limit: 1,
                            action: "",
                            "on-remove": function (value) {
                              return _vm.handleRemove(value, 4)
                            },
                            "show-file-list":
                              _vm.dataObj.iosMobileprovisionFileList.length > 0,
                            "file-list": _vm.dataObj.iosMobileprovisionFileList,
                            "http-request": function (value) {
                              return _vm.handleFileUpload(
                                value,
                                "ios.mobileprovision",
                                4
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("点击上传")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "both-label",
                          attrs: { slot: "label" },
                          slot: "label",
                        },
                        [
                          _c("i", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v("\n            证书密码:\n          "),
                        ]
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "400px" },
                        attrs: {
                          type: "password",
                          "show-password": true,
                          placeholder: "请输入内容",
                        },
                        model: {
                          value: _vm.dataObj.iosPsd,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataObj, "iosPsd", $$v)
                          },
                          expression: "dataObj.iosPsd",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "both-label",
                          attrs: { slot: "label" },
                          slot: "label",
                        },
                        [
                          _c("i", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v("\n            百川安全图:\n          "),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-upload",
                        {
                          staticStyle: { width: "400px" },
                          attrs: {
                            limit: 1,
                            action: "",
                            "on-remove": function (value) {
                              return _vm.handleRemove(value, 5)
                            },
                            "show-file-list":
                              _vm.dataObj.iosBcPhotoList.length > 0,
                            "file-list": _vm.dataObj.iosBcPhotoList,
                            "http-request": function (value) {
                              return _vm.handleFileUpload(
                                value,
                                "ios_baichuan_yw_1222_baichuan.jpg",
                                5
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("点击上传")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "both-label",
                          attrs: { slot: "label" },
                          slot: "label",
                        },
                        [
                          _c("i", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v("\n            百川AppKey:\n          "),
                        ]
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "400px" },
                        attrs: { placeholder: "请输入内容" },
                        model: {
                          value: _vm.dataObj.ios_bc_AppKey,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataObj, "ios_bc_AppKey", $$v)
                          },
                          expression: "dataObj.ios_bc_AppKey",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticStyle: { "margin-top": "100px" } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.save },
                        },
                        [_vm._v("保存")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }