"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
// create an axios instance
var service = _axios.default.create({
  // baseURL: '/api', // url = base url + request url
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000 // request timeout default 5000
});

// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent

  if (_store.default.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers['Authorization'] = (0, _auth.getToken)();
    config.headers['user-id'] = _store.default.getters.userId;
    config.headers['app-id'] = _store.default.getters.appId;
  }
  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;

  // if the custom code is not 20000, it is judged as an error.
  if (res.code !== 0) {
    //  10120101: Token expired;
    if (res.code === 10120101) {
      // to re-login
      _elementUi.MessageBox.confirm('登录已过期！请确认是否重新登录', '温馨提示', {
        confirmButtonText: '重新登录',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(function () {
        _store.default.dispatch('user/resetToken').then(function () {
          location.reload();
        });
      });
    } else {
      (0, _elementUi.Message)({
        message: res.msg || 'Error',
        type: 'error',
        duration: 5 * 1000
      });
    }
    return Promise.reject(new Error(res.message || 'Error'));
  } else {
    return res;
  }
}, function (error) {
  console.log('err' + error); // for debug
  (0, _elementUi.Message)({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
var _default = exports.default = service;