var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "activity flex frb" }, [
    _c("div", { staticClass: "img_box" }, [
      _c("img", {
        attrs: { src: _vm.item.mainImage, alt: _vm.item.mainImage },
      }),
      _vm._v(" "),
      _vm.item.status === 1
        ? _c("div", { staticClass: "ongoingState fc color-97ff0c" }, [
            _vm._v("进行中"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.item.status === 0
        ? _c("div", { staticClass: "ongoingState fc color-96" }, [
            _vm._v("未开始"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.item.status === 2
        ? _c("div", { staticClass: "ongoingState fc color-96" }, [
            _vm._v("已结束"),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "text" }, [
      _c("div", [
        _c("div", { staticClass: "beyond_hiding title" }, [
          _vm._v("\n        " + _vm._s(_vm.item.name) + "\n      "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "startTime" }, [
          _vm._v("开始时间：" + _vm._s(_vm.item.startTime)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "startTime" }, [
          _vm._v("结束时间：" + _vm._s(_vm.item.endTime)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "startTime" }, [
          _vm._v("创建时间：" + _vm._s(_vm.item.createTime)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex text_btn" }, [
        _c(
          "div",
          {
            staticClass: "fc pointer",
            on: {
              click: function ($event) {
                return _vm.onControls("editor")
              },
            },
          },
          [_vm._v("编辑")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "fc pointer",
            on: {
              click: function ($event) {
                return _vm.onControls("delete")
              },
            },
          },
          [_vm._v("删除")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }