var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "box" }, [
      _vm.type === 1
        ? _c("div", [
            _vm.homeTopData.isShow === 1
              ? _c(
                  "div",
                  {
                    staticClass: "home-top",
                    style: {
                      backgroundImage: "url(" + _vm.homeTopData.bg + ")",
                      backgroundSize: "cover",
                    },
                    on: {
                      mouseover: function ($event) {
                        return _vm.bothMouseOver(1)
                      },
                    },
                  },
                  [
                    _vm.boole1
                      ? _c(
                          "div",
                          {
                            staticClass: "cover",
                            on: {
                              mouseleave: function ($event) {
                                return _vm.bothMouseLeave(1)
                              },
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-edit",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.bothClick(1)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "ipt" }, [
                      _c("img", {
                        staticStyle: {
                          width: "14px",
                          height: "14px",
                          margin: "0 7px",
                        },
                        attrs: { src: require("@/assets/search.png") },
                      }),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "font-size": "10px" } }, [
                        _vm._v("搜索商品或粘贴宝贝标题"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "right-box" },
                      _vm._l(_vm.homeTopData.iconList, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "both-right-box" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "small-box",
                                style: { background: item.url ? "none" : "" },
                              },
                              [
                                item.url
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%",
                                      },
                                      attrs: { src: item.url },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "text",
                                style: { color: _vm.homeTopData.textColor },
                              },
                              [_vm._v(_vm._s(item.text))]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.myData.homeData2.isShow === 1
              ? _c(
                  "div",
                  {
                    staticClass: "both-box",
                    on: {
                      mouseover: function ($event) {
                        return _vm.bothMouseOver(2)
                      },
                    },
                  },
                  [
                    _vm.boole2
                      ? _c(
                          "div",
                          {
                            staticClass: "cover",
                            on: {
                              mouseleave: function ($event) {
                                return _vm.bothMouseLeave(2)
                              },
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-edit",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.bothClick(2)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-carousel",
                      {
                        staticStyle: {
                          background: "#d8d8d8",
                          "border-radius": "15px",
                        },
                        attrs: {
                          arrow: "never",
                          height: "85px",
                          "indicator-position": "none",
                        },
                      },
                      _vm._l(
                        _vm.myData.homeData2.bannerList,
                        function (item, index) {
                          return _c(
                            "el-carousel-item",
                            { key: index },
                            [
                              _c("el-image", {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: { src: item.pic, fit: "fill" },
                              }),
                            ],
                            1
                          )
                        }
                      ),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.myData.homeData3.isShow === 1
              ? _c(
                  "div",
                  {
                    staticClass: "both-box",
                    on: {
                      mouseover: function ($event) {
                        return _vm.bothMouseOver(3)
                      },
                    },
                  },
                  [
                    _vm.boole3
                      ? _c(
                          "div",
                          {
                            staticClass: "cover",
                            on: {
                              mouseleave: function ($event) {
                                return _vm.bothMouseLeave(3)
                              },
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-edit",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.bothClick(3)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-carousel",
                      {
                        staticStyle: {
                          background: "#d8d8d8",
                          "border-radius": "15px",
                        },
                        attrs: {
                          arrow: "never",
                          height: "70px",
                          "indicator-position": "none",
                        },
                      },
                      _vm._l(
                        _vm.myData.homeData3.bannerList,
                        function (item, index) {
                          return _c(
                            "el-carousel-item",
                            { key: index },
                            [
                              _c("el-image", {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: { src: item.pic, fit: "fill" },
                              }),
                            ],
                            1
                          )
                        }
                      ),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.myData.homeData4.isShow === 1
              ? _c(
                  "div",
                  {
                    staticClass: "home-second-top",
                    on: {
                      mouseover: function ($event) {
                        return _vm.bothMouseOver(4)
                      },
                    },
                  },
                  [
                    _vm.boole4
                      ? _c(
                          "div",
                          {
                            staticClass: "cover",
                            on: {
                              mouseleave: function ($event) {
                                return _vm.bothMouseLeave(4)
                              },
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-edit",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.bothClick(4)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "diamond" }, [
                      _vm.myData.homeData4.locations
                        ? _c(
                            "div",
                            { staticClass: "type-box" },
                            _vm._l(
                              _vm.myData.homeData4.locations.filter(function (
                                items,
                                index
                              ) {
                                return index <= 4
                              }),
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: item.enable === 1,
                                        expression: "item.enable===1",
                                      },
                                    ],
                                    key: index,
                                  },
                                  [_vm._v(_vm._s(item.locationName))]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.myData.homeData4.locations
                        ? _c(
                            "div",
                            { staticClass: "diamond-box" },
                            _vm._l(
                              _vm.myData.homeData4.bannerList.length > 0
                                ? _vm.myData.homeData4.bannerList
                                : 10,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          item.locationCode ===
                                          _vm.myData.homeData4.locations[0]
                                            .locationCode,
                                        expression:
                                          "item.locationCode===myData.homeData4.locations[0].locationCode",
                                      },
                                    ],
                                    key: index,
                                    staticClass: "diamond-son",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "small-box",
                                        staticStyle: { position: "relative" },
                                      },
                                      [
                                        _c("el-image", {
                                          staticStyle: {
                                            width: "100%",
                                            height: "100%",
                                          },
                                          attrs: { src: item.pic, fit: "fill" },
                                        }),
                                        _vm._v(" "),
                                        item.tagFlag === 1
                                          ? _c("el-image", {
                                              staticStyle: {
                                                width: "15px",
                                                height: "15px",
                                                position: "absolute",
                                                right: "-12px",
                                                top: "-8px",
                                              },
                                              attrs: {
                                                src: item.tag,
                                                fit: "fill",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "text" }, [
                                      _vm._v(_vm._s(item.name)),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.myData.homeData5.isShow === 1
              ? _c(
                  "div",
                  {
                    staticClass: "both-box",
                    on: {
                      mouseover: function ($event) {
                        return _vm.bothMouseOver(5)
                      },
                    },
                  },
                  [
                    _vm.boole5
                      ? _c(
                          "div",
                          {
                            staticClass: "cover",
                            on: {
                              mouseleave: function ($event) {
                                return _vm.bothMouseLeave(5)
                              },
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-edit",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.bothClick(5)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          background: "#d8d8d8",
                          width: "100%",
                          height: "30px",
                          "border-radius": "10px",
                          display: "flex",
                          "justify-content": "flex-start",
                          "align-items": "center",
                        },
                      },
                      [
                        _c("img", {
                          staticStyle: {
                            width: "20%",
                            height: "13px",
                            margin: "0 3px",
                          },
                          attrs: {
                            src: _vm.myData.homeData5.bannerList[0].pic,
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-carousel",
                          {
                            staticStyle: { width: "75%" },
                            attrs: {
                              direction: "vertical",
                              arrow: "never",
                              interval: 4500,
                              height: "30px",
                              "indicator-position": "none",
                            },
                          },
                          _vm._l(
                            _vm.myData.homeData5.bannerList,
                            function (item, index) {
                              return _c("el-carousel-item", { key: index }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "long-text",
                                    staticStyle: {
                                      "font-size": "11px",
                                      "line-height": "30px",
                                      "text-align": "left",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.content))]
                                ),
                              ])
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.myData.homeData6.isShow === 1
              ? _c(
                  "div",
                  {
                    staticClass: "both-box",
                    on: {
                      mouseover: function ($event) {
                        return _vm.bothMouseOver(6)
                      },
                    },
                  },
                  [
                    _vm.boole6
                      ? _c(
                          "div",
                          {
                            staticClass: "cover",
                            on: {
                              mouseleave: function ($event) {
                                return _vm.bothMouseLeave(6)
                              },
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-edit",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.bothClick(6)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      _vm._l(
                        _vm.myData.homeData6.bannerList.length > 0
                          ? _vm.myData.homeData6.bannerList.filter(function (
                              items,
                              index
                            ) {
                              return index <= 4
                            })
                          : 5,
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticStyle: {
                                "border-radius": "3px",
                                width: "19%",
                                "background-color": "#d8d8d8",
                                height: "50px",
                              },
                            },
                            [
                              item.pic
                                ? _c("el-image", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%",
                                    },
                                    attrs: { src: item.pic, fit: "fill" },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        }
                      ),
                      0
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.myData.homeData7.isShow === 1
              ? _c(
                  "div",
                  {
                    staticClass: "both-box",
                    on: {
                      mouseover: function ($event) {
                        return _vm.bothMouseOver(7)
                      },
                    },
                  },
                  [
                    _vm.boole7
                      ? _c(
                          "div",
                          {
                            staticClass: "cover",
                            on: {
                              mouseleave: function ($event) {
                                return _vm.bothMouseLeave(7)
                              },
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-edit",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.bothClick(7)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-carousel",
                      {
                        staticStyle: {
                          background: "#d8d8d8",
                          "border-radius": "15px",
                        },
                        attrs: {
                          arrow: "never",
                          height: "70px",
                          "indicator-position": "none",
                        },
                      },
                      _vm._l(_vm.myData.homeData7.bannerList, function (item) {
                        return _c(
                          "el-carousel-item",
                          { key: item.id },
                          [
                            _c("el-image", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: { src: item.pic, fit: "fill" },
                            }),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.myData.homeData8.isShow === 1
              ? _c(
                  "div",
                  {
                    staticClass: "both-box",
                    on: {
                      mouseover: function ($event) {
                        return _vm.bothMouseOver(8)
                      },
                    },
                  },
                  [
                    _vm.boole8
                      ? _c(
                          "div",
                          {
                            staticClass: "cover",
                            on: {
                              mouseleave: function ($event) {
                                return _vm.bothMouseLeave(8)
                              },
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-edit",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.bothClick(8)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      _vm._l(
                        _vm.myData.homeData8.bannerList.length > 0
                          ? _vm.myData.homeData8.bannerList.filter(function (
                              items,
                              index
                            ) {
                              return index <= 3
                            })
                          : 4,
                        function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticStyle: {
                                "border-radius": "3px",
                                width: "24%",
                                "background-color": "#d8d8d8",
                                height: "75px",
                              },
                            },
                            [
                              item.pic
                                ? _c("el-image", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%",
                                    },
                                    attrs: { src: item.pic, fit: "fill" },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        }
                      ),
                      0
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "home-middle" }, [
              _c("div", { staticClass: "three-box" }, [
                _vm.myData.homeData9.isShow === 1
                  ? _c(
                      "div",
                      {
                        staticClass: "three-left",
                        staticStyle: { position: "relative" },
                        on: {
                          mouseover: function ($event) {
                            return _vm.bothMouseOver(9)
                          },
                        },
                      },
                      [
                        _vm.boole9
                          ? _c(
                              "div",
                              {
                                staticClass: "cover",
                                on: {
                                  mouseleave: function ($event) {
                                    return _vm.bothMouseLeave(9)
                                  },
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-edit",
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.bothClick(9)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "el-carousel",
                          {
                            staticStyle: {
                              background: "#d8d8d8",
                              "border-radius": "15px",
                            },
                            attrs: {
                              arrow: "never",
                              height: "160px",
                              "indicator-position": "none",
                            },
                          },
                          _vm._l(
                            _vm.myData.homeData9.bannerList,
                            function (item) {
                              return _c(
                                "el-carousel-item",
                                { key: item.id },
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%",
                                    },
                                    attrs: { src: item.pic, fit: "fill" },
                                  }),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.myData.homeData10.isShow === 1
                  ? _c(
                      "div",
                      {
                        staticClass: "three-right",
                        staticStyle: { position: "relative" },
                        on: {
                          mouseover: function ($event) {
                            return _vm.bothMouseOver(10)
                          },
                        },
                      },
                      [
                        _vm.boole10
                          ? _c(
                              "div",
                              {
                                staticClass: "cover",
                                on: {
                                  mouseleave: function ($event) {
                                    return _vm.bothMouseLeave(10)
                                  },
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-edit",
                                    size: "mini",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.bothClick(10)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "right-son" },
                          _vm._l(
                            _vm.myData.homeData10.bannerList.filter(function (
                              items,
                              index
                            ) {
                              return index === 0
                            }),
                            function (item) {
                              return _c("el-image", {
                                key: item.id,
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: { src: item.pic, fit: "fill" },
                              })
                            }
                          ),
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "right-son",
                            staticStyle: {
                              "margin-top": "0",
                              background: "none",
                              display: "flex",
                              "justify-content": "flex-start",
                              "flex-wrap": "wrap",
                            },
                          },
                          _vm._l(
                            _vm.myData.homeData10.bannerList.filter(function (
                              items,
                              index
                            ) {
                              return index !== 0
                            }).length > 0
                              ? _vm.myData.homeData10.bannerList.filter(
                                  function (items, index) {
                                    return index !== 0 && index <= 4
                                  }
                                )
                              : 4,
                            function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticStyle: {
                                    "border-radius": "3px",
                                    width: "48%",
                                    margin: "3px 0 0 2px",
                                    height: "36px",
                                    background: "#d8d8d8",
                                  },
                                },
                                [
                                  item.pic
                                    ? _c("el-image", {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                        },
                                        attrs: { src: item.pic, fit: "fill" },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _vm.homeFootData.isShow === 1
              ? _c(
                  "div",
                  {
                    staticClass: "home-foot",
                    style: {
                      background: _vm.homeFootData.tbBg
                        ? _vm.homeFootData.tbBg
                        : "",
                    },
                    on: {
                      mouseover: function ($event) {
                        return _vm.bothMouseOver(13)
                      },
                    },
                  },
                  [
                    _vm.boole13
                      ? _c(
                          "div",
                          {
                            staticClass: "cover",
                            on: {
                              mouseleave: function ($event) {
                                return _vm.bothMouseLeave(13)
                              },
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-edit",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.bothClick(13)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.typeList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "text",
                          style: {
                            color: _vm.homeFootData.textColor
                              ? _vm.homeFootData.textColor
                              : "",
                          },
                        },
                        [_vm._v(_vm._s(item))]
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "foot-box",
                staticStyle: { "z-index": "999" },
                on: {
                  mouseover: function ($event) {
                    return _vm.bothMouseOver(14)
                  },
                },
              },
              [
                _vm.boole14
                  ? _c(
                      "div",
                      {
                        staticClass: "cover",
                        on: {
                          mouseleave: function ($event) {
                            return _vm.bothMouseLeave(14)
                          },
                        },
                      },
                      [
                        _c("el-button", {
                          attrs: {
                            type: "primary",
                            icon: "el-icon-edit",
                            size: "mini",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.bothClick(14)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.tabBarData, function (item, index) {
                  return _c("div", { key: index, staticClass: "foot-son" }, [
                    _c(
                      "div",
                      {
                        staticClass: "small",
                        style: { background: item.normalUrl ? "none" : "" },
                      },
                      [
                        item.normalUrl
                          ? _c("img", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: { src: item.normalUrl },
                            })
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                  ])
                }),
              ],
              2
            ),
            _vm._v(" "),
            _vm.homeFootData.goTop
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "z-index": "99",
                      width: "40px",
                      height: "40px",
                      position: "absolute",
                      top: "60%",
                      right: "20px",
                    },
                  },
                  [
                    _c("el-image", {
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: { src: _vm.homeFootData.goTop, fit: "fill" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.homeFootData.footIcon
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "z-index": "99",
                      width: "40px",
                      height: "40px",
                      position: "absolute",
                      top: "70%",
                      right: "20px",
                    },
                  },
                  [
                    _c("el-image", {
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: { src: _vm.homeFootData.footIcon, fit: "fill" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.myData.homeData11.isShow === 1
              ? _c(
                  "div",
                  {
                    class: [
                      _vm.myData.homeData11.bannerList.length > 0 ? "my-x" : "",
                    ],
                    staticStyle: {
                      "z-index": "99",
                      width: "40px",
                      height: "40px",
                      "background-color": "#d8d8d8",
                      position: "absolute",
                      top: "50%",
                      right: "20px",
                    },
                  },
                  _vm._l(
                    _vm.myData.homeData11.bannerList.filter(function (
                      items,
                      index
                    ) {
                      return index === 0
                    }),
                    function (item, index) {
                      return _c("el-image", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.pic,
                            expression: "item.pic",
                          },
                        ],
                        key: index,
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: { src: item.pic, fit: "fill" },
                      })
                    }
                  ),
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.myData.homeData12.isShow === 1
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "flex-start",
                      "align-items": "center",
                      "z-index": "99",
                      "border-radius": "20px",
                      position: "absolute",
                      bottom: "85px",
                      width: "290px",
                      background:
                        "linear-gradient(270deg, #b47dff, #7d96f4 200px, #7289ff 300px)",
                    },
                  },
                  [
                    _vm.myData.homeData12.bannerList.length > 0
                      ? _c("img", {
                          staticStyle: {
                            width: "60px",
                            "margin-left": "10px",
                            height: "30px",
                          },
                          attrs: {
                            src: _vm.myData.homeData12.bannerList[0].pic,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.myData.homeData12.bannerList
                      ? _c(
                          "div",
                          {
                            staticClass: "scrolling-text",
                            staticStyle: { display: "flex", flex: "1" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "text-wrapper" },
                              _vm._l(
                                _vm.myData.homeData12.bannerList,
                                function (item, index) {
                                  return _c(
                                    "span",
                                    {
                                      key: index,
                                      staticStyle: {
                                        "font-size": "11px",
                                        "margin-right": "50px",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.content))]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
          ])
        : _vm.type === 2
        ? _c("div", [
            _vm.centerTopData.isShow === 1
              ? _c(
                  "div",
                  {
                    staticClass: "home-top",
                    style: {
                      backgroundImage: "url(" + _vm.centerTopData.bgUrl + ")",
                      backgroundSize: "cover",
                    },
                  },
                  [
                    _c("div", { staticStyle: { width: "100%" } }, [
                      _c(
                        "div",
                        {
                          staticStyle: { position: "relative" },
                          on: {
                            mouseover: function ($event) {
                              return _vm.bothMouseOver(1)
                            },
                          },
                        },
                        [
                          _vm.boole1
                            ? _c(
                                "div",
                                {
                                  staticClass: "cover",
                                  on: {
                                    mouseleave: function ($event) {
                                      return _vm.bothMouseLeave(1)
                                    },
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      type: "primary",
                                      icon: "el-icon-edit",
                                      size: "mini",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.bothClick(1)
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "space-between",
                              },
                            },
                            [
                              _c("div", {
                                staticClass: "my-center",
                                staticStyle: { width: "100px" },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "right-box" },
                                _vm._l(
                                  _vm.centerTopData.iconList,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "both-right-box",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "small-box",
                                            style: {
                                              background: item.url
                                                ? "none"
                                                : "",
                                            },
                                          },
                                          [
                                            item.url
                                              ? _c("img", {
                                                  staticStyle: {
                                                    width: "100%",
                                                    height: "100%",
                                                  },
                                                  attrs: { src: item.url },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "text",
                                            style: {
                                              color:
                                                _vm.centerTopData.textColor,
                                            },
                                          },
                                          [_vm._v(_vm._s(item.text))]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "my-center" }, [
                            _c("img", {
                              staticStyle: { width: "60px", height: "60px" },
                              attrs: { src: require("@/assets/user.png") },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "text" }, [
                              _vm._v("用户昵称"),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.myCenterData.centerData2.isShow === 1
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                position: "relative",
                                "margin-top": "10px",
                              },
                              on: {
                                mouseover: function ($event) {
                                  return _vm.bothMouseOver(2)
                                },
                              },
                            },
                            [
                              _vm.boole2
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "cover",
                                      on: {
                                        mouseleave: function ($event) {
                                          return _vm.bothMouseLeave(2)
                                        },
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          type: "primary",
                                          icon: "el-icon-edit",
                                          size: "mini",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.bothClick(2)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "center-btn",
                                  style: {
                                    backgroundImage:
                                      "url(" +
                                      _vm.myCenterData.centerData2.bgUrl +
                                      ")",
                                    backgroundSize: "cover",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "27px",
                                        height: "27px",
                                      },
                                    },
                                    [
                                      _vm.myCenterData.centerData2.leftLogo
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "100%",
                                              height: "100%",
                                            },
                                            attrs: {
                                              src: _vm.myCenterData.centerData2
                                                .leftLogo,
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticStyle: { width: "64%" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "long-text",
                                        style: {
                                          fontSize: "12px",
                                          color:
                                            _vm.myCenterData.centerData2
                                              .firstTextColor,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.myCenterData.centerData2
                                              .firstText
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "long-text",
                                        style: {
                                          fontSize: "9px",
                                          color:
                                            _vm.myCenterData.centerData2
                                              .secondTextColor,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.myCenterData.centerData2
                                              .secondText
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "s-btn",
                                      style: {
                                        background:
                                          _vm.myCenterData.centerData2
                                            .rightBtnColor,
                                        color:
                                          _vm.myCenterData.centerData2
                                            .rightBtnTextColor,
                                      },
                                    },
                                    [_vm._v("去升级")]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.myCenterData.centerData3 &&
            _vm.myCenterData.centerData3.isShow === 1
              ? _c(
                  "div",
                  {
                    staticClass: "center-second",
                    on: {
                      mouseover: function ($event) {
                        return _vm.bothMouseOver(3)
                      },
                    },
                  },
                  [
                    _vm.boole3
                      ? _c(
                          "div",
                          {
                            staticClass: "cover",
                            on: {
                              mouseleave: function ($event) {
                                return _vm.bothMouseLeave(3)
                              },
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-edit",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.bothClick(3)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.myCenterData.centerData3
                      ? _c(
                          "div",
                          {
                            staticClass: "small-box",
                            style: {
                              background: _vm.myCenterData.centerData3.bgColor,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "tips long-text",
                                style: {
                                  color: _vm.myCenterData.centerData3.explain
                                    ? _vm.myCenterData.centerData3.explain
                                    : "",
                                  background:
                                    _vm.myCenterData.centerData3.explainBg,
                                  height: "22px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.myCenterData.centerData3.explainText
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "center-middle" }, [
                              _c("div", { staticClass: "middle-son" }, [
                                _c("div", { staticClass: "son-num" }, [
                                  _vm._v("100"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "son-text" }, [
                                  _vm._v("累计已结算"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "son-btn",
                                    style: {
                                      color: _vm.myCenterData.centerData3
                                        .detailedText
                                        ? _vm.myCenterData.centerData3
                                            .detailedText
                                        : "",
                                      background: _vm.myCenterData.centerData3
                                        .detailedBtn
                                        ? _vm.myCenterData.centerData3
                                            .detailedBtn
                                        : "",
                                    },
                                  },
                                  [_vm._v("收益明细")]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("img", {
                                staticStyle: { width: "1px" },
                                attrs: { src: require("@/assets/solid.png") },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "middle-son" }, [
                                _c("div", { staticClass: "son-num" }, [
                                  _vm._v("10011"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "son-text" }, [
                                  _vm._v("账户余额"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "son-btn",
                                    style: {
                                      color: _vm.myCenterData.centerData3
                                        .cashText
                                        ? _vm.myCenterData.centerData3.cashText
                                        : "",
                                      background: _vm.myCenterData.centerData3
                                        .cashBtn
                                        ? _vm.myCenterData.centerData3.cashBtn
                                        : "",
                                    },
                                  },
                                  [_vm._v("去提现")]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticStyle: { width: "100%" } }, [
                              _c("img", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  src: require("@/assets/solid-water.png"),
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "center-bottom" },
                              _vm._l(_vm.textList, function (item, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "bottom-son" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "bottom-text",
                                        staticStyle: { "margin-top": "10px" },
                                      },
                                      [_vm._v(_vm._s(item.text))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "bottom-num",
                                        staticStyle: { "margin-top": "5px" },
                                      },
                                      [_vm._v(_vm._s(item.num))]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.myCenterData.centerData4
              ? _c(
                  "div",
                  {
                    staticClass: "center-second",
                    on: {
                      mouseover: function ($event) {
                        return _vm.bothMouseOver(4)
                      },
                    },
                  },
                  [
                    _vm.boole4
                      ? _c(
                          "div",
                          {
                            staticClass: "cover",
                            on: {
                              mouseleave: function ($event) {
                                return _vm.bothMouseLeave(4)
                              },
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-edit",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.bothClick(4)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(
                      _vm.myCenterData.centerData4.tableData,
                      function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "three-box",
                            staticStyle: { padding: "15px" },
                          },
                          _vm._l(
                            item.items.length === 0
                              ? 4
                              : item.items.filter(function (m, n) {
                                  return n <= 3
                                }),
                            function (Item, Index) {
                              return _c(
                                "div",
                                { key: Index, staticClass: "three-son" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "three-box-son",
                                      style: {
                                        background: Item.picUrl ? "none" : "",
                                        display: "inline-block",
                                      },
                                    },
                                    [
                                      Item.picUrl
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "100%",
                                              height: "100%",
                                            },
                                            attrs: { src: Item.picUrl },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text",
                                      staticStyle: { "font-size": "10px" },
                                    },
                                    [_vm._v(_vm._s(Item.configName))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "long-text",
                                      staticStyle: {
                                        "font-size": "8px",
                                        "margin-top": "7px",
                                        height: "10px",
                                      },
                                    },
                                    [_vm._v(_vm._s(Item.remark))]
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      }
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.myCenterData.centerData5 &&
            _vm.myCenterData.centerData5.isShow == 1
              ? _c(
                  "div",
                  {
                    staticClass: "both-box",
                    on: {
                      mouseover: function ($event) {
                        return _vm.bothMouseOver(5)
                      },
                    },
                  },
                  [
                    _vm.boole5
                      ? _c(
                          "div",
                          {
                            staticClass: "cover",
                            on: {
                              mouseleave: function ($event) {
                                return _vm.bothMouseLeave(5)
                              },
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-edit",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.bothClick(5)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-carousel",
                      {
                        staticStyle: {
                          background: "#d8d8d8",
                          "border-radius": "15px",
                        },
                        attrs: {
                          arrow: "never",
                          height: "85px",
                          "indicator-position": "none",
                        },
                      },
                      _vm._l(
                        _vm.myCenterData.centerData5.tableData,
                        function (item, index) {
                          return _c(
                            "el-carousel-item",
                            { key: index },
                            [
                              item.pic
                                ? _c("el-image", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%",
                                    },
                                    attrs: { src: item.pic, fit: "fill" },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        }
                      ),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.myCenterData.centerData6 &&
            _vm.myCenterData.centerData6.isShow == 1
              ? _c(
                  "div",
                  {
                    staticClass: "both-box",
                    staticStyle: { padding: "0" },
                    on: {
                      mouseover: function ($event) {
                        return _vm.bothMouseOver(6)
                      },
                    },
                  },
                  [
                    _vm.boole6
                      ? _c(
                          "div",
                          {
                            staticClass: "cover",
                            on: {
                              mouseleave: function ($event) {
                                return _vm.bothMouseLeave(6)
                              },
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-edit",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.bothClick(6)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          background: "#d8d8d8",
                          height: "20px",
                          width: "99%",
                        },
                      },
                      [
                        _vm.myCenterData.centerData6.sharePic
                          ? _c("img", {
                              staticStyle: { width: "100%", height: "100%" },
                              attrs: {
                                src: _vm.myCenterData.centerData6.sharePic,
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.myCenterData.centerData8
              ? _c(
                  "div",
                  { staticClass: "both-box" },
                  _vm._l(
                    _vm.myCenterData.centerData8.tableData,
                    function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticStyle: {
                            background: "#fff",
                            "border-radius": "10px",
                            padding: "13px 10px",
                            "margin-bottom": "10px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                "font-weight": "bold",
                              },
                            },
                            [_vm._v(_vm._s(item.configGroupName))]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "flex-start",
                                "flex-wrap": "wrap",
                              },
                            },
                            _vm._l(item.items, function (Item, Index) {
                              return _c(
                                "div",
                                {
                                  key: Index,
                                  staticStyle: {
                                    width: "20%",
                                    "text-align": "center",
                                    margin: "10px 0 0 0",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        position: "relative",
                                        width: "20px",
                                        height: "20px",
                                        display: "inline-block",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          width: "100%",
                                          height: "100%",
                                        },
                                        attrs: { src: Item.picUrl },
                                      }),
                                      _vm._v(" "),
                                      Item.tagFlag === 1 && Item.tagUrl
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "15px",
                                              height: "15px",
                                              position: "absolute",
                                              right: "-10px",
                                              top: "-6px",
                                            },
                                            attrs: { src: Item.tagUrl },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "long-text",
                                      staticStyle: { "font-size": "9px" },
                                    },
                                    [_vm._v(_vm._s(Item.configName))]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      )
                    }
                  ),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.myCenterData.centerData7 &&
            _vm.myCenterData.centerData7.isShow == 1
              ? _c(
                  "div",
                  {
                    staticClass: "both-box",
                    staticStyle: { "margin-bottom": "10px" },
                    on: {
                      mouseover: function ($event) {
                        return _vm.bothMouseOver(7)
                      },
                    },
                  },
                  [
                    _vm.boole7
                      ? _c(
                          "div",
                          {
                            staticClass: "cover",
                            on: {
                              mouseleave: function ($event) {
                                return _vm.bothMouseLeave(7)
                              },
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-edit",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.bothClick(7)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-carousel",
                      {
                        staticStyle: {
                          background: "#d8d8d8",
                          "border-radius": "15px",
                        },
                        attrs: {
                          arrow: "never",
                          height: "85px",
                          "indicator-position": "none",
                        },
                      },
                      _vm._l(
                        _vm.myCenterData.centerData7.tableData,
                        function (item, index) {
                          return _c(
                            "el-carousel-item",
                            { key: index },
                            [
                              item.pic
                                ? _c("el-image", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%",
                                    },
                                    attrs: { src: item.pic, fit: "fill" },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        }
                      ),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }