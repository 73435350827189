var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: "编辑版本信息",
            width: "750px",
            top: "2vh",
            "custom-class": "dialog_box",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.onClose,
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm12",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "更新标题", prop: "title" } },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: !_vm.updateBoole,
                          placeholder: "输入更新标题",
                          clearable: "",
                        },
                        model: {
                          value: _vm.ruleForm.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "title", $$v)
                          },
                          expression: "ruleForm.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "更新内容", prop: "main" } },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: !_vm.updateBoole,
                          type: "textarea",
                          autosize: { minRows: 1, maxRows: 6 },
                          placeholder: "输入更新内容",
                          resize: "none",
                        },
                        model: {
                          value: _vm.ruleForm.main,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "main", $$v)
                          },
                          expression: "ruleForm.main",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "平台类型", prop: "osType" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.changeOsType },
                          model: {
                            value: _vm.ruleForm.osType,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "osType", $$v)
                            },
                            expression: "ruleForm.osType",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            { attrs: { disabled: true, label: 0 } },
                            [_vm._v("安卓")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            { attrs: { disabled: true, label: 1 } },
                            [_vm._v("苹果")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "版本号", prop: "versionCode" } },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: true,
                          placeholder:
                            "当前包版本号，必须大于当前线上发行版本号",
                          clearable: "",
                        },
                        model: {
                          value: _vm.ruleForm.versionCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "versionCode", $$v)
                          },
                          expression: "ruleForm.versionCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.ruleForm.osType === 0
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "下载链接", prop: "link" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: !_vm.updateBoole,
                              placeholder:
                                "填写下载链接，复制粘贴APP打包-云编译后的包链接",
                              clearable: "",
                            },
                            model: {
                              value: _vm.ruleForm.link,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "link", $$v)
                              },
                              expression: "ruleForm.link",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.ruleForm.osType === 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "AppStore", prop: "iosLink" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: !_vm.updateBoole,
                              placeholder: "APP上线苹果后台后获取",
                              clearable: "",
                            },
                            model: {
                              value: _vm.ruleForm.iosLink,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "iosLink", $$v)
                              },
                              expression: "ruleForm.iosLink",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "强制更新", prop: "forceBoole" } },
                    [
                      _c("el-switch", {
                        attrs: { disabled: !_vm.updateBoole },
                        model: {
                          value: _vm.ruleForm.forceBoole,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "forceBoole", $$v)
                          },
                          expression: "ruleForm.forceBoole",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "打开后，APP的升级弹窗无法关闭",
                            placement: "right",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                display: "inline-block",
                                "vertical-align": "middle",
                                "margin-left": "10px",
                              },
                            },
                            [_c("i", { staticClass: "el-icon-question" })]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "上线发行", prop: "onlineBoole" } },
                    [
                      _vm.specialBoole
                        ? _c("el-switch", {
                            attrs: { disabled: true },
                            model: {
                              value: _vm.ruleForm.onlineBoole,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "onlineBoole", $$v)
                              },
                              expression: "ruleForm.onlineBoole",
                            },
                          })
                        : _c("el-switch", {
                            attrs: { disabled: !_vm.updateBoole },
                            model: {
                              value: _vm.ruleForm.onlineBoole,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "onlineBoole", $$v)
                              },
                              expression: "ruleForm.onlineBoole",
                            },
                          }),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content:
                              "打开后，替换当前线上发行版本，若当前处于上线状态，无法直接关闭",
                            placement: "right",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                display: "inline-block",
                                "vertical-align": "middle",
                                "margin-left": "10px",
                              },
                            },
                            [_c("i", { staticClass: "el-icon-question" })]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核版本", prop: "examineBoole" } },
                    [
                      _c("el-switch", {
                        attrs: { disabled: !_vm.updateBoole },
                        model: {
                          value: _vm.ruleForm.examineBoole,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "examineBoole", $$v)
                          },
                          expression: "ruleForm.examineBoole",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content:
                              "应用商店审核时使用，打开后，会隐藏部分功能",
                            placement: "right",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                display: "inline-block",
                                "vertical-align": "middle",
                                "margin-left": "10px",
                              },
                            },
                            [_c("i", { staticClass: "el-icon-question" })]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.ruleForm.examineBoole
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "审核时邀请码", prop: "invite" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.invite,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "invite", $$v)
                                },
                                expression: "ruleForm.invite",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    disabled: !_vm.updateBoole,
                                    label: 1,
                                  },
                                },
                                [_vm._v("必填")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    disabled: !_vm.updateBoole,
                                    label: 2,
                                  },
                                },
                                [_vm._v("不填")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    disabled: !_vm.updateBoole,
                                    label: 0,
                                  },
                                },
                                [_vm._v("非必填(可跳过)")]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content:
                                  "应用商店审核时使用，控制登录时是否填写邀请码",
                                placement: "right",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "vertical-align": "middle",
                                    "margin-left": "10px",
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-question" })]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      !_vm.updateBoole
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.updateBoole = true
                                },
                              },
                            },
                            [_vm._v("修改")]
                          )
                        : _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.submitForm("ruleForm12")
                                },
                              },
                            },
                            [_vm._v("提交")]
                          ),
                      _vm._v(" "),
                      _c("el-button", { on: { click: _vm.onClose } }, [
                        _vm._v("关闭"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }