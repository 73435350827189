"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ResultPop',
  components: {},
  data: function data() {
    return {
      dialogVisible: false,
      normalList: [],
      failResult: []
    };
  },
  created: function created() {},
  methods: {
    open: function open(val) {
      console.log(val, '=============');
      this.normalList = val.normalList;
      this.failResult = val.failResult;
      this.dialogVisible = true;
    },
    onClose: function onClose() {
      this.dialogVisible = false;
    }
  }
};