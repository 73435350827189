import request from '@/utils/request';
export function getList(params) {
  return request({
    url: '/portal/system/personalCenter/findConfigList',
    method: 'post',
    data: params
  });
}
export function findConfigAll(query) {
  return request({
    url: '/portal/system/personalCenter/findConfigAll',
    method: 'get',
    params: query
  });
}
export function addItem(params) {
  return request({
    url: '/portal/system/personalCenter/save',
    method: 'post',
    data: params
  });
}
export function delItem(params) {
  return request({
    url: '/portal/system/personalCenter/deleteById',
    method: 'post',
    data: params
  });
}
export function updateItem(params) {
  return request({
    url: '/portal/system/personalCenter/update',
    method: 'post',
    data: params
  });
}
export function dragUpdateConfig(params) {
  return request({
    url: '/portal/system/personalCenter/dragUpdateConfig',
    method: 'post',
    data: params
  });
}
export function findConfigGroupList(params) {
  return request({
    url: '/portal/system/personalCenter/group/list',
    method: 'post',
    data: params
  });
}
export function saveGroup(params) {
  return request({
    url: '/portal/system/personalCenter/group/save',
    method: 'post',
    data: params
  });
}
export function updateGroup(params) {
  return request({
    url: '/portal/system/personalCenter/group/update',
    method: 'post',
    data: params
  });
}
export function delGroup(params) {
  return request({
    url: '/portal/system/personalCenter/group/delete',
    method: 'post',
    data: params
  });
}