var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticStyle: {
          background: "#fff",
          padding: "30px",
          "border-radius": "20px",
        },
        attrs: {
          "element-loading-text": "拼命打包中...",
          "element-loading-spinner": "el-icon-loading",
        },
      },
      [
        _c("div", { staticClass: "title" }, [_vm._v("APP云编译")]),
        _vm._v(" "),
        _c("el-divider"),
        _vm._v(" "),
        _c("div", { staticStyle: { color: "red" } }, [
          _vm._v("注意：编辑信息后，需点击启动才可生成新包!"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { "margin-top": "20px" } },
          [
            _c(
              "el-form",
              {
                staticClass: "demo-ruleForm",
                staticStyle: { "margin-top": "20px" },
                attrs: { "label-width": "120px" },
              },
              [
                _c(
                  "el-form-item",
                  [
                    _c(
                      "div",
                      {
                        staticClass: "both-label",
                        attrs: { slot: "label" },
                        slot: "label",
                      },
                      [
                        _c("i", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v("\n            平台\n          "),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.radio,
                          callback: function ($$v) {
                            _vm.radio = $$v
                          },
                          expression: "radio",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "0" } }, [
                          _vm._v("安卓"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: "1" } }, [
                          _vm._v("苹果"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "div",
                      {
                        staticClass: "both-label",
                        attrs: { slot: "label" },
                        slot: "label",
                      },
                      [
                        _c("i", { staticStyle: { color: "red" } }),
                        _vm._v("\n            APP名称\n          "),
                      ]
                    ),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "my-item",
                      staticStyle: { width: "400px" },
                      attrs: { readonly: true },
                      model: {
                        value: _vm.appName,
                        callback: function ($$v) {
                          _vm.appName = $$v
                        },
                        expression: "appName",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "div",
                      {
                        staticClass: "both-label",
                        attrs: { slot: "label" },
                        slot: "label",
                      },
                      [
                        _c("i", { staticStyle: { color: "red" } }),
                        _vm._v("\n            uniapp-appId\n          "),
                      ]
                    ),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "my-item",
                      staticStyle: { width: "400px" },
                      attrs: { readonly: true },
                      model: {
                        value: _vm.uni_appId,
                        callback: function ($$v) {
                          _vm.uni_appId = $$v
                        },
                        expression: "uni_appId",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "div",
                      {
                        staticClass: "both-label",
                        attrs: { slot: "label" },
                        slot: "label",
                      },
                      [
                        _c("i", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v("\n            版本号\n          "),
                      ]
                    ),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "my-item",
                      staticStyle: { width: "400px" },
                      model: {
                        value: _vm.versionName,
                        callback: function ($$v) {
                          _vm.versionName =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "versionName",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: { "font-size": "12px", color: "#969696" },
                      },
                      [_vm._v("升级时必须高于上一次设置的值，如（1.1.1）")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "div",
                      {
                        staticClass: "both-label",
                        attrs: { slot: "label" },
                        slot: "label",
                      },
                      [
                        _c("i", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v("\n            内建版本号\n          "),
                      ]
                    ),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "my-item",
                      staticStyle: { width: "400px" },
                      model: {
                        value: _vm.versionCode,
                        callback: function ($$v) {
                          _vm.versionCode =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "versionCode",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: { "font-size": "12px", color: "#969696" },
                      },
                      [
                        _vm._v(
                          "内建版本号，必须是整教，取值范围1~2147483647;升级时必须高于上一次设置的值"
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "div",
                      {
                        staticClass: "both-label",
                        attrs: { slot: "label" },
                        slot: "label",
                      },
                      [_vm._v("\n            备注\n          ")]
                    ),
                    _vm._v(" "),
                    _c("el-input", {
                      staticStyle: { width: "400px" },
                      attrs: {
                        type: "textarea",
                        autosize: "",
                        placeholder: "请输入内容",
                      },
                      model: {
                        value: _vm.remark,
                        callback: function ($$v) {
                          _vm.remark = $$v
                        },
                        expression: "remark",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticStyle: { "margin-top": "40px" } },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { width: "200px" },
                        attrs: { type: "primary" },
                        on: { click: _vm.save },
                      },
                      [_vm._v("启动")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { "margin-top": "40px" } },
          [
            _c("div", { staticClass: "title" }, [_vm._v("编译记录")]),
            _vm._v(" "),
            _c("el-divider"),
            _vm._v(" "),
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: _vm.tableData, border: "" },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "buildVersion",
                    label: "版本号",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "osType", label: "编译平台", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.osType === 0 ? "安卓" : "苹果") +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "status", label: "状态", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.statusObj[scope.row.status]) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "remark", label: "备注", align: "center" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "applyTime",
                    label: "申请时间",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "finishTime",
                    label: "完成时间",
                    align: "center",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "", label: "操作", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "fc" }, [
                            scope.row.status === 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "Operation_button",
                                    on: {
                                      click: function ($event) {
                                        return _vm.linkDown(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                下载链接\n              "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }