var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            title: "商品详情",
            width: "70%",
            top: "2vh",
            "custom-class": "dialog_box",
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                { attrs: { inline: true, "label-width": "75px" } },
                [
                  _c(
                    "el-form-item",
                    { staticStyle: { margin: "0" }, attrs: { label: "" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入商品id" },
                        model: {
                          value: _vm.goodsId,
                          callback: function ($$v) {
                            _vm.goodsId = $$v
                          },
                          expression: "goodsId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticStyle: { margin: "0" }, attrs: { label: "" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入商品名称" },
                        model: {
                          value: _vm.goodsName,
                          callback: function ($$v) {
                            _vm.goodsName = $$v
                          },
                          expression: "goodsName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticStyle: { margin: "0" }, attrs: { label: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.goodsType,
                            callback: function ($$v) {
                              _vm.goodsType = $$v
                            },
                            expression: "goodsType",
                          },
                        },
                        _vm._l(_vm.typeList, function (items) {
                          return _c("el-option", {
                            key: items.id,
                            attrs: { label: items.cname, value: items.cname },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticStyle: { margin: "0" }, attrs: { label: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择商品状态",
                          },
                          model: {
                            value: _vm.expired,
                            callback: function ($$v) {
                              _vm.expired = $$v
                            },
                            expression: "expired",
                          },
                        },
                        _vm._l(_vm.statusList, function (items) {
                          return _c("el-option", {
                            key: items.value,
                            attrs: { label: items.label, value: items.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { margin: "0 0 0 10px" },
                      attrs: { label: "" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.search()
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.add()
                            },
                          },
                        },
                        [_vm._v("添加商品")]
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { margin: "10px 0 0 0" },
                      attrs: { label: "" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.refresh({ id: _vm.item.id }, "all")
                            },
                          },
                        },
                        [_vm._v("同步商品信息")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: {
                            click: function ($event) {
                              _vm.moreBoole = true
                            },
                          },
                        },
                        [_vm._v("多选")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.moreBoole
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            margin: "10px 0 0 30px",
                            "border-left": "1px solid #DDDDDD",
                            "padding-left": "30px",
                          },
                          attrs: { label: "" },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "已选" +
                                _vm._s(
                                  _vm.list.filter(function (i, m) {
                                    return i.isShow
                                  }).length
                                ) +
                                "个商品 /" +
                                _vm._s(_vm.total)
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              staticStyle: { margin: "0 20px" },
                              attrs: { plain: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.noMore()
                                },
                              },
                            },
                            [_vm._v("取消多选")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-dropdown",
                            { on: { command: _vm.handleCommand } },
                            [
                              _c("span", { staticClass: "el-dropdown-link" }, [
                                _vm._v("\n              批量操作"),
                                _c("i", {
                                  staticClass:
                                    "el-icon-arrow-down el-icon--right",
                                }),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                [
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: 1 } },
                                    [_vm._v("批量编辑标签")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: 2 } },
                                    [_vm._v("批量删除标签")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: 3 } },
                                    [_vm._v("批量取消置顶")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: 4 } },
                                    [_vm._v("批量置顶")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: 5 } },
                                    [_vm._v("批量删除")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: {
                display: "flex",
                "justify-content": "flex-start",
                "flex-wrap": "wrap",
              },
            },
            _vm._l(_vm.list, function (items, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticStyle: {
                    width: "24%",
                    "margin-right": "1%",
                    "margin-top": "20px",
                    position: "relative",
                  },
                },
                [
                  items.tag
                    ? _c("img", {
                        staticStyle: {
                          position: "absolute",
                          left: "0",
                          width: "37px",
                          height: "37px",
                          "z-index": "998",
                        },
                        attrs: { src: items.tag },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  items.expired === 0 && _vm.moreBoole
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            left: "0",
                            "z-index": "1000",
                            background: "rgb(0, 0, 0,0.5)",
                            display: "flex",
                            "justify-content": "center",
                            "align-items": "center",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              class: items.isShow ? "active-btn" : "",
                              staticStyle: {
                                "border-radius": "17px",
                                padding: "5px 20px",
                                width: "100px",
                                "text-align": "center",
                                background: "white",
                                color: "#1890FF",
                                cursor: "pointer",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.btnClick(items)
                                },
                              },
                            },
                            [_vm._v(_vm._s(items.isShow ? "已选择" : "选择"))]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-card",
                    {
                      attrs: {
                        "body-style": { padding: "7px" },
                        shadow: "hover",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between",
                            position: "relative",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: { height: "104px", width: "104px" },
                            },
                            [
                              _c("el-image", {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: { src: items.picUrl, fit: "fill" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: { flex: "1", "margin-left": "7px" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "long-text",
                                  staticStyle: {
                                    "font-weight": "bold",
                                    color: "rgba(0,0,0,0.85)",
                                  },
                                },
                                [_vm._v(_vm._s(items.title))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "flex-start",
                                    "flex-wrap": "wrap",
                                    margin: "15px 0",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        background: "#FF004F",
                                        color: "#fff",
                                        padding: "0 5px",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "in-box",
                                          staticStyle: {
                                            "border-right": "1px dashed #fff",
                                            "padding-right": "5px",
                                            "box-sizing": "border-box",
                                          },
                                        },
                                        [_vm._v("返")]
                                      ),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "in-box" }, [
                                        _vm._v(
                                          _vm._s(items.goodsDetail.profit || 0)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        background: "#fff",
                                        color: "#FF004F",
                                        padding: "0 5px",
                                        border: "1px solid #FF004F",
                                        "margin-left": "3px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "in-box",
                                          staticStyle: {
                                            "border-right":
                                              "1px dashed #FF004F",
                                            "padding-right": "5px",
                                            "box-sizing": "border-box",
                                          },
                                        },
                                        [_vm._v("券")]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "in-box" }, [
                                        _vm._v(
                                          _vm._s(
                                            items.goodsDetail.couponAmount || 0
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "space-between",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-size": "12px",
                                          color: "#282828",
                                        },
                                      },
                                      [_vm._v("到手价")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-weight": "bold",
                                          color: "#FF0000",
                                          "font-size": "13px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "￥" +
                                            _vm._s(
                                              items.goodsDetail.actualPrice
                                            )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-size": "11px",
                                        color: "#969696",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  已卖" +
                                          _vm._s(items.goodsDetail.salesCount) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          items.expired === 1
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "z-index": "999",
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                    left: "0",
                                    top: "0",
                                    background: "rgb(0, 0, 0,0.5)",
                                    display: "flex",
                                    "justify-content": "center",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "70px",
                                      height: "44px",
                                    },
                                    attrs: {
                                      src: require("../../../../assets/time-out.png"),
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-top": "10px",
                            display: "flex",
                            "justify-content": "space-around",
                            "align-items": "center",
                          },
                        },
                        [
                          items.pinned === 0
                            ? _c(
                                "el-link",
                                {
                                  attrs: { underline: false },
                                  on: {
                                    click: function ($event) {
                                      return _vm.top([items.id])
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-top",
                                    staticStyle: {
                                      color: "#52C41A",
                                      "margin-right": "4px",
                                    },
                                  }),
                                  _vm._v("置顶"),
                                ]
                              )
                            : _c(
                                "el-link",
                                {
                                  staticStyle: { color: "#52C41A" },
                                  attrs: { underline: false },
                                  on: {
                                    click: function ($event) {
                                      return _vm.noTop([items.id])
                                    },
                                  },
                                },
                                [_vm._v("取消置顶")]
                              ),
                          _vm._v(" "),
                          _c(
                            "el-link",
                            {
                              attrs: {
                                underline: false,
                                icon: "el-icon-refresh",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.refresh(items)
                                },
                              },
                            },
                            [_vm._v("刷新")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-link",
                            {
                              attrs: { underline: false },
                              on: {
                                click: function ($event) {
                                  return _vm.details(items)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-edit",
                                staticStyle: {
                                  color: "#1890FF",
                                  "margin-right": "4px",
                                },
                              }),
                              _vm._v("编辑"),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-link",
                            {
                              attrs: { underline: false },
                              on: {
                                click: function ($event) {
                                  return _vm.del([items.id])
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-delete",
                                staticStyle: {
                                  color: "#FF0000",
                                  "margin-right": "4px",
                                },
                              }),
                              _vm._v("删除"),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("el-empty", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.list.length === 0,
                expression: "list.length===0",
              },
            ],
            attrs: { "image-size": 200 },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "pagination-container",
              staticStyle: { margin: "20px 0", "text-align": "right" },
            },
            [
              _c("el-pagination", {
                staticStyle: { display: "inline-block" },
                attrs: {
                  "current-page": _vm.page,
                  "page-sizes": [10, 20, 30, 50],
                  "page-size": _vm.limit,
                  total: _vm.total,
                  background: "",
                  layout: "total, prev, pager, next, sizes,jumper",
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("goodsDetail", { ref: "goodsDetail", on: { insetOk: _vm.mBuff } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }