"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
var _add = _interopRequireDefault(require("./components/add.vue"));
var _otherH5Page = require("@/api/otherH5Page");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: '',
  components: {
    addDialog: _add.default
  },
  data: function data() {
    return {
      name: '',
      list: [],
      page: 1,
      limit: 10,
      total: 0,
      listLoading: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    copy: function copy(val) {
      var _this = this;
      this.$copyText(val).then(function () {
        _this.$notify.success({
          title: '温馨提示',
          message: '拷贝成功'
        });
      }).catch(function () {
        _this.$notify.success({
          title: '温馨提示',
          message: '拷贝失败，请手动复制'
        });
      });
    },
    del: function del(val) {
      var _this2 = this;
      this.$confirm('确认删除该活动？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _otherH5Page.delAct)({
          id: val
        }).then(function (res) {
          if (res.data) {
            _this2.$message({
              type: 'success',
              message: '操作成功'
            });
            _this2.getList();
          }
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // 分页
    handleSizeChange: function handleSizeChange(val) {
      this.limit = val;
      this.getList();
    },
    // 当前分页
    handleCurrentChange: function handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    search: function search() {
      this.page = 1;
      this.getList();
    },
    getList: function getList() {
      var _this3 = this;
      this.listLoading = true;
      (0, _otherH5Page.listPageApi)({
        pageNo: this.page,
        limit: this.limit,
        name: this.name
      }).then(function (res) {
        _this3.total = res.data.total;
        _this3.list = res.data.data;
        _this3.listLoading = false;
      }).catch(function () {
        _this3.listLoading = false;
      });
    },
    details: function details(val) {
      console.log(val);
      this.$refs.addDialog.open((0, _objectSpread2.default)({
        openType: '2',
        title: '活动编辑'
      }, val));
    },
    onAdd: function onAdd() {
      this.$refs.addDialog.open({
        openType: '1',
        title: '活动添加'
      });
    },
    refresh: function refresh(val) {
      console.log(val);
      if (val === '1') {
        this.name = '';
        this.page = 1;
      }
      this.getList();
    }
  }
};