var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "withdrawal_management" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("活动管理")]),
      _vm._v(" "),
      _c("div", { staticClass: "search_criteria" }, [
        _c(
          "div",
          { staticStyle: { "margin-left": "0" } },
          [
            _c("el-input", {
              attrs: { placeholder: "输入活动名称查询", clearable: "" },
              model: {
                value: _vm.queryData.activityName,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "activityName", $$v)
                },
                expression: "queryData.activityName",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: "选择活动状态", clearable: "" },
                model: {
                  value: _vm.queryData.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "status", $$v)
                  },
                  expression: "queryData.status",
                },
              },
              [
                _c("el-option", { attrs: { value: 0, label: "未开始" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 1, label: "进行中" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 2, label: "已结束" } }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "flex",
            staticStyle: { width: "fit-content", height: "40px" },
          },
          [
            _c(
              "el-button",
              {
                staticStyle: { height: "40px" },
                attrs: { type: "primary" },
                on: { click: _vm.onInquire },
              },
              [_vm._v("查询")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "ml10",
                staticStyle: { height: "40px" },
                attrs: { type: "info", plain: "" },
                on: { click: _vm.onInitialize },
              },
              [_vm._v("重置")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                attrs: { type: "primary", plain: "" },
                on: { click: _vm.onAdd },
              },
              [_vm._v("新增+")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "flex ",
            staticStyle: {
              border: "1px solid red",
              "border-radius": "10px",
              width: "fit-content",
              padding: "0 10px",
              "font-size": "16px",
              color: "red",
            },
          },
          [
            _c("div", { staticClass: "fc" }, [
              _vm._v("推广位总数：" + _vm._s(_vm.getTljPidUseInfoData.total)),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "fc", staticStyle: { "margin-left": "20px" } },
              [_vm._v("已占用：" + _vm._s(_vm.getTljPidUseInfoData.useNum))]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { "margin-top": "24px" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.tableLoading,
                expression: "tableLoading",
              },
            ],
            staticStyle: {
              display: "flex",
              "justify-content": "flex-start",
              "flex-wrap": "wrap",
            },
          },
          _vm._l(_vm.tableData, function (item) {
            return _c("ItemActivity", {
              key: item.id,
              staticStyle: {
                width: "19%",
                "margin-right": "1%",
                "margin-top": "20px",
              },
              attrs: { item: item },
              on: {
                controls: function ($event) {
                  return _vm.controls($event)
                },
              },
            })
          }),
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "活动" + (_vm.eventsType === "add" ? "添加" : "编辑"),
            visible: _vm.dialogVisible,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.handleClose,
          },
        },
        [
          _vm.dialogVisible
            ? _c("div", { staticClass: "fc" }, [
                _c("div", { staticStyle: { width: "800px" } }, [
                  _c(
                    "div",
                    [
                      _c(
                        "el-form",
                        {
                          ref: "ruleForm",
                          staticClass: "demo-ruleForm",
                          attrs: {
                            model: _vm.ruleForm,
                            rules: _vm.rules,
                            "label-width": "120px",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "活动名称",
                                prop: "activityName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.ruleForm.activityName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "activityName", $$v)
                                  },
                                  expression: "ruleForm.activityName",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "目标用户",
                                prop: "targetUserType",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.ruleForm.targetUserType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "targetUserType",
                                        $$v
                                      )
                                    },
                                    expression: "ruleForm.targetUserType",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("未出单用户"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 2 } }, [
                                    _vm._v("90天未出单用户"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 3 } }, [
                                    _vm._v("所有人"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "每人限购次数",
                                prop: "subsidyNum",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.ruleForm.subsidyNum,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "subsidyNum",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "ruleForm.subsidyNum",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "头图", prop: "headerPic" } },
                            [
                              _c(
                                "div",
                                { staticClass: "frb" },
                                [
                                  _c("upload", {
                                    attrs: {
                                      name: "headerPic",
                                      "img-url": _vm.ruleForm.headerPic,
                                    },
                                    on: {
                                      pictureAddress: function ($event) {
                                        return _vm.onPictureAddress($event)
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "教程图",
                                        prop: "tutorialsSharePic",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex" },
                                        [
                                          _c("upload", {
                                            attrs: {
                                              name: "tutorialsSharePic",
                                              "img-url":
                                                _vm.ruleForm.tutorialsSharePic,
                                            },
                                            on: {
                                              pictureAddress: function (
                                                $event
                                              ) {
                                                return _vm.onPictureAddress(
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "活动分享海报",
                                prop: "activitySharePic",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "frb" },
                                [
                                  _c("upload", {
                                    attrs: {
                                      name: "activitySharePic",
                                      "img-url": _vm.ruleForm.activitySharePic,
                                    },
                                    on: {
                                      pictureAddress: function ($event) {
                                        return _vm.onPictureAddress($event)
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "标签图",
                                        prop: "tagPic",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex" },
                                        [
                                          _c("upload", {
                                            attrs: {
                                              name: "tagPic",
                                              "img-url": _vm.ruleForm.tagPic,
                                            },
                                            on: {
                                              pictureAddress: function (
                                                $event
                                              ) {
                                                return _vm.onPictureAddress(
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "背景色值", prop: "color" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入色号，例：“#FFFFFF”",
                                },
                                model: {
                                  value: _vm.ruleForm.color,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "color", $$v)
                                  },
                                  expression: "ruleForm.color",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "活动规则文案",
                                prop: "activityTxt",
                              },
                            },
                            [
                              _vm.dialogVisible
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          border: "1px solid #ccc",
                                        },
                                      },
                                      [
                                        _c("Toolbar", {
                                          staticStyle: {
                                            "border-bottom": "1px solid #ccc",
                                          },
                                          attrs: {
                                            editor: _vm.editor,
                                            "default-config": _vm.toolbarConfig,
                                            mode: _vm.mode,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("Editor", {
                                          staticStyle: {
                                            height: "450px",
                                            "overflow-y": "hidden",
                                          },
                                          attrs: {
                                            "default-config": _vm.editorConfig,
                                            mode: _vm.mode,
                                          },
                                          on: { onCreated: _vm.onCreated },
                                          model: {
                                            value: _vm.ruleForm.activityTxt,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleForm,
                                                "activityTxt",
                                                $$v
                                              )
                                            },
                                            expression: "ruleForm.activityTxt",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "活动时间" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "datetimerange",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  "default-time": ["00:00:00", "23:59:59"],
                                },
                                model: {
                                  value: _vm.ruleForm.timeArray,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "timeArray", $$v)
                                  },
                                  expression: "ruleForm.timeArray",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submitForm("ruleForm")
                                    },
                                  },
                                },
                                [_vm._v("确认")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.resetForm("ruleForm")
                                    },
                                  },
                                },
                                [_vm._v("重置")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 page_box" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.queryData.page.page,
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.queryData.page.size,
              layout: "total, prev, pager, next, sizes,jumper",
              total: _vm.total,
              background: "",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }