"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getManifest = getManifest;
exports.getPack = getPack;
exports.listPack = listPack;
exports.runPack = runPack;
exports.saveManifest = saveManifest;
exports.searchInfo = searchInfo;
exports.stopPack = stopPack;
var _request = _interopRequireDefault(require("@/utils/request"));
function saveManifest(data) {
  return (0, _request.default)({
    url: '/portal/system/package/saveManifest',
    method: 'post',
    data: data
  });
}
function getManifest(params) {
  return (0, _request.default)({
    url: '/portal/system/package/getManifest',
    method: 'get',
    params: params
  });
}
function stopPack(data) {
  return (0, _request.default)({
    url: '/portal/system/package/stopPack',
    method: 'post',
    data: data
  });
}
function runPack(data) {
  return (0, _request.default)({
    url: '/portal/system/package/runPack',
    method: 'post',
    data: data
  });
}
function getPack(params) {
  return (0, _request.default)({
    url: '/portal/system/package/getPack',
    method: 'get',
    params: params
  });
}
function searchInfo(params) {
  return (0, _request.default)({
    url: '/portal/system/tenant/queryAssembleInfo',
    method: 'get',
    params: params
  });
}
function listPack(params) {
  return (0, _request.default)({
    url: '/portal/system/package/listPack',
    method: 'get',
    params: params
  });
}