// 二级营销位
import request from '@/utils/request';
// 获取该类型下所有的位置信息
export function getBusinessCollege(params) {
  return request({
    url: '/portal/system/marketing/getLocations',
    method: 'get',
    params: params
  });
}

// 分页查询
export function marketingList(data) {
  return request({
    url: '/portal/system/marketing/list',
    method: 'post',
    data: data
  });
}
// 保存
export function marketingSave(data) {
  return request({
    url: '/portal/system/marketing/save',
    method: 'post',
    data: data
  });
}
// 获取单个营销位信息
export function marketingGetById(params) {
  return request({
    url: '/portal/system/marketing/getById',
    method: 'get',
    params: params
  });
}
// 修改
export function marketingUpdate(data) {
  return request({
    url: '/portal/system/marketing/update',
    method: 'post',
    data: data
  });
}