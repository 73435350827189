var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "header_box" }, [
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-arrow-left" },
              on: { click: _vm.onBlack },
            },
            [_vm._v("返回")]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "name_span" }, [_vm._v("订单详情")]),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { padding: "0 40px 40px 40px" } },
      [
        _c(
          "el-tabs",
          {
            model: {
              value: _vm.activeName,
              callback: function ($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName",
            },
          },
          [
            _c("el-tab-pane", { attrs: { label: "订单详情", name: "first" } }, [
              _c(
                "div",
                { staticClass: "flex", staticStyle: { "margin-top": "40px" } },
                [
                  _c("div", { staticClass: "img_icon" }, [
                    _c("img", {
                      staticClass: "img_icon",
                      attrs: { src: _vm.order.itemImage, alt: "" },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml20" }, [
                    _c("div", { staticClass: "title_txt" }, [
                      _vm._v(_vm._s(_vm.order.title)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "title_txt1" }, [
                      _vm._v("付款金额：¥" + _vm._s(_vm.order.amount)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "title_txt2" }, [
                      _vm._v("付款时间：" + _vm._s(_vm.order.payTime)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "title_txt2" }, [
                      _vm._v("店铺名称：" + _vm._s(_vm.order.shopName)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "title_txt2" }, [
                      _vm._v(
                        "平台类型：" +
                          _vm._s(_vm.PlatformType[_vm.order.unionCode])
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { width: "100%" } }, [
                      _vm._v(
                        "\n              --------------------------------------------------------------------------------------------------------------------------------\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", {}, [
                      _c("span", { staticClass: "txt3" }, [
                        _vm._v("订单编号：" + _vm._s(_vm.order.orderNo)),
                      ]),
                      _vm._v(" "),
                      _c("img", {
                        staticStyle: {
                          width: "20px",
                          height: "20px",
                          "margin-left": "10px",
                        },
                        attrs: {
                          src: require("@/assets/copy_icon.png"),
                          alt: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.copyText(_vm.order.orderNo)
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "mt6 txt3 pointer",
                        on: {
                          click: function ($event) {
                            return _vm.onUserDetails({
                              splitMemberId: _vm.order.memberId,
                              splitMemberName: _vm.order.memberName,
                            })
                          },
                        },
                      },
                      [
                        _vm._v("\n              下单用户："),
                        _c("span", { staticClass: "span1" }, [
                          _vm._v(
                            _vm._s(_vm.order.memberName) +
                              "（用户ID：" +
                              _vm._s(_vm.order.memberId) +
                              "）"
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt6 txt3" }, [
                      _vm._v(
                        "\n              订单状态：" +
                          _vm._s(_vm.order.status) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt6 txt3" }, [
                      _vm._v(
                        "\n              订单类型：" +
                          _vm._s(
                            _vm.order.type === 0 ? "正常订单" : "违规订单"
                          ) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.order.cancelReason
                      ? _c("div", { staticClass: "mt6 txt3" }, [
                          _vm._v("处理原因：" + _vm._s(_vm.order.cancelReason)),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt6 txt3" }, [
                      _vm._v(
                        "\n              特殊业务订单类型：\n              "
                      ),
                      _vm.order.subType === 0
                        ? _c("span", [_vm._v("正常订单")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.order.subType === 1
                        ? _c("span", [_vm._v("淘礼金订单")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.order.subType === 4
                        ? _c("span", [_vm._v("京东价保订单")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.order.subType === 7
                        ? _c("span", [_vm._v("维权订单")])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt6 txt3" }, [
                      _vm._v("佣金比例：" + _vm._s(_vm.order.incomeRate) + "%"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt6 txt3" }, [
                      _vm._v(
                        "\n              预估佣金(未去除服务费)：¥" +
                          _vm._s(_vm.order.feeOrigin) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt6 txt3" }, [
                      _vm._v(
                        "\n              预估佣金(去除服务费)：¥" +
                          _vm._s(_vm.order.feeShare) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mt6 txt3" }, [
                      _vm._v("结算状态：" + _vm._s(_vm.order.settleStatus)),
                    ]),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("el-tab-pane", { attrs: { label: "分佣明细", name: "third" } }, [
              _c(
                "div",
                { staticClass: "mt10" },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.splitList, border: "" },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "sort",
                          label: "订单编号",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", { staticClass: "flex" }, [
                                  _c("div", { staticClass: "fc" }, [
                                    _c("div", [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(scope.row.orderNo) +
                                          "\n                    "
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "fc" }, [
                                    _c("img", {
                                      staticStyle: {
                                        width: "20px",
                                        height: "20px",
                                        "margin-left": "10px",
                                      },
                                      attrs: {
                                        src: require("@/assets/copy_icon.png"),
                                        alt: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.copyText(scope.row.orderNo)
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "sort",
                          label: "会员昵称",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "span1 pointer",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onUserDetails(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(scope.row.splitMemberName) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "title_txt2" }, [
                                  _vm._v(
                                    "\n                  UID:" +
                                      _vm._s(scope.row.splitMemberId) +
                                      "\n                "
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "preFee",
                          label: "总分佣金额",
                          align: "center",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "splitRate",
                          label: "分佣比例",
                          align: "center",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "splitAmount",
                          label: "分账金额",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                Number(scope.row.splitAmount) < 0
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(scope.row.splitAmount) +
                                            "\n                "
                                        ),
                                      ]
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(scope.row.splitAmount) +
                                          "\n                "
                                      ),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "incomeType",
                          label: "分账类型",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.incomeType === "self"
                                  ? _c("div", { staticClass: "span1" }, [
                                      _vm._v(
                                        "\n                  自购\n                "
                                      ),
                                    ])
                                  : _c("div", { staticClass: "span1" }, [
                                      _vm._v("团队"),
                                    ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "settleStatus",
                          label: "结算状态",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.settleStatus === 1
                                  ? _c("div", [_vm._v("已结算")])
                                  : _c("div", [_vm._v("未结算")]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "createTime",
                          label: "支付下单时间",
                          align: "center",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "sort",
                          label: "结算到余额时间",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.settleTime
                                  ? _c("div", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(scope.row.settleTime) +
                                          "\n                "
                                      ),
                                    ])
                                  : _c("div", [_vm._v("-")]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }