var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "details_box" },
    [
      _c("div", { staticClass: "header_box" }, [
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-arrow-left" },
                on: { click: _vm.onBlack },
              },
              [_vm._v("返回")]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "name_span" }, [
              _vm._v("会员（" + _vm._s(_vm.nickname) + "）明细"),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "fc", staticStyle: { "margin-top": "30px" } },
        _vm._l(_vm.nameList, function (item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "title_type fc",
              class: item.id === _vm.selectIndex ? "select_title_style" : "",
              on: {
                click: function ($event) {
                  return _vm.onSelect(item)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(item.name) + "\n    ")]
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.selectIndex === "gk"
        ? _c("generalSituation", {
            key: _vm.times,
            attrs: { "member-id": _vm.memberId },
            on: {
              AccountDetails: function ($event) {
                return _vm.AccountDetails($event)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectIndex === "gxl"
        ? _c("RelationChain", {
            key: _vm.times,
            attrs: { "member-id": _vm.memberId },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectIndex === "fs"
        ? _c("Vermicelli", { attrs: { "member-id": _vm.memberId } })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectIndex === "zgdd"
        ? _c("PurchaseOrder", { attrs: { "member-id": _vm.memberId } })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectIndex === "tgdd"
        ? _c("PromotionOrder", { attrs: { "member-id": _vm.memberId } })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectIndex === "zgzh"
        ? _c("PurchaseAccount", { attrs: { "member-id": _vm.memberId } })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectIndex === "tgzh"
        ? _c("PromotionAccount", { attrs: { "member-id": _vm.memberId } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }