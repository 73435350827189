"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _homeTrim = _interopRequireDefault(require("../appRenovation/component/homeTrim.vue"));
var _userCenter = _interopRequireDefault(require("../appRenovation/component/userCenter.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: '',
  components: {
    HomeTrim: _homeTrim.default,
    userCenter: _userCenter.default
  },
  data: function data() {
    return {
      tabList: [{
        name: '首页装修',
        id: 0
      }, {
        name: '个人中心装修',
        id: 1
      }],
      activeNum: 0
    };
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    changeType: function changeType(val) {
      this.activeNum = val;
    }
  }
};