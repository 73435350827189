"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAppId = getAppId;
exports.getToken = getToken;
exports.removeAppId = removeAppId;
exports.removeToken = removeToken;
exports.setAppId = setAppId;
exports.setToken = setToken;
exports.transformTreeToRoutes = transformTreeToRoutes;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var TokenKey = 'comprehensiveShopping_admin_token'; // 请求需要的token

function getToken() {
  return _jsCookie.default.get(TokenKey);
}
function setToken(token) {
  return _jsCookie.default.set(TokenKey, token);
}
function removeToken() {
  sessionStorage.clear();
  return _jsCookie.default.remove(TokenKey);
}
function transformTreeToRoutes(tree) {
  return tree;
}
function setAppId(appId) {
  return _jsCookie.default.set('appId', appId);
}
function getAppId() {
  return _jsCookie.default.get('appId') || '';
}
function removeAppId() {
  return _jsCookie.default.remove('appId');
}