var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Platform detail" }, [
    _c("div", { staticClass: "header_box" }, [
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-arrow-left" },
              on: { click: _vm.onBlack },
            },
            [_vm._v("返回")]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "name_span" }, [_vm._v("各平台结算明细")]),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "tabulation" }, [
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.dataList, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "nickname", label: "平台类型", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "nickname", label: "结算金额", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "环比上月", align: "center" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }