"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.auditFiling = auditFiling;
exports.batchAuditFiling = batchAuditFiling;
exports.listWithdrawFilingPaged = listWithdrawFilingPaged;
var _request = _interopRequireDefault(require("@/utils/request"));
// 提现列表
function listWithdrawFilingPaged(params) {
  return (0, _request.default)({
    url: '/portal/system/withdraw/listWithdrawFilingPaged',
    method: 'get',
    params: params
  });
}
// 审核备案
function auditFiling(data) {
  return (0, _request.default)({
    url: '/portal/system/withdraw/auditFiling',
    method: 'post',
    data: data
  });
}
// 批量审核备案
function batchAuditFiling(data) {
  return (0, _request.default)({
    url: '/portal/system/withdraw/batchAuditFiling',
    method: 'post',
    data: data
  });
}