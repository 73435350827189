import request from '@/utils/request';
// 用户反馈列表
export function queryMemberSuggestsApi(data) {
  return request({
    url: '/portal/system/member/queryMemberSuggests',
    method: 'post',
    data: data
  });
}
// 意见回复
export function suggestRecoverApi(data) {
  return request({
    url: '/portal/system/member/suggestRecover',
    method: 'post',
    data: data
  });
}