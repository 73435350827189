var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            title: _vm.conditionType.name,
            width: "750px",
            top: "2vh",
            "custom-class": "dialog_box",
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "div",
            { staticClass: "from_box" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "130px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "聚合页名称:", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入聚合页名称" },
                        model: {
                          value: _vm.ruleForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "name", $$v)
                          },
                          expression: "ruleForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "聚合页背景色值:", prop: "colorValue" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入聚合页背景色值" },
                            model: {
                              value: _vm.ruleForm.colorValue,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "colorValue", $$v)
                              },
                              expression: "ruleForm.colorValue",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "fc" },
                            [
                              _c("el-color-picker", {
                                model: {
                                  value: _vm.ruleForm.colorValue,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "colorValue", $$v)
                                  },
                                  expression: "ruleForm.colorValue",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "ml10",
                          staticStyle: {
                            color: "#333333",
                            "font-weight": "600",
                          },
                        },
                        [_vm._v("\n            聚合页内容编辑:\n          ")]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.ruleForm.activityList, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "activity_item" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "100px",
                                  label: "活动图片:",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      width: "200px",
                                      height: "100px",
                                      background: "#f9f9f9",
                                      "border-radius": "4px",
                                      border: "1px solid #eeeeee",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-upload",
                                      {
                                        staticClass: "avatar-uploader",
                                        attrs: {
                                          "show-file-list": false,
                                          "on-success": _vm.handleAvatarSuccess,
                                          action: "",
                                          "http-request": _vm.handleFileUpload,
                                          disabled: "",
                                        },
                                      },
                                      [
                                        item.activityPic
                                          ? _c("img", {
                                              staticClass: "avatar",
                                              attrs: { src: item.activityPic },
                                            })
                                          : _c("i", {
                                              staticClass:
                                                "el-icon-plus avatar-uploader-icon",
                                            }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "100px",
                                  label: "平台类型:",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex" },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "300px" },
                                      attrs: {
                                        disabled: "",
                                        placeholder: "活动选择后获取",
                                      },
                                      model: {
                                        value:
                                          _vm.PlatformTypeList[item.unionCode],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.PlatformTypeList,
                                            item.unionCode,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "PlatformTypeList[item.unionCode]",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "pointer",
                                        staticStyle: { color: "#389bff" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onOfficialEvent(index)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  去选择活动\n                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "100px",
                                  label: "排序设置:",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "请输入序号" },
                                  model: {
                                    value: item.sort,
                                    callback: function ($$v) {
                                      _vm.$set(item, "sort", _vm._n($$v))
                                    },
                                    expression: "item.sort",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "fc pointer del_btn",
                                staticStyle: {
                                  "font-size": "14px",
                                  color: "#e96e7e",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDelNewActivity(index)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "20px",
                                    height: "20px",
                                    "margin-left": "10px",
                                  },
                                  attrs: {
                                    src: require("@/assets/LittleActivityCreation/delete.png"),
                                    alt: "",
                                  },
                                }),
                                _vm._v("\n              删除\n            "),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mt20", attrs: { "label-width": "50px" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v("确认")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.resetForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: { click: _vm.onNewActivity },
                        },
                        [_vm._v("新增活动")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("ActivitySelectionPopup", {
            ref: "activitySelectionPopup",
            on: { SelectEvents: _vm.onSelectEvents },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }