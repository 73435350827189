var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            title: _vm.conditionType.name,
            width: "750px",
            top: "10vh",
            "custom-class": "dialog_box",
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "div",
            { staticClass: "from_box" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动名称:", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入活动名称" },
                        model: {
                          value: _vm.ruleForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "name", $$v)
                          },
                          expression: "ruleForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "平台类型:", prop: "unionCode" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择平台类型" },
                          model: {
                            value: _vm.ruleForm.unionCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "unionCode", $$v)
                            },
                            expression: "ruleForm.unionCode",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "淘宝", value: "TB" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "京东", value: "JD" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "抖音", value: "DY" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "拼多多", value: "PDD" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "唯品会", value: "WPH" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "背景色值:", prop: "colorValue" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输背景色值" },
                            model: {
                              value: _vm.ruleForm.colorValue,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "colorValue", $$v)
                              },
                              expression: "ruleForm.colorValue",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "fc" },
                            [
                              _c("el-color-picker", {
                                model: {
                                  value: _vm.ruleForm.colorValue,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "colorValue", $$v)
                                  },
                                  expression: "ruleForm.colorValue",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "链接类型", prop: "type" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ruleForm.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "type", $$v)
                            },
                            expression: "ruleForm.type",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 0 },
                              model: {
                                value: _vm.ruleForm.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "type", $$v)
                                },
                                expression: "ruleForm.type",
                              },
                            },
                            [_vm._v("活动ID")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              model: {
                                value: _vm.ruleForm.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "type", $$v)
                                },
                                expression: "ruleForm.type",
                              },
                            },
                            [_vm._v("链接URL")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "unionIds" } },
                    [
                      _vm.ruleForm.type === 0
                        ? _c("el-input", {
                            staticStyle: { width: "420px" },
                            attrs: { placeholder: "请输入活动ID" },
                            model: {
                              value: _vm.ruleForm.unionIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "unionIds", $$v)
                              },
                              expression: "ruleForm.unionIds",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.ruleForm.type === 1
                        ? _c("el-input", {
                            staticStyle: { width: "420px" },
                            attrs: { placeholder: "请输入活动URL" },
                            model: {
                              value: _vm.ruleForm.unionIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "unionIds", $$v)
                              },
                              expression: "ruleForm.unionIds",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "头图:", prop: "mainImage" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "130px",
                            height: "130px",
                            background: "#f9f9f9",
                            "border-radius": "4px",
                            border: "1px solid #eeeeee",
                          },
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "avatar-uploader",
                              attrs: {
                                "show-file-list": false,
                                "on-success": _vm.handleAvatarSuccess,
                                action: "",
                                "http-request": _vm.handleFileUpload,
                              },
                            },
                            [
                              _vm.ruleForm.mainImage
                                ? _c("img", {
                                    staticClass: "avatar",
                                    attrs: { src: _vm.ruleForm.mainImage },
                                  })
                                : _c("i", {
                                    staticClass:
                                      "el-icon-plus avatar-uploader-icon",
                                  }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "推广文案:", prop: "promoteDesc" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 3,
                              placeholder: "请输入推广文案",
                            },
                            model: {
                              value: _vm.ruleForm.promoteDesc,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "promoteDesc", $$v)
                              },
                              expression: "ruleForm.promoteDesc",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "活动时间", prop: "" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetimerange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          "default-time": ["00:00:00", "23:59:59"],
                        },
                        model: {
                          value: _vm.ruleForm.timeArray,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "timeArray", $$v)
                          },
                          expression: "ruleForm.timeArray",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v("确认")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.resetForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }