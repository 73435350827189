// 用户管理
import request from '@/utils/request';
// 添加人员
export function addUserApi(data) {
  return request({
    url: '/portal/system/user/addUser',
    method: 'post',
    data: data
  });
}
// 删除人员
export function deleteUserApi(params) {
  return request({
    url: '/portal/system/user/deleteUser',
    method: 'get',
    params: params
  });
}
// 人员列表
export function queryUsersApi(params) {
  return request({
    url: '/portal/system/user/queryUsers',
    method: 'get',
    params: params
  });
}

// 修改人员
export function updateUserApi(data) {
  return request({
    url: '/portal/system/user/updateUser',
    method: 'post',
    data: data
  });
}