var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "giveaway_box" }, [
    _c("div", { staticClass: "giveaway_name" }, [_vm._v("区域分佣配置")]),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { "margin-top": "40px" } },
      [
        _c(
          "el-form",
          {
            ref: "ruleForm",
            staticClass: "demo-ruleForm",
            attrs: { model: _vm.ruleForm, "label-width": "100px" },
          },
          [
            _c("el-form-item", { attrs: { label: "配置内容" } }, [
              _c("div", { staticClass: "scale_box " }, [
                _c(
                  "span",
                  {
                    staticClass: "ml10",
                    staticStyle: { "font-size": "14px", color: "#282828" },
                  },
                  [_vm._v(" 区域合伙人分佣机制")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("el-form-item", { attrs: { label: "分佣占比" } }, [
              _c("div", { staticClass: "scale_box" }, [
                _c("span", { staticClass: "ml10" }, [
                  _vm._v(_vm._s(_vm.ruleForm.rate) + "%"),
                ]),
                _vm._v(" "),
                _c("img", {
                  staticClass: "pointer",
                  staticStyle: { width: "26px", height: "26px" },
                  attrs: {
                    src: require("@/assets/personnelManage/editor.png"),
                    alt: "",
                  },
                  on: { click: _vm.onEditor },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "配置状态", prop: "status" } },
              [
                _c("el-switch", {
                  on: { change: _vm.onSwitchChange },
                  model: {
                    value: _vm.ruleForm.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "status", $$v)
                    },
                    expression: "ruleForm.status",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              "close-on-click-modal": false,
              visible: _vm.dialogVisible,
              width: "550px",
            },
            on: { close: _vm.onclose },
          },
          [
            _c("div", { staticClass: "fc txt_box" }, [
              _vm._v("编辑区域分佣占比"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "fc", staticStyle: { "margin-top": "15px" } },
              [
                _c(
                  "el-input",
                  {
                    staticStyle: { width: "260px" },
                    attrs: { placeholder: "请输入分佣占比" },
                    on: { input: _vm.handleInput },
                    model: {
                      value: _vm.ruleForm.rate,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "rate", $$v)
                      },
                      expression: "ruleForm.rate",
                    },
                  },
                  [_c("template", { slot: "append" }, [_vm._v("%")])],
                  2
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "fc", staticStyle: { "margin-top": "20px" } },
              [
                _c(
                  "el-button",
                  { staticClass: "btn_box", on: { click: _vm.onEditorVerify } },
                  [_vm._v("确认")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }