import request from '@/utils/request';
// 发圈-个性化
// 查询素材圈基础配置
export function getMaterialBaseConfigApi(params) {
  return request({
    url: '/portal/system/material/baseConfig/getMaterialBaseConfig',
    method: 'get',
    params: params
  });
}
// 保存素材圈基础配置
export function saveMaterialBaseConfigApi(data) {
  return request({
    url: '/portal/system/material/baseConfig/saveMaterialBaseConfig',
    method: 'post',
    data: data
  });
}
// 修改素材圈基础配置
export function updateMaterialBaseConfigApi(data) {
  return request({
    url: '/portal/system/material/baseConfig/updateMaterialBaseConfig',
    method: 'post',
    data: data
  });
}

// 发圈素材*****************************************************************************************
// 查询素材圈管理
export function getMaterialManagerApi(data) {
  return request({
    url: '/portal/system/material/manager/getMaterialManager',
    method: 'post',
    data: data
  });
}
// 保存素材圈管理
export function saveMaterialManagerApi(data) {
  return request({
    url: '/portal/system/material/manager/saveMaterialManager',
    method: 'post',
    data: data
  });
}
// 修改素材圈管理
export function updateMaterialManagerApi(data) {
  return request({
    url: '/portal/system/material/manager/updateMaterialManager',
    method: 'post',
    data: data
  });
}
// 删除素材圈管理
export function deleteMaterialManagerApi(data) {
  return request({
    url: '/portal/system/material/manager/deleteMaterialManager',
    method: 'post',
    data: data
  });
}
// 获取素材圈单个配置
export function getByIdApi(params) {
  return request({
    url: '/portal/system/material/manager/getById',
    method: 'get',
    params: params
  });
}
// 上下线
export function materialUpdateStatus(data) {
  return request({
    url: '/portal/system/material/manager/updateStatus',
    method: 'post',
    data: data
  });
}
// 发圈分类********************************************************************************************
// 删除素材圈分类配置
export function deleteMaterialCategoryApi(data) {
  return request({
    url: '/portal/system/material/category/deleteMaterialCategory',
    method: 'post',
    data: data
  });
}
// 分类上下线
export function updateStatusApi(data) {
  return request({
    url: '/portal/system/material/category/updateStatus',
    method: 'post',
    data: data
  });
}
// 查询所属子节点分类配置
export function getMaterialCategoryApi(params) {
  return request({
    url: '/portal/system/material/category/getMaterialCategory',
    method: 'get',
    params: params
  });
}
// 查询所有配置
export function categoryListApi(params) {
  return request({
    url: '/portal/system/material/category/list',
    method: 'get',
    params: params
  });
}

// 保存素材圈分类配置
export function saveMaterialCategoryApi(data) {
  return request({
    url: '/portal/system/material/category/saveMaterialCategory',
    method: 'post',
    data: data
  });
}
// 修改素材圈分类配置
export function updateMaterialCategoryApi(data) {
  return request({
    url: '/portal/system/material/category/updateMaterialCategory',
    method: 'post',
    data: data
  });
}
// 查询单个分类配置
export function getMaterialCategoryByIdApi(params) {
  return request({
    url: '/portal/system/material/category/getMaterialCategoryById',
    method: 'get',
    params: params
  });
}