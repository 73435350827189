var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "CustomOfficialEvents" }, [
    _c("div", { staticClass: "mt10 frb" }, [
      _c(
        "div",
        { staticClass: "flex" },
        _vm._l(_vm.PlatformData, function (item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "fc Platform_type pointer",
              class:
                _vm.queryData.unionCode === item.id ? "Platform_type1" : "",
              on: {
                click: function ($event) {
                  return _vm.onPlatform(item)
                },
              },
            },
            [_vm._v("\n        " + _vm._s(item.name) + "\n      ")]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "search_criteria" }, [
        _c(
          "div",
          [
            _c("el-input", {
              attrs: { placeholder: "活动名称", clearable: "" },
              model: {
                value: _vm.queryData.name,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "name", $$v)
                },
                expression: "queryData.name",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: "状态", clearable: "" },
                model: {
                  value: _vm.queryData.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "status", $$v)
                  },
                  expression: "queryData.status",
                },
              },
              [
                _c("el-option", { attrs: { value: 0, label: "未开始" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 1, label: "进行中" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 2, label: "已结束" } }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex", staticStyle: { width: "fit-content" } },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.onInquire } },
              [_vm._v("查询")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "ml10",
                attrs: { type: "info", plain: "" },
                on: { click: _vm.onInitialize },
              },
              [_vm._v("重置")]
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", [
      _c(
        "div",
        {
          staticClass: "mt10",
          staticStyle: { "overflow-y": "auto", width: "100%", height: "600px" },
        },
        [
          _c(
            "div",
            { staticClass: "flex fw" },
            _vm._l(_vm.tableData, function (item, index) {
              return _c("div", { key: index, staticClass: "details_box" }, [
                _c("div", { staticClass: "details_img" }, [
                  item.mainImage
                    ? _c("img", { attrs: { src: item.mainImage, alt: "" } })
                    : _c("img", {
                        attrs: {
                          src: require("@/assets/LittleActivityCreation/acquiesce_icon.png"),
                          alt: "",
                        },
                      }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "details_txt" }, [
                  _c("div", { staticClass: "txt_title ellipsis-multiline" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "time mt4" }, [
                    _vm._v("开始时间：" + _vm._s(item.startTime)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "time mt4" }, [
                    _vm._v("结束时间:" + _vm._s(item.endTime)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "frb mt4" }, [
                    _c("div", { staticClass: "fc" }, [
                      item.status === 1
                        ? _c("div", { staticClass: "details_style_1 fc" }, [
                            _vm._v("进行中"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      item.status === 0
                        ? _c("div", { staticClass: "details_style_2 fc" }, [
                            _vm._v("未开始"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      item.status === 2
                        ? _c("div", { staticClass: "details_style_3 fc" }, [
                            _vm._v("已结束"),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "details_select pointer",
                        on: {
                          click: function ($event) {
                            return _vm.onSelectEvents(item)
                          },
                        },
                      },
                      [_vm._v("√选择")]
                    ),
                  ]),
                ]),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20 page_box" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.queryData.page.page,
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.queryData.page.size,
                  layout: "total, prev, pager, next, sizes,jumper",
                  total: _vm.pagination.total,
                  background: "",
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }