// 通用活动创建
import request from '@/utils/request';
// 查询活动列表
export function listActivityApi(data) {
  return request({
    url: '/portal/system/activity/listActivity',
    method: 'post',
    data: data
  });
}
// 添加活动
export function saveActivityApi(data) {
  return request({
    url: '/portal/system/activity/saveActivity',
    method: 'post',
    data: data
  });
}

// 编辑活动
export function updateActivityApi(data) {
  return request({
    url: '/portal/system/activity/updateActivity',
    method: 'post',
    data: data
  });
}

// 删除活动
export function deleteActivityApi(data) {
  return request({
    url: '/portal/system/activity/deleteActivity',
    method: 'post',
    data: data
  });
}
// 查询活动
export function getActivityApi(params) {
  return request({
    url: '/portal/system/activity/getActivity',
    method: 'get',
    params: params
  });
}