var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "allocation_box" }, [
    _c("div", { staticClass: "allocation_name" }, [_vm._v("等级配置")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "allocation_head", staticStyle: { "margin-top": "20px" } },
      [
        _c(
          "el-button",
          {
            staticStyle: { background: "#02c58d", border: "none" },
            attrs: { type: "primary", icon: "el-icon-plus" },
            on: { click: _vm.onAddAllocation },
          },
          [_vm._v("添加")]
        ),
        _vm._v(" "),
        _c("img", {
          staticStyle: { width: "16px", height: "16px", "margin-left": "10px" },
          attrs: { src: require("@/assets/icon/mark.png"), alt: "" },
        }),
        _vm._v(" "),
        _vm._m(0),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "my_table_name", staticStyle: { "margin-top": "20px" } },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.tableLoading,
                expression: "tableLoading",
              },
            ],
            staticStyle: { width: "100%" },
            attrs: {
              "header-row-class-name": "tab_box",
              data: _vm.tableData,
              "row-key": "name",
              border: "",
              "highlight-current-row": true,
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                label: "排序",
                type: "index",
                width: "50",
                align: "center",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "name",
                label: "等级名称",
                align: "center",
                height: "40",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "lateralModel",
                label: "条件模式",
                align: "center",
                height: "40",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.upgradeModel === 0
                        ? _c("div", [_vm._v("任意条件")])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.upgradeModel === 1
                        ? _c("div", [_vm._v("同时满足")])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.upgradeModel === 2
                        ? _c("div", [_vm._v("注册即满足")])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "status",
                label: "状态",
                align: "center",
                height: "40",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.status === 1
                        ? _c("div", [_vm._v("禁用")])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.status === 0
                        ? _c("div", [_vm._v("启用")])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "selfRate",
                label: "自购分佣",
                align: "center",
                height: "40",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "subRate",
                label: "平台补贴",
                align: "center",
                height: "40",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "teamRate",
                label: "团队分佣",
                align: "center",
                height: "40",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "lateralRateList",
                label: "平1级分佣",
                align: "center",
                height: "40",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(scope.row.lateralRateList[0].rate) +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "date",
                label: "平2级分佣",
                align: "center",
                height: "40",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(scope.row.lateralRateList[1].rate) +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "date",
                label: "平3级分佣",
                align: "center",
                height: "40",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(scope.row.lateralRateList[2].rate) +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "createTime",
                label: "创建时间",
                align: "center",
                height: "40",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "name",
                label: "操作",
                align: "center",
                width: "200",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", [
                        _c("img", {
                          staticClass: "icon_box pointer handle",
                          attrs: {
                            src: require("@/assets/icon/mark1.png"),
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "icon_box ml20 pointer",
                          attrs: {
                            src: require("@/assets/personnelManage/editor.png"),
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onEditor(scope.row)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "icon_box ml20 pointer",
                          attrs: {
                            src: require("@/assets/personnelManage/delete.png"),
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onDelete(scope.row)
                            },
                          },
                        }),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "el-dialog",
          {
            attrs: {
              "close-on-click-modal": false,
              visible: _vm.dialogVisible,
              width: "750px",
            },
            on: { close: _vm.close },
          },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-ruleForm",
                attrs: {
                  model: _vm.ruleForm,
                  "status-icon": "",
                  rules: _vm.rules,
                  "label-width": "100px",
                },
              },
              [
                _c("div", { staticClass: "title" }, [
                  _c("div", { staticClass: "bock_box" }, [
                    _c("div", { staticClass: "block" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [_vm._v("基础配置")]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "mt30",
                    attrs: { label: "等级名称：", prop: "name" },
                  },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.ruleForm.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "name", _vm._n($$v))
                        },
                        expression: "ruleForm.name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt30", attrs: { label: "等级图标：" } },
                  [
                    _c("div", { staticClass: "upload_box flex" }, [
                      _c("div", { staticClass: "img" }, [
                        _c("div", [
                          _vm.ruleForm.icon
                            ? _c("img", {
                                staticStyle: { width: "100%", height: "38px" },
                                attrs: { src: _vm.ruleForm.icon, alt: "" },
                              })
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "fc" }, [
                          _vm._v("建议尺寸200×50,PNG格式"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "upload_btn fc ml10" },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "upload",
                              staticClass: "upload-demo",
                              attrs: {
                                limit: 1,
                                "on-success": _vm.handleAvatarSuccess,
                                "http-request": _vm.onRequest,
                                "show-file-list": false,
                                accept: "image/jpeg,image/png",
                                action: "",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "medium", type: "primary" } },
                                [_vm._v("点击上传")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "title" }, [
                  _c("div", { staticClass: "bock_box" }, [
                    _c("div", { staticClass: "block" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v("\n              升级配置\n              "),
                      _c(
                        "span",
                        {
                          staticClass: "ml20",
                          staticStyle: {
                            "font-size": "14px",
                            color: "#969696",
                            "font-weight": "400",
                          },
                        },
                        [_vm._v("以下升级条件，设置为0则不启用")]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt30", attrs: { label: "条件模式：" } },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: 0 },
                        model: {
                          value: _vm.ruleForm.upgradeModel,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "upgradeModel", $$v)
                          },
                          expression: "ruleForm.upgradeModel",
                        },
                      },
                      [_vm._v("任意条件")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: 1 },
                        model: {
                          value: _vm.ruleForm.upgradeModel,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "upgradeModel", $$v)
                          },
                          expression: "ruleForm.upgradeModel",
                        },
                      },
                      [_vm._v("同时满足")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: 2 },
                        model: {
                          value: _vm.ruleForm.upgradeModel,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "upgradeModel", $$v)
                          },
                          expression: "ruleForm.upgradeModel",
                        },
                      },
                      [_vm._v("注册即满足")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.ruleForm.upgradeModel != 2
                  ? _c("el-form-item", { attrs: { label: "升级条件：" } }, [
                      _c(
                        "div",
                        { staticStyle: { width: "100%" } },
                        _vm._l(_vm.ruleForm.termList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticStyle: { width: "100%" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mb10 scalation_Condition flex",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "fc checkbox" },
                                    [
                                      _c("el-checkbox", {
                                        attrs: {
                                          "true-label": 1,
                                          "false-label": 0,
                                        },
                                        model: {
                                          value: item.enable,
                                          callback: function ($$v) {
                                            _vm.$set(item, "enable", $$v)
                                          },
                                          expression: "item.enable",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        "popper-class": "select",
                                        placeholder: "请选择时间",
                                      },
                                      model: {
                                        value: item.timeRange,
                                        callback: function ($$v) {
                                          _vm.$set(item, "timeRange", $$v)
                                        },
                                        expression: "item.timeRange",
                                      },
                                    },
                                    _vm._l(_vm.TimeOptions, function (i) {
                                      return _c("el-option", {
                                        key: i.value,
                                        attrs: {
                                          label: i.label,
                                          value: i.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "fc inviteUser" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(item.termItem) +
                                        "\n                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "el-input",
                                    {
                                      staticStyle: { height: "38px" },
                                      attrs: {
                                        max: 100,
                                        min: 0,
                                        type: "number",
                                        placeholder: "请输入内容",
                                      },
                                      model: {
                                        value: item.requiredValue,
                                        callback: function ($$v) {
                                          _vm.$set(item, "requiredValue", $$v)
                                        },
                                        expression: "item.requiredValue",
                                      },
                                    },
                                    [
                                      _c(
                                        "template",
                                        {
                                          staticStyle: {
                                            height: "38px",
                                            background: "#cacaca",
                                          },
                                          slot: "append",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.unitData[item.termItem])
                                          ),
                                        ]
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "title" }, [
                  _c("div", { staticClass: "bock_box" }, [
                    _c("div", { staticClass: "block" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v("\n              自购分佣配置\n              "),
                      _c(
                        "span",
                        {
                          staticClass: "ml20",
                          staticStyle: {
                            "font-size": "14px",
                            color: "#969696",
                            "font-weight": "400",
                          },
                        },
                        [_vm._v("以下分佣配置，设置为0则不启用")]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt30", attrs: { label: "自购分佣：" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: { placeholder: "请输入内容" },
                        model: {
                          value: _vm.ruleForm.selfRate,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "selfRate", $$v)
                          },
                          expression: "ruleForm.selfRate",
                        },
                      },
                      [_c("template", { slot: "append" }, [_vm._v("%")])],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "acquiesce_tips" }, [
                      _vm._v(
                        "\n            自购/分享佣金是，为联盟扣除服务费后的佣金百分比\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isExceed,
                            expression: "isExceed",
                          },
                        ],
                        staticClass: "title_tips",
                      },
                      [
                        _vm._v(
                          "\n            佣金百分比不能超过100\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "title" }, [
                  _c("div", { staticClass: "bock_box" }, [
                    _c("div", { staticClass: "block" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v("\n              平台补贴配置\n              "),
                      _c(
                        "span",
                        {
                          staticClass: "ml20",
                          staticStyle: {
                            "font-size": "14px",
                            color: "#969696",
                            "font-weight": "400",
                          },
                        },
                        [_vm._v("以下分佣配置，设置为0则不启用")]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt30", attrs: { label: "平台补贴:" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: { placeholder: "请输入内容" },
                        model: {
                          value: _vm.ruleForm.subRate,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "subRate", $$v)
                          },
                          expression: "ruleForm.subRate",
                        },
                      },
                      [_c("template", { slot: "append" }, [_vm._v("%")])],
                      2
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "title" }, [
                  _c("div", { staticClass: "bock_box" }, [
                    _c("div", { staticClass: "block" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v("\n              团队分佣配置\n              "),
                      _c(
                        "span",
                        {
                          staticClass: "ml20",
                          staticStyle: {
                            "font-size": "14px",
                            color: "#969696",
                            "font-weight": "400",
                          },
                        },
                        [_vm._v("以下分佣配置，设置为0则不启用")]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { staticClass: "mt30", attrs: { label: "平级奖模式：" } },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: 0 },
                        model: {
                          value: _vm.ruleForm.lateralModel,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "lateralModel", $$v)
                          },
                          expression: "ruleForm.lateralModel",
                        },
                      },
                      [_vm._v("平台奖励")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: 1 },
                        model: {
                          value: _vm.ruleForm.lateralModel,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "lateralModel", $$v)
                          },
                          expression: "ruleForm.lateralModel",
                        },
                      },
                      [_vm._v("上一等级运营商团队分佣里扣除")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "团队分佣：" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.ruleForm.teamRate,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "teamRate", $$v)
                          },
                          expression: "ruleForm.teamRate",
                        },
                      },
                      [_c("template", { slot: "append" }, [_vm._v("%")])],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "acquiesce_tips" }, [
                      _vm._v(
                        "\n            团队分佣比，为联盟扣除服务费后的佣金，再减去自购/分享佣金部分的百分比\n          "
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._l(_vm.ruleForm.lateralRateList, function (i) {
                  return _c(
                    "el-form-item",
                    {
                      key: i.order,
                      attrs: { label: "平" + i.order + "级奖励：" },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: { placeholder: "" },
                          model: {
                            value: i.rate,
                            callback: function ($$v) {
                              _vm.$set(i, "rate", $$v)
                            },
                            expression: "i.rate",
                          },
                        },
                        [_c("template", { slot: "append" }, [_vm._v("%")])],
                        2
                      ),
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "状态：" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "select",
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.ruleForm.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "status", $$v)
                          },
                          expression: "ruleForm.status",
                        },
                      },
                      _vm._l(_vm.headStatus, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "fc" },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: {
                      width: "172px",
                      height: "52px",
                      background: "#4d8aff",
                      "border-radius": "4px",
                    },
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.submitForm("ruleForm")
                      },
                    },
                  },
                  [_vm._v("确认")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: { visible: _vm.delDialogVisible, width: "400px" },
            on: {
              "update:visible": function ($event) {
                _vm.delDialogVisible = $event
              },
              close: _vm.delClose,
            },
          },
          [
            _c("div", [_vm._v("会员等级状态是启用情况下，不可直接删除")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "fc mt30" },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: {
                      width: "172px",
                      height: "52px",
                      background: "#4d8aff",
                      "border-radius": "4px",
                    },
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.delDialogVisible = false
                      },
                    },
                  },
                  [_vm._v("确认")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("span", [_vm._v("温馨提示：只可以删除禁用状态下的等级名称")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }