var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "both-pd-box" }, [
    _c(
      "div",
      {
        staticStyle: {
          background: "#fff",
          padding: "30px",
          "border-radius": "20px",
        },
      },
      [
        _c("h3", [_vm._v("APP基础模式配置")]),
        _vm._v(" "),
        _c("el-divider"),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-ruleForm",
                attrs: {
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  "label-width": "150px",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "APP模式设置", prop: "agent" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.ruleForm.agent,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "agent", $$v)
                          },
                          expression: "ruleForm.agent",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("代理模式"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("非代理模式"),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", [
                      _c("div", [
                        _vm._v("1.代理模式下：新注册用户默认为团队代理身份"),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v("2.非代理模式下：新注册用户默认为自购身份"),
                      ]),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "邀请码是否填写", prop: "inviteConfig" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.ruleForm.inviteConfig,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "inviteConfig", $$v)
                          },
                          expression: "ruleForm.inviteConfig",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("必填"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("不填"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("非必填（可跳过）"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.submitForm("ruleForm")
                          },
                        },
                      },
                      [_vm._v("保存")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }