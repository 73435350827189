var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex frb" }, [
    _c("div", { staticClass: "activity" }, [
      _c(
        "div",
        { staticClass: "img", staticStyle: { "border-radius": "10px" } },
        [
          _vm.item.mainImage
            ? _c("img", {
                staticStyle: {
                  width: "290px",
                  height: "100px",
                  "border-radius": "10px",
                },
                attrs: { src: _vm.item.mainImage, alt: "" },
              })
            : _c("img", {
                staticStyle: {
                  width: "280px",
                  height: "100px",
                  "border-radius": "10px",
                },
                attrs: {
                  src: require("@/assets/LittleActivityCreation/acquiesce_icon.png"),
                  alt: "",
                },
              }),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "text mt10" }, [
        _c("div", [
          _c("div", { staticClass: "beyond_hiding title" }, [
            _vm._v(_vm._s(_vm.item.name)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "startTime" }, [
            _vm._v("创建时间：" + _vm._s(_vm.item.createTime)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "startTime" }, [
            _vm._v(
              "活动时间：" +
                _vm._s(_vm.item.startTime) +
                "-" +
                _vm._s(_vm.item.endTime)
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "frb" },
            [
              _vm.item.status === 1
                ? _c("div", { staticClass: "ongoingState State" }, [
                    _vm._v("进行中"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.item.status === 0
                ? _c("div", { staticClass: "ongoingState State1" }, [
                    _vm._v("未开始"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.item.status === 2
                ? _c("div", { staticClass: "ongoingState State2" }, [
                    _vm._v("已结束"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._t("default"),
            ],
            2
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }