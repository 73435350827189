var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt20", staticStyle: { padding: "0 88px 60px" } },
    [
      _c(
        "div",
        { staticClass: "mt10" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "date", label: "商品信息", width: "500" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "flex" }, [
                          _c("div", [
                            _c("img", {
                              staticStyle: {
                                width: "50px",
                                height: "50px",
                                "border-radius": "4px",
                              },
                              attrs: { src: scope.row.img, alt: "" },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "ml10" }, [
                            _c("div", [
                              _c("img", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: scope.row.platform === "TB",
                                    expression: "scope.row.platform=== 'TB'",
                                  },
                                ],
                                staticClass: "icon_img",
                                attrs: {
                                  alt: "",
                                  src: require("@/assets/platformIcon/tb.png"),
                                },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: scope.row.platform === "JD",
                                    expression: "scope.row.platform=== 'JD'",
                                  },
                                ],
                                staticClass: "icon_img",
                                attrs: {
                                  alt: "",
                                  src: require("@/assets/platformIcon/jd.png"),
                                },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: scope.row.platform === "PDD",
                                    expression: "scope.row.platform=== 'PDD'",
                                  },
                                ],
                                staticClass: "icon_img",
                                attrs: {
                                  alt: "",
                                  src: require("@/assets/platformIcon/pdd.png"),
                                },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: scope.row.platform === "WPH",
                                    expression: "scope.row.platform=== 'WPH'",
                                  },
                                ],
                                staticClass: "icon_img",
                                attrs: {
                                  alt: "",
                                  src: require("@/assets/platformIcon/wph.png"),
                                },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: scope.row.platform === "DY",
                                    expression: "scope.row.platform=== 'DY'",
                                  },
                                ],
                                staticClass: "icon_img",
                                attrs: {
                                  alt: "",
                                  src: require("@/assets/platformIcon/dy.png"),
                                },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: scope.row.platform === "KS",
                                    expression: "scope.row.platform=== 'KS'",
                                  },
                                ],
                                staticClass: "icon_img",
                                attrs: {
                                  alt: "",
                                  src: require("@/assets/platformIcon/ks.png"),
                                },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: scope.row.platform === "ELM",
                                    expression: "scope.row.platform=== 'ELM'",
                                  },
                                ],
                                staticClass: "icon_img",
                                attrs: {
                                  alt: "",
                                  src: require("@/assets/platformIcon/elm.png"),
                                },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: scope.row.platform === "MT",
                                    expression: "scope.row.platform=== 'MT'",
                                  },
                                ],
                                staticClass: "icon_img",
                                attrs: {
                                  alt: "",
                                  src: require("@/assets/platformIcon/mt.png"),
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "ellipsis-multiline" },
                                [_vm._v(_vm._s(scope.row.title))]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "frb",
                                staticStyle: { "margin-top": "4px" },
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    "订单号：" + _vm._s(scope.row.orderNo)
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "mr10" }, [
                                  _vm._v(
                                    "付款金额：¥" + _vm._s(scope.row.payAmount)
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "status", label: "订单状态", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status === 1
                          ? _c("span", [_vm._v("已支付")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status === 2
                          ? _c("span", [_vm._v("已取消")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status === 3
                          ? _c("span", [_vm._v("已结算")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.status === 4
                          ? _c("span", [_vm._v("已收货")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "commissionRate",
                  label: "佣金比例",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "commission",
                  label: "预估佣金",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "payTime", label: "支付时间", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "", label: "确认收货时间", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "date", label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.handleClick(scope.row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 page_box" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.queryData.page.page,
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.queryData.page.size,
              layout: "total, prev, pager, next, sizes,jumper",
              total: _vm.total,
              background: "",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }