// 聚合页
import request from '@/utils/request';
// 查询集合页
export function aggregatePageList(data) {
  return request({
    url: '/portal/system/aggregatePage/list',
    method: 'post',
    data: data
  });
}
// 保存聚合页
export function aggregatePageSave(data) {
  return request({
    url: '/portal/system/aggregatePage/save',
    method: 'post',
    data: data
  });
}
// 修改聚合页
export function aggregatePageUpdate(data) {
  return request({
    url: '/portal/system/aggregatePage/update',
    method: 'post',
    data: data
  });
}
// 删除聚合页
export function aggregatePageDelete(data) {
  return request({
    url: '/portal/system/aggregatePage/delete',
    method: 'post',
    data: data
  });
}