import request from '@/utils/request';
// 等级配置
// 配置项列表
export function listClassSetApi(params) {
  return request({
    url: '/portal/system/class/listClassSet',
    method: 'get',
    params: params
  });
}

// 创建等级配置项
export function saveClassSetApi(data) {
  return request({
    url: '/portal/system/class/saveClassSet',
    method: 'post',
    data: data
  });
}
// 等级配置项详情
export function getClassSetApi(params) {
  return request({
    url: '/portal/system/class/getClassSet',
    method: 'get',
    params: params
  });
}
// 更新等级配置项
export function updateClassSetApi(data) {
  return request({
    url: '/portal/system/class/updateClassSet',
    method: 'post',
    data: data
  });
}
// 删除等级配置项
export function deleteClassSetApi(data) {
  return request({
    url: '/portal/system/class/deleteClassSet',
    method: 'post',
    data: data
  });
}
// 拖拽等级配置项
export function moveClassSetApi(data) {
  return request({
    url: '/portal/system/class/moveClassSet',
    method: 'post',
    data: data
  });
}

// 区域分佣配置*************************************
// 查询区域配置
export function getRegionApi(params) {
  return request({
    url: '/portal/system/tenant/getRegion',
    method: 'get',
    params: params
  });
}
// 更新区域配置
export function updateRegionApi(data) {
  return request({
    url: '/portal/system/tenant/updateRegion',
    method: 'post',
    data: data
  });
}