"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.queryMemberOrderListApi = queryMemberOrderListApi;
var _request = _interopRequireDefault(require("@/utils/request"));
// 会员订单列表
function queryMemberOrderListApi(data) {
  return (0, _request.default)({
    url: '/portal/system/order/queryMemberOrderList',
    method: 'post',
    data: data
  });
}