import request from '@/utils/request';
// 保存海报
export function savePosterApi(data) {
  return request({
    url: '/portal/system/poster/savePoster',
    method: 'post',
    data: data
  });
}
export function listPosterApi(data) {
  return request({
    url: '/portal/system/poster/listPoster',
    method: 'post',
    data: data
  });
}
// 删除
export function deletePosterApi(params) {
  return request({
    url: '/portal/system/poster/deletePoster',
    method: 'get',
    params: params
  });
}
// 查询
export function getPosterApi(params) {
  return request({
    url: '/portal/system/poster/getPoster',
    method: 'get',
    params: params
  });
}
// 编辑
export function updatePosterApi(data) {
  return request({
    url: '/portal/system/poster/updatePoster',
    method: 'post',
    data: data
  });
}