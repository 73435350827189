"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _subsidyDetail = require("@/api/subsidyDetail");
var _AcquisitionTime = require("@/utils/AcquisitionTime");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: '',
  data: function data() {
    return {
      subsidyMoney: '',
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      tableData: [],
      value1: [],
      orderNo: '',
      memberId: '',
      subsidyStatus: '',
      options: [{
        name: '补贴中',
        value: '1'
      }, {
        name: '补贴失败',
        value: '2'
      }, {
        name: '已补贴',
        value: '3'
      }],
      statusTypeDataIndex: 0,
      statusTypeData: [{
        name: '全部',
        id: 0
      }, {
        name: '今日',
        id: 1
      }, {
        name: '昨日',
        id: 2
      }, {
        name: '前天',
        id: 8
      }, {
        name: '近7天',
        id: 3
      }, {
        name: '近30天',
        id: 4
      }, {
        name: '近60天',
        id: 5
      }, {
        name: '自定义',
        id: 6
      }]
    };
  },
  mounted: function mounted() {
    this.onStatusList({
      id: 0
    });
  },
  methods: {
    // 用户详情
    onUserDetails: function onUserDetails(value) {
      this.$router.push({
        path: '/member/userDetails',
        query: {
          memberId: value.memberId,
          nickname: value.nickName
        }
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      this.size = val;
      this.listBalance();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.page = val;
      this.listBalance();
    },
    listBalance: function listBalance() {
      var _this = this;
      var obj = {
        startTime: this.value1.length > 0 ? this.value1[0] : null,
        endTime: this.value1.length > 0 ? this.value1[1] : null,
        subsidyStatus: this.subsidyStatus,
        orderNo: this.orderNo,
        memberId: this.memberId,
        page: {
          page: this.page,
          size: this.size
        }
      };
      this.loading = true;
      (0, _subsidyDetail.queryList)(obj).then(function (res) {
        _this.tableData = res.data.data;
        _this.total = res.data.total;
        _this.loading = false;
      }).catch(function () {
        _this.loading = false;
      });
      (0, _subsidyDetail.subsidyAmtCondition)(obj).then(function (res) {
        _this.subsidyMoney = res.data;
      }).catch(function () {});
    },
    onStatusList: function onStatusList(data) {
      this.statusTypeDataIndex = data.id;
      if (data.id === 0) {
        this.value1 = [null, null];
      }
      if (data.id === 1) {
        this.value1 = [(0, _AcquisitionTime.weekDay)(0)[0], (0, _AcquisitionTime.weekDay)(0)[0].replace('00:00:00', '23:59:59')];
      }
      if (data.id === 2) {
        this.value1 = [(0, _AcquisitionTime.weekDay)(1)[0], (0, _AcquisitionTime.weekDay)(1)[0].replace('00:00:00', '23:59:59')];
      }
      if (data.id === 3) {
        this.value1 = (0, _AcquisitionTime.weekDay)(7);
      }
      if (data.id === 4) {
        this.value1 = (0, _AcquisitionTime.weekDay)(30);
      }
      if (data.id === 5) {
        this.value1 = (0, _AcquisitionTime.weekDay)(60);
      }
      if (data.id === 7) {
        this.value1 = (0, _AcquisitionTime.weekDay)(90);
      }
      if (data.id === 8) {
        this.value1 = [(0, _AcquisitionTime.weekDay)(2)[0], (0, _AcquisitionTime.weekDay)(2)[0].replace('00:00:00', '23:59:59')];
      }
      if (data.id === 6) {
        this.value1 = [];
        return false;
      }
      this.page = 1;
      this.listBalance();
    },
    changeDataPicker: function changeDataPicker() {
      if (this.value1 === null) {
        this.value1 = [];
      }
      this.page = 1;
      this.listBalance();
    },
    onInquire: function onInquire() {
      this.page = 1;
      this.listBalance();
    },
    onInitialize: function onInitialize() {
      this.statusTypeDataIndex = 0;
      this.value1 = [null, null];
      this.orderNo = '';
      this.memberId = '';
      this.subsidyStatus = '';
      this.page = 1;
      this.listBalance();
    }
  }
};