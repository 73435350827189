var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Information change" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "奖励金发放/款项扣除变动记录",
            visible: _vm.regionalInformation,
            "before-close": _vm.regionClose,
            "close-on-click-modal": false,
            width: "70%",
          },
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.userOptionData, "max-height": "400" } },
            [
              _c("el-table-column", {
                attrs: { property: "memberId", label: "用户ID", width: "150" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { property: "operator", label: "操作人", width: "150" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "operationType",
                  "min-width": "150",
                  label: "操作类型",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.type === 1
                                ? "用户等级修改"
                                : "自定义邀请码修改"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "remark",
                  "min-width": "200",
                  label: "操作金额（元）",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "remark",
                  "min-width": "200",
                  label: "操作账户",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  property: "remark",
                  "min-width": "200",
                  label: "操作原因",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }