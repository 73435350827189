"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRelease = getRelease;
exports.listReleasePaged = listReleasePaged;
exports.saveRelease = saveRelease;
exports.updateRelease = updateRelease;
var _request = _interopRequireDefault(require("@/utils/request"));
function saveRelease(data) {
  return (0, _request.default)({
    url: '/portal/system/release/saveRelease',
    method: 'post',
    data: data
  });
}
function updateRelease(data) {
  return (0, _request.default)({
    url: '/portal/system/release/updateRelease',
    method: 'post',
    data: data
  });
}
function listReleasePaged(params) {
  return (0, _request.default)({
    url: '/portal/system/release/listReleasePaged',
    method: 'get',
    params: params
  });
}
function getRelease(params) {
  return (0, _request.default)({
    url: '/portal/system/release/getRelease',
    method: 'get',
    params: params
  });
}