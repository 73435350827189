var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            title: "聚合活动预览",
            width: "750px",
            top: "10vh",
            "custom-class": "dialog_box",
          },
          on: { close: _vm.onClose },
        },
        [
          _c("div", { staticClass: "from_box fc" }, [
            _c("div", { staticClass: "preview_box" }, [
              _c("div", { staticClass: "preview_header fc" }, [
                _vm._v(_vm._s(_vm.dataList.name)),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: { height: "570px", "overflow-y": "auto" },
                  style: { background: _vm.dataList.colorValue },
                },
                _vm._l(_vm.dataList.activityList, function (item, index) {
                  return _c("div", { key: index }, [
                    index === 0
                      ? _c(
                          "div",
                          { staticStyle: { padding: "10px 10px 0 10px" } },
                          [
                            _c("div", { staticClass: "preview_content_div1" }, [
                              _c("div", { staticClass: "div1_img" }, [
                                item.activityPic
                                  ? _c("img", {
                                      attrs: { src: item.activityPic, alt: "" },
                                    })
                                  : _c("img", {
                                      attrs: {
                                        src: require("@/assets/LittleActivityCreation/acquiesce_icon.png"),
                                        alt: "",
                                      },
                                    }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "mt10 fc" }, [
                                _c("div", { staticClass: "btn1 fc" }, [
                                  _vm._v("进入会场"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "btn2 fc" }, [
                                  _vm._v("复制链接"),
                                ]),
                              ]),
                            ]),
                          ]
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "mt10",
                            staticStyle: { padding: "0 10px" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "preview_content_div2 flex" },
                              [
                                _c("div", { staticClass: "div1_img fc" }, [
                                  item.activityPic
                                    ? _c("img", {
                                        attrs: {
                                          src: item.activityPic,
                                          alt: "",
                                        },
                                      })
                                    : _c("img", {
                                        attrs: {
                                          src: require("@/assets/LittleActivityCreation/acquiesce_icon.png"),
                                          alt: "",
                                        },
                                      }),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "ml10 mr10 fc" }, [
                                  _c("div", [
                                    _c("div", { staticClass: "btn1 fc" }, [
                                      _vm._v("进入会场"),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "btn2 fc" }, [
                                      _vm._v("复制链接"),
                                    ]),
                                  ]),
                                ]),
                              ]
                            ),
                          ]
                        ),
                  ])
                }),
                0
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }