var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible1,
            "close-on-click-modal": false,
            title: _vm.title,
            width: "40%",
            top: "2vh",
            "custom-class": "dialog_box",
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm15",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "100px",
                  },
                },
                [
                  _vm.openType === "1"
                    ? _c(
                        "el-form-item",
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.radio,
                                callback: function ($$v) {
                                  _vm.radio = $$v
                                },
                                expression: "radio",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("批量导入"),
                              ]),
                              _vm._v(" "),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("单个添加"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.radio === 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "文件导入", prop: "fileList" } },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "upload",
                              staticClass: "upload-demo",
                              attrs: {
                                action: "",
                                "on-change": _vm.change,
                                "on-remove": _vm.handleRemove,
                                "file-list": _vm.ruleForm.fileList,
                                "auto-upload": false,
                                limit: 1,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    slot: "trigger",
                                    size: "small",
                                    type: "success",
                                  },
                                  slot: "trigger",
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-upload el-icon--left",
                                  }),
                                  _vm._v("选取文件"),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { "margin-left": "10px" },
                                  attrs: { type: "danger", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.down()
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-download el-icon--left",
                                  }),
                                  _vm._v("下载模板"),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "标签", prop: "headPhoto" } },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "130px",
                                    height: "130px",
                                    background: "#f9f9f9",
                                    "border-radius": "4px",
                                    border: "1px solid #eeeeee",
                                    position: "relative",
                                  },
                                },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      staticClass: "avatar-uploader",
                                      attrs: {
                                        "show-file-list": false,
                                        action: "",
                                        "http-request": _vm.handleFileUpload,
                                      },
                                    },
                                    [
                                      _vm.ruleForm.headPhoto
                                        ? _c("img", {
                                            staticClass: "avatar_img",
                                            attrs: {
                                              src: _vm.ruleForm.headPhoto,
                                            },
                                          })
                                        : _c("i", {
                                            staticClass:
                                              "el-icon-plus avatar-uploader-icon",
                                          }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.ruleForm.headPhoto
                                    ? _c("i", {
                                        staticClass: "el-icon-circle-close",
                                        staticStyle: {
                                          position: "absolute",
                                          left: "135px",
                                          top: "0",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clearPhoto()
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "#666",
                                    "font-size": "12px",
                                  },
                                },
                                [_vm._v("推荐标签尺寸:75px * 75px")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.openType != "3"
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "类目", prop: "type" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        filterable: "",
                                        clearable: "",
                                        placeholder: "请选择类目",
                                      },
                                      model: {
                                        value: _vm.ruleForm.type,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.ruleForm, "type", $$v)
                                        },
                                        expression: "ruleForm.type",
                                      },
                                    },
                                    _vm._l(_vm.typeList, function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.cname,
                                          value: item.cname,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.openType != "3"
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "商品Id", prop: "goodsId" } },
                                [
                                  _c("el-input", {
                                    attrs: { readonly: _vm.openType === "2" },
                                    model: {
                                      value: _vm.ruleForm.goodsId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "goodsId",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "ruleForm.goodsId",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticStyle: { "margin-top": "40px" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("ruleForm15")
                            },
                          },
                        },
                        [_vm._v("确认")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.resetForm("ruleForm15")
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }