"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.dropPermission = dropPermission;
exports.queryMenuTree = queryMenuTree;
exports.saveOrUpdatePermission = saveOrUpdatePermission;
var _request = _interopRequireDefault(require("@/utils/request"));
// [new]导航菜单管理
function queryMenuTree(data) {
  return (0, _request.default)({
    url: '/portal/system/permission/queryMenuTree',
    method: 'post',
    data: data
  });
}
// [new]添加、修改权限资源
function saveOrUpdatePermission(data) {
  return (0, _request.default)({
    url: '/portal/system/permission/saveOrUpdatePermission',
    method: 'post',
    data: data
  });
}
// [new]删除权限
function dropPermission(params) {
  return (0, _request.default)({
    url: '/portal/system/permission/dropPermission',
    method: 'get',
    params: params
  });
}