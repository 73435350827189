var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticStyle: { display: "flex", "justify-content": "flex-start" } },
      [
        _c(
          "el-form",
          {
            ref: "ruleForm",
            staticClass: "demo-ruleForm",
            staticStyle: { width: "50%" },
            attrs: {
              model: _vm.ruleForm,
              rules: _vm.rules,
              "label-width": "180px",
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "APP名称", prop: "name" } },
              [
                _c("el-input", {
                  attrs: { clearable: "", type: "text", autocomplete: "off" },
                  model: {
                    value: _vm.ruleForm.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "name", $$v)
                    },
                    expression: "ruleForm.name",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "APP短码", prop: "shortCode" } },
              [
                _c("el-input", {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.ruleForm.shortCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "shortCode", $$v)
                    },
                    expression: "ruleForm.shortCode",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "APP域名", prop: "appBaseUrl" } },
              [
                _c("el-input", {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.ruleForm.appBaseUrl,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "appBaseUrl", $$v)
                    },
                    expression: "ruleForm.appBaseUrl",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "uni-app应用标识(AppId)", prop: "uniAppId" } },
              [
                _c("el-input", {
                  model: {
                    value: _vm.ruleForm.uniAppId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "uniAppId", $$v)
                    },
                    expression: "ruleForm.uniAppId",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "APP编码", prop: "appNo" } },
              [
                _c("el-input", {
                  attrs: {
                    clearable: "",
                    type: "text",
                    autocomplete: "off",
                    disabled: "",
                  },
                  model: {
                    value: _vm.ruleForm.appNo,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "appNo", $$v)
                    },
                    expression: "ruleForm.appNo",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("el-form-item", { attrs: { label: "默认管理员" } }, [
              _c(
                "div",
                {
                  staticStyle: {
                    background: "#D7D7D7",
                    "border-radius": "10px",
                    padding: "20px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "100px", label: "管理员昵称" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.Administrator.administratorName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.Administrator,
                              "administratorName",
                              $$v
                            )
                          },
                          expression: "Administrator.administratorName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-top": "20px" },
                      attrs: { "label-width": "100px", label: "手机号码" },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.Administrator.administratorPhone,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.Administrator,
                              "administratorPhone",
                              $$v
                            )
                          },
                          expression: "Administrator.administratorPhone",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-top": "20px" },
                      attrs: { "label-width": "100px", label: "管理员状态" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.Administrator.administratorStatus,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.Administrator,
                                "administratorStatus",
                                $$v
                              )
                            },
                            expression: "Administrator.administratorStatus",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("正常"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("禁用"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "APP模式设置", prop: "agent" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.ruleForm.agent,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "agent", $$v)
                      },
                      expression: "ruleForm.agent",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 0 } }, [
                      _vm._v("非代理模式"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 1 } }, [
                      _vm._v("代理模式"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", [
                  _c("div", [
                    _vm._v(
                      "注意：代理模式关联用户首次注册登录的身份，需仔细核对用户需求！"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v("1.代理模式下：新注册用户默认为团队代理身份"),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v("2.非代理模式下：新注册用户默认为自购身份。"),
                  ]),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "邀请码是否填写", prop: "inviteConfig" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.ruleForm.inviteConfig,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "inviteConfig", $$v)
                      },
                      expression: "ruleForm.inviteConfig",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 1 } }, [_vm._v("必填")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 2 } }, [_vm._v("不填")]),
                    _vm._v(" "),
                    _c("el-radio", { attrs: { label: 0 } }, [
                      _vm._v("非必填（可跳过）"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "APP图标", prop: "icon" } },
              [
                _c(
                  "el-upload",
                  {
                    attrs: {
                      action: "",
                      "http-request": function (value) {
                        return _vm.handleFileUpload(value, "icon")
                      },
                      "show-file-list": false,
                    },
                  },
                  [
                    _vm.ruleForm.icon
                      ? _c("img", {
                          staticClass: "avatar",
                          staticStyle: {
                            width: "148px",
                            height: "148px",
                            "border-radius": "10px",
                          },
                          attrs: { src: _vm.ruleForm.icon },
                        })
                      : _c("i", {
                          staticClass: "el-icon-plus avatar-uploader-icon",
                        }),
                  ]
                ),
                _vm._v(" "),
                _c("span", { staticStyle: { color: "#969696" } }, [
                  _vm._v("180*180PNG（图标仅在超管端及租户端登录时展示）"),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "120px", width: "100px" },
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.submitForm("ruleForm")
                      },
                    },
                  },
                  [_vm._v("保 存")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }