var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            title: "订单处理明细",
            width: "1000px",
            top: "10vh",
            "custom-class": "dialog_box",
          },
          on: { close: _vm.onClose },
        },
        [
          _c("div", { staticClass: "search_criteria" }, [
            _c(
              "div",
              [
                _c("el-input", {
                  attrs: { placeholder: "订单号查询", clearable: "" },
                  model: {
                    value: _vm.queryData.orderNo,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryData, "orderNo", $$v)
                    },
                    expression: "queryData.orderNo",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("el-input", {
                  attrs: { placeholder: "渠道ID查询", clearable: "" },
                  model: {
                    value: _vm.queryData.relationId,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryData, "relationId", _vm._n($$v))
                    },
                    expression: "queryData.relationId",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "选择处理状态", clearable: "" },
                    model: {
                      value: _vm.queryData.handlerStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryData, "handlerStatus", $$v)
                      },
                      expression: "queryData.handlerStatus",
                    },
                  },
                  [
                    _c("el-option", { attrs: { value: 0, label: "处理失败" } }),
                    _vm._v(" "),
                    _c("el-option", { attrs: { value: 1, label: "处理成功" } }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex", staticStyle: { width: "fit-content" } },
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.onInquire } },
                  [_vm._v("查询")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "ml10",
                    attrs: { type: "info", plain: "" },
                    on: { click: _vm.onInitialize },
                  },
                  [_vm._v("重置")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { height: "400", data: _vm.tableData, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "orderNo",
                      label: "订单号",
                      align: "center",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "relationId",
                      label: "渠道ID",
                      align: "center",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "处理状态", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  scope.row.handlerStatus === 0
                                    ? "处理失败"
                                    : "处理成功"
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "订单状态", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  scope.row.handlerStatus === 0
                                    ? "有效"
                                    : "失效"
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "创建时间",
                      align: "center",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "updateTime",
                      label: "处理时间",
                      align: "center",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "failContent",
                      label: "备注",
                      align: "center",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20 page_box" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.queryData.page.page,
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.queryData.page.size,
                  layout: "total, prev, pager, next, sizes,jumper",
                  total: _vm.total,
                  background: "",
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }