var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "both-pd-box" },
    [
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              staticStyle: {
                background: "#02c58d",
                border: "none",
                "margin-left": "7px",
                "border-radius": "8px",
              },
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.onAdd },
            },
            [_vm._v("添加")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "box" },
        _vm._l(_vm.dataList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "box_item fc",
              on: {
                click: function ($event) {
                  return _vm.onEdit(item)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(item.name) + "\n    ")]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 page_box" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.pagination.currentPage,
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.queryData.page.size,
              layout: "total, prev, pager, next, sizes,jumper",
              total: _vm.pagination.total,
              background: "",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.dialogVisible,
            width: "550px",
            center: "",
            "close-on-click-modal": false,
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "div",
            { staticClass: "fc" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  staticStyle: { width: "330px" },
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "0px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "输入页面名称" },
                        model: {
                          value: _vm.ruleForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "name", $$v)
                          },
                          expression: "ruleForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "url" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "输入页面URL" },
                        model: {
                          value: _vm.ruleForm.url,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "url", $$v)
                          },
                          expression: "ruleForm.url",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "fc" },
            [
              _c(
                "el-button",
                {
                  staticStyle: {
                    width: "172px",
                    height: "52px",
                    background: "#4d8aff",
                    "border-radius": "4px",
                  },
                  attrs: { type: "primary" },
                  on: { click: _vm.onVerify },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }