var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        background: "#fff",
        padding: "30px",
        "border-radius": "20px",
      },
    },
    [
      _c("div", { staticClass: "title" }, [_vm._v("APP启动图设置")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c("div", { staticStyle: { color: "red" } }, [
        _vm._v("注意：启动图设置后，下次打包才会生效!"),
      ]),
      _vm._v(" "),
      _c("div", { staticStyle: { "margin-top": "10px" } }, [
        _c("div", { staticClass: "box" }, [
          _c("div", { staticClass: "left-box" }, [
            _c(
              "div",
              {
                staticStyle: {
                  width: "297px",
                  height: "624px",
                  "border-radius": "15px",
                  "margin-left": "6px",
                },
              },
              [
                _vm.AndroidUrl
                  ? _c("img", {
                      staticStyle: {
                        width: "100%",
                        height: "100%",
                        "border-radius": "15px",
                      },
                      attrs: { src: _vm.AndroidUrl },
                    })
                  : _vm._e(),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-left": "60px" } },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-ruleForm",
                  staticStyle: { "margin-top": "20px" },
                  attrs: { "label-width": "100px" },
                },
                [
                  _c("el-form-item", [
                    _c(
                      "div",
                      {
                        staticClass: "both-label",
                        attrs: { slot: "label" },
                        slot: "label",
                      },
                      [
                        _c("i", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v("\n              安卓启动图:\n            "),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "flex-start",
                          "flex-wrap": "wrap",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "130px",
                              height: "130px",
                              background: "#eeeeee",
                              "border-radius": "4px",
                              border: "1px dashed #d9d9d9",
                              margin: "0 30px 0 0",
                            },
                          },
                          [
                            _c(
                              "el-upload",
                              {
                                staticClass: "avatar-uploader",
                                attrs: {
                                  "show-file-list": false,
                                  action: "",
                                  "http-request": function (value) {
                                    return _vm.handleFileUpload(
                                      value,
                                      "1080_2340.png"
                                    )
                                  },
                                },
                              },
                              [
                                _vm.AndroidUrl
                                  ? _c("el-image", {
                                      staticClass: "avatar_img",
                                      attrs: {
                                        fit: "scale-down",
                                        src: _vm.AndroidUrl,
                                      },
                                    })
                                  : _c("i", {
                                      staticClass:
                                        "el-icon-plus avatar-uploader-icon",
                                    }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticStyle: { "font-size": "12px", color: "#666" } },
                      [
                        _vm._v(
                          "尺寸1080*23400的PNG格式图片，建议使用纯色背景，不要使用复杂图案"
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "both-label",
                          attrs: { slot: "label" },
                          slot: "label",
                        },
                        [
                          _c("i", { staticStyle: { color: "red" } }, [
                            _vm._v("*"),
                          ]),
                          _vm._v("\n              ios启动图:\n            "),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            limit: 1,
                            action: "",
                            "on-remove": _vm.handleRemove,
                            "show-file-list": _vm.fileList.length > 0,
                            "file-list": _vm.fileList,
                            "http-request": function (value) {
                              return _vm.handleFileUploadIos(
                                value,
                                "CustomStoryBoard.zip"
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("点击上传")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { "font-size": "12px", color: "#666" } },
                        [
                          _vm._v(
                            "苹果启动图需要将图片转换成zip文件，转换流程请点击"
                          ),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "blue",
                                "text-decoration": "underline",
                                cursor: "pointer",
                                "margin-left": "5px",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.jump()
                                },
                              },
                            },
                            [_vm._v("跳转链接")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.save },
                        },
                        [_vm._v("保存")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }