var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "management_box" },
    [
      _c("div", { staticClass: "search_criteria" }, [
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: "选择营销位", clearable: "" },
                model: {
                  value: _vm.queryData.locationCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "locationCode", $$v)
                  },
                  expression: "queryData.locationCode",
                },
              },
              _vm._l(_vm.marketingBitList, function (item) {
                return _c("el-option", {
                  key: item.locationCode,
                  attrs: { label: item.locationName, value: item.locationCode },
                })
              }),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: "状态", clearable: "" },
                model: {
                  value: _vm.queryData.enable,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "enable", $$v)
                  },
                  expression: "queryData.enable",
                },
              },
              [
                _c("el-option", { attrs: { value: 1, label: "上线" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 0, label: "下线" } }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex", staticStyle: { width: "fit-content" } },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.onInquire } },
              [_vm._v("查询")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "ml10",
                attrs: { type: "info", plain: "" },
                on: { click: _vm.onInitialize },
              },
              [_vm._v("重置")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { type: "primary", icon: "el-icon-circle-plus-outline" },
                on: { click: _vm.onAddPopup },
              },
              [_vm._v("新增")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                "header-cell-style": { "text-align": "center" },
                "cell-style": { "text-align": "center" },
                data: _vm.tableData,
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          staticStyle: { width: "60px", height: "60px" },
                          attrs: {
                            src: scope.row.pic,
                            "preview-src-list": [scope.row.pic],
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "locationName",
                  label: "所属营销位",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sort", label: "排序", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "跳转类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._l(scope.row.region, function (item, index) {
                          return _c("div", { key: index }, [
                            Number(item) === 1
                              ? _c("span", [_vm._v("首页")])
                              : _vm._e(),
                            _vm._v(" "),
                            Number(item) === 2
                              ? _c("span", [_vm._v("吃喝玩乐")])
                              : _vm._e(),
                            _vm._v(" "),
                            Number(item) === 5
                              ? _c("span", [_vm._v("个人中心")])
                              : _vm._e(),
                          ])
                        }),
                        _vm._v(" "),
                        Number(scope.row.activityType) === 1
                          ? _c("div", [_vm._v("自定义活动")])
                          : _vm._e(),
                        _vm._v(" "),
                        Number(scope.row.activityType) === 2
                          ? _c("div", [_vm._v("官方活动")])
                          : _vm._e(),
                        _vm._v(" "),
                        Number(scope.row.activityType) === 3
                          ? _c("div", [_vm._v("聚合页")])
                          : _vm._e(),
                        _vm._v(" "),
                        Number(scope.row.activityType) === 4
                          ? _c("div", [_vm._v("通用h5")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "activityName",
                  label: "活动名称",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.enable === 1 ? "上线" : "下线") +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "startTime",
                  label: "开始时间",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "endTime", label: "结束时间", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  fixed: "right",
                  width: "150",
                  label: "操作",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onDetail(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n            编辑\n          ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onDel(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n            删除\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "pagination-container",
              staticStyle: { position: "relative", "margin-top": "20px" },
            },
            [
              _c("el-pagination", {
                staticStyle: { position: "absolute", right: "0px", top: "0px" },
                attrs: {
                  "current-page": _vm.queryData.pageParam.page,
                  "page-sizes": [10, 20, 30, 50],
                  "page-size": _vm.queryData.pageParam.size,
                  total: _vm.pagination.total,
                  background: "",
                  layout: "total, prev, pager, next, sizes,jumper",
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("secondaryMarketing", {
        ref: "secondaryMarketing",
        on: { refresh: _vm.onRefresh },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }