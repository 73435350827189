"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.orderList = orderList;
var _request = _interopRequireDefault(require("@/utils/request"));
// 订单管理
function orderList(data) {
  return (0, _request.default)({
    url: '/portal/system/buyrestitution/order/pageList',
    method: 'post',
    data: data
  });
}