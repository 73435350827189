var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "top-nav" },
        _vm._l(_vm.tabList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              class: [
                "tab-box",
                _vm.activeNum === index ? "tab-box-active" : "",
              ],
              on: {
                click: function ($event) {
                  return _vm.changeType(index)
                },
              },
            },
            [_vm._v(_vm._s(item.name) + "\n    ")]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { padding: "10px" } },
        [
          _c("pictures", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeNum === 0,
                expression: "activeNum === 0",
              },
            ],
          }),
          _vm._v(" "),
          _c("storyBoard", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeNum === 1,
                expression: "activeNum === 1",
              },
            ],
          }),
          _vm._v(" "),
          _c("certificate", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeNum === 2,
                expression: "activeNum === 2",
              },
            ],
          }),
          _vm._v(" "),
          _c("compile", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeNum === 3,
                expression: "activeNum === 3",
              },
            ],
          }),
        ],
        1
      ),
      _vm._v(" "),
      false
        ? _c(
            "el-form",
            { attrs: { "label-width": "100px", "label-position": "top" } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "div",
                    {
                      staticClass: "both-label",
                      attrs: { slot: "label" },
                      slot: "label",
                    },
                    [
                      _c("i", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                      _vm._v("\n        uni-app应用标识（AppId）\n      "),
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "my-item",
                    model: {
                      value: _vm.settings.appid,
                      callback: function ($$v) {
                        _vm.$set(_vm.settings, "appid", $$v)
                      },
                      expression: "settings.appid",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "div",
                    {
                      staticClass: "both-label",
                      attrs: { slot: "label" },
                      slot: "label",
                    },
                    [
                      _c("i", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                      _vm._v("\n        应用名称\n      "),
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "my-item",
                    model: {
                      value: _vm.settings.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.settings, "name", $$v)
                      },
                      expression: "settings.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "div",
                    {
                      staticClass: "both-label",
                      attrs: { slot: "label" },
                      slot: "label",
                    },
                    [
                      _c("i", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                      _vm._v("\n        应用描述\n      "),
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "my-item",
                    model: {
                      value: _vm.settings.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.settings, "description", $$v)
                      },
                      expression: "settings.description",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "div",
                    {
                      staticClass: "both-label",
                      attrs: { slot: "label" },
                      slot: "label",
                    },
                    [
                      _c("i", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                      _vm._v("\n        应用版本名称\n        "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            color: "#969696",
                          },
                        },
                        [_vm._v("升级时必须高于上一次设置的值，如（1.1.1）")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "my-item",
                    model: {
                      value: _vm.settings.versionName,
                      callback: function ($$v) {
                        _vm.$set(_vm.settings, "versionName", $$v)
                      },
                      expression: "settings.versionName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "div",
                    {
                      staticClass: "both-label",
                      attrs: { slot: "label" },
                      slot: "label",
                    },
                    [
                      _c("i", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                      _vm._v("\n        应用版本号\n        "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            color: "#969696",
                          },
                        },
                        [
                          _vm._v(
                            "应用版本号，必须是整教，取值范围1~2147483647;升级时必须高于上一次设置的值"
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "my-item",
                    model: {
                      value: _vm.settings.versionCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.settings, "versionCode", $$v)
                      },
                      expression: "settings.versionCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "div",
                    {
                      staticClass: "both-label",
                      attrs: { slot: "label" },
                      slot: "label",
                    },
                    [
                      _c("i", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                      _vm._v("\n        android包名\n      "),
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "my-item",
                    model: {
                      value: _vm.settings.androidPackageName,
                      callback: function ($$v) {
                        _vm.$set(_vm.settings, "androidPackageName", $$v)
                      },
                      expression: "settings.androidPackageName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "div",
                    {
                      staticClass: "both-label",
                      attrs: { slot: "label" },
                      slot: "label",
                    },
                    [
                      _c("i", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                      _vm._v("\n        ios包名\n      "),
                    ]
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    staticClass: "my-item",
                    model: {
                      value: _vm.settings.iosPackageName,
                      callback: function ($$v) {
                        _vm.$set(_vm.settings, "iosPackageName", $$v)
                      },
                      expression: "settings.iosPackageName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "div",
                    {
                      staticClass: "both-label",
                      attrs: { slot: "label" },
                      slot: "label",
                    },
                    [
                      _c("i", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                      _vm._v("\n        文件上传\n      "),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      staticClass: "upload-demo",
                      attrs: {
                        action: "",
                        limit: 15,
                        multiple: "",
                        "http-request": function (value) {
                          return _vm.handleFileUpload(value)
                        },
                        "show-file-list": false,
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "up",
                          attrs: { slot: "trigger" },
                          slot: "trigger",
                        },
                        [_vm._v("+")]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.save } }, [_vm._v("保存")]),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.begin } }, [
                _vm._v("启动打包"),
              ]),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.searchStatus } }, [
                _vm._v("查询打包状态"),
              ]),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.stop } }, [
                _vm._v("终止打包"),
              ]),
              _vm._v(" "),
              _vm.packageStatus === 2
                ? _c("el-button", { on: { click: _vm.downLoad } }, [
                    _vm._v("下载包"),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }