//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ScrunchieMaterial from "./components/ScrunchieMaterial.vue";
import individuation from "./components/individuation.vue";
import classification from "./components/classification.vue";
export default {
  name: 'MarketingPosition',
  components: {
    ScrunchieMaterial: ScrunchieMaterial,
    individuation: individuation,
    classification: classification
  },
  data: function data() {
    return {
      activeName: 'ScrunchieMaterial'
    };
  },
  methods: {}
};