"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.search");
var _logList = require("@/api/authorityManagement_user_app/logList");
var _creatApp = require("@/api/creatApp");
var _store = _interopRequireDefault(require("@/store"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      searchApp: '',
      appId: _store.default.getters.appId,
      id: null,
      name: '',
      time: null,
      options: [{
        value: 'POST',
        label: 'POST'
      }, {
        value: 'GET',
        label: 'GET'
      }],
      method: '',
      dataList: [],
      page: 1,
      limit: 10,
      total: 0,
      appList: []
    };
  },
  mounted: function mounted() {
    if (this.appId * 1 === 0) {
      this.getApp();
    }
    this.getList();
  },
  methods: {
    clearSearch: function clearSearch() {
      this.id = null;
      this.search();
    },
    getApp: function getApp() {
      var _this = this;
      (0, _creatApp.getList)({
        appStatus: 1
      }).then(function (res) {
        _this.appList = res.data;
      });
    },
    rest: function rest() {
      this.id = null;
      this.time = null;
      this.name = '';
      this.method = '';
      this.page = 1;
      this.getList();
    },
    search: function search() {
      this.page = 1;
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;
      var obj = {
        createUser: this.id === null ? null : this.id * 1,
        nickname: this.name,
        createTime: this.time,
        method: this.method,
        pageNo: this.page,
        limit: this.limit
      };
      if (this.appId * 1 === 0) {
        obj['tenantId'] = this.searchApp ? this.searchApp : null;
      } else {
        obj['tenantId'] = this.appId;
      }
      (0, _logList.queryList)(obj).then(function (res) {
        _this2.total = res.data.total;
        _this2.dataList = res.data.data;
      });
    },
    // 分页
    handleSizeChange: function handleSizeChange(val) {
      this.limit = val;
      this.getList();
    },
    // 当前分页
    handleCurrentChange: function handleCurrentChange(val) {
      this.page = val;
      this.getList();
    }
  }
};