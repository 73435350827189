var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "upload" },
      [
        _c(
          "el-upload",
          {
            staticClass: "avatar-uploader",
            attrs: {
              action: "https://jsonplaceholder.typicode.com/posts/",
              "show-file-list": false,
              "before-upload": _vm.beforeAvatarUpload,
              "http-request": _vm.handleFileUpload,
            },
          },
          [
            _vm.imageUrl
              ? _c("img", {
                  staticClass: "avatar",
                  attrs: { src: _vm.imageUrl },
                })
              : _c("i", { staticClass: "el-icon-plus avatar-uploader-icon" }),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { "margin-top": "6px" } },
      [
        _vm.name === "tagPic"
          ? _c(
              "span",
              { staticStyle: { color: "#F59A23", "font-size": "12px" } },
              [_vm._v("注：即商品图片左上角标签，建议尺寸74px*74px")]
            )
          : _vm.name === "headerPic"
          ? _c(
              "span",
              { staticStyle: { color: "#F59A23", "font-size": "12px" } },
              [_vm._v("注:建议尺寸750px*320px")]
            )
          : _vm.name === "tutorialsSharePic"
          ? _c(
              "span",
              { staticStyle: { color: "#F59A23", "font-size": "12px" } },
              [_vm._v("注:建议尺寸702px*255px")]
            )
          : _c(
              "span",
              { staticStyle: { color: "#F59A23", "font-size": "12px" } },
              [_vm._v("注：建议尺寸750px*1000px")]
            ),
        _vm._v(" "),
        _c(
          "el-popover",
          { attrs: { placement: "right", width: "820", trigger: "hover" } },
          [
            _c("div", [
              _c("img", {
                staticStyle: { width: "800px", height: "630px" },
                attrs: { src: require("@/assets/dasasdasdasdaa.png"), alt: "" },
              }),
            ]),
            _vm._v(" "),
            _vm.name === "activitySharePic"
              ? _c(
                  "span",
                  {
                    staticStyle: {
                      border: "1px solid #F59A23",
                      padding: "6px",
                      "border-radius": "6px",
                      color: "#F59A23",
                    },
                    attrs: { slot: "reference" },
                    slot: "reference",
                  },
                  [_vm._v("查看示例")]
                )
              : _vm._e(),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }