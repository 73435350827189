"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'ItemActivity',
  props: {
    item: {
      type: Object,
      default: function _default() {}
    }
  },
  methods: {
    onPreview: function onPreview(data) {
      // console.log(data)
      // this.$refs.previewPopup.open(data)
      this.$parent.onPreview(data);
    }
  }
};