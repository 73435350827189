"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.appUpdateCommonConfig = appUpdateCommonConfig;
exports.appUpdateTenant = appUpdateTenant;
exports.queryAppDetail = queryAppDetail;
var _request = _interopRequireDefault(require("@/utils/request"));
function queryAppDetail(params) {
  return (0, _request.default)({
    url: '/portal/system/tenant/queryAppDetail',
    method: 'get',
    params: params
  });
}
function appUpdateCommonConfig(data) {
  return (0, _request.default)({
    url: '/portal/system/tenant/appUpdateCommonConfig',
    method: 'post',
    data: data
  });
}
function appUpdateTenant(data) {
  return (0, _request.default)({
    url: '/portal/system/tenant/appUpdateTenant',
    method: 'post',
    data: data
  });
}