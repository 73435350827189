"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ScrunchieMaterial = _interopRequireDefault(require("./components/ScrunchieMaterial.vue"));
var _individuation = _interopRequireDefault(require("./components/individuation.vue"));
var _classification = _interopRequireDefault(require("./components/classification.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'MarketingPosition',
  components: {
    ScrunchieMaterial: _ScrunchieMaterial.default,
    individuation: _individuation.default,
    classification: _classification.default
  },
  data: function data() {
    return {
      activeName: 'ScrunchieMaterial'
    };
  },
  methods: {}
};