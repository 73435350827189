var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "withdrawal_management" }, [
    _c("div", { staticClass: "title" }, [_vm._v("订单管理")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "search_criteria", staticStyle: { "margin-top": "10px" } },
      [
        _c(
          "div",
          { staticStyle: { "margin-left": "0" } },
          [
            _c("el-input", {
              attrs: { placeholder: "输入订单编号查询", clearable: "" },
              model: {
                value: _vm.queryData.orderNo,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "orderNo", $$v)
                },
                expression: "queryData.orderNo",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-input", {
              attrs: { placeholder: "输入商品名称查询", clearable: "" },
              model: {
                value: _vm.queryData.title,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "title", $$v)
                },
                expression: "queryData.title",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-input", {
              attrs: { placeholder: "输入商品ID查询", clearable: "" },
              model: {
                value: _vm.queryData.goodsId,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "goodsId", $$v)
                },
                expression: "queryData.goodsId",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-input", {
              attrs: { placeholder: "输入用户ID查询", clearable: "" },
              model: {
                value: _vm.queryData.memberId,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "memberId", $$v)
                },
                expression: "queryData.memberId",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: "选择商品平台类型", clearable: "" },
                model: {
                  value: _vm.queryData.platformCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "platformCode", $$v)
                  },
                  expression: "queryData.platformCode",
                },
              },
              [
                _c("el-option", { attrs: { value: "TB", label: "淘宝" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: "PDD", label: "拼多多" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: "JD", label: "京东" } }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                staticStyle: { width: "100%" },
                attrs: {
                  clearable: "",
                  filterable: "",
                  placeholder: "选择所属活动",
                },
                model: {
                  value: _vm.queryData.activityId,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "activityId", $$v)
                  },
                  expression: "queryData.activityId",
                },
              },
              _vm._l(_vm.options, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.activityName, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: "选择订单状态", clearable: "" },
                model: {
                  value: _vm.queryData.orderStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "orderStatus", $$v)
                  },
                  expression: "queryData.orderStatus",
                },
              },
              [
                _c("el-option", { attrs: { value: 1, label: "已支付" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 2, label: "已取消" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 3, label: "已结算" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 4, label: "已收货" } }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "flex",
            staticStyle: { width: "fit-content", height: "40px" },
          },
          [
            _c(
              "el-button",
              {
                staticStyle: { height: "40px" },
                attrs: { type: "primary" },
                on: { click: _vm.onInquire },
              },
              [_vm._v("查询")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "ml10",
                staticStyle: { height: "40px" },
                attrs: { type: "info", plain: "" },
                on: { click: _vm.onInitialize },
              },
              [_vm._v("重置")]
            ),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", [
      _c(
        "div",
        { staticClass: "mt20" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                border: "",
                data: _vm.tableData,
                "tooltip-effect": "dark",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "withdrawNo", label: "会员", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "pointer",
                            staticStyle: {
                              "font-size": "14px",
                              color: "#4d8aff",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onUserDetails(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(scope.row.username) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v("uid:" + _vm._s(scope.row.memberId)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "orderNo", label: "订单编号", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "goodsId", label: "商品ID", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "商品信息", align: "center", width: "500" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "flex mt10",
                            staticStyle: {
                              border: "1px solid #dcdfe6",
                              width: "fit-content",
                              "border-radius": "10px",
                              padding: "10px",
                              "line-height": "20px",
                            },
                          },
                          [
                            scope.row.goodsDetailVo &&
                            scope.row.goodsDetailVo.picUrl
                              ? _c("div", { staticClass: "fc" }, [
                                  _c("img", {
                                    staticStyle: {
                                      width: "40px",
                                      height: "40px",
                                    },
                                    attrs: {
                                      src: scope.row.goodsDetailVo.picUrl,
                                      alt: "",
                                    },
                                  }),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "ml10" }, [
                              _c(
                                "div",
                                { staticClass: "ellipsis-multiline img" },
                                [
                                  _c("img", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: scope.row.platformCode === "TB",
                                        expression:
                                          "scope.row.platformCode === 'TB'",
                                      },
                                    ],
                                    staticClass: "icon_img",
                                    attrs: {
                                      alt: "",
                                      src: require("@/assets/platformIcon/tb.png"),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("img", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: scope.row.platformCode === "JD",
                                        expression:
                                          "scope.row.platformCode === 'JD'",
                                      },
                                    ],
                                    staticClass: "icon_img",
                                    attrs: {
                                      alt: "",
                                      src: require("@/assets/platformIcon/jd.png"),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("img", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: scope.row.platformCode === "PDD",
                                        expression:
                                          "scope.row.platformCode === 'PDD'",
                                      },
                                    ],
                                    staticClass: "icon_img",
                                    attrs: {
                                      alt: "",
                                      src: require("@/assets/platformIcon/pdd.png"),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("img", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: scope.row.platformCode === "WPH",
                                        expression:
                                          "scope.row.platformCode === 'WPH'",
                                      },
                                    ],
                                    staticClass: "icon_img",
                                    attrs: {
                                      alt: "",
                                      src: require("@/assets/platformIcon/wph.png"),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("img", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: scope.row.platformCode === "DY",
                                        expression:
                                          "scope.row.platformCode === 'DY'",
                                      },
                                    ],
                                    staticClass: "icon_img",
                                    attrs: {
                                      alt: "",
                                      src: require("@/assets/platformIcon/dy.png"),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("img", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: scope.row.platformCode === "KS",
                                        expression:
                                          "scope.row.platformCode === 'KS'",
                                      },
                                    ],
                                    staticClass: "icon_img",
                                    attrs: {
                                      alt: "",
                                      src: require("@/assets/platformIcon/ks.png"),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("img", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: scope.row.platformCode === "ELM",
                                        expression:
                                          "scope.row.platformCode === 'ELM'",
                                      },
                                    ],
                                    staticClass: "icon_img",
                                    attrs: {
                                      alt: "",
                                      src: require("@/assets/platformIcon/elm.png"),
                                    },
                                  }),
                                  _vm._v(" "),
                                  scope.row.goodsDetailVo
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              scope.row.goodsDetailVo.title
                                            )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              scope.row.goodsDetailVo
                                ? _c("div", { staticClass: "flex" }, [
                                    _c("div", [
                                      _vm._v("\n                    原价:"),
                                      _c(
                                        "span",
                                        { staticClass: "original-price" },
                                        [
                                          _vm._v(
                                            "￥" +
                                              _vm._s(
                                                scope.row.goodsDetailVo.orgPrice
                                              )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "ml20" }, [
                                      _vm._v("\n                    券额:"),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [
                                          _vm._v(
                                            "￥" +
                                              _vm._s(
                                                scope.row.goodsDetailVo.discount
                                              )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "ml20" }, [
                                      _vm._v("\n                    券后价:"),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "red" } },
                                        [
                                          _vm._v(
                                            "￥" +
                                              _vm._s(
                                                scope.row.goodsDetailVo
                                                  .actualPrice
                                              )
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "amount", label: "支付金额", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "subAmt", label: "补贴金额", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "feeOrigin", label: "佣金", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "selfOrderCount",
                  label: "商品平台类型",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.platformMapping[scope.row.platformCode]
                            ) +
                            "\n          "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "activityName",
                  label: "所属活动",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "targetUserType",
                  label: "用户类型",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.targetUserType === 1
                          ? _c("div", [_vm._v("所有人")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.targetUserType === 3
                          ? _c("div", [_vm._v("指定等级")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "orderStatus", label: "状态", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        Number(scope.row.orderStatus) === 1
                          ? _c("div", [_vm._v("已支付")])
                          : _vm._e(),
                        _vm._v(" "),
                        Number(scope.row.orderStatus) === 2
                          ? _c("div", [_vm._v("已取消")])
                          : _vm._e(),
                        _vm._v(" "),
                        Number(scope.row.orderStatus) === 3
                          ? _c("div", [_vm._v("已结算")])
                          : _vm._e(),
                        _vm._v(" "),
                        Number(scope.row.orderStatus) === 4
                          ? _c("div", [_vm._v("已收货")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "创建时间",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "remark",
                  label: "操作",
                  align: "center",
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "fc" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDetails(scope.row)
                                  },
                                },
                              },
                              [_vm._v("详情")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 page_box" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.queryData.page.page,
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.queryData.page.size,
              layout: "total, prev, pager, next, sizes,jumper",
              total: _vm.total,
              background: "",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }