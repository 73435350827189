var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            title: "跑马灯管理",
            width: "1200px",
            top: "10vh",
            "custom-class": "dialog_box",
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                padding: "0 40px",
                "max-height": "700px",
                "overflow-y": "auto",
              },
            },
            [
              _c(
                "div",
                { staticClass: "frb", staticStyle: { width: "100%" } },
                [
                  _c("div", { staticClass: "flex" }, [
                    _c(
                      "div",
                      { staticClass: "from_box" },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "ruleForm",
                            staticClass: "demo-ruleForm",
                            attrs: { "label-width": "120px" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "130px",
                                  height: "130px",
                                  background: "#f9f9f9",
                                  "border-radius": "4px",
                                  border: "1px solid #eeeeee",
                                },
                              },
                              [
                                _c(
                                  "el-upload",
                                  {
                                    staticClass: "avatar-uploader",
                                    attrs: {
                                      "show-file-list": false,
                                      "on-success": _vm.handleAvatarSuccess,
                                      action: "",
                                      "http-request": _vm.handleFileUpload,
                                    },
                                  },
                                  [
                                    _vm.picImg
                                      ? _c("img", {
                                          staticClass: "avatar",
                                          attrs: { src: _vm.picImg },
                                        })
                                      : _c("i", {
                                          staticClass:
                                            "el-icon-plus avatar-uploader-icon",
                                        }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "ml20 Tips_text" }, [
                      _c("div", [_vm._v("左侧图标标识，建议尺寸(286*66)")]),
                      _vm._v(" "),
                      _c("div", [_vm._v("请上传头条图标")]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "Tips_text" }, [
                    _c(
                      "div",
                      {
                        staticClass: "Official_event fc",
                        on: { click: _vm.onAdd },
                      },
                      [_vm._v("新增")]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt10" },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { border: "", data: _vm.tableData },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          type: "index",
                          width: "50",
                          align: "center",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "content",
                          label: "内容",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.id
                                  ? _c("el-input", {
                                      staticClass: "input_box",
                                      attrs: {
                                        placeholder: "",
                                        clearable: "",
                                        disabled: scope.row.inputState,
                                      },
                                      model: {
                                        value: scope.row.content,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "content", $$v)
                                        },
                                        expression: "scope.row.content",
                                      },
                                    })
                                  : _c("el-input", {
                                      staticClass: "input_box",
                                      attrs: { placeholder: "", clearable: "" },
                                      model: {
                                        value: scope.row.content,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "content", $$v)
                                        },
                                        expression: "scope.row.content",
                                      },
                                    }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "content",
                          label: "跳转类型",
                          align: "center",
                          width: "350",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: {
                                      label: "1",
                                      disabled: scope.row.inputState,
                                    },
                                    model: {
                                      value: scope.row.jumpType,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "jumpType", $$v)
                                      },
                                      expression: "scope.row.jumpType",
                                    },
                                  },
                                  [_vm._v("无跳转")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: {
                                      label: "2",
                                      disabled: scope.row.inputState,
                                    },
                                    model: {
                                      value: scope.row.jumpType,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "jumpType", $$v)
                                      },
                                      expression: "scope.row.jumpType",
                                    },
                                  },
                                  [_vm._v("输入URL")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: {
                                      label: "3",
                                      disabled: scope.row.inputState,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.onRadioChange(scope.$index)
                                      },
                                    },
                                    model: {
                                      value: scope.row.jumpType,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "jumpType", $$v)
                                      },
                                      expression: "scope.row.jumpType",
                                    },
                                  },
                                  [_vm._v("去选择")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "content",
                          label: "活动",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.jumpType === "2"
                                  ? _c(
                                      "div",
                                      [
                                        scope.row.id
                                          ? _c("el-input", {
                                              staticClass: "input_box",
                                              attrs: {
                                                placeholder: "",
                                                clearable: "",
                                                disabled: scope.row.inputState,
                                              },
                                              model: {
                                                value: scope.row.jumpContent,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "jumpContent",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.jumpContent",
                                              },
                                            })
                                          : _c("el-input", {
                                              staticClass: "input_box",
                                              attrs: {
                                                placeholder: "",
                                                clearable: "",
                                              },
                                              model: {
                                                value: scope.row.jumpContent,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "jumpContent",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "scope.row.jumpContent",
                                              },
                                            }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                scope.row.jumpType === "3"
                                  ? _c("div", [
                                      _c("div", [
                                        _vm._v(_vm._s(scope.row.activityName)),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "content",
                          width: "100",
                          label: "上下线状态",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-switch", {
                                  attrs: {
                                    "active-value": 1,
                                    "inactive-value": 0,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onChangeSwitch(
                                        scope.row,
                                        $event
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.enable,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "enable", $$v)
                                    },
                                    expression: "scope.row.enable",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "name",
                          width: "150",
                          label: "操作",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("div", { staticClass: "fc" }, [
                                  scope.row.inputState
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "Operation_button fc",
                                          staticStyle: { color: "#83c1ff" },
                                          on: {
                                            click: function ($event) {
                                              scope.row.inputState = false
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              width: "14px",
                                              height: "14px",
                                            },
                                            attrs: {
                                              src: require("@/assets/LittleActivityCreation/editor.png"),
                                              alt: "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", [_vm._v("编辑")]),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass: "Operation_button fc",
                                          staticStyle: { color: "#83c1ff" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onSave(
                                                scope.row,
                                                scope.$index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              width: "14px",
                                              height: "14px",
                                            },
                                            attrs: {
                                              src: require("@/assets/save_icon.png"),
                                              alt: "",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("span", [_vm._v("保存")]),
                                        ]
                                      ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "Operation_button ml10 fc",
                                      staticStyle: {
                                        border: "1px solid #e96e7e",
                                        color: "#e96e7e",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onDelete(
                                            scope.row,
                                            scope.$index
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          width: "14px",
                                          height: "14px",
                                        },
                                        attrs: {
                                          src: require("@/assets/LittleActivityCreation/delete.png"),
                                          alt: "",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("删除")]),
                                    ]
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("ActivitySelectionPopup", {
        ref: "activitySelectionPopup",
        on: { SelectEvents: _vm.onSelectEvents },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }