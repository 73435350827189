"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/fe-ug-comprehensive-shopping-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
var _interopRequireWildcard2 = _interopRequireDefault(require("/root/.jenkins/workspace/fe-ug-comprehensive-shopping-admin-dev/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
_vue.default.use(_vueRouter.default);

/* Layout */

/**
 * Note: sub-menu only appear when route children.length >= 1
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = exports.constantRoutes = [{
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/',
  redirect: '/login',
  children: [{
    path: '/login',
    name: 'login',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));
      });
    }
  }]
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/404'));
    });
  },
  hidden: true
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
var asyncRoutes = exports.asyncRoutes = [{
  path: '/systemAdministrator/creatApp',
  component: _layout.default,
  redirect: '/systemAdministrator/creatApp/index',
  name: 'systemAdministrator',
  meta: {
    title: 'APP创建',
    roles: ['sys_admin']
  },
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/systemAdministrator/creatApp/index'));
      });
    },
    // Parent router-view
    name: 'creatApp',
    meta: {
      title: 'APP创建',
      roles: ['sys_admin']
    }
  }, {
    path: '/systemAdministrator/creatApp/appDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/systemAdministrator/creatApp/appDetail'));
      });
    },
    // Parent router-view
    name: 'appDetail',
    meta: {
      title: '',
      roles: ['sys_admin']
    },
    hidden: true
  }]
}, {
  path: '/systemAdministrator/personnelManage',
  component: _layout.default,
  redirect: '/systemAdministrator/personnelManage/index',
  name: 'personnelManage',
  meta: {
    title: '用户管理',
    roles: ['sys_admin']
  },
  children: [{
    path: 'personnelManage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/systemAdministrator/personnelManage/index'));
      });
    },
    name: 'personnelManage',
    meta: {
      title: '用户管理',
      roles: ['sys_admin']
    }
  }]
}, {
  path: '/systemAdministrator/activityCreation',
  component: _layout.default,
  redirect: '/systemAdministrator/activityCreation/index',
  name: 'activityCreation',
  meta: {
    title: '通用活动创建',
    roles: ['sys_admin', 'activity_admin']
  },
  children: [{
    path: 'activityCreation',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/systemAdministrator/activityCreation/index'));
      });
    },
    name: 'activityCreation',
    meta: {
      title: '通用活动创建',
      roles: ['sys_admin', 'activity_admin']
    }
  }]
}, {
  path: '/systemAdministrator/universalH5Page',
  component: _layout.default,
  redirect: '/systemAdministrator/universalH5Page/index',
  name: 'universalH5Page',
  meta: {
    title: '通用H5页面',
    roles: ['sys_admin', 'activity_admin']
  },
  children: [{
    path: 'universalH5Page',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/systemAdministrator/universalH5Page/index'));
      });
    },
    name: 'universalH5Page',
    meta: {
      title: '通用H5页面',
      roles: ['sys_admin', 'activity_admin']
    }
  }]
}, {
  path: '/systemAdministrator/otherH5Page',
  component: _layout.default,
  redirect: '/systemAdministrator/otherH5Page/index',
  name: 'otherH5Page',
  meta: {
    title: '第三方代下单',
    roles: ['sys_admin', 'activity_admin']
  },
  children: [{
    path: 'otherH5Page',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/systemAdministrator/otherH5Page/index'));
      });
    },
    name: 'otherH5Page',
    meta: {
      title: '第三方代下单',
      roles: ['sys_admin', 'activity_admin']
    }
  }]
}, {
  path: '/dataMarket',
  component: _layout.default,
  redirect: '/dataMarket/index',
  name: 'dataMarket',
  meta: {
    title: '数据大盘',
    roles: ['app_admin']
  },
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dataMarket/index'));
      });
    },
    name: 'dataMarket',
    meta: {
      title: '数据大盘',
      roles: ['app_admin'],
      keepAlive: false
    }
  }]
}, {
  path: '/member',
  component: _layout.default,
  redirect: '/member/membershipList/index',
  name: 'membershipList1',
  meta: {
    title: '会员管理',
    roles: ['app_admin']
  },
  children: [{
    path: 'membershipList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/membershipList/index'));
      });
    },
    name: 'membershipList',
    meta: {
      title: '会员列表',
      roles: ['app_admin'],
      keepAlive: true
    }
  }, {
    path: 'userDetails',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/userDetails/index'));
      });
    },
    name: 'userDetails',
    meta: {
      title: '会员详情',
      roles: ['app_admin']
    },
    hidden: true
  }, {
    path: 'feedback',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/member/feedback/index'));
      });
    },
    name: 'feedback',
    meta: {
      title: '反馈意见',
      keepAlive: true,
      roles: ['app_admin']
    }
  }]
}, {
  path: '/operationManagement',
  component: _layout.default,
  redirect: '/operationManagement/marketingPosition/index',
  name: 'marketingPosition1',
  meta: {
    title: '运营管理',
    roles: ['app_admin']
  },
  children: [{
    path: 'appRenovation',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/operationManagement/appRenovation/index'));
      });
    },
    name: 'appRenovation',
    meta: {
      title: 'app装修',
      roles: ['app_admin']
    }
  },
  // {
  //   path: 'marketingPosition',
  //   component: () => import('@/views/operationManagement/marketingPosition/index'),
  //   name: 'marketingPosition',
  //   meta: { title: '首页营销位', roles: ['app_admin'] }
  // },
  // {
  //   path: 'localMarketingPosition',
  //   component: () => import('@/views/operationManagement/localMarketingPosition/index'),
  //   name: 'localMarketingPosition',
  //   meta: { title: '本地生活营销位', roles: ['app_admin'] }
  // },
  // {
  //   path: 'venueList',
  //   component: () => import('@/views/operationManagement/venueList/index'),
  //   name: 'venueList',
  //   meta: { title: '榜单会场榜', roles: ['app_admin'] }
  // },
  {
    path: 'scrunchieArrangement',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/operationManagement/scrunchieArrangement/index'));
      });
    },
    name: 'scrunchieArrangement',
    meta: {
      title: '发圈管理',
      roles: ['app_admin']
    }
  }, {
    path: 'BusinessSchool',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/operationManagement/BusinessSchool/index'));
      });
    },
    name: 'BusinessSchool',
    meta: {
      title: '商学院配置',
      keepAlive: true,
      roles: ['app_admin']
    }
  }, {
    path: 'posterManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/operationManagement/posterManagement/index'));
      });
    },
    name: 'posterManagement',
    meta: {
      title: '海报管理',
      keepAlive: true,
      roles: ['app_admin']
    }
  }, {
    path: 'personalCenter',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/operationManagement/personalCenter/index'));
      });
    },
    name: 'personalCenter',
    meta: {
      title: '个人中心管理',
      roles: ['app_admin']
    }
  }, {
    path: 'aggregatePageManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/operationManagement/aggregatePageManagement/index'));
      });
    },
    name: 'aggregatePageManagement',
    meta: {
      title: '聚合页管理',
      roles: ['app_admin']
    }
  }, {
    path: 'popupManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/operationManagement/popupManagement/index'));
      });
    },
    name: 'popupManagement',
    meta: {
      title: '弹窗管理',
      roles: ['app_admin']
    }
  }, {
    path: 'secondaryMarketingPosition',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/operationManagement/secondaryMarketingPosition/index'));
      });
    },
    name: 'secondaryMarketingPosition',
    meta: {
      title: '二级营销位管理',
      roles: ['app_admin']
    }
  }, {
    path: 'customizePageManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/operationManagement/customizePageManagement/index'));
      });
    },
    name: 'customizePageManagement',
    meta: {
      title: '自定义页面管理',
      roles: ['app_admin']
    }
  }, {
    path: 'goodsListActive',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/operationManagement/goodsListActive/index'));
      });
    },
    name: 'goodsListActive',
    meta: {
      title: '商品列表活动',
      roles: ['app_admin']
    }
  }
  // {
  //   path: 'dataMarket',
  //   component: () => import('@/views/dataMarket/index'),
  //   name: 'dataMarket',
  //   meta: { title: '数据大盘', roles: ['app_admin'] }
  // }
  // {
  //   path: 'functionModule',
  //   component: () => import('@/views/operationManagement/functionModule/index'),
  //   name: 'functionModule',
  //   meta: { title: '功能模块', roles: ['app_admin'] }
  // }

  //  {
  //         path: 'LittleActivityCreation',
  //         component: () => import('@/views/operationManagement/LittleActivityCreation/index'),
  //         name: 'LittleActivityCreation',
  //         meta: { title: '活动管理', roles: ['app_admin'] }
  //       }
  // {
  //   path: 'BusinessSchool',
  //   component: () => import('@/views/operationManagement/BusinessSchool/index'),
  //   name: 'BusinessSchool',
  //   meta: { title: '商学院配置', roles: ['app_admin'] }
  // }
  ]
}, {
  path: '/LittleActivityCreation',
  component: _layout.default,
  redirect: '/LittleActivityCreation/index',
  name: 'LittleActivityCreation',
  meta: {
    title: '自定义活动管理',
    roles: ['app_admin']
  },
  children: [{
    path: 'LittleActivityCreation',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/LittleActivityCreation/index'));
      });
    },
    name: 'LittleActivityCreation',
    meta: {
      title: '自定义活动管理',
      roles: ['app_admin'],
      keepAlive: true
    }
  }]
}, {
  path: '/financialManagement',
  component: _layout.default,
  redirect: '/financialManagement/withdrawalManagement/index',
  name: 'withdrawalManagement',
  meta: {
    title: '财务管理',
    roles: ['app_admin']
  },
  children: [{
    path: 'withdrawalManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/financialManagement/withdrawalManagement/index'));
      });
    },
    name: 'withdrawalManagement',
    meta: {
      title: '会员提现管理',
      roles: ['app_admin']
    }
  }, {
    path: 'monthlyBalanceStatement',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/financialManagement/monthlyBalanceStatement/index'));
      });
    },
    name: 'monthlyBalanceStatement',
    meta: {
      title: '月结对账单',
      roles: ['app_admin']
    }
  }, {
    path: 'withdrawalAudit',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/financialManagement/withdrawalAudit/index'));
      });
    },
    name: 'withdrawalAudit',
    meta: {
      title: '提现账户审核',
      roles: ['app_admin']
    }
  }, {
    path: 'platformDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/financialManagement/monthlyBalanceStatement/platformDetail'));
      });
    },
    name: 'platformDetail',
    meta: {
      title: '平台明细',
      roles: ['app_admin']
    },
    hidden: true
  }, {
    path: 'memberAccountDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/financialManagement/monthlyBalanceStatement/memberAccountDetail'));
      });
    },
    name: 'memberAccountDetail',
    meta: {
      title: '会员出账明细',
      roles: ['app_admin']
    },
    hidden: true
  }, {
    path: 'withdrawalRulesConfiguration',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/financialManagement/withdrawalRulesConfiguration/index'));
      });
    },
    name: 'withdrawalRulesConfiguration',
    meta: {
      title: '提现规则配置',
      roles: ['app_admin']
    }
  }, {
    path: 'memberBalanceDetails',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/financialManagement/memberBalanceDetails/index'));
      });
    },
    name: 'memberBalanceDetails',
    meta: {
      title: '会员余额明细',
      roles: ['app_admin']
    }
  }]
}, {
  path: '/orderManagement',
  component: _layout.default,
  redirect: '/orderManagement/commissionBaseDeductionSetting/index',
  name: 'withdrawalManagement',
  meta: {
    title: '订单管理',
    roles: ['app_admin']
  },
  children: [{
    path: 'commissionBaseDeductionSetting',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/orderManagement/commissionBaseDeductionSetting/index'));
      });
    },
    name: 'commissionBaseDeductionSetting',
    meta: {
      title: '订单管理',
      roles: ['app_admin'],
      keepAlive: true
    }
  }, {
    path: 'orderDetails',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/orderManagement/commissionBaseDeductionSetting/orderDetails'));
      });
    },
    name: 'orderDetails',
    meta: {
      title: '订单详情',
      roles: ['app_admin']
    },
    hidden: true
  }
  // {
  //   path: 'telephoneTransaction',
  //   component: () => import('@/views/orderManagement/telephoneTransaction/index'),
  //   name: 'telephoneTransaction',
  //   meta: { title: '话费交易订单', roles: ['app_admin'] }
  // },
  // {
  //   path: 'promotionOrder',
  //   component: () => import('@/views/orderManagement/promotionOrder/index'),
  //   name: 'promotionOrder',
  //   meta: { title: '佣金基数扣除', roles: ['app_admin'] }
  // }
  ]
}, {
  path: '/shoppingGift',
  component: _layout.default,
  redirect: '/shoppingGift/accountDetails/index',
  name: 'shoppingGift',
  meta: {
    title: '淘礼金',
    roles: ['app_admin']
  },
  children: [{
    path: 'accountDetails',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shoppingGift/accountDetails/index'));
      });
    },
    name: 'accountDetails',
    meta: {
      title: '淘礼金账户明细',
      roles: ['app_admin'],
      keepAlive: true
    }
  }, {
    path: 'createRecord',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/shoppingGift/createRecord/index'));
      });
    },
    name: 'createRecord',
    meta: {
      title: '淘礼金创建记录',
      roles: ['app_admin'],
      keepAlive: true
    }
  }]
},
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: 'history',
    // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = exports.default = router;