"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addOrUpdateRole = addOrUpdateRole;
exports.buildRolePermission = buildRolePermission;
exports.deleteAdminRole = deleteAdminRole;
exports.queryAdminAllPermission = queryAdminAllPermission;
exports.queryAppAllPermission = queryAppAllPermission;
exports.queryPermissionTreeByRoleId = queryPermissionTreeByRoleId;
exports.queryRolePage = queryRolePage;
var _request = _interopRequireDefault(require("@/utils/request"));
// 小b端所有权限树
function queryAppAllPermission(params) {
  return (0, _request.default)({
    url: '/portal/system/permission/queryAppAllPermission',
    method: 'get',
    params: params
  });
}
// 超管权限树
function queryAdminAllPermission(params) {
  return (0, _request.default)({
    url: '/portal/system/permission/queryAdminAllPermission',
    method: 'get',
    params: params
  });
}

// 通用新增、修改角色
function addOrUpdateRole(data) {
  return (0, _request.default)({
    url: '/portal/system/role/addOrUpdateRole',
    method: 'post',
    data: data
  });
}
// 通用删除角色
function deleteAdminRole(params) {
  return (0, _request.default)({
    url: '/portal/system/role/deleteAdminRole',
    method: 'get',
    params: params
  });
}
// 通用角色列表
function queryRolePage(data) {
  return (0, _request.default)({
    url: '/portal/system/role/queryRolePage',
    method: 'post',
    data: data
  });
}

// 通用角色授权
function buildRolePermission(data) {
  return (0, _request.default)({
    url: '/portal/system/role/buildRolePermission',
    method: 'post',
    data: data
  });
}

// 查询角色的权限树
function queryPermissionTreeByRoleId(params) {
  return (0, _request.default)({
    url: '/portal/system/role/queryPermissionTreeByRoleId',
    method: 'get',
    params: params
  });
}