var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "box" }, [
        _c(
          "div",
          { staticClass: "left-box" },
          [
            _c("MobileContent", {
              attrs: {
                type: 1,
                "tab-bar-list": _vm.tabBarList,
                "home-foot-list": _vm.homeFootList,
                "all-config": _vm.allConfig,
                "home-top-list": _vm.homeTopList,
              },
              on: { childData: _vm.handleChildData },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "right-box" }, [
          _c("div", { staticClass: "right-top-box" }, [
            _vm.activeNum != 1 && _vm.activeNum != 13 && _vm.activeNum != 14
              ? _c("div", { staticClass: "title" }, [_vm._v("首页模块")])
              : _c("div", { staticClass: "title" }, [
                  _vm._v(
                    _vm._s(
                      _vm.baseData.filter(function (i) {
                        return i.myType === _vm.activeNum
                      })[0].text
                    )
                  ),
                ]),
            _vm._v(" "),
            _vm.activeNum === 1 || _vm.activeNum === 13 || _vm.activeNum === 14
              ? _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-right": "15px" },
                        attrs: { plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.goBack()
                          },
                        },
                      },
                      [_vm._v("返回")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      { attrs: { type: "primary" }, on: { click: _vm.save } },
                      [_vm._v("保存")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.activeNum != 1 &&
                    _vm.activeNum != 13 &&
                    _vm.activeNum != 14,
                  expression: "(activeNum!=1&&activeNum!=13&&activeNum!=14)",
                },
              ],
              staticStyle: { "margin-top": "20px" },
            },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    "header-cell-style": { "text-align": "center" },
                    "cell-style": { "text-align": "center" },
                    data: _vm.baseData,
                    border: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { width: "80", type: "index", label: "序号" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { width: "200", prop: "text", label: "名称" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { width: "200", prop: "", label: "上下线状态" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.myType === 14
                              ? _c("div", [_vm._v("-")])
                              : _c("el-switch", {
                                  attrs: {
                                    "active-value": 1,
                                    "inactive-value": 0,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeStatus(scope.row)
                                    },
                                  },
                                  model: {
                                    value: scope.row.isShow,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "isShow", $$v)
                                    },
                                    expression: "scope.row.isShow",
                                  },
                                }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "190", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  size: "small",
                                  type: "primary",
                                  icon: "el-icon-edit",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.setting(scope.row)
                                  },
                                },
                              },
                              [_vm._v("管理")]
                            ),
                            _vm._v(" "),
                            scope.row.category === "4"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "small",
                                      icon: "el-icon-setting",
                                      plain: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.locatinClick(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.activeNum === 1
            ? _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        "header-cell-style": { "text-align": "center" },
                        "cell-style": { "text-align": "center" },
                        data: _vm.tableData,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { width: "60", type: "index", label: "序号" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "150", prop: "name", label: "名称" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "150", prop: "", label: "图片" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.type != "textColor"
                                    ? _c(
                                        "div",
                                        [
                                          scope.row.url
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    position: "relative",
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticStyle: {
                                                      width: "48px",
                                                      height: "48px",
                                                    },
                                                    attrs: {
                                                      src: scope.row.url,
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-delete",
                                                    staticStyle: {
                                                      position: "absolute",
                                                      right: "0",
                                                      top: "0",
                                                      cursor: "pointer",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.del(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _c(
                                                "el-upload",
                                                {
                                                  ref: "upload",
                                                  staticClass: "upload-demo",
                                                  attrs: {
                                                    action: "",
                                                    limit: 1,
                                                    "http-request": function (
                                                      value
                                                    ) {
                                                      return _vm.handleFileUpload(
                                                        value,
                                                        scope.row
                                                      )
                                                    },
                                                    "show-file-list": false,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "up",
                                                      attrs: {
                                                        slot: "trigger",
                                                      },
                                                      slot: "trigger",
                                                    },
                                                    [_vm._v("+")]
                                                  ),
                                                ]
                                              ),
                                        ],
                                        1
                                      )
                                    : _c("span", [_vm._v("-")]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1304260035
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "150", prop: "", label: "尺寸说明" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        scope.row.size ? scope.row.size : "-"
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2641519317
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "150", prop: "", label: "色值" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.type === "textColor"
                                    ? _c("el-color-picker", {
                                        model: {
                                          value: scope.row.color,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "color", $$v)
                                          },
                                          expression: "scope.row.color",
                                        },
                                      })
                                    : _c("span", [_vm._v("-")]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1624472715
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.activeNum === 13
            ? _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        "header-cell-style": { "text-align": "center" },
                        "cell-style": { "text-align": "center" },
                        data: _vm.tableDataFour,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { width: "60", type: "index", label: "序号" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "150", prop: "name", label: "名称" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "150", prop: "", label: "图片" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  !(
                                    scope.row.type === "textColor" ||
                                    scope.row.type === "tbBg"
                                  )
                                    ? _c(
                                        "div",
                                        [
                                          scope.row.url
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    position: "relative",
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    staticStyle: {
                                                      width: "48px",
                                                      height: "48px",
                                                    },
                                                    attrs: {
                                                      src: scope.row.url,
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-delete",
                                                    staticStyle: {
                                                      position: "absolute",
                                                      right: "0",
                                                      top: "0",
                                                      cursor: "pointer",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.del(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _c(
                                                "el-upload",
                                                {
                                                  ref: "upload",
                                                  staticClass: "upload-demo",
                                                  attrs: {
                                                    action: "",
                                                    limit: 1,
                                                    "http-request": function (
                                                      value
                                                    ) {
                                                      return _vm.handleFileUpload(
                                                        value,
                                                        scope.row
                                                      )
                                                    },
                                                    "show-file-list": false,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "up",
                                                      attrs: {
                                                        slot: "trigger",
                                                      },
                                                      slot: "trigger",
                                                    },
                                                    [_vm._v("+")]
                                                  ),
                                                ]
                                              ),
                                        ],
                                        1
                                      )
                                    : _c("span", [_vm._v("-")]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          446464212
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "150", prop: "", label: "尺寸说明" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        scope.row.size ? scope.row.size : "-"
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2641519317
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "150", prop: "", label: "色值" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.type === "textColor" ||
                                  scope.row.type === "tbBg"
                                    ? _c("el-color-picker", {
                                        model: {
                                          value: scope.row.color,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "color", $$v)
                                          },
                                          expression: "scope.row.color",
                                        },
                                      })
                                    : _c("span", [_vm._v("-")]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3414093149
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.activeNum === 14
            ? _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        "header-cell-style": { "text-align": "center" },
                        "cell-style": { "text-align": "center" },
                        data: _vm.tableDataFif,
                        border: "",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { width: "60", type: "index", label: "序号" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "250", prop: "name", label: "名称" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.type === "local" ||
                                  scope.row.type === "rank" ||
                                  scope.row.type === "hair"
                                    ? _c("el-input", {
                                        attrs: {
                                          maxlength: "6",
                                          placeholder: "请输入内容",
                                        },
                                        model: {
                                          value: scope.row.name,
                                          callback: function ($$v) {
                                            _vm.$set(scope.row, "name", $$v)
                                          },
                                          expression: "scope.row.name",
                                        },
                                      })
                                    : _c("span", [
                                        _vm._v(_vm._s(scope.row.name)),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3761583587
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "190", prop: "", label: "选中图标" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    [
                                      scope.row.selectUrl
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                position: "relative",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  width: "48px",
                                                  height: "48px",
                                                },
                                                attrs: {
                                                  src: scope.row.selectUrl,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("i", {
                                                staticClass: "el-icon-delete",
                                                staticStyle: {
                                                  position: "absolute",
                                                  right: "0",
                                                  top: "0",
                                                  cursor: "pointer",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.del(
                                                      scope.row,
                                                      "selectUrl"
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          )
                                        : _c(
                                            "el-upload",
                                            {
                                              ref: "upload",
                                              staticClass: "upload-demo",
                                              attrs: {
                                                action: "",
                                                limit: 1,
                                                "http-request": function (
                                                  value
                                                ) {
                                                  return _vm.handleFileUpload(
                                                    value,
                                                    scope.row,
                                                    "selectUrl"
                                                  )
                                                },
                                                "show-file-list": false,
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "up",
                                                  attrs: { slot: "trigger" },
                                                  slot: "trigger",
                                                },
                                                [_vm._v("+")]
                                              ),
                                            ]
                                          ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2203900893
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "190", prop: "", label: "未选中图标" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    [
                                      scope.row.normalUrl
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                position: "relative",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  width: "48px",
                                                  height: "48px",
                                                },
                                                attrs: {
                                                  src: scope.row.normalUrl,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("i", {
                                                staticClass: "el-icon-delete",
                                                staticStyle: {
                                                  position: "absolute",
                                                  right: "0",
                                                  top: "0",
                                                  cursor: "pointer",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.del(
                                                      scope.row,
                                                      "normalUrl"
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          )
                                        : _c(
                                            "el-upload",
                                            {
                                              ref: "upload",
                                              staticClass: "upload-demo",
                                              attrs: {
                                                action: "",
                                                limit: 1,
                                                "http-request": function (
                                                  value
                                                ) {
                                                  return _vm.handleFileUpload(
                                                    value,
                                                    scope.row,
                                                    "normalUrl"
                                                  )
                                                },
                                                "show-file-list": false,
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "up",
                                                  attrs: { slot: "trigger" },
                                                  slot: "trigger",
                                                },
                                                [_vm._v("+")]
                                              ),
                                            ]
                                          ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3109902557
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { width: "190", prop: "", label: "尺寸说明" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        scope.row.size ? scope.row.size : "-"
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2641519317
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "金刚区模块编辑",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            width: "35%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: { display: "flex", "justify-content": "flex-start" },
            },
            [
              _c("div", { staticStyle: { "line-height": "40px" } }, [
                _vm._v("子模块配置："),
              ]),
              _vm._v(" "),
              _c(
                "div",
                _vm._l(_vm.diamondTypeList, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticStyle: { "margin-bottom": "10px" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "170px" },
                        attrs: {
                          disabled: item.boole,
                          placeholder: "子模块名",
                        },
                        model: {
                          value: item.input,
                          callback: function ($$v) {
                            _vm.$set(item, "input", $$v)
                          },
                          expression: "item.input",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { width: "100px" },
                        attrs: { disabled: item.boole, placeholder: "序号" },
                        model: {
                          value: item.num,
                          callback: function ($$v) {
                            _vm.$set(item, "num", $$v)
                          },
                          expression: "item.num",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100px" },
                          attrs: {
                            disabled: item.boole,
                            placeholder: "请选择",
                          },
                          model: {
                            value: item.status,
                            callback: function ($$v) {
                              _vm.$set(item, "status", $$v)
                            },
                            expression: "item.status",
                          },
                        },
                        _vm._l(_vm.options, function (items) {
                          return _c("el-option", {
                            key: items.value,
                            attrs: { label: items.label, value: items.value },
                          })
                        }),
                        1
                      ),
                      _vm._v(" "),
                      item.boole
                        ? _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  item.boole = !item.boole
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          )
                        : _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "10px" },
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.saveDiamond(item, index)
                                },
                              },
                            },
                            [_vm._v("保存")]
                          ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          staticStyle: { color: "red", "margin-left": "10px" },
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.delDiamond(item, index)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.addDiamond()
                    },
                  },
                },
                [_vm._v("添加一个")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("ModulePopup", { ref: "ModulePopup" }),
      _vm._v(" "),
      _c("HeadlineManagementPopup", { ref: "HeadlineManagementPopup" }),
      _vm._v(" "),
      _c("HorseRaceLampPopup", { ref: "HorseRaceLampPopup" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }