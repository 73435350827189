var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "management_box" },
    [
      _c("div", { staticClass: "search_criteria" }, [
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: "弹窗位置", clearable: "" },
                model: {
                  value: _vm.queryData.region,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "region", $$v)
                  },
                  expression: "queryData.region",
                },
              },
              [
                _c("el-option", { attrs: { value: 1, label: "首页" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 2, label: "吃喝玩乐" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 5, label: "个人中心" } }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: "状态", clearable: "" },
                model: {
                  value: _vm.queryData.enable,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "enable", $$v)
                  },
                  expression: "queryData.enable",
                },
              },
              [
                _c("el-option", { attrs: { value: 1, label: "上线" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 0, label: "下线" } }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: "弹窗类型", clearable: "" },
                model: {
                  value: _vm.queryData.popType,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "popType", $$v)
                  },
                  expression: "queryData.popType",
                },
              },
              [
                _c("el-option", { attrs: { value: 1, label: "普通弹窗" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 2, label: "新人注册弹窗" } }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex", staticStyle: { width: "fit-content" } },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.onInquire } },
              [_vm._v("查询")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "ml10",
                attrs: { type: "info", plain: "" },
                on: { click: _vm.onInitialize },
              },
              [_vm._v("重置")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { type: "primary", icon: "el-icon-circle-plus-outline" },
                on: { click: _vm.onAddPopup },
              },
              [_vm._v("新增弹窗")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "margin-top": "30px" } },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                "header-cell-style": { "text-align": "center" },
                "cell-style": { "text-align": "center" },
                data: _vm.tableData,
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "图片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-image", {
                          staticStyle: { width: "60px", height: "60px" },
                          attrs: {
                            src: scope.row.pic,
                            "preview-src-list": [scope.row.pic],
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "弹窗名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "弹窗类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.popType === 1
                                ? "普通弹窗"
                                : "新人注册弹窗"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "弹窗位置" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return _vm._l(scope.row.region, function (item, index) {
                        return _c("div", { key: index }, [
                          Number(item) === 1
                            ? _c("span", [_vm._v("首页")])
                            : _vm._e(),
                          _vm._v(" "),
                          Number(item) === 2
                            ? _c("span", [_vm._v("吃喝玩乐")])
                            : _vm._e(),
                          _vm._v(" "),
                          Number(item) === 5
                            ? _c("span", [_vm._v("个人中心")])
                            : _vm._e(),
                        ])
                      })
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "展示人群" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [
                          !!scope.row.userType
                            ? _c("div", [
                                Number(scope.row.userType) === 1
                                  ? _c("span", [_vm._v("新用户")])
                                  : _vm._e(),
                                _vm._v(" "),
                                Number(scope.row.userType) === 2
                                  ? _c("span", [_vm._v("老用户激活")])
                                  : _vm._e(),
                                _vm._v(" "),
                                Number(scope.row.userType) === 3
                                  ? _c("span", [_vm._v("指定等级")])
                                  : _vm._e(),
                                _vm._v(" "),
                                Number(scope.row.userType) === 4
                                  ? _c("span", [_vm._v("未登录")])
                                  : _vm._e(),
                                _vm._v(" "),
                                Number(scope.row.userType) === 5
                                  ? _c("span", [_vm._v("无上级用户")])
                                  : _vm._e(),
                              ])
                            : _c("div", [
                                _vm._v("\n              所有人\n            "),
                              ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "弹出次数" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.num))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(scope.row.enable ? "上线" : "下线")),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "排序" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.sort))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "创建时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.createTime))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "投放日期", width: "160" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", [_vm._v(_vm._s(scope.row.startDate))]),
                        _vm._v(" "),
                        _c("div", [_vm._v("-")]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(scope.row.endDate))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "投放时间段" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(scope.row.startTime) +
                              "-" +
                              _vm._s(scope.row.endTime)
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  fixed: "right",
                  width: "150",
                  label: "操作",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onDetail(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.onDel(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 删除 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "pagination-container",
              staticStyle: { position: "relative", "margin-top": "20px" },
            },
            [
              _c("el-pagination", {
                staticStyle: { position: "absolute", right: "0px", top: "0px" },
                attrs: {
                  "current-page": _vm.queryData.page.page,
                  "page-sizes": [10, 20, 30, 50],
                  "page-size": _vm.queryData.page.size,
                  total: _vm.pagination.total,
                  background: "",
                  layout: "total, prev, pager, next, sizes,jumper",
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("PopupOperation", {
        ref: "PopupOperation",
        on: { refresh: _vm.onRefresh },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }