//
//
//
//
//
//

export default {
  name: 'App',
  created: function created() {
    window.addEventListener('unload', this.saveState);
  },
  methods: {
    saveState: function saveState() {
      // 把面包屑导航的list存下来，主要解决页面刷新store清空问题
      sessionStorage.setItem('breadList', JSON.stringify(this.$store.getters.breadList));
      sessionStorage.setItem('breadActiveNum', JSON.stringify(this.$store.getters.breadActiveNum));
    }
  }
};