"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLast30Days = getLast30Days;
exports.getLast7Days = getLast7Days;
exports.getLastCurrentMonth = getLastCurrentMonth;
exports.getLastMonthDates = getLastMonthDates;
exports.getTodayTimeRange = getTodayTimeRange;
exports.getYesterday = getYesterday;
exports.weekDay = weekDay;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es7.string.pad-start");
function getTodayTimeRange() {
  // 获取今天时间
  var today = new Date();
  var year = today.getFullYear();
  var month = String(today.getMonth() + 1).padStart(2, '0');
  var day = String(today.getDate()).padStart(2, '0');
  return ["".concat(year, "-").concat(month, "-").concat(day), "".concat(year, "-").concat(month, "-").concat(day)];
}
// 获取昨天的时间
function getYesterday() {
  var yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  var year = yesterday.getFullYear();
  var month = String(yesterday.getMonth() + 1).padStart(2, '0');
  var day = String(yesterday.getDate()).padStart(2, '0');
  return ["".concat(year, "-").concat(month, "-").concat(day), "".concat(year, "-").concat(month, "-").concat(day)];
}

// 获取近7天时间
function getLast7Days() {
  // 今天的日期
  var today = new Date();
  // 7天前的日期
  var sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);
  // 格式化日期函数
  var formatDate = function formatDate(date) {
    var year = date.getFullYear();
    var month = String(date.getMonth() + 1).padStart(2, '0');
    var day = String(date.getDate()).padStart(2, '0');
    return "".concat(year, "-").concat(month, "-").concat(day);
  };
  return [formatDate(sevenDaysAgo), formatDate(today)];
}

// 获取近30天的时间
function getLast30Days() {
  // 获取今天和30天前的日期
  var today = new Date();
  var thirtyDaysAgo = new Date(today);
  thirtyDaysAgo.setDate(today.getDate() - 30);

  // 格式化日期函数
  var formatDate = function formatDate(date) {
    var year = date.getFullYear();
    var month = String(date.getMonth() + 1).padStart(2, '0');
    var day = String(date.getDate()).padStart(2, '0');
    return "".concat(year, "-").concat(month, "-").concat(day);
  };

  // 格式化后的日期字符串
  var todayFormatted = formatDate(today);
  var thirtyDaysAgoFormatted = formatDate(thirtyDaysAgo);
  return [thirtyDaysAgoFormatted, todayFormatted];
}

// 获取本月的时间
function getLastCurrentMonth() {
  var today = new Date();
  var year = today.getFullYear();
  var month = String(today.getMonth() + 1).padStart(2, '0');
  var day = String(today.getDate()).padStart(2, '0');
  var todayFormatted = "".concat(year, "-").concat(month, "-").concat(day);
  var firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  var firstDayYear = firstDayOfMonth.getFullYear();
  var firstDayMonth = String(firstDayOfMonth.getMonth() + 1).padStart(2, '0');
  var firstDayFormatted = "".concat(firstDayYear, "-").concat(firstDayMonth, "-01");
  return [firstDayFormatted, todayFormatted];
}

// 获取上月的时间
function getLastMonthDates() {
  var date = new Date();
  // 获取上月最后一天
  var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
  // 获取上月第一天
  var firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
  // 格式化日期为 YYYY-MM-DD
  var formatDate = function formatDate(date) {
    var year = date.getFullYear();
    var month = String(date.getMonth() + 1).padStart(2, '0');
    var day = String(date.getDate()).padStart(2, '0');
    return "".concat(year, "-").concat(month, "-").concat(day);
  };
  return [formatDate(firstDay), formatDate(lastDay)];
}
function weekDay(num) {
  // 获取近七天的起始日期时间
  var startDate = new Date();
  startDate.setTime(startDate.getTime() - 24 * 60 * 60 * 1000 * num);
  var m = startDate.getMonth() + 1;
  var r = startDate.getDate();
  if (m <= 9) {
    m = '0' + m;
  }
  if (r <= 9) {
    r = '0' + r;
  }
  var res = startDate.getFullYear() + '-' + m + '-' + r;

  // 获取当前时间戳
  var myDate = new Date();
  var year = myDate.getFullYear(); // 获取完整的年份(4位,1970-????)
  var month = Number(myDate.getMonth() + 1) + ''; // 获取当前月份(0-11,0代表1月)
  var date = myDate.getDate() - 1 + ''; // 获取当前日(1-31)
  // 获取当前时
  var hour = myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours();
  // 获取当前分
  var minute = myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes();
  // 获取当前秒
  var second = myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds();
  if (date.length === 1) {
    date = '0' + date;
  }
  if (month.length === 1) {
    month = '0' + month;
  }
  // 开始时间
  var startTime = res + ' ' + '00' + ':' + '00' + ':' + '00';
  // 结束时间
  var endTime = year + '-' + month + '-' + date + ' ' + '23' + ':' + '59' + ':' + '59';
  // console.log('近' + num + '天', startTime, endTime)
  var data = [startTime, endTime];
  return data;
}