"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/fe-ug-comprehensive-shopping-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _BalanceDetail = _interopRequireDefault(require("./components/BalanceDetail.vue"));
var _PddOrderEntry = _interopRequireDefault(require("./components/PddOrderEntry.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: '',
  components: {
    BalanceDetail: _BalanceDetail.default,
    PddOrderEntry: _PddOrderEntry.default
  },
  data: function data() {
    return {
      activeName: 'first'
    };
  },
  methods: {
    handleClick: function handleClick(tab, event) {
      console.log(tab, event);
    }
  }
};