//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AccountDetails',
  data: function data() {
    return {
      dialogVisible: false,
      type: 'self',
      options: [{
        value: '',
        label: '全部'
      }, {
        value: '01',
        label: '1月'
      }, {
        value: '02',
        label: '2月'
      }, {
        value: '03',
        label: '3月'
      }, {
        value: '04',
        label: '4月'
      }, {
        value: '05',
        label: '5月'
      }, {
        value: '06',
        label: '6月'
      }, {
        value: '07',
        label: '7月'
      }, {
        value: '08',
        label: '8月'
      }, {
        value: '09',
        label: '9月'
      }, {
        value: '10',
        label: '10月'
      }, {
        value: '11',
        label: '11月'
      }, {
        value: '12',
        label: '12月'
      }],
      options1: [{
        value: '',
        label: '全部'
      }, {
        value: '2022',
        label: '2022'
      }, {
        value: '2023',
        label: '2023'
      }, {
        value: '2024',
        label: '2024'
      }, {
        value: '2025',
        label: '2025'
      }, {
        value: '2026',
        label: '2026'
      }, {
        value: '2027',
        label: '2027'
      }, {
        value: '2028',
        label: '2028'
      }],
      yearValue: '',
      monthValue: ''
    };
  },
  methods: {
    open: function open() {
      this.dialogVisible = true;
    },
    handleClose: function handleClose() {
      this.dialogVisible = false;
    },
    onType: function onType(val) {
      this.type = val;
    },
    resetValue: function resetValue() {},
    searchAccount: function searchAccount() {}
  }
};