"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'PlatformDetail',
  data: function data() {
    return {
      loading: false,
      // loading
      queryData: {
        queryType: 1,
        // 查询类型[1:用户管理，2：黑名单用户，3：风险用户]
        memberListParam: {
          memberId: '',
          // 会员id
          phone: '',
          // 手机号
          nickname: '' // 用户昵称
        },
        page: {
          page: 1,
          size: 10
        }
      },
      dataList: [{}]
    };
  },
  methods: {
    onBlack: function onBlack() {
      this.$router.go(-1);
    },
    onPlatformDetail: function onPlatformDetail() {
      this.$router.push({
        path: '/financialManagement/PlatformDetail',
        query: {
          memberId: 1,
          nickname: '123'
        }
      });
    }
  }
};