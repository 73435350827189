import "/root/.jenkins/workspace/fe-ug-comprehensive-shopping-admin-dev/node_modules/core-js/modules/es6.array.iterator.js";
import "/root/.jenkins/workspace/fe-ug-comprehensive-shopping-admin-dev/node_modules/core-js/modules/es6.promise.js";
import "/root/.jenkins/workspace/fe-ug-comprehensive-shopping-admin-dev/node_modules/core-js/modules/es6.object.assign.js";
import "/root/.jenkins/workspace/fe-ug-comprehensive-shopping-admin-dev/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import locale from 'element-ui/lib/locale/lang/en' // lang i18n

import '@/styles/index.scss'; // global css

import App from "./App";
import store from "./store";
import router from "./router";
import '@/icons'; // icon
import '@/permission'; // permission control

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// 注释mock服务器代码 modify by daiysh 20200222 16:45
if (process.env.NODE_ENV === 'production') {
  var _require = require("../mock"),
    mockXHR = _require.mockXHR;
  mockXHR();
}

// set ElementUI lang to EN
// Vue.use(ElementUI, { locale })
// 如果想要中文版 element-ui，按如下方式声明
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);
Vue.use(ElementUI);
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});