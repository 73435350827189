"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.aggregatePageDelete = aggregatePageDelete;
exports.aggregatePageList = aggregatePageList;
exports.aggregatePageSave = aggregatePageSave;
exports.aggregatePageUpdate = aggregatePageUpdate;
var _request = _interopRequireDefault(require("@/utils/request"));
// 聚合页

// 查询集合页
function aggregatePageList(data) {
  return (0, _request.default)({
    url: '/portal/system/aggregatePage/list',
    method: 'post',
    data: data
  });
}
// 保存聚合页
function aggregatePageSave(data) {
  return (0, _request.default)({
    url: '/portal/system/aggregatePage/save',
    method: 'post',
    data: data
  });
}
// 修改聚合页
function aggregatePageUpdate(data) {
  return (0, _request.default)({
    url: '/portal/system/aggregatePage/update',
    method: 'post',
    data: data
  });
}
// 删除聚合页
function aggregatePageDelete(data) {
  return (0, _request.default)({
    url: '/portal/system/aggregatePage/delete',
    method: 'post',
    data: data
  });
}