var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "upload" },
      [
        _c(
          "el-upload",
          {
            staticClass: "avatar-uploader",
            attrs: {
              action: "https://jsonplaceholder.typicode.com/posts/",
              "show-file-list": false,
              "before-upload": _vm.beforeAvatarUpload,
              "http-request": _vm.handleFileUpload,
            },
          },
          [
            _vm.imageUrl
              ? _c("img", {
                  staticClass: "avatar",
                  attrs: { src: _vm.imageUrl },
                })
              : _c("i", { staticClass: "el-icon-plus avatar-uploader-icon" }),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticStyle: { "margin-top": "6px" } }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }