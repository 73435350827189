var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "commissionBaseDeductionSetting" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.onTabClick },
          model: {
            value: _vm.queryData.unionCode,
            callback: function ($$v) {
              _vm.$set(_vm.queryData, "unionCode", $$v)
            },
            expression: "queryData.unionCode",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "所有", name: "QB" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "淘宝", name: "TB" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "京东", name: "JD" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "拼多多", name: "PDD" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "唯品会", name: "WPH" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "抖音", name: "DY" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "快手", name: "KS" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "美团", name: "MT" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "饿了么", name: "ELM" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "滴滴出行", name: "DD" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "直充卡券", name: "QF" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "在线点单", name: "OT" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "苏宁", name: "SN" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "考拉", name: "KL" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "中免日上", name: "ZMRS" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "多麦聚合", name: "DMJH" } }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "腾讯云选", name: "TXYX" } }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "search_criteria" }, [
          _c(
            "div",
            [
              _c("el-input", {
                attrs: { placeholder: "输入订单号查询", clearable: "" },
                model: {
                  value: _vm.queryData.orderNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "orderNo", $$v)
                  },
                  expression: "queryData.orderNo",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("el-input", {
                attrs: { placeholder: "输入用户ID查询", clearable: "" },
                model: {
                  value: _vm.queryData.memberId,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "memberId", _vm._n($$v))
                  },
                  expression: "queryData.memberId",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("el-input", {
                attrs: { placeholder: "输入用户手机号查询", clearable: "" },
                model: {
                  value: _vm.queryData.phoneNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "phoneNo", $$v)
                  },
                  expression: "queryData.phoneNo",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("el-input", {
                attrs: { placeholder: "输入订单关键词查询", clearable: "" },
                model: {
                  value: _vm.queryData.keyWord,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "keyWord", $$v)
                  },
                  expression: "queryData.keyWord",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "订单状态", clearable: "" },
                  model: {
                    value: _vm.queryData.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryData, "status", $$v)
                    },
                    expression: "queryData.status",
                  },
                },
                [
                  _c("el-option", { attrs: { value: 1, label: "已支付" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { value: 2, label: "已取消" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { value: 3, label: "已结算" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { value: 4, label: "已收货" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "订单类型", clearable: "" },
                  model: {
                    value: _vm.queryData.orderType,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryData, "orderType", $$v)
                    },
                    expression: "queryData.orderType",
                  },
                },
                [
                  _c("el-option", { attrs: { value: 1, label: "正常" } }),
                  _vm._v(" "),
                  _c("el-option", { attrs: { value: 0, label: "违规" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("el-input", {
                attrs: { placeholder: "输入佣金比例", clearable: "" },
                model: {
                  value: _vm.queryData.incomeRate,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "incomeRate", $$v)
                  },
                  expression: "queryData.incomeRate",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("el-input", {
                attrs: { placeholder: "佣金金额", clearable: "" },
                model: {
                  value: _vm.queryData.income,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "income", $$v)
                  },
                  expression: "queryData.income",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("el-date-picker", {
                staticClass: "w100",
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  type: "date",
                  placeholder: "订单生成开始时间",
                  clearable: "",
                },
                model: {
                  value: _vm.queryData.createTimeStart,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "createTimeStart", $$v)
                  },
                  expression: "queryData.createTimeStart",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("el-date-picker", {
                staticClass: "w100",
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  type: "date",
                  placeholder: "订单生成结束时间",
                  clearable: "",
                },
                model: {
                  value: _vm.queryData.createTimeEnd,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData, "createTimeEnd", $$v)
                  },
                  expression: "queryData.createTimeEnd",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex", staticStyle: { width: "fit-content" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onInquire } },
                [_vm._v("查询")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml10",
                  attrs: { type: "info", plain: "" },
                  on: { click: _vm.onInitialize },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt10" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.dataList, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "orderNo",
                  label: "订单编号",
                  align: "center",
                  width: "250",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "flex" }, [
                          _c("div", { staticClass: "fc" }, [
                            _c("div", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(scope.row.orderNo) +
                                  "\n              "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "fc" }, [
                            _c("img", {
                              staticStyle: {
                                width: "20px",
                                height: "20px",
                                "margin-left": "10px",
                              },
                              attrs: {
                                src: require("@/assets/copy_icon.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.copyText(scope.row.orderNo)
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "sort",
                  label: "商品信息",
                  align: "center",
                  width: "500",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "flex" }, [
                          _c("div", [
                            _c("img", {
                              staticStyle: {
                                width: "50px",
                                height: "50px",
                                "border-radius": "6px",
                              },
                              attrs: { src: scope.row.itemImage, alt: "" },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("div", { staticClass: "ml4" }, [
                              _vm._v(_vm._s(scope.row.title)),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "ml4" }, [
                              _c("span", [
                                _vm._v(
                                  "付款时间：" + _vm._s(scope.row.payTime)
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "10px" } },
                                [
                                  _vm._v(
                                    "付款金额：" + _vm._s(scope.row.amount)
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "memberName",
                  label: "下单用户",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "span1 pointer",
                            staticStyle: {
                              "font-size": "14px",
                              color: "#4d8aff",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onUserDetails(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.memberName) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "title_txt2" }, [
                          _vm._v("UID:" + _vm._s(scope.row.memberId)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "sort", label: "订单状态", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.status) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "incomeRate",
                  label: "佣金比例",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.row.incomeRate) + "% ")]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "feeOrigin",
                  label: "佣金金额",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "fc" }, [
                          _c(
                            "div",
                            {
                              staticClass: "Operation_button",
                              on: {
                                click: function ($event) {
                                  return _vm.onDetails(scope.row)
                                },
                              },
                            },
                            [_vm._v("\n              详情\n            ")]
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 page_box" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.queryData.page,
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.queryData.page.size,
              layout: "total, prev, pager, next, sizes,jumper",
              total: _vm.pagination.total,
              background: "",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }