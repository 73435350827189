var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "both-pd-box" },
    [
      _c("h3", [_vm._v("APP内版本更新")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "flex-start",
            "margin-top": "10px",
          },
        },
        [
          _c("el-input", {
            staticStyle: { width: "300px" },
            attrs: { placeholder: "输入更新标题查询", clearable: "" },
            model: {
              value: _vm.title,
              callback: function ($$v) {
                _vm.title = $$v
              },
              expression: "title",
            },
          }),
          _vm._v(" "),
          _c("el-input", {
            staticStyle: { width: "300px", margin: "0 10px" },
            attrs: { placeholder: "输入版本号查询", clearable: "" },
            model: {
              value: _vm.versionNum,
              callback: function ($$v) {
                _vm.versionNum = $$v
              },
              expression: "versionNum",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-left": "10px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.search()
                    },
                  },
                },
                [_vm._v("查询")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "ml10",
                  attrs: { type: "info", plain: "" },
                  on: { click: _vm.onInitialize },
                },
                [_vm._v("重置")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.add()
                    },
                  },
                },
                [_vm._v("发布新版本")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.del()
                    },
                  },
                },
                [_vm._v("批量删除")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt10" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, border: "" },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  selectable: _vm.selectableCheck,
                  type: "selection",
                  width: "55",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "title", label: "更新标题", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "version", label: "版本号\n", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "os", label: "平台", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.os ? "苹果" : "安卓") +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "audit", label: "审核模式", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.audit ? "开启" : "关闭") +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "online", label: "安装包状态", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.online ? "上线" : "下线") +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "上传时间",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "", label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "fc" }, [
                          _c(
                            "div",
                            {
                              staticClass: "Operation_button",
                              on: {
                                click: function ($event) {
                                  return _vm.onUserDetails(scope.row)
                                },
                              },
                            },
                            [_vm._v("\n              详情\n            ")]
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "mt20 page_box",
              staticStyle: { "text-align": "right" },
            },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.page,
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.size,
                  layout: "total, prev, pager, next, sizes,jumper",
                  total: _vm.total,
                  background: "",
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("addVersion", { ref: "addVersion", on: { success: _vm.handSuccess } }),
      _vm._v(" "),
      _c("updateVersion", {
        ref: "updateVersion",
        on: { success: _vm.handSuccess },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }