var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "CustomOfficialEvents" }, [
    _c("div", { staticClass: "mt10 frb" }, [
      _c("div"),
      _vm._v(" "),
      _c("div", { staticClass: "search_criteria" }, [
        _c(
          "div",
          [
            _c("el-input", {
              attrs: { placeholder: "活动名称", clearable: "" },
              model: {
                value: _vm.queryData.name,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "name", $$v)
                },
                expression: "queryData.name",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex", staticStyle: { width: "fit-content" } },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.onInquire } },
              [_vm._v("查询")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "ml10",
                attrs: { type: "info", plain: "" },
                on: { click: _vm.onInitialize },
              },
              [_vm._v("重置")]
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", [
      _c(
        "div",
        {
          staticClass: "mt10",
          staticStyle: { "overflow-y": "auto", width: "100%", height: "600px" },
        },
        [
          _c(
            "div",
            { staticClass: "flex fw" },
            _vm._l(_vm.dataList, function (item, index) {
              return _c("div", { key: index, staticClass: "details_box fc" }, [
                _vm._v("\n          " + _vm._s(item.name) + "\n          "),
                _c(
                  "div",
                  {
                    staticClass: "txt_title",
                    on: {
                      click: function ($event) {
                        return _vm.onSelectEvents(item)
                      },
                    },
                  },
                  [_vm._v("√选择")]
                ),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20 page_box" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.queryData.page.page,
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.queryData.page.size,
                  layout: "total, prev, pager, next, sizes,jumper",
                  total: _vm.pagination.total,
                  background: "",
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }