//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CustomOfficialEvents from "./CustomOfficialEvents.vue";
import CustomOfficialEvents1 from "./CustomOfficialEvents1.vue";
export default {
  name: 'ModulePopup',
  components: {
    CustomOfficialEvents: CustomOfficialEvents,
    CustomOfficialEvents1: CustomOfficialEvents1
  },
  data: function data() {
    return {
      showPopup: false,
      categoryData: [{
        name: '自定义活动',
        id: 1
      }, {
        name: '官方活动',
        id: 2
      }],
      selectCategory: 1
    };
  },
  methods: {
    open: function open() {
      this.showPopup = true;
    },
    onClone: function onClone() {
      this.showPopup = false;
    },
    onCategory: function onCategory(value) {
      this.selectCategory = value.id;
    },
    // 自定义活动回传
    onSelectEvents: function onSelectEvents(data) {
      this.$emit('SelectEvents', data);
      this.onClone();
    },
    // 防止穿透
    handleSmallDivClick: function handleSmallDivClick() {},
    onAaaa: function onAaaa() {
      this.$notify.success({
        title: '温馨提示',
        message: '删除成功'
      });
    }
  }
};