var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "40px 80px" } },
    [
      _c("div", [
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "block_representation" }),
          _vm._v(" "),
          _c("div", { staticClass: "block_title" }, [
            _vm._v("基础信息" + _vm._s(_vm.memberId)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mt10 message_box" }, [
          _c("div", { staticClass: "template1" }, [
            _c("span", { staticClass: "span1" }, [_vm._v("用户ID:")]),
            _vm._v(" "),
            _c("span", { staticClass: "span2" }, [
              _vm._v(" " + _vm._s(_vm.dataObj.userId)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "template1" }, [
            _c("span", { staticClass: "span1" }, [_vm._v("用户昵称:")]),
            _vm._v(" "),
            _c("span", { staticClass: "span2" }, [
              _vm._v(" " + _vm._s(_vm.dataObj.nickname)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "template1" }, [
            _c("span", { staticClass: "span1" }, [_vm._v("手机号码:")]),
            _vm._v(" "),
            _c("span", { staticClass: "span2" }, [
              _vm._v(" " + _vm._s(_vm.dataObj.phone)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "template1" }, [
            _c("span", { staticClass: "span1" }, [_vm._v("用户状态:")]),
            _vm._v(" "),
            _vm.dataObj.status === 0
              ? _c("span", { staticClass: "span2" }, [_vm._v(" 正常")])
              : _vm.dataObj.status === 1
              ? _c("span", { staticClass: "span2" }, [_vm._v(" 锁定")])
              : _vm.dataObj.status === 2
              ? _c("span", { staticClass: "span2" }, [_vm._v(" 注销")])
              : _vm.dataObj.status === 2
              ? _c(
                  "span",
                  {
                    staticStyle: {
                      "margin-left": "60px",
                      "font-size": "12px",
                      color: "#4d8aff",
                      border: "1px solid #4d8aff",
                      padding: "6px 10px",
                      "border-radius": "4px",
                    },
                  },
                  [_vm._v("撤销注销")]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "template1" }, [
            _c("span", { staticClass: "span1" }, [_vm._v("注册时间:")]),
            _vm._v(" "),
            _c("span", { staticClass: "span2" }, [
              _vm._v(" " + _vm._s(_vm.dataObj.registerTime)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "template1" }, [
            _c("span", { staticClass: "span1" }, [_vm._v("最后登录时间:")]),
            _vm._v(" "),
            _c("span", { staticClass: "span2" }, [
              _vm._v(" " + _vm._s(_vm.dataObj.loginTime)),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "mt10 message_box" }, [
          _c("div", { staticClass: "template1 flex frb" }, [
            _c("div", [
              _c("span", { staticClass: "span1" }, [_vm._v("用户等级:")]),
              _vm._v(" "),
              _vm.details
                ? _c("span", { staticClass: "span2" }, [
                    _vm._v(" " + _vm._s(_vm.details.gradeName)),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "div",
                {
                  staticClass: "editor_box fc pointer",
                  on: {
                    click: function ($event) {
                      return _vm.onEditPurchaseGrade(_vm.details.selfGrade)
                    },
                  },
                },
                [_vm._v("\n            编辑\n          ")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "template1" }, [
            _c("span", { staticClass: "span1" }, [_vm._v("系统邀请码：")]),
            _vm._v(" "),
            _c("span", { staticClass: "span2" }, [
              _vm._v(" " + _vm._s(_vm.details.systemInviteCode)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "template1 flex frb" }, [
            _c("div", [
              _c("span", { staticClass: "span1" }, [_vm._v("自定义邀请码：")]),
              _vm._v(" "),
              _c("span", { staticClass: "span2" }, [
                _vm._v(" " + _vm._s(_vm.details.defineInviteCode)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "div",
                {
                  staticClass: "editor_box fc pointer",
                  on: {
                    click: function ($event) {
                      return _vm.onEditCode(_vm.details.defineInviteCode)
                    },
                  },
                },
                [_vm._v("\n            编辑\n          ")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "template1" }, [
            _c("span", { staticClass: "span1" }, [_vm._v("是否绑定微信：")]),
            _vm._v(" "),
            _c("span", { staticClass: "span2" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.details.bindWechat ? "是" : "否")
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "template1 flex frb" }, [
            _c("div", [
              _c("span", { staticClass: "span1" }, [
                _vm._v("拼多多是否已授权："),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "span2" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.details.pddAuth ? "是" : "否")
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.details.pddAuth
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "editor_box fc pointer",
                      on: { click: _vm.onUntiePDD },
                    },
                    [_vm._v("取消授权")]
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "template1 flex frb" }, [
            _c("div", [
              _c("span", { staticClass: "span1" }, [
                _vm._v("淘宝是否已授权："),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "span2" }, [
                _vm._v(_vm._s(_vm.details.tbAuth ? "是" : "否")),
              ]),
            ]),
            _vm._v(" "),
            _vm.details.tbAuth
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "editor_box fc pointer",
                      on: { click: _vm.onUntieTB },
                    },
                    [_vm._v("取消授权")]
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "template1" }, [
            _c("span", { staticClass: "span1" }, [_vm._v("直邀请粉丝数：")]),
            _vm._v(" "),
            _c("span", { staticClass: "span2" }, [
              _vm._v(" " + _vm._s(_vm.details.fansCount)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "template1" }, [
            _c("span", { staticClass: "span1" }, [_vm._v("是否有效会员：")]),
            _vm._v(" "),
            _c("span", { staticClass: "span2" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.details.effective ? "是" : "否")
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "template1 flex frb" }, [
            _c("div", [
              _c("span", { staticClass: "span1" }, [_vm._v("自购账户余额：")]),
              _vm._v(" "),
              _c("span", { staticClass: "span2" }, [
                _vm._v(" " + _vm._s(_vm.details.selfAccountAmount)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "div",
                {
                  staticClass: "editor_box fc pointer",
                  on: {
                    click: function ($event) {
                      return _vm.onDetail(_vm.dataObj.userId, "self")
                    },
                  },
                },
                [_vm._v("\n            明细\n          ")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "template1 flex frb" }, [
            _c("div", [
              _c("span", { staticClass: "span1" }, [_vm._v("推广账户余额：")]),
              _vm._v(" "),
              _c("span", { staticClass: "span2" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.details.promotionAccountAmount)
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "div",
                {
                  staticClass: "editor_box fc pointer",
                  on: {
                    click: function ($event) {
                      return _vm.onDetail(_vm.dataObj.userId, "team")
                    },
                  },
                },
                [_vm._v("\n            明细\n          ")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "template1" }, [
            _c("span", { staticClass: "span1" }, [_vm._v("拼多多id:")]),
            _vm._v(" "),
            _c("span", { staticClass: "span2" }, [
              _vm._v(" " + _vm._s(_vm.details.pddRelationId)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "template1" }, [
            _c("span", { staticClass: "span1" }, [_vm._v("淘宝id:")]),
            _vm._v(" "),
            _c("span", { staticClass: "span2" }, [
              _vm._v(" " + _vm._s(_vm.details.tbRelationId)),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _vm.dataObj.inviter
          ? _c("div", { staticClass: "mt10 message_box" }, [
              _c("div", { staticClass: "template1" }, [
                _c("span", { staticClass: "span1" }, [_vm._v("邀请人用户ID:")]),
                _vm._v(" "),
                _c("span", { staticClass: "span2" }, [
                  _vm._v(" " + _vm._s(_vm.dataObj.inviter.userId || null)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "template1" }, [
                _c("span", { staticClass: "span1" }, [_vm._v("邀请人昵称:")]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "pointer",
                    staticStyle: { "font-size": "14px", color: "#4d8aff" },
                    on: {
                      click: function ($event) {
                        return _vm.onUserDetails(
                          _vm.dataObj.inviter.userId,
                          _vm.dataObj.inviter.nickname
                        )
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.dataObj.inviter.nickname))]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "template1" }, [
                _c("span", { staticClass: "span1" }, [
                  _vm._v("邀请人直属上级ID:"),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "span2" }, [
                  _vm._v(" " + _vm._s(_vm.dataObj.inviter.superId || null)),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _c("div", { staticClass: "mt10 message_box" }, [
          _vm._m(3),
          _vm._v(" "),
          false
            ? _c(
                "div",
                {
                  staticClass: "template1 flex frb",
                  staticStyle: { width: "580px" },
                },
                [
                  _vm.regions.type === 1
                    ? _c("div", [
                        _c("span", { staticClass: "span1" }, [
                          _vm._v("拥有的区代:"),
                        ]),
                        _vm._v(" "),
                        _vm.regions.agentPhones
                          ? _c(
                              "span",
                              { staticClass: "span2" },
                              _vm._l(_vm.regions.agentPhones, function (item) {
                                return _c(
                                  "span",
                                  { key: item, staticClass: "mr10" },
                                  [_vm._v("\n              " + _vm._s(item))]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ])
                    : _c("div", [
                        _c("span", { staticClass: "span1" }, [
                          _vm._v("区/县代表:"),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "span2" }, [
                          _vm._v(" " + _vm._s(_vm.regions.agentRegion)),
                        ]),
                      ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "editor_box fc pointer",
                        on: {
                          click: function ($event) {
                            return _vm.onRegion(_vm.regions.agentRegionCode)
                          },
                        },
                      },
                      [_vm._v("\n            编辑\n          ")]
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex mt20 fai" }, [
          _c("div", { staticClass: "block_representation" }),
          _vm._v(" "),
          _c("div", { staticClass: "block_title" }, [
            _vm._v("是否设置为推广客"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ml10" },
            [
              _c("el-switch", {
                attrs: {
                  value: _vm.dataObj.promotion,
                  "active-color": "#4D8AFF",
                  "inactive-color": "#B7B7B7",
                },
                on: { change: _vm.onChangeSwitch },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "fw", staticStyle: { "margin-top": "70px" } },
          [
            !_vm.dataObj.disable
              ? _c(
                  "div",
                  {
                    staticClass: "btn_box fc pointer",
                    on: { click: _vm.onLoseEffectivenessUser },
                  },
                  [_vm._v("\n        失效当前用户\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.dataObj.disable
              ? _c("div", { staticClass: "btn_box fc pointer" }, [
                  _vm._v("\n        当前用户用户已失效\n      "),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.dataObj.locked
              ? _c(
                  "div",
                  {
                    staticClass: "btn_box fc pointer",
                    on: { click: _vm.onLockUser },
                  },
                  [_vm._v("\n        锁定当前用户\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.dataObj.locked
              ? _c(
                  "div",
                  {
                    staticClass: "btn_box fc pointer",
                    on: { click: _vm.onUnlockUser },
                  },
                  [_vm._v("\n        解锁当前用户\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.dataObj.blacklist
              ? _c(
                  "div",
                  {
                    staticClass: "btn_box fc pointer",
                    on: { click: _vm.onJoinBlacklist },
                  },
                  [_vm._v("\n        加入黑名单\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.dataObj.blacklist
              ? _c(
                  "div",
                  {
                    staticClass: "btn_box fc pointer",
                    on: { click: _vm.onCancelBlacklist },
                  },
                  [_vm._v("\n        取消黑名单\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "btn_box fc pointer" },
              [
                _c(
                  "el-popover",
                  { attrs: { placement: "top", trigger: "click" } },
                  [
                    _c(
                      "div",
                      { staticClass: "issue_deduction" },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "ruleForm",
                            staticClass: "demo-ruleForm",
                            attrs: {
                              "label-position": "top",
                              model: _vm.ruleForm,
                              rules: _vm.rules,
                              "label-width": "100px",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "业务类型", prop: "region" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择活动区域" },
                                    model: {
                                      value: _vm.ruleForm.region,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "region", $$v)
                                      },
                                      expression: "ruleForm.region",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: {
                                        label: "区域一",
                                        value: "shanghai",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: {
                                        label: "区域二",
                                        value: "beijing",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              { attrs: { label: "账户选择", prop: "region" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择活动区域" },
                                    model: {
                                      value: _vm.ruleForm.region,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm, "region", $$v)
                                      },
                                      expression: "ruleForm.region",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: {
                                        label: "区域一",
                                        value: "shanghai",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-option", {
                                      attrs: {
                                        label: "区域二",
                                        value: "beijing",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.submitForm("ruleForm")
                                      },
                                    },
                                  },
                                  [_vm._v("确定")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.resetForm("ruleForm")
                                      },
                                    },
                                  },
                                  [_vm._v("取消")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "issue_deduction_btn",
                        attrs: { slot: "reference", type: "text" },
                        slot: "reference",
                      },
                      [_vm._v("余额发放/扣除")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "btn_box fc pointer",
                on: { click: _vm.onBonusPaymentRecords },
              },
              [_vm._v("\n        余额发放/扣除明细\n      ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "btn_box fc pointer",
                on: { click: _vm.onChangeLog },
              },
              [_vm._v("\n        用户信息变更记录\n      ")]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.editData.type === 1 ? "修改自购等级" : "修改邀请码",
            visible: _vm.dialogVisible,
            width: "650px",
            "before-close": _vm.handleClose,
            "close-on-click-modal": false,
          },
        },
        [
          _c("div", { staticClass: "fc" }, [
            _c("div", [
              _vm.editData.type === 1
                ? _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "fc" }, [
                      _c("span", { staticClass: "text_box" }, [
                        _vm._v("修改等级"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ml20", staticStyle: { width: "390px" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "390px" },
                            attrs: { placeholder: "请选择" },
                            model: {
                              value: _vm.editData.value,
                              callback: function ($$v) {
                                _vm.$set(_vm.editData, "value", $$v)
                              },
                              expression: "editData.value",
                            },
                          },
                          _vm._l(_vm.editOptions, function (item) {
                            return _c("el-option", {
                              key: item.grade,
                              attrs: { label: item.name, value: item.grade },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.editData.type === 2
                ? _c("div", { staticClass: "flex" }, [
                    _c("div", { staticClass: "fc" }, [
                      _c("span", { staticClass: "text_box" }, [
                        _vm._v("修改邀请码"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ml20", staticStyle: { width: "390px" } },
                      [
                        _c("el-input", {
                          attrs: {
                            maxlength: "6",
                            minlength: "6",
                            placeholder: "请输入邀请码",
                          },
                          model: {
                            value: _vm.editData.value,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.editData,
                                "value",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "editData.value",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "flex mt20" }, [
                _c("div", { staticClass: "fc" }, [
                  _c("span", { staticClass: "text_box" }, [_vm._v("修改原因")]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "ml20", staticStyle: { width: "390px" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "输入原因" },
                      model: {
                        value: _vm.editData.remark,
                        callback: function ($$v) {
                          _vm.$set(_vm.editData, "remark", $$v)
                        },
                        expression: "editData.remark",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "fc mt20" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onVerify } },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.untieDialog,
            width: "650px",
            "before-close": _vm.untieClose,
            "close-on-click-modal": false,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "fc",
              staticStyle: {
                "font-weight": "600",
                "font-size": "18px",
                color: "#474245",
              },
            },
            [
              _vm._v(
                "\n      是否确定要解绑" +
                  _vm._s(_vm.platform === "PDD" ? "拼多多" : "淘宝") +
                  "授权？\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "fc mt30" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onUntieVerify },
                },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.untieClose } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.regionalInformation,
            "before-close": _vm.regionClose,
            "close-on-click-modal": false,
            width: "746px",
          },
        },
        [
          _c(
            "div",
            { staticClass: "mainWrap" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    inline: true,
                    "label-position": "left",
                    "label-width": "175px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "请选择区域代理类型:" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.regionDataList.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.regionDataList, "type", $$v)
                            },
                            expression: "regionDataList.type",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("总代"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("区代"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.regionDataList.type === 0
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "添加区域:" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "138px" },
                                  attrs: { placeholder: "请选择省" },
                                  on: { change: _vm.getCity },
                                  model: {
                                    value: _vm.regionDataList.firstRegionCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.regionDataList,
                                        "firstRegionCode",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "regionDataList.firstRegionCode",
                                  },
                                },
                                _vm._l(
                                  _vm.regionDataList.firstRegionCodeData,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.regionName,
                                        value: item.regionCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "138px" },
                                  attrs: { placeholder: "请选择市" },
                                  on: { change: _vm.getRegion },
                                  model: {
                                    value: _vm.regionDataList.secondRegionCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.regionDataList,
                                        "secondRegionCode",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "regionDataList.secondRegionCode",
                                  },
                                },
                                _vm._l(
                                  _vm.regionDataList.secondRegionCodeData,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.regionName,
                                        value: item.regionCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "138px" },
                                  attrs: { placeholder: "请选择区" },
                                  model: {
                                    value: _vm.regionDataList.thirdRegionCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.regionDataList,
                                        "thirdRegionCode",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "regionDataList.thirdRegionCode",
                                  },
                                },
                                _vm._l(
                                  _vm.regionDataList.thirdRegionCodeData,
                                  function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.regionName,
                                        value: item.regionCode,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否要绑定总代:" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.isAll,
                                    callback: function ($$v) {
                                      _vm.isAll = $$v
                                    },
                                    expression: "isAll",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("绑定"),
                                  ]),
                                  _vm._v(" "),
                                  _c("el-radio", { attrs: { label: 0 } }, [
                                    _vm._v("不绑定"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.isAll === 1
                            ? _c(
                                "el-form-item",
                                { attrs: { label: " " } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "350px" },
                                    attrs: { placeholder: "请输入手机号码" },
                                    model: {
                                      value:
                                        _vm.regionDataList.generalAgentPhone,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.regionDataList,
                                          "generalAgentPhone",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "regionDataList.generalAgentPhone",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "flex" }, [
                _c(
                  "div",
                  {
                    staticClass: "confirm pointer",
                    on: { click: _vm.onUpdateUserRegion },
                  },
                  [_vm._v("确认")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "ml20 cancel_regin pointer",
                    on: { click: _vm.onCancelRegion },
                  },
                  [_vm._v("\n          取消总代/区代身份\n        ")]
                ),
              ]),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("AccountDetails", { ref: "accountDetails" }),
      _vm._v(" "),
      _c("InformationChangePopup", { ref: "InformationChangePopup" }),
      _vm._v(" "),
      _c("BonusPaymentRecordsPopupCopy", {
        ref: "BonusPaymentRecordsPopupCopy",
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex mt20" }, [
      _c("div", { staticClass: "block_representation" }),
      _vm._v(" "),
      _c("div", { staticClass: "block_title" }, [_vm._v("扩展信息")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex mt20" }, [
      _c("div", { staticClass: "block_representation" }),
      _vm._v(" "),
      _c("div", { staticClass: "block_title" }, [_vm._v("邀请人信息")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex mt20" }, [
      _c("div", { staticClass: "block_representation" }),
      _vm._v(" "),
      _c("div", { staticClass: "block_title" }, [_vm._v("区域信息")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "template1" }, [
      _c("span", { staticClass: "span1" }, [_vm._v("归属区域：")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }