"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listOrderPagedApi = listOrderPagedApi;
exports.loadOrderDetail = loadOrderDetail;
exports.violatedOrderDetail = violatedOrderDetail;
exports.violatedOrderImport = violatedOrderImport;
exports.violatedOrderRecord = violatedOrderRecord;
var _request = _interopRequireDefault(require("@/utils/request"));
function listOrderPagedApi(params) {
  return (0, _request.default)({
    url: '/portal/system/order/listOrderPaged',
    method: 'get',
    params: params
  });
}
function loadOrderDetail(params) {
  return (0, _request.default)({
    url: '/portal/system/order/loadOrderDetail',
    method: 'get',
    params: params
  });
}
// 违规订单处理/portal/system/order/violatedOrder/import
function violatedOrderImport(data) {
  return (0, _request.default)({
    url: '/portal/system/order/violatedOrder/import',
    method: 'post',
    data: data
  });
}
// 违规订单记录
function violatedOrderRecord(data) {
  return (0, _request.default)({
    url: '/portal/system/order/violatedOrder/record',
    method: 'post',
    data: data
  });
}
// 违规订单记录详情
function violatedOrderDetail(data) {
  return (0, _request.default)({
    url: '/portal/system/order/violatedOrder/detail',
    method: 'post',
    data: data
  });
}