import { render, staticRenderFns } from "./BillingDetails.vue?vue&type=template&id=98717ff8&scoped=true"
import script from "./BillingDetails.vue?vue&type=script&lang=js"
export * from "./BillingDetails.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98717ff8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('98717ff8')) {
      api.createRecord('98717ff8', component.options)
    } else {
      api.reload('98717ff8', component.options)
    }
    module.hot.accept("./BillingDetails.vue?vue&type=template&id=98717ff8&scoped=true", function () {
      api.rerender('98717ff8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/financialManagement/monthlyBalanceStatement/components/BillingDetails.vue"
export default component.exports