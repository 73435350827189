var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c("div", { staticClass: "search_criteria" }, [
        _c(
          "div",
          [
            _c("el-input", {
              attrs: {
                disabled: _vm.disabled,
                placeholder: "用户ID",
                clearable: "",
              },
              model: {
                value: _vm.queryData.memberListParam.memberId,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.queryData.memberListParam,
                    "memberId",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "queryData.memberListParam.memberId",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-input", {
              attrs: {
                disabled: _vm.disabled,
                placeholder: "手机号",
                clearable: "",
              },
              model: {
                value: _vm.queryData.memberListParam.phone,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.queryData.memberListParam,
                    "phone",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "queryData.memberListParam.phone",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-input", {
              attrs: {
                disabled: _vm.disabled,
                placeholder: "会员",
                clearable: "",
              },
              model: {
                value: _vm.queryData.memberListParam.nickname,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.queryData.memberListParam,
                    "nickname",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "queryData.memberListParam.nickname",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-input", {
              attrs: {
                disabled: _vm.disabled,
                placeholder: "系统邀请码",
                clearable: "",
              },
              model: {
                value: _vm.queryData.memberListParam.spreadCodeSystem,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.queryData.memberListParam,
                    "spreadCodeSystem",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "queryData.memberListParam.spreadCodeSystem",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-input", {
              attrs: {
                disabled: _vm.disabled,
                placeholder: "自定义邀请码",
                clearable: "",
              },
              model: {
                value: _vm.queryData.memberListParam.spreadCodeDefined,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.queryData.memberListParam,
                    "spreadCodeDefined",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "queryData.memberListParam.spreadCodeDefined",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-date-picker", {
              staticClass: "w100",
              attrs: {
                disabled: _vm.disabled,
                "value-format": "yyyy-MM-dd",
                type: "date",
                placeholder: "注册开始时间",
                clearable: "",
              },
              model: {
                value: _vm.queryData.memberListParam.startRegisterTime,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.queryData.memberListParam,
                    "startRegisterTime",
                    $$v
                  )
                },
                expression: "queryData.memberListParam.startRegisterTime",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-date-picker", {
              staticClass: "w100",
              attrs: {
                disabled: _vm.disabled,
                "value-format": "yyyy-MM-dd",
                type: "date",
                placeholder: "注册结束时间",
                clearable: "",
              },
              model: {
                value: _vm.queryData.memberListParam.endRegisterTime,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.queryData.memberListParam,
                    "endRegisterTime",
                    $$v
                  )
                },
                expression: "queryData.memberListParam.endRegisterTime",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-date-picker", {
              staticClass: "w100",
              attrs: {
                disabled: _vm.disabled,
                "value-format": "yyyy-MM-dd",
                type: "date",
                placeholder: "用户最后登录时间",
                clearable: "",
              },
              model: {
                value: _vm.queryData.memberListParam.lastLoginTime,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData.memberListParam, "lastLoginTime", $$v)
                },
                expression: "queryData.memberListParam.lastLoginTime",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                attrs: {
                  disabled: _vm.disabled,
                  placeholder: "用户是否有效",
                  clearable: "",
                },
                model: {
                  value: _vm.queryData.memberListParam.effective,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData.memberListParam, "effective", $$v)
                  },
                  expression: "queryData.memberListParam.effective",
                },
              },
              [
                _c("el-option", { attrs: { value: true, label: "有效" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: false, label: "无效" } }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                attrs: {
                  placeholder: "用户状态",
                  clearable: "",
                  disabled: _vm.disabled,
                },
                model: {
                  value: _vm.queryData.memberListParam.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData.memberListParam, "status", $$v)
                  },
                  expression: "queryData.memberListParam.status",
                },
              },
              [
                _c("el-option", { attrs: { value: 0, label: "正常" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 1, label: "锁定" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 2, label: "注销" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 4, label: "拉黑" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 3, label: "失效" } }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                attrs: {
                  placeholder: "用户等级",
                  clearable: "",
                  disabled: _vm.disabled,
                },
                model: {
                  value: _vm.queryData.memberListParam.grade,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData.memberListParam, "grade", $$v)
                  },
                  expression: "queryData.memberListParam.grade",
                },
              },
              _vm._l(_vm.options, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.name, value: item.grade },
                })
              }),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                attrs: {
                  disabled: _vm.disabled,
                  placeholder: "活跃用户查询",
                  clearable: "",
                },
                model: {
                  value: _vm.queryData.memberListParam.lastActiveDays,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.queryData.memberListParam,
                      "lastActiveDays",
                      $$v
                    )
                  },
                  expression: "queryData.memberListParam.lastActiveDays",
                },
              },
              [
                _c("el-option", { attrs: { value: 0, label: "近3天活跃" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 1, label: "近1周活跃" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 2, label: "近1月活跃" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 3, label: "近2月活跃" } }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                attrs: {
                  disabled: _vm.disabled,
                  placeholder: "已消费用户查询",
                  clearable: "",
                },
                model: {
                  value: _vm.queryData.memberListParam.lastTakeDays,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData.memberListParam, "lastTakeDays", $$v)
                  },
                  expression: "queryData.memberListParam.lastTakeDays",
                },
              },
              [
                _c("el-option", { attrs: { value: 0, label: "近3天消费" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 1, label: "近1周消费" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 2, label: "近1月消费" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { value: 3, label: "近2月消费" } }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-input", {
              attrs: {
                disabled: _vm.disabled,
                placeholder: "推广金（预估）",
                clearable: "",
              },
              model: {
                value: _vm.queryData.memberListParam.teamIncomeStart,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.queryData.memberListParam,
                    "teamIncomeStart",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "queryData.memberListParam.teamIncomeStart",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-input", {
              attrs: {
                disabled: _vm.disabled,
                placeholder: "自购金（预估）",
                clearable: "",
              },
              model: {
                value: _vm.queryData.memberListParam.selfAmount,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.queryData.memberListParam,
                    "selfAmount",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "queryData.memberListParam.selfAmount",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-input", {
              attrs: {
                disabled: _vm.disabled,
                placeholder: "淘宝ID",
                clearable: "",
              },
              model: {
                value: _vm.queryData.memberListParam.tbRelationId,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData.memberListParam, "tbRelationId", $$v)
                },
                expression: "queryData.memberListParam.tbRelationId",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-input", {
              attrs: {
                disabled: _vm.disabled,
                placeholder: "拼多多ID",
                clearable: "",
              },
              model: {
                value: _vm.queryData.memberListParam.pddRelationId,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData.memberListParam, "pddRelationId", $$v)
                },
                expression: "queryData.memberListParam.pddRelationId",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-date-picker", {
              staticClass: "w100 h100",
              attrs: {
                "value-format": "yyyy-MM-dd",
                type: "date",
                placeholder: "选择起始时间",
                clearable: "",
              },
              model: {
                value: _vm.queryData.memberListParam.startTime,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData.memberListParam, "startTime", $$v)
                },
                expression: "queryData.memberListParam.startTime",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-date-picker", {
              staticClass: "w100",
              attrs: {
                "value-format": "yyyy-MM-dd",
                type: "date",
                placeholder: "选择结束时间",
                clearable: "",
              },
              model: {
                value: _vm.queryData.memberListParam.endTime,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData.memberListParam, "endTime", $$v)
                },
                expression: "queryData.memberListParam.endTime",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-input", {
              attrs: { placeholder: "输入最小订单数量", clearable: "" },
              model: {
                value: _vm.queryData.memberListParam.minOrderCount,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData.memberListParam, "minOrderCount", $$v)
                },
                expression: "queryData.memberListParam.minOrderCount",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("el-input", {
              attrs: { placeholder: "输入最大订单数量", clearable: "" },
              model: {
                value: _vm.queryData.memberListParam.maxOrderCount,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData.memberListParam, "maxOrderCount", $$v)
                },
                expression: "queryData.memberListParam.maxOrderCount",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: "平台筛选", clearable: "" },
                model: {
                  value: _vm.queryData.memberListParam.platform,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryData.memberListParam, "platform", $$v)
                  },
                  expression: "queryData.memberListParam.platform",
                },
              },
              [
                _c("el-option", { attrs: { label: "淘宝", value: "TB" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { label: "京东", value: "JD" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { label: "抖音", value: "DY" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { label: "拼多多", value: "PDD" } }),
                _vm._v(" "),
                _c("el-option", { attrs: { label: "唯品会", value: "WPH" } }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex", staticStyle: { width: "fit-content" } },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.onInquire } },
              [_vm._v("查询")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "ml10",
                attrs: { type: "info", plain: "" },
                on: { click: _vm.onInitialize },
              },
              [_vm._v("重置")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "ml10",
                attrs: { type: "info", plain: "" },
                on: { click: _vm.onBatchModification },
              },
              [_vm._v("批量修改自购等级")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", [
          _c(
            "div",
            { staticClass: "pull_down_box" },
            [
              _c(
                "el-button",
                { staticClass: "ml10", attrs: { type: "info", plain: "" } },
                [
                  _c("img", {
                    staticStyle: { width: "16px", height: "16px" },
                    attrs: {
                      src: require("@/assets/member/UserManage/icon1.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("img", {
                    staticStyle: {
                      width: "12px",
                      height: "12px",
                      "margin-left": "10px",
                    },
                    attrs: {
                      src: require("@/assets/member/UserManage/icon2.png"),
                      alt: "",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "down_item" }, [
                _c(
                  "div",
                  { staticClass: "style:height:100%" },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        on: { change: _vm.onCheckBox },
                        model: {
                          value: _vm.checkList,
                          callback: function ($$v) {
                            _vm.checkList = $$v
                          },
                          expression: "checkList",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "mt10" },
                          [
                            _c("el-checkbox", { attrs: { label: "头像" } }, [
                              _vm._v("头像"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt10" },
                          [
                            _c(
                              "el-checkbox",
                              { attrs: { label: "用户昵称" } },
                              [_vm._v("用户昵称")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt10" },
                          [
                            _c(
                              "el-checkbox",
                              { attrs: { label: "手机号码" } },
                              [_vm._v("手机号码")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt10" },
                          [
                            _c("el-checkbox", { attrs: { label: "用户ID" } }, [
                              _vm._v("用户ID"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt10" },
                          [
                            _c(
                              "el-checkbox",
                              { attrs: { label: "有效用户" } },
                              [_vm._v("有效用户")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt10" },
                          [
                            _c(
                              "el-checkbox",
                              { attrs: { label: "用户等级" } },
                              [_vm._v("自购等级")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt10" },
                          [
                            _c(
                              "el-checkbox",
                              { attrs: { label: "累计推广金" } },
                              [_vm._v("累计推广金")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt10" },
                          [
                            _c(
                              "el-checkbox",
                              { attrs: { label: "累计自购金" } },
                              [_vm._v("累计自购金")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt10" },
                          [
                            _c(
                              "el-checkbox",
                              { attrs: { label: "直邀请粉丝数" } },
                              [_vm._v("直邀请粉丝数")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt10" },
                          [
                            _c(
                              "el-checkbox",
                              { attrs: { label: "注册时间" } },
                              [_vm._v("注册时间")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mt10" },
                          [
                            _c(
                              "el-checkbox",
                              { attrs: { label: "最后登录时间" } },
                              [_vm._v("最后登录时间")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt10" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.tableData, border: "" },
            },
            [
              _vm.checkList.includes("头像")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "icon",
                      label: "头像",
                      width: "180",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("img", {
                                staticStyle: {
                                  width: "50px",
                                  height: "50px",
                                  "border-radius": "8px",
                                },
                                attrs: { src: scope.row.icon, alt: "" },
                              }),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3960858937
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkList.includes("用户昵称")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "nickname",
                      label: "用户昵称",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticClass: "fc" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "nickname_style",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onUserDetails(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(scope.row.nickname) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3496490383
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkList.includes("手机号码")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "phone",
                      label: "手机号码",
                      align: "center",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkList.includes("用户ID")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "memberId",
                      label: "用户ID",
                      align: "center",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkList.includes("有效用户")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "effective",
                      label: "有效用户",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    scope.row.effective ? "有效" : "无效"
                                  ) +
                                  "\n        "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2930904572
                    ),
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkList.includes("注册时间")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      width: "180",
                      label: "注册时间",
                      align: "center",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "", label: "用户状态", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.black
                          ? _c("div", [_vm._v("拉黑")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.disable
                          ? _c("div", [_vm._v("失效")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.lock ? _c("div", [_vm._v("锁定")]) : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "", label: "状态变更时间", align: "center" },
              }),
              _vm._v(" "),
              _vm.checkList.includes("用户最近使用时间")
                ? _c("el-table-column", {
                    attrs: {
                      width: "180",
                      prop: "lastLoginTime",
                      label: "用户最近使用时间",
                      align: "center",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkList.includes("累计推广金")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "promotionAmount",
                      label: "累计推广金",
                      align: "center",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkList.includes("累计自购金")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "selfAmount",
                      label: "累计自购金",
                      align: "center",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkList.includes("直邀请粉丝数")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "fansCount",
                      label: "直邀请粉丝数",
                      align: "center",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkList.includes("用户等级")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "gradeName",
                      label: "用户等级",
                      align: "center",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.checkList.includes("订单量")
                ? _c("el-table-column", {
                    attrs: {
                      prop: "orderCount",
                      label: "订单量",
                      align: "center",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "fc" }, [
                          _c(
                            "div",
                            {
                              staticClass: "Operation_button",
                              on: {
                                click: function ($event) {
                                  return _vm.onUserDetails(scope.row)
                                },
                              },
                            },
                            [_vm._v("\n              详情\n            ")]
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 page_box" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.queryData.page.page,
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.queryData.page.size,
              layout: "total, prev, pager, next, sizes,jumper",
              total: _vm.pagination.total,
              background: "",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("ModifyingUserLevel", {
        ref: "userLevel",
        on: { refresh: _vm.refresh },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }