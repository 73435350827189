"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.link");
var _versionUpdate = require("@/api/versionUpdate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      ruleForm: {
        title: '',
        main: '',
        osType: 0,
        versionCode: '',
        link: '',
        forceBoole: false,
        onlineBoole: false,
        examineBoole: false,
        invite: 0,
        iosLink: ''
      },
      rules: {
        title: [],
        main: [{
          required: true,
          message: '请输入更新内容',
          trigger: 'blur'
        }],
        osType: [{
          required: true
        }],
        versionCode: [{
          required: true,
          message: '请输入版本号 ',
          trigger: 'blur'
        }],
        link: [{
          required: true,
          message: '请输入下载链接 ',
          trigger: 'blur'
        }],
        iosLink: [{
          required: true,
          message: '请输入AppStore下载链接 ',
          trigger: 'blur'
        }],
        forceBoole: [],
        onlineBoole: [],
        examineBoole: [],
        invite: []
      },
      dialogVisible: false
    };
  },
  methods: {
    submitForm: function submitForm(formName) {
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          console.log(_this.ruleForm);
          (0, _versionUpdate.saveRelease)({
            title: _this.ruleForm.title,
            content: _this.ruleForm.main,
            os: _this.ruleForm.osType,
            version: _this.ruleForm.versionCode,
            link: _this.ruleForm.osType === 0 ? _this.ruleForm.link : _this.ruleForm.iosLink,
            forceUpdate: _this.ruleForm.forceBoole ? 1 : 0,
            online: _this.ruleForm.onlineBoole ? 1 : 0,
            audit: _this.ruleForm.examineBoole ? 1 : 0,
            auditInvite: _this.ruleForm.invite
          }).then(function (res) {
            if (res.data) {
              _this.$notify.success({
                title: '温馨提示',
                message: '操作成功！'
              });
              _this.onClose();
              _this.$emit('success', 1);
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    changeOsType: function changeOsType() {
      this.$refs.ruleForm12.validateField('link');
      this.$refs.ruleForm12.validateField('iosLink');
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    open: function open() {
      this.dialogVisible = true;
    },
    onClose: function onClose() {
      this.dialogVisible = false;
      this.resetForm('ruleForm12');
    }
  }
};