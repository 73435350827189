"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CustomOfficialEvents = _interopRequireDefault(require("./CustomOfficialEvents.vue"));
var _CustomOfficialEvents2 = _interopRequireDefault(require("./CustomOfficialEvents1.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ModulePopup',
  components: {
    CustomOfficialEvents: _CustomOfficialEvents.default,
    CustomOfficialEvents1: _CustomOfficialEvents2.default
  },
  data: function data() {
    return {
      showPopup: false,
      categoryData: [{
        name: '自定义活动',
        id: 1
      }, {
        name: '官方活动',
        id: 2
      }],
      selectCategory: 1
    };
  },
  methods: {
    open: function open() {
      this.showPopup = true;
    },
    onClone: function onClone() {
      this.showPopup = false;
    },
    onCategory: function onCategory(value) {
      this.selectCategory = value.id;
    },
    // 自定义活动回传
    onSelectEvents: function onSelectEvents(data) {
      this.$emit('SelectEvents', data);
      this.onClone();
    },
    // 防止穿透
    handleSmallDivClick: function handleSmallDivClick() {},
    onAaaa: function onAaaa() {
      this.$notify.success({
        title: '温馨提示',
        message: '删除成功'
      });
    }
  }
};