"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bindingBannerApi = bindingBannerApi;
exports.getBannerManagerByIdApi = getBannerManagerByIdApi;
exports.getLocationsApi = getLocationsApi;
exports.getModelDetail = getModelDetail;
exports.headlineDeleteApi = headlineDeleteApi;
exports.headlineListApi = headlineListApi;
exports.headlineSaveApi = headlineSaveApi;
exports.headlineUpdateApi = headlineUpdateApi;
exports.headlineUploadPicApi = headlineUploadPicApi;
exports.marqueeDeleteApi = marqueeDeleteApi;
exports.marqueeListApi = marqueeListApi;
exports.marqueeSaveApi = marqueeSaveApi;
exports.marqueeUpdateApi = marqueeUpdateApi;
exports.marqueeUpdateStatusApi = marqueeUpdateStatusApi;
exports.marqueeUploadPicApi = marqueeUploadPicApi;
exports.unbindMarketingPosition = unbindMarketingPosition;
exports.unbindingBannerApi = unbindingBannerApi;
exports.updateBannerManagerApi = updateBannerManagerApi;
var _request = _interopRequireDefault(require("@/utils/request"));
// banner模型创建
// 获取该类型下所有的位置信息
function getLocationsApi(params) {
  return (0, _request.default)({
    url: '/portal/system/banner/model/getLocations',
    method: 'get',
    params: params
  });
}

// 查询模型详情
function getModelDetail(data) {
  return (0, _request.default)({
    url: '/portal/system/banner/model/getModelDetail',
    method: 'post',
    data: data
  });
}
// 绑定发布banner
function bindingBannerApi(data) {
  return (0, _request.default)({
    url: '/portal/system/banner/model/bindingBanner',
    method: 'post',
    data: data
  });
}
// 修改单个绑定信息
function updateBannerManagerApi(data) {
  return (0, _request.default)({
    url: 'portal/system/banner/model/updateBannerManager',
    method: 'post',
    data: data
  });
}
// 解绑banner
function unbindingBannerApi(data) {
  return (0, _request.default)({
    url: 'portal/system/banner/model/unbindingBanner',
    method: 'post',
    data: data
  });
}
// 解绑营销位
function unbindMarketingPosition(data) {
  return (0, _request.default)({
    url: 'portal/system/marketing/unbindingBanner',
    method: 'post',
    data: data
  });
}
// 获取单个绑定信息
function getBannerManagerByIdApi(params) {
  return (0, _request.default)({
    url: '/portal/system/banner/model/getBannerManagerById',
    method: 'get',
    params: params
  });
}
// 头条************************************************
// 查询所有
function headlineListApi(data) {
  return (0, _request.default)({
    url: '/portal/system/headline/list',
    method: 'post',
    data: data
  });
}
// 保存
function headlineSaveApi(data) {
  return (0, _request.default)({
    url: '/portal/system/headline/save',
    method: 'post',
    data: data
  });
}
// 修改
function headlineUpdateApi(data) {
  return (0, _request.default)({
    url: '/portal/system/headline/update',
    method: 'post',
    data: data
  });
}
// 删除
function headlineDeleteApi(data) {
  return (0, _request.default)({
    url: '/portal/system/headline/delete',
    method: 'post',
    data: data
  });
}
// 上传图片
function headlineUploadPicApi(data) {
  return (0, _request.default)({
    url: '/portal/system/headline/uploadPic',
    method: 'post',
    data: data
  });
}

// 跑马灯********************************************
// 查询所有
function marqueeListApi(data) {
  return (0, _request.default)({
    url: '/portal/system/marquee/list',
    method: 'post',
    data: data
  });
}
// 保存
function marqueeSaveApi(data) {
  return (0, _request.default)({
    url: '/portal/system/marquee/save',
    method: 'post',
    data: data
  });
}
// 修改
function marqueeUpdateApi(data) {
  return (0, _request.default)({
    url: '/portal/system/marquee/update',
    method: 'post',
    data: data
  });
}
// 删除
function marqueeDeleteApi(data) {
  return (0, _request.default)({
    url: '/portal/system/marquee/delete',
    method: 'post',
    data: data
  });
}
// 编辑
function marqueeUpdateStatusApi(data) {
  return (0, _request.default)({
    url: '/portal/system/marquee/updateStatus',
    method: 'post',
    data: data
  });
}
// 上传图片
function marqueeUploadPicApi(data) {
  return (0, _request.default)({
    url: '/portal/system/marquee/uploadPic',
    method: 'post',
    data: data
  });
}