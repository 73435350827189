import request from '@/utils/request';
// 查询页面列表
export function pageListPage(params) {
  return request({
    url: '/portal/system/custom/page/listPage',
    method: 'post',
    data: params
  });
}
// 保存页面
export function pageSavePage(params) {
  return request({
    url: '/portal/system/custom/page/savePage',
    method: 'post',
    data: params
  });
}
// 更新页面
export function pageUpdatePage(params) {
  return request({
    url: '/portal/system/custom/page/updatePage',
    method: 'post',
    data: params
  });
}
// 删除页面
export function pageDeletePage(params) {
  return request({
    url: '/portal/system/custom/page/deletePage',
    method: 'post',
    data: params
  });
}