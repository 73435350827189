var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            title: _vm.conditionType.name,
            width: "950px",
            top: "5vh",
            "custom-class": "dialog_box",
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "div",
            { staticClass: "from_box" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题:", prop: "title" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入标题" },
                            model: {
                              value: _vm.ruleForm.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "title", $$v)
                              },
                              expression: "ruleForm.title",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "图文内容:", prop: "text" } },
                    [
                      _vm.EditorOpen
                        ? [
                            _c(
                              "div",
                              { staticStyle: { border: "1px solid #ccc" } },
                              [
                                _c("Toolbar", {
                                  staticStyle: {
                                    "border-bottom": "1px solid #ccc",
                                  },
                                  attrs: {
                                    editor: _vm.editor,
                                    "default-config": _vm.toolbarConfig,
                                    mode: _vm.mode,
                                  },
                                }),
                                _vm._v(" "),
                                _c("Editor", {
                                  staticStyle: {
                                    height: "450px",
                                    "overflow-y": "hidden",
                                  },
                                  attrs: {
                                    "default-config": _vm.editorConfig,
                                    mode: _vm.mode,
                                  },
                                  on: { onCreated: _vm.onCreated },
                                  model: {
                                    value: _vm.ruleForm.text,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "text", $$v)
                                    },
                                    expression: "ruleForm.text",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.resetForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v("确认")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }