var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box both-pd-box" },
    [
      _c("div", { staticClass: "platform" }, [
        _c(
          "div",
          { staticClass: "flex" },
          _vm._l(_vm.platformList, function (item) {
            return _c(
              "div",
              {
                key: item.type,
                staticClass: "platform_item pointer",
                style:
                  _vm.queryData.unionCode === item.type
                    ? "background: #001629;color: #FFFFFF;"
                    : "background: #F9F9F9",
                on: {
                  click: function ($event) {
                    return _vm.onPlatform(item.type)
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(item.name) + "\n      ")]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "new_activity pointer",
            on: { click: _vm.onAddEvents },
          },
          [_vm._v("+新增活动")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tableLoading,
              expression: "tableLoading",
            },
          ],
          staticClass: "activity_box flex fw",
        },
        _vm._l(_vm.listActivityData, function (item) {
          return _c("ItemActivity", {
            key: item.activityId,
            staticClass: "mt10 ml10",
            attrs: { item: item },
            on: {
              controls: function ($event) {
                return _vm.controls($event)
              },
            },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt20 page_box" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.queryData.page.page,
              "page-sizes": [10, 20, 30, 40],
              "page-size": _vm.queryData.page.size,
              layout: "total, prev, pager, next, sizes,jumper",
              total: _vm.pagination.total,
              background: "",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("ActivityPopup", {
        ref: "activityPopup",
        on: { refresh: _vm.onRefresh },
      }),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.delDialogVisible,
                width: "550px",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.delDialogVisible = $event
                },
              },
            },
            [
              _c("div", { staticClass: "fc title" }, [_vm._v("删除活动")]),
              _vm._v(" "),
              _c("div", { staticClass: "fc mt10 title_name" }, [
                _vm._v("是否确认要删除该活动"),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      attrs: { type: "primary" },
                      on: { click: _vm.onVerify },
                    },
                    [_vm._v("\n          确 定\n        ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }