"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.sort");
var _goodsListActive = require("@/api/operationManagement/goodsListActive");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'CategoryPop',
  components: {},
  data: function data() {
    return {
      dialogVisible: false,
      name: '',
      table: [],
      activityId: '',
      platformCode: ''
    };
  },
  created: function created() {},
  methods: {
    del: function del(val, index) {
      var _this = this;
      console.log(val);
      if (val.id) {
        this.$confirm('删除类目后，类目所包含的商品会一并删除，确认删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          (0, _goodsListActive.deleteCustomGoodsCategory)({
            id: val.id
          }).then(function (res) {
            _this.name = '';
            _this.getAllList();
          });
        }).catch(function () {
          _this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      } else {
        this.table.splice(index, 1);
        console.log(index);
      }
    },
    save: function save(val) {
      var _this2 = this;
      console.log(val, this.table);
      if (val.id) {
        (0, _goodsListActive.updateCustomGoodsCategory)({
          cname: val.cname,
          id: val.id,
          sort: val.sort
        }).then(function (res) {
          if (res.data) {
            _this2.$message({
              type: 'success',
              message: '成功'
            });
            _this2.name = '';
            _this2.getAllList();
          }
        });
      } else {
        (0, _goodsListActive.saveCustomGoodsCategory)({
          activityId: this.activityId,
          platformCode: this.platformCode,
          cname: val.cname,
          sort: val.sort
        }).then(function (res) {
          _this2.$message({
            type: 'success',
            message: '成功'
          });
          if (res.data) {
            _this2.name = '';
            _this2.getAllList();
          }
        });
      }
    },
    detail: function detail(val) {
      this.$set(val, 'isAdd', true);
      console.log(val);
    },
    addC: function addC() {
      this.table.push({
        sort: '',
        cname: '',
        isAdd: true
      });
    },
    open: function open(val) {
      console.log(val);
      this.activityId = val.id;
      this.platformCode = val.platformCode;
      this.getAllList();
      this.dialogVisible = true;
    },
    getAllList: function getAllList() {
      var _this3 = this;
      (0, _goodsListActive.getCustomGoodsCategory)({
        activityId: this.activityId,
        cname: this.name,
        platformCode: this.platformCode
      }).then(function (res) {
        _this3.table = res.data;
      });
    },
    onClose: function onClose() {
      this.dialogVisible = false;
    }
  }
};