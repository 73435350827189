"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/fe-ug-comprehensive-shopping-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.search");
var _goodsDetail = _interopRequireDefault(require("../components/goodsDetail.vue"));
var _goodsListActive = require("@/api/operationManagement/goodsListActive");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'GoodsPop',
  components: {
    goodsDetail: _goodsDetail.default
  },
  data: function data() {
    return {
      tagUrl: '',
      listLoading: false,
      dialogVisible: false,
      goodsId: '',
      goodsName: '',
      item: {},
      typeList: [],
      goodsType: '',
      expired: '',
      statusList: [{
        label: '有效',
        value: '0'
      }, {
        label: '失效',
        value: '1'
      }],
      moreBoole: false,
      page: 1,
      limit: 10,
      total: 0,
      list: []
    };
  },
  created: function created() {},
  methods: {
    handleCommand: function handleCommand(val) {
      if (this.list.filter(function (i) {
        return i.isShow;
      }).length > 0) {
        var arr = this.list.filter(function (i) {
          return i.isShow;
        }).map(function (i) {
          return i.id;
        });
        if (val === 3) {
          this.noTop(arr);
        } else if (val === 4) {
          this.top(arr);
        } else if (val === 5) {
          this.del(arr);
        } else if (val === 2) {
          this.removeTag(arr);
        } else if (val === 1) {
          this.$refs.goodsDetail.open({
            openType: '3',
            title: '批量编辑商品标签'
          });
        }
      } else {
        this.$message({
          type: 'error',
          message: '请先选择商品！'
        });
      }
    },
    removeTag: function removeTag(val) {
      var _this = this;
      this.$confirm('确认要批量删除标签？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _goodsListActive.batchSetTag)({
          ids: val,
          tag: ''
        }).then(function (res) {
          if (res.data) {
            _this.$message({
              type: 'success',
              message: '操作成功'
            });
            _this.getList();
          }
        });
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    noTop: function noTop(val) {
      var _this2 = this;
      console.log(val);
      (0, _goodsListActive.batchCancelTop)({
        ids: val
      }).then(function (res) {
        if (res.data) {
          _this2.$message({
            type: 'success',
            message: '操作成功'
          });
          _this2.getList();
        }
      });
    },
    noMore: function noMore() {
      var _this3 = this;
      this.$confirm('确认要取消多选？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.list.map(function (i, m) {
          i.isShow = false;
        });
        _this3.moreBoole = false;
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    btnClick: function btnClick(val) {
      console.log(val);
      val.isShow = !val.isShow;
    },
    mBuff: function mBuff(val) {
      console.log(val);
      if (val.type === '1') {
        this.clearSearch();
      } else if (val.type === '2') {
        this.search();
      } else if (val.type === '3') {
        this.tagUrl = val.url;
        this.tagBuff();
      }
    },
    tagBuff: function tagBuff() {
      var _this4 = this;
      var arr = this.list.filter(function (i) {
        return i.isShow;
      }).map(function (i) {
        return i.id;
      });
      (0, _goodsListActive.batchSetTag)({
        ids: arr,
        tag: this.tagUrl
      }).then(function (res) {
        if (res.data) {
          _this4.$message({
            type: 'success',
            message: '操作成功'
          });
          _this4.getList();
        }
      });
    },
    clearSearch: function clearSearch() {
      this.goodsId = '';
      this.goodsName = '';
      this.goodsType = '';
      this.page = 1;
      this.getList();
    },
    details: function details(items) {
      console.log(items);
      this.$refs.goodsDetail.open({
        openType: '2',
        title: '编辑商品',
        typeList: this.typeList,
        id: items.id,
        tag: items.tag,
        goodsCategory: items.goodsCategory,
        goodsId: items.goodsId
      });
    },
    add: function add() {
      this.$refs.goodsDetail.open({
        openType: '1',
        title: '添加商品',
        typeList: this.typeList,
        activityId: this.item.id,
        platformCode: this.item.platformCode
      });
    },
    del: function del(val) {
      var _this5 = this;
      this.$confirm('确认删除该商品？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _goodsListActive.batchDelete)({
          ids: val
        }).then(function (res) {
          if (res.data) {
            _this5.$message({
              type: 'success',
              message: '操作成功'
            });
            _this5.getList();
          }
        });
      }).catch(function () {
        _this5.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    refresh: function refresh(val, text) {
      var _this6 = this;
      var obj = {};
      if (text) {
        console.log(text);
        obj['customActivityId'] = val.id;
      } else {
        obj['customGoodsId'] = val.id;
      }
      (0, _goodsListActive.refGoods)(obj).then(function (res) {
        if (res.data) {
          _this6.$message({
            type: 'success',
            message: '操作成功'
          });
          _this6.getList();
        }
      });
    },
    top: function top(val) {
      var _this7 = this;
      (0, _goodsListActive.batchTop)({
        ids: val
      }).then(function (res) {
        if (res.data) {
          _this7.$message({
            type: 'success',
            message: '操作成功'
          });
          _this7.getList();
        }
      });
    },
    search: function search() {
      this.page = 1;
      this.getList();
    },
    getGoodsTye: function getGoodsTye() {
      var _this8 = this;
      (0, _goodsListActive.getCustomGoodsCategory)({
        activityId: this.item.id,
        platformCode: this.item.platformCode
      }).then(function (res) {
        _this8.typeList = res.data;
      });
    },
    getList: function getList() {
      var _this9 = this;
      this.listLoading = true;
      (0, _goodsListActive.queryGoods)({
        platformCode: this.item.platformCode,
        goodsCategory: this.goodsType,
        goodsId: this.goodsId,
        title: this.goodsName,
        pageNo: this.page,
        limit: this.limit,
        activityId: this.item.id,
        expired: this.expired
      }).then(function (res) {
        if (res.data.data) {
          _this9.total = res.data.total;
          res.data.data.map(function (i) {
            i.goodsDetail = JSON.parse(i.goodsDetail);
            i['isShow'] = false;
          });
          _this9.list = res.data.data;
          _this9.listLoading = false;
          console.log(res.data.data);
        }
      }).catch(function () {
        _this9.listLoading = false;
      });
    },
    open: function open(val) {
      console.log(val, '=============');
      this.item = val;
      this.getGoodsTye();
      this.getList();
      this.dialogVisible = true;
    },
    onClose: function onClose() {
      Object.assign(this.$data, this.$options.data());
      this.dialogVisible = false;
    },
    // 分页
    handleSizeChange: function handleSizeChange(val) {
      this.limit = val;
      this.getList();
    },
    // 当前分页
    handleCurrentChange: function handleCurrentChange(val) {
      this.page = val;
      this.getList();
    }
  }
};