var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "individuation_box" }, [
    _c("div", { staticStyle: { width: "650px" } }, [
      _c(
        "div",
        { staticClass: "from_box" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "130px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "小编头像:", prop: "authorIcon" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "130px",
                        height: "130px",
                        background: "#f9f9f9",
                        "border-radius": "4px",
                        border: "1px solid #eeeeee",
                      },
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            "show-file-list": false,
                            action: "",
                            "http-request": _vm.handleFileUpload,
                          },
                        },
                        [
                          _vm.ruleForm.authorIcon
                            ? _c("img", {
                                staticClass: "avatar",
                                staticStyle: {
                                  width: "130px",
                                  height: "130px",
                                },
                                attrs: { src: _vm.ruleForm.authorIcon },
                              })
                            : _c("i", {
                                staticClass:
                                  "el-icon-plus avatar-uploader-icon",
                              }),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "小编昵称:", prop: "authorName" } },
                [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入" },
                        model: {
                          value: _vm.ruleForm.authorName,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "authorName", $$v)
                          },
                          expression: "ruleForm.authorName",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "分享按钮颜色:",
                    prop: "shareButtonBackgroundColor",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入色号，例：'#FFFFFF'" },
                        model: {
                          value: _vm.ruleForm.shareButtonBackgroundColor,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.ruleForm,
                              "shareButtonBackgroundColor",
                              $$v
                            )
                          },
                          expression: "ruleForm.shareButtonBackgroundColor",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "分享按钮字体色:",
                    prop: "shareButtonBackgroundTextColor",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入色号，例：'#FFFFFF'" },
                        model: {
                          value: _vm.ruleForm.shareButtonBackgroundTextColor,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.ruleForm,
                              "shareButtonBackgroundTextColor",
                              $$v
                            )
                          },
                          expression: "ruleForm.shareButtonBackgroundTextColor",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确认")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.resetForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }