"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listBalance = listBalance;
exports.loadBalance = loadBalance;
var _request = _interopRequireDefault(require("@/utils/request"));
// 月列表
function listBalance(params) {
  return (0, _request.default)({
    url: '/portal/system/account/listBalance',
    method: 'get',
    params: params
  });
}
function loadBalance(params) {
  return (0, _request.default)({
    url: '/portal/system/account/loadBalance',
    method: 'get',
    params: params
  });
}