import request from '@/utils/request';
// 数据统计查询
export function loadData(params) {
  return request({
    url: '/portal/system/bi/loadData',
    method: 'get',
    params: params
  });
}
// 历史数据查询
export function listHistory(params) {
  return request({
    url: '/portal/system/bi/listHistory',
    method: 'get',
    params: params
  });
}