var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "search_criteria" }, [
      _c(
        "div",
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "选择触发风险的时间", clearable: "" },
              model: {
                value: _vm.queryData.riskMemberParam.timeType,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData.riskMemberParam, "timeType", $$v)
                },
                expression: "queryData.riskMemberParam.timeType",
              },
            },
            [
              _c("el-option", { attrs: { value: 1, label: "全部" } }),
              _vm._v(" "),
              _c("el-option", { attrs: { value: 2, label: "昨日" } }),
              _vm._v(" "),
              _c("el-option", { attrs: { value: 3, label: "7天内" } }),
              _vm._v(" "),
              _c("el-option", { attrs: { value: 4, label: "上个自然月" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("el-input", {
            attrs: { placeholder: "用户ID", clearable: "" },
            model: {
              value: _vm.queryData.riskMemberParam.memberId,
              callback: function ($$v) {
                _vm.$set(_vm.queryData.riskMemberParam, "memberId", $$v)
              },
              expression: "queryData.riskMemberParam.memberId",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "触发类型", clearable: "" },
              model: {
                value: _vm.queryData.riskMemberParam.memberType,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData.riskMemberParam, "memberType", $$v)
                },
                expression: "queryData.riskMemberParam.memberType",
              },
            },
            [
              _c("el-option", { attrs: { value: 1, label: "风险用户" } }),
              _vm._v(" "),
              _c("el-option", { attrs: { value: 0, label: "白名单" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "平台类型", clearable: "" },
              model: {
                value: _vm.queryData.riskMemberParam.platform,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData.riskMemberParam, "platform", $$v)
                },
                expression: "queryData.riskMemberParam.platform",
              },
            },
            [
              _c("el-option", { attrs: { value: "JD", label: "京东" } }),
              _vm._v(" "),
              _c("el-option", { attrs: { value: "TB", label: "淘宝" } }),
              _vm._v(" "),
              _c("el-option", { attrs: { value: "PDD", label: "拼多多" } }),
              _vm._v(" "),
              _c("el-option", { attrs: { value: "DY", label: "抖音" } }),
              _vm._v(" "),
              _c("el-option", { attrs: { value: "WPH", label: "唯品会" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex", staticStyle: { width: "fit-content" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onInquire } },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "ml10",
              attrs: { type: "info", plain: "" },
              on: { click: _vm.onReset },
            },
            [_vm._v("重置")]
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mt10" },
      [
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: { data: _vm.tableData, border: "" },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "icon",
                label: "头像",
                width: "180",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("img", {
                        staticStyle: {
                          width: "50px",
                          height: "50px",
                          "border-radius": "8px",
                        },
                        attrs: { src: scope.row.icon, alt: "" },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "nickname", label: "用户昵称", align: "center" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "phone", label: "手机号码", align: "center" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "effective", label: "有效用户", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(scope.row.effective ? "有效" : "无效") +
                          "\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "gradeName", label: "用户等级", align: "center" },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "selfAmount",
                label: "累计自购金",
                align: "center",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "promotionAmount",
                label: "累计推广金",
                align: "center",
              },
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: { prop: "fansCount", label: "触发原因", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._l(scope.row.causes, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "trigger" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.platformList[item.platform]) +
                                ",风险订单占比" +
                                _vm._s(item.rate) +
                                "%\n          "
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      scope.row.riskType === 1
                        ? _c("div", { staticClass: "fc" }, [
                            _c("div", { staticClass: "Risk" }, [
                              _vm._v("风险用户"),
                            ]),
                          ])
                        : scope.row.riskType === 0
                        ? _c("div", { staticClass: "fc" }, [
                            _c("div", { staticClass: "Risk1" }, [
                              _vm._v("白名单用户"),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "date",
                label: "操作",
                width: "180",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", { staticClass: "fc" }, [
                        _c(
                          "div",
                          {
                            staticClass: "Operation_button",
                            on: {
                              click: function ($event) {
                                return _vm.handleClick(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n              设置为正常\n            ")]
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mt20 page_box" },
      [
        _c("el-pagination", {
          attrs: {
            "current-page": _vm.queryData.page.page,
            "page-sizes": [10, 20, 30, 40],
            "page-size": _vm.queryData.page.size,
            layout: "total, prev, pager, next, sizes,jumper",
            total: _vm.total,
            background: "",
          },
          on: {
            "size-change": _vm.handleSizeChange,
            "current-change": _vm.handleCurrentChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }