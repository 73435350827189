"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: '',
  props: {
    type: {
      // 类型（1:首页部分；2：个人中心部分）
      type: Number,
      default: 1
    },
    homeTopList: {
      type: Array,
      default: null
    },
    homeFootList: {
      type: Array,
      default: null
    },
    tabBarList: {
      type: Array,
      default: null
    },
    centerList: {
      type: Array,
      default: null
    },
    allConfig: {
      type: Array,
      default: null
    },
    allCenterConfig: {
      type: Array,
      default: null
    }
  },
  data: function data() {
    return {
      homeTopData: {},
      homeFootData: {},
      tabBarData: {},
      myData: {},
      myCenterData: {},
      centerTopData: {},
      diamondTypeList: ['热门', '购物', '生活', '充值', '点单'],
      diamondList: [],
      typeList: ['淘宝', '天猫', '京东', '抖音', '唯品会'],
      textList: [{
        num: 1000,
        text: '今日预估'
      }, {
        num: 1000,
        text: '本月预估'
      }, {
        num: 1000,
        text: '上月预估'
      }, {
        num: 1000,
        text: '待结算'
      }],
      activeNum: 1,
      boole1: false,
      boole2: false,
      boole3: false,
      boole4: false,
      boole5: false,
      boole6: false,
      boole7: false,
      boole8: false,
      boole9: false,
      boole10: false,
      boole13: false,
      boole14: false
    };
  },
  computed: {},
  watch: {
    allConfig: {
      // 首页数据
      handler: function handler(newVal, oldVal) {
        var _this = this;
        // newVal是获取的是新的值
        newVal.map(function (i) {
          _this.$set(_this.myData, "homeData".concat(i.category), i);
        });
        this.$forceUpdate(); // 刷新视图
      },
      deep: true // deep属性是深度监听对象里的属性的值是否改变。
    },
    allCenterConfig: {
      // 个人中数据
      handler: function handler(newVal, oldVal) {
        var _this2 = this;
        // newVal是获取的是新的值
        // console.log(newVal, '=========')
        newVal.map(function (i) {
          if (i.key === 1) {
            i.tableData.map(function (m) {
              if (m.type && !m.text) {
                _this2.centerTopData[m.type] = m.type === 'textColor' || m.type === 'bgColor' ? m.color : m.url;
              }
            });
            _this2.centerTopData['iconList'] = i.tableData.filter(function (i) {
              return i.text;
            });
            _this2.centerTopData['isShow'] = i.isShow;
          } else {
            _this2.$set(_this2.myCenterData, "centerData".concat(i.key), i);
          }
        });
        this.centerBuff();
        this.$forceUpdate(); // 刷新视图
      },
      deep: true // deep属性是深度监听对象里的属性的值是否改变。
    },
    homeTopList: {
      // 模块1
      handler: function handler(newVal, oldVal) {
        var _this3 = this;
        // newVal是获取的是新的值
        newVal.map(function (i) {
          if (i.type && !i.text) {
            _this3.homeTopData[i.type] = i.type === 'textColor' || i.type === 'bgColor' ? i.color : i.url;
          }
          if (i.myType === 1) {
            _this3.homeTopData['isShow'] = i.isShow;
          }
        });
        this.homeTopData['iconList'] = newVal.filter(function (i) {
          return i.text;
        });
        this.$forceUpdate(); // 刷新视图
      },
      deep: true // deep属性是深度监听对象里的属性的值是否改变。
    },
    homeFootList: {
      // 模块4
      handler: function handler(newVal) {
        var _this4 = this;
        newVal.map(function (i) {
          _this4.homeFootData[i.type] = i.type === 'textColor' || i.type === 'tbBg' ? i.color : i.url;
          if (i.myType === 13) {
            _this4.homeFootData['isShow'] = i.isShow;
          }
        });
        this.$forceUpdate();
      },
      deep: true
    },
    tabBarList: {
      // 模块5
      handler: function handler(newVal) {
        this.tabBarData = newVal;
        this.$forceUpdate();
      },
      deep: true
    }
  },
  mounted: function mounted() {},
  created: function created() {
    for (var i = 0; i <= 10; i++) {
      this.$set(this.myData, "homeData".concat(i + 2), {});
    }
  },
  methods: {
    centerBuff: function centerBuff() {
      var _this5 = this;
      var _loop = function _loop(i) {
        _this5.myCenterData[i].tableData.map(function (m) {
          if (_this5.myCenterData[i].key === 2) {
            _this5.myCenterData[i][m.type] = m.type === 'leftLogo' || m.type === 'bgUrl' ? m.url : m.color;
            if (m.text) {
              _this5.myCenterData[i][m.text] = m.name;
            }
          } else if (_this5.myCenterData[i].key === 3) {
            if (m.type === 'explain') {
              _this5.myCenterData[i]['explainText'] = m.name;
            }
            _this5.myCenterData[i][m.type] = m.color;
          } else if (_this5.myCenterData[i].key === 6) {
            _this5.myCenterData[i][m.type] = m.url;
          }
        });
      };
      for (var i in this.myCenterData) {
        _loop(i);
      }
      // console.log(this.myCenterData)
    },
    bothMouseOver: function bothMouseOver(val) {
      if (val === 1) {
        this.boole1 = true;
      } else if (val === 2) {
        this.boole2 = true;
      } else if (val === 3) {
        this.boole3 = true;
      } else if (val === 4) {
        this.boole4 = true;
      } else if (val === 5) {
        this.boole5 = true;
      } else if (val === 6) {
        this.boole6 = true;
      } else if (val === 7) {
        this.boole7 = true;
      } else if (val === 8) {
        this.boole8 = true;
      } else if (val === 9) {
        this.boole9 = true;
      } else if (val === 10) {
        this.boole10 = true;
      } else if (val === 13) {
        this.boole13 = true;
      } else if (val === 14) {
        this.boole14 = true;
      }
    },
    bothMouseLeave: function bothMouseLeave(val) {
      if (val === 1) {
        this.boole1 = false;
      } else if (val === 2) {
        this.boole2 = false;
      } else if (val === 3) {
        this.boole3 = false;
      } else if (val === 4) {
        this.boole4 = false;
      } else if (val === 5) {
        this.boole5 = false;
      } else if (val === 6) {
        this.boole6 = false;
      } else if (val === 7) {
        this.boole7 = false;
      } else if (val === 8) {
        this.boole8 = false;
      } else if (val === 9) {
        this.boole9 = false;
      } else if (val === 10) {
        this.boole10 = false;
      } else if (val === 13) {
        this.boole13 = false;
      } else if (val === 14) {
        this.boole14 = false;
      }
    },
    bothClick: function bothClick(val) {
      this.$emit('childData', val);
    }
  }
};