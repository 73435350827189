"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addOrUpdateAdminUser = addOrUpdateAdminUser;
exports.deleteUser = deleteUser;
exports.queryAdminRoles = queryAdminRoles;
exports.queryUserCanDelete = queryUserCanDelete;
exports.queryUserInfo = queryUserInfo;
exports.queryUserPage = queryUserPage;
var _request = _interopRequireDefault(require("@/utils/request"));
// 通用用户分页列表
function queryUserPage(data) {
  return (0, _request.default)({
    url: '/portal/system/user/queryUserPage',
    method: 'post',
    data: data
  });
}

// [new]超管端添加或修改用户
function addOrUpdateAdminUser(data) {
  return (0, _request.default)({
    url: '/portal/system/user/addOrUpdateAdminUser',
    method: 'post',
    data: data
  });
}
// [new]超管端所有角色
function queryAdminRoles(params) {
  return (0, _request.default)({
    url: '/portal/system/role/queryAdminRoles',
    method: 'get',
    params: params
  });
}
// [new]通用用户详情
function queryUserInfo(params) {
  return (0, _request.default)({
    url: '/portal/system/user/queryUserInfo',
    method: 'get',
    params: params
  });
}
// [new]删除用户
function deleteUser(data) {
  return (0, _request.default)({
    url: '/portal/system/user/deleteUser',
    method: 'post',
    data: data
  });
}
// [new]获取登陆用户删除权限
function queryUserCanDelete(params) {
  return (0, _request.default)({
    url: '/portal/system/auth/queryUserCanDelete',
    method: 'get',
    params: params
  });
}