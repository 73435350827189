"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/fe-ug-comprehensive-shopping-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selfDownload = selfDownload;
var _auth = require("@/utils/auth");
var _store = _interopRequireDefault(require("@/store"));
var _axios = _interopRequireDefault(require("axios"));
/**
 * Created by json-wang on 2024/11/19.
 */

function selfDownload() {
  var _this = this;
  (0, _axios.default)({
    method: 'get',
    url: process.env.VUE_APP_BASE_API + '/portal/system/custom/goods/downTemplate',
    headers: {
      'Authorization': (0, _auth.getToken)(),
      'user-id': _store.default.getters.userId,
      'app-id': 101
    },
    'responseType': 'blob'
  }).then(function (res) {
    var blob = new Blob([res.data], {
      type: 'application/vnd.ms-excel'
    });
    var linkNode = document.createElement('a');
    linkNode.style.display = 'none';
    linkNode.href = URL.createObjectURL(blob); // 生成一个Blob URL
    document.body.appendChild(linkNode);
    linkNode.click(); // 模拟在按钮上的一次鼠标单击
    URL.revokeObjectURL(linkNode.href); // 释放URL 对象
    document.body.removeChild(linkNode);
  }).catch(function () {
    _this.$message({
      type: 'info',
      message: '请稍后再试'
    });
  });
}