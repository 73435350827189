"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/fe-ug-comprehensive-shopping-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CustomOfficialEvents = _interopRequireDefault(require("./CustomOfficialEvents.vue"));
var _goodsListPage = _interopRequireDefault(require("./goodsListPage.vue"));
var _otherPlatform = _interopRequireDefault(require("./otherPlatform.vue"));
var _CustomOfficialEvents2 = _interopRequireDefault(require("./CustomOfficialEvents1.vue"));
var _PolymerizationActivityPopup = _interopRequireDefault(require("./PolymerizationActivityPopup.vue"));
var _GeneralH5Activity = _interopRequireDefault(require("./GeneralH5Activity.vue"));
var _CustomizePage = _interopRequireDefault(require("./CustomizePage.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ModulePopup',
  components: {
    CustomOfficialEvents: _CustomOfficialEvents.default,
    CustomOfficialEvents1: _CustomOfficialEvents2.default,
    PolymerizationActivityPopup: _PolymerizationActivityPopup.default,
    GeneralH5Activity: _GeneralH5Activity.default,
    CustomizePage: _CustomizePage.default,
    GoodsListPage: _goodsListPage.default,
    otherPlatform: _otherPlatform.default
  },
  data: function data() {
    return {
      showPopup: false,
      categoryData: [{
        name: '自定义活动',
        id: 1
      }, {
        name: '商品列表活动',
        id: 6
      }, {
        name: '官方活动',
        id: 2
      }, {
        name: '聚合页活动',
        id: 3
      }, {
        name: '通用H5活动',
        id: 4
      }, {
        name: '第三方代下单',
        id: 7
      }, {
        name: '自定义页面',
        id: 5
      }],
      selectCategory: 1
    };
  },
  methods: {
    open: function open() {
      this.showPopup = true;
    },
    onClone: function onClone() {
      this.showPopup = false;
    },
    onCategory: function onCategory(value) {
      this.selectCategory = value.id;
    },
    // 自定义活动回传
    onSelectEvents: function onSelectEvents(data) {
      this.$emit('SelectEvents', data);
      this.onClone();
    },
    // 防止穿透
    handleSmallDivClick: function handleSmallDivClick() {},
    onAaaa: function onAaaa() {
      this.$notify.success({
        title: '温馨提示',
        message: '删除成功'
      });
    }
  }
};