//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import UserManageVue from "./components/UserManage.vue";
// import OrderInquiry from './components/OrderInquiry.vue'
import BlacklistUser from "./components/BlacklistUser.vue";
import RiskUser from "./components/RiskUser.vue";
export default {
  name: 'MemberList',
  components: {
    UserManageVue: UserManageVue,
    BlacklistUser: BlacklistUser,
    RiskUser: RiskUser
  },
  data: function data() {
    return {
      activeName: 'first'
    };
  }
};