var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "both-pd-box" }, [
    _c(
      "div",
      { staticClass: "top-box" },
      _vm._l(_vm.topList, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            class: [
              "tips-box",
              _vm.topTipNum === item.key ? "tips-box-active" : "",
            ],
            on: {
              click: function ($event) {
                _vm.topTipNum = item.key
              },
            },
          },
          [_vm._v(_vm._s(item.text))]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [
      _vm.appList.length > 0
        ? _c(
            "div",
            { staticClass: "content-son" },
            _vm._l(_vm.appList, function (item, index) {
              return _c("div", { key: index, staticClass: "content-box" }, [
                item.id !== "add"
                  ? _c("div", { staticClass: "content-list" }, [
                      _c("div", [
                        _c("img", {
                          staticStyle: {
                            width: "69px",
                            height: "69px",
                            display: "block",
                          },
                          attrs: { src: item.icon },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "right-box" }, [
                        _c("div", { staticClass: "title-box" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "flex-start",
                              "align-items": "center",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "btn" },
                              [
                                _c(
                                  "el-link",
                                  {
                                    attrs: {
                                      icon: "el-icon-edit",
                                      underline: false,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.detail(item)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            item.status === 1
                              ? _c(
                                  "div",
                                  { staticClass: "btn" },
                                  [
                                    _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          icon: "el-icon-delete",
                                          underline: false,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.del(item)
                                          },
                                        },
                                      },
                                      [_vm._v("禁用")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.status === 2
                              ? _c(
                                  "div",
                                  { staticClass: "btn" },
                                  [
                                    _c(
                                      "el-link",
                                      {
                                        attrs: {
                                          icon: "el-icon-circle-check",
                                          underline: false,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.del(item)
                                          },
                                        },
                                      },
                                      [_vm._v("启用")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ])
                  : _c(
                      "div",
                      {
                        staticClass: "no-app",
                        on: {
                          click: function ($event) {
                            return _vm.jumpAdd()
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-plus",
                          staticStyle: { "font-size": "22px" },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("添加")]),
                      ]
                    ),
              ])
            }),
            0
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }