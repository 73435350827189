var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showPopup
    ? _c("div", { staticClass: "Module_popup fc" }, [
        _c(
          "div",
          {
            staticClass: "content_box",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleSmallDivClick($event)
              },
            },
          },
          [
            _c("div", { staticClass: "content_header frb" }, [
              _c("div", [_vm._v("活动选择")]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "fc pointer",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.onClone($event)
                    },
                  },
                },
                [
                  _c("img", {
                    staticStyle: { width: "20px", height: "20px" },
                    attrs: {
                      src: require("@/assets/homeDecoration/Cross.png"),
                      alt: "",
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "content_body" },
              [
                _c(
                  "div",
                  { staticClass: "flex" },
                  _vm._l(_vm.categoryData, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "fc category pointer",
                        class:
                          _vm.selectCategory === item.id ? "category1" : "",
                        on: {
                          click: function ($event) {
                            return _vm.onCategory(item)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(item.name) + "\n        "
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("div", { staticClass: "line mt10" }),
                _vm._v(" "),
                _vm.selectCategory === 1
                  ? _c("CustomOfficialEvents", {
                      attrs: { "select-category": _vm.selectCategory },
                      on: { SelectEvents: _vm.onSelectEvents },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectCategory === 2
                  ? _c("CustomOfficialEvents1", {
                      attrs: { "select-category": _vm.selectCategory },
                      on: { SelectEvents: _vm.onSelectEvents },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectCategory === 3
                  ? _c("PolymerizationActivityPopup", {
                      on: { SelectEvents: _vm.onSelectEvents },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectCategory === 4
                  ? _c("GeneralH5Activity", {
                      on: { SelectEvents: _vm.onSelectEvents },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectCategory === 5
                  ? _c("CustomizePage", {
                      on: { SelectEvents: _vm.onSelectEvents },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectCategory === 6
                  ? _c("GoodsListPage", {
                      attrs: { "select-category": _vm.selectCategory },
                      on: { SelectEvents: _vm.onSelectEvents },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectCategory === 7
                  ? _c("otherPlatform", {
                      attrs: { "select-category": _vm.selectCategory },
                      on: { SelectEvents: _vm.onSelectEvents },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }