"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.search");
var _addVersion = _interopRequireDefault(require("../appSet/components/addVersion.vue"));
var _updateVersion = _interopRequireDefault(require("../appSet/components/updateVersion.vue"));
var _versionUpdate = require("@/api/versionUpdate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: '',
  components: {
    addVersion: _addVersion.default,
    updateVersion: _updateVersion.default
  },
  data: function data() {
    return {
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      tableData: [],
      title: '',
      versionNum: '',
      selectArray: []
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.loading = true;
      (0, _versionUpdate.listReleasePaged)({
        page: this.page,
        size: this.size,
        title: this.title,
        version: this.versionNum
      }).then(function (res) {
        _this.tableData = res.data.data;
        _this.total = res.data.total;
        _this.loading = false;
      }).catch(function () {
        _this.loading = false;
      });
    },
    handSuccess: function handSuccess(val) {
      if (val) {
        // 新增
        this.page = 1;
      }
      this.getList();
    },
    add: function add() {
      this.$refs.addVersion.open();
    },
    onUserDetails: function onUserDetails(val) {
      var _this2 = this;
      (0, _versionUpdate.getRelease)({
        releaseId: val.releaseId
      }).then(function (res) {
        _this2.$refs.updateVersion.open(res.data);
      });
    },
    del: function del() {
      var _this3 = this;
      if (this.selectArray.length > 0) {
        this.$confirm('确认是否要删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {}).catch(function () {
          _this3.$message({
            type: 'info',
            message: '已取消'
          });
        });
      } else {
        this.$message.error('请选择将要删除的数据');
      }
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.selectArray = val;
    },
    selectableCheck: function selectableCheck(row) {
      return row.online !== 1; // 决定是否可选
    },
    handleSizeChange: function handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.page = val;
      this.selectArray = [];
      this.getList();
    },
    onInitialize: function onInitialize() {
      this.title = '';
      this.versionNum = '';
      this.search();
    },
    search: function search() {
      this.page = 1;
      this.getList();
    }
  }
};