"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addOrCancelBlacklistApi = addOrCancelBlacklistApi;
exports.downloadMemberGradeTemplateApi = downloadMemberGradeTemplateApi;
exports.getMemberUpdateLogType = getMemberUpdateLogType;
exports.giveBalance = giveBalance;
exports.importMemberGradeTemplate = importMemberGradeTemplate;
exports.memberDisableApi = memberDisableApi;
exports.memberQueryMemberDetailApi = memberQueryMemberDetailApi;
exports.memberQueryMemberGradeApi = memberQueryMemberGradeApi;
exports.memberQueryMemberListApi = memberQueryMemberListApi;
exports.memberQueryRelationChainApi = memberQueryRelationChainApi;
exports.memberUnbindPlatformAuthApi = memberUnbindPlatformAuthApi;
exports.memberUpdateMemberApi = memberUpdateMemberApi;
exports.queryMemberAgentRegionApi = queryMemberAgentRegionApi;
exports.queryRegionListApi = queryRegionListApi;
exports.resetLogoff = resetLogoff;
exports.updateMemberPromotion = updateMemberPromotion;
exports.updateMemberRegionApi = updateMemberRegionApi;
exports.updateMemberStatusApi = updateMemberStatusApi;
var _request = _interopRequireDefault(require("@/utils/request"));
// 会员粉丝列表
function memberQueryMemberListApi(data) {
  return (0, _request.default)({
    url: '/portal/system/member/queryMemberList',
    method: 'post',
    data: data
  });
}
// 会员关系链
function memberQueryRelationChainApi(data) {
  return (0, _request.default)({
    url: '/portal/system/member/queryRelationChain',
    method: 'post',
    data: data
  });
}
// 会员概况
function memberQueryMemberDetailApi(params) {
  return (0, _request.default)({
    url: '/portal/system/member/queryMemberDetail',
    method: 'get',
    params: params
  });
}
// 会员等级列表查询
function memberQueryMemberGradeApi(params) {
  return (0, _request.default)({
    url: '/portal/system/member/queryMemberGrade',
    method: 'get',
    params: params
  });
}
// 修改自购等级或邀请码
function memberUpdateMemberApi(data) {
  return (0, _request.default)({
    url: '/portal/system/member/updateMember',
    method: 'post',
    data: data
  });
}
// 解除平台授权
function memberUnbindPlatformAuthApi(data) {
  return (0, _request.default)({
    url: '/portal/system/member/unbindPlatformAuth',
    method: 'post',
    data: data
  });
}
// ********************************************************************************************
// 查询区域列表
function queryRegionListApi(data) {
  return (0, _request.default)({
    url: '/portal/system/region/queryRegionList',
    method: 'post',
    data: data
  });
}
// 查询用户代理区域
function queryMemberAgentRegionApi(data) {
  return (0, _request.default)({
    url: '/portal/system/member/queryMemberAgentRegion',
    method: 'post',
    data: data
  });
}
// 修改用户代理区域
function updateMemberRegionApi(data) {
  return (0, _request.default)({
    url: '/portal/system/member/updateMemberRegion',
    method: 'post',
    data: data
  });
}

// ***********************************************************************************************
// 失效用户
function memberDisableApi(data) {
  return (0, _request.default)({
    url: '/portal/system/member/memberDisable',
    method: 'post',
    data: data
  });
}
// 修改用户状态[锁定、解锁用户]
function updateMemberStatusApi(data) {
  return (0, _request.default)({
    url: '/portal/system/member/updateAccountStatus',
    method: 'post',
    data: data
  });
}
// 加入、取消黑名单
function addOrCancelBlacklistApi(data) {
  return (0, _request.default)({
    url: '/portal/system/member/addOrCancelBlacklist',
    method: 'post',
    data: data
  });
}
// *************************************************************
// 下载批量设置会员等级excel模板
function downloadMemberGradeTemplateApi(params) {
  return (0, _request.default)({
    url: '/portal/system/file/downloadMemberGradeTemplate',
    method: 'get',
    params: params
  });
}
// 导入批量设置会员等级excel模板
function importMemberGradeTemplate(data) {
  return (0, _request.default)({
    url: '/portal/system/member/importMemberGradeTemplate',
    method: 'post',
    data: data
  });
}
// 设置是否为推广客
function updateMemberPromotion(data) {
  return (0, _request.default)({
    url: '/portal/system/member/updateMemberPromotion',
    method: 'post',
    data: data
  });
}
// 注销恢复
function resetLogoff(data) {
  return (0, _request.default)({
    url: '/portal/system/member/resetLogoff',
    method: 'post',
    data: data
  });
}
function getMemberUpdateLogType(params) {
  return (0, _request.default)({
    url: '/portal/system/member/queryMemberUpdateLogType',
    method: 'get',
    params: params
  });
}
// 注销恢复
function giveBalance(data) {
  return (0, _request.default)({
    url: '/portal/system/account/giveBalance',
    method: 'post',
    data: data
  });
}