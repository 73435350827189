var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "545px",
            "close-on-click-modal": false,
            center: "",
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "div",
            [
              _c("div", { staticClass: "fc title" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.title === "add" ? "添加用户" : "修改用户") +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "0", prop: "nickname" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "输入用户昵称" },
                        model: {
                          value: _vm.ruleForm.nickname,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "nickname", $$v)
                          },
                          expression: "ruleForm.nickname",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "0", prop: "phone" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "11",
                          type: "text",
                          placeholder: "输入用户手机号",
                        },
                        model: {
                          value: _vm.ruleForm.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "phone", $$v)
                          },
                          expression: "ruleForm.phone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v("\n        确 定\n      ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }