"use strict";

var _interopRequireDefault = require("/root/.jenkins249/workspace/web-ug-admin-dev/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.queryList = queryList;
exports.subsidyAmtCondition = subsidyAmtCondition;
var _request = _interopRequireDefault(require("@/utils/request"));
function queryList(data) {
  return (0, _request.default)({
    url: '/portal/system/newbiesubsidy/pageList',
    method: 'post',
    data: data
  });
}
function subsidyAmtCondition(data) {
  return (0, _request.default)({
    url: '/portal/system/newbiesubsidy/subsidyAmtCondition',
    method: 'post',
    data: data
  });
}