var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "both-pd-box" },
    [
      _c("h3", [_vm._v("升级中心装修")]),
      _vm._v(" "),
      _c("el-divider"),
      _vm._v(" "),
      _c("div", { staticClass: "flex" }, [
        _c(
          "div",
          { staticStyle: { width: "500px" } },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-ruleForm",
                attrs: {
                  model: _vm.ruleForm,
                  rules: _vm.rules,
                  "label-width": "150px",
                },
              },
              [
                _c(
                  "el-form-item",
                  {
                    staticStyle: { width: "600px" },
                    attrs: { label: "标题", prop: "title" },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: _vm.ruleForm.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "title", $$v)
                        },
                        expression: "ruleForm.title",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticStyle: { width: "600px" },
                    attrs: { label: "APP宣传文字" },
                  },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入" },
                      model: {
                        value: _vm.ruleForm.ConditionStatement,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "ConditionStatement", $$v)
                        },
                        expression: "ruleForm.ConditionStatement",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "顶部背景图", prop: "topBackground" } },
                  [
                    _c(
                      "div",
                      { staticClass: "frb" },
                      [
                        _c(
                          "upload",
                          {
                            attrs: {
                              name: "topBackground",
                              "img-url": _vm.ruleForm.topBackground,
                            },
                            on: {
                              pictureAddress: function ($event) {
                                return _vm.onPictureAddress($event)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#f59a23",
                                  "font-size": "12px",
                                },
                              },
                              [_vm._v("建议尺寸不超过650px*220px，PNG格式")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticStyle: { width: "600px" },
                    attrs: { label: "名词注释", prop: "NounAnnotation" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "frb" },
                      [
                        _c(
                          "upload",
                          {
                            attrs: {
                              name: "NounAnnotation",
                              "img-url": _vm.ruleForm.NounAnnotation,
                            },
                            on: {
                              pictureAddress: function ($event) {
                                return _vm.onPictureAddress($event)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#f59a23",
                                  "font-size": "12px",
                                },
                              },
                              [_vm._v("建议宽度不超过560px，高度自拟，PNG格式")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticStyle: { width: "600px" },
                    attrs: { label: "备注", prop: "" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "frb" },
                      [
                        _c(
                          "upload",
                          {
                            attrs: {
                              name: "remark",
                              "img-url": _vm.ruleForm.remark,
                            },
                            on: {
                              pictureAddress: function ($event) {
                                return _vm.onPictureAddress($event)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#f59a23",
                                  "font-size": "12px",
                                },
                              },
                              [_vm._v("建议宽度不超过69px，高度自拟，PNG格式")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "升级图", prop: "upgradeChart" } },
                  [
                    _c(
                      "div",
                      { staticClass: "frb" },
                      [
                        _c(
                          "upload",
                          {
                            attrs: {
                              name: "upgradeChart",
                              "img-url": _vm.ruleForm.upgradeChart,
                            },
                            on: {
                              pictureAddress: function ($event) {
                                return _vm.onPictureAddress($event)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#f59a23",
                                  "font-size": "12px",
                                },
                              },
                              [_vm._v("建议尺寸不超过560px*250px，PNG格式")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "主色", prop: "dominantColor" } },
                  [
                    _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入色值，例“#ffffff”" },
                          model: {
                            value: _vm.ruleForm.dominantColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "dominantColor", $$v)
                            },
                            expression: "ruleForm.dominantColor",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-color-picker", {
                          model: {
                            value: _vm.ruleForm.dominantColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "dominantColor", $$v)
                            },
                            expression: "ruleForm.dominantColor",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "辅色", prop: "ComplementaryColor" } },
                  [
                    _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入色值，例“#ffffff”" },
                          model: {
                            value: _vm.ruleForm.ComplementaryColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "ComplementaryColor", $$v)
                            },
                            expression: "ruleForm.ComplementaryColor",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-color-picker", {
                          model: {
                            value: _vm.ruleForm.ComplementaryColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "ComplementaryColor", $$v)
                            },
                            expression: "ruleForm.ComplementaryColor",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "背景色", prop: "BackgroundColor" } },
                  [
                    _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入色值，例“#ffffff”" },
                          model: {
                            value: _vm.ruleForm.BackgroundColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "BackgroundColor", $$v)
                            },
                            expression: "ruleForm.BackgroundColor",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-color-picker", {
                          model: {
                            value: _vm.ruleForm.BackgroundColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "BackgroundColor", $$v)
                            },
                            expression: "ruleForm.BackgroundColor",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: { label: "卡片背景色", prop: "CardBackgroundColor" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入色值，例“#ffffff”" },
                          model: {
                            value: _vm.ruleForm.CardBackgroundColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "CardBackgroundColor", $$v)
                            },
                            expression: "ruleForm.CardBackgroundColor",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-color-picker", {
                          model: {
                            value: _vm.ruleForm.CardBackgroundColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "CardBackgroundColor", $$v)
                            },
                            expression: "ruleForm.CardBackgroundColor",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "卡片边框色", prop: "CardBorderColor" } },
                  [
                    _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入色值，例“#ffffff”" },
                          model: {
                            value: _vm.ruleForm.CardBorderColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "CardBorderColor", $$v)
                            },
                            expression: "ruleForm.CardBorderColor",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-color-picker", {
                          model: {
                            value: _vm.ruleForm.CardBorderColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "CardBorderColor", $$v)
                            },
                            expression: "ruleForm.CardBorderColor",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: { label: "昵称文字颜色", prop: "NicknameTextColor" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入色值，例“#ffffff”" },
                          model: {
                            value: _vm.ruleForm.NicknameTextColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "NicknameTextColor", $$v)
                            },
                            expression: "ruleForm.NicknameTextColor",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-color-picker", {
                          model: {
                            value: _vm.ruleForm.NicknameTextColor,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "NicknameTextColor", $$v)
                            },
                            expression: "ruleForm.NicknameTextColor",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "升级按钮背景色",
                      prop: "UpgradeButtonBackgroundColor",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入色值，例“#ffffff”" },
                          model: {
                            value: _vm.ruleForm.UpgradeButtonBackgroundColor,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "UpgradeButtonBackgroundColor",
                                $$v
                              )
                            },
                            expression: "ruleForm.UpgradeButtonBackgroundColor",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-color-picker", {
                          model: {
                            value: _vm.ruleForm.UpgradeButtonBackgroundColor,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "UpgradeButtonBackgroundColor",
                                $$v
                              )
                            },
                            expression: "ruleForm.UpgradeButtonBackgroundColor",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "升级按钮文字颜色",
                      prop: "UpgradeButtonTextColor",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex" },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入色值，例“#ffffff”" },
                          model: {
                            value: _vm.ruleForm.UpgradeButtonTextColor,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "UpgradeButtonTextColor",
                                $$v
                              )
                            },
                            expression: "ruleForm.UpgradeButtonTextColor",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-color-picker", {
                          model: {
                            value: _vm.ruleForm.UpgradeButtonTextColor,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "UpgradeButtonTextColor",
                                $$v
                              )
                            },
                            expression: "ruleForm.UpgradeButtonTextColor",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.submitForm("ruleForm")
                          },
                        },
                      },
                      [_vm._v("保存")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticStyle: { "margin-left": "300px", width: "500px" } }, [
          _c(
            "div",
            {
              staticStyle: { width: "375px", "border-radius": "20px" },
              style: { background: _vm.ruleForm.BackgroundColor },
            },
            [
              _c(
                "div",
                {
                  staticClass: "fc",
                  staticStyle: {
                    padding: "10px",
                    color: "#fff",
                    "font-weight": "500",
                    "font-size": "20px",
                  },
                },
                [
                  _vm._v(
                    "\n          " + _vm._s(_vm.ruleForm.title) + "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { padding: "10px 10px 30px 10px" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "100%",
                      "border-radius": "8px",
                      padding: "15px 20px",
                      position: "relative",
                    },
                    style: { background: _vm.ruleForm.CardBackgroundColor },
                  },
                  [
                    _c("img", {
                      staticStyle: {
                        width: "100%",
                        height: "90px",
                        "border-radius": "20px",
                      },
                      attrs: { src: _vm.ruleForm.topBackground, alt: "" },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          position: "absolute",
                          top: "-5px",
                          left: "50%",
                          transform: "translateX(-50%)",
                        },
                      },
                      [
                        _c("div", [
                          _c("img", {
                            staticStyle: { width: "60px", height: "60px" },
                            attrs: { src: require("@/assets/user.png") },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "fc",
                            style: { color: _vm.ruleForm.NicknameTextColor },
                          },
                          [_vm._v("昵称")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "fc",
                        staticStyle: { "margin-top": "10px", color: "#fff" },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.ruleForm.ConditionStatement) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-top": "10px",
                      "border-radius": "8px",
                    },
                    style: {
                      border: "1px solid " + _vm.ruleForm.CardBorderColor,
                      background: _vm.ruleForm.CardBackgroundColor,
                    },
                  },
                  [
                    _c("div", { staticStyle: { padding: "10px" } }, [
                      _c("div", { staticClass: "frb" }, [
                        _c("div", [
                          _c("div", { staticClass: "flex" }, [
                            _c(
                              "div",
                              { style: { color: _vm.ruleForm.dominantColor } },
                              [_vm._v("高级导师")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "fc",
                                staticStyle: { "margin-left": "6px" },
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "50px",
                                    height: "15px",
                                  },
                                  attrs: {
                                    src: require("@/assets/hierarchicalCommissionAllocation/supervisor.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: { "margin-top": "4px" },
                              style: { color: _vm.ruleForm.dominantColor },
                            },
                            [_vm._v("升级条件说明文字")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "fc" }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                padding: "5px 10px",
                                "border-radius": "10px",
                                "font-size": "12px",
                              },
                              style: {
                                color: _vm.ruleForm.UpgradeButtonTextColor,
                                background:
                                  _vm.ruleForm.UpgradeButtonBackgroundColor,
                              },
                            },
                            [_vm._v("立即升级")]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { "margin-top": "16px" } }, [
                        _c(
                          "div",
                          { staticClass: "flex way" },
                          _vm._l(_vm.wayList, function (item) {
                            return _c(
                              "div",
                              {
                                key: item.id,
                                staticClass: "fc",
                                class:
                                  item.id === 1
                                    ? "select_style"
                                    : "no_select_style",
                                style: {
                                  color:
                                    item.id === 1
                                      ? _vm.ruleForm.ComplementaryColor
                                      : "#fff",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(item.name) +
                                    "\n                  "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              background: "#fff",
                              padding: "10px",
                              "border-radius": "0 0 10px 10px",
                            },
                          },
                          [
                            _c("div", { staticClass: "frb" }, [
                              _c("div", [
                                _c("div", {
                                  staticStyle: {
                                    width: "230px",
                                    height: "12px",
                                    "border-radius": "5px",
                                  },
                                  style: {
                                    background: _vm.ruleForm.ComplementaryColor,
                                  },
                                }),
                                _vm._v(" "),
                                _vm._m(0),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "fc" }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      padding: "5px 10px",
                                      "border-radius": "15px",
                                      color: "#fff",
                                    },
                                    style: {
                                      background:
                                        _vm.ruleForm.ComplementaryColor,
                                    },
                                  },
                                  [_vm._v("已完成")]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "frb top-up",
                                staticStyle: {
                                  "margin-top": "20px",
                                  "font-size": "16px",
                                },
                              },
                              [
                                _c("div", { staticClass: "fc" }, [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        padding: "6px 16px",
                                        "border-radius": "5px",
                                        color: "#fff",
                                      },
                                      style: {
                                        background:
                                          _vm.ruleForm.ComplementaryColor,
                                      },
                                    },
                                    [_vm._v("月度")]
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm._m(1),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "fc",
                                    staticStyle: { width: "fit-content" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          color: "#fff",
                                          padding: "4px 8px",
                                          "border-radius": "15px",
                                        },
                                        style: {
                                          background:
                                            _vm.ruleForm.ComplementaryColor,
                                        },
                                      },
                                      [_vm._v("去支付")]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-top": "10px",
                      "border-radius": "8px",
                      padding: "10px",
                    },
                    style: {
                      border: "1px solid " + _vm.ruleForm.CardBorderColor,
                      background: _vm.ruleForm.CardBackgroundColor,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "fc",
                        style: { color: _vm.ruleForm.dominantColor },
                      },
                      [_vm._v("权益对比")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "flex way1",
                        staticStyle: { "margin-top": "10px" },
                      },
                      _vm._l(_vm.wayList1, function (item) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            staticClass: "fc",
                            class:
                              item.id === 1
                                ? "select_style"
                                : "no_select_style",
                            style: {
                              color:
                                item.id === 1
                                  ? _vm.ruleForm.ComplementaryColor
                                  : "#fff",
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(item.name) +
                                "\n              "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          background: "#fff",
                          padding: "10px",
                          "border-radius": "0 0 10px 10px",
                        },
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "100%", height: "200px" },
                          attrs: {
                            src: require("@/assets/hierarchicalCommissionAllocation/equity.png"),
                            alt: "",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticStyle: { "margin-top": "10px" } }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-top": "10px",
                        "border-radius": "8px",
                        padding: "10px",
                      },
                      style: {
                        border: "1px solid " + _vm.ruleForm.CardBorderColor,
                        background: _vm.ruleForm.CardBackgroundColor,
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "100%", height: "250px" },
                        attrs: { src: _vm.ruleForm.remark },
                      }),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "frb",
        staticStyle: {
          "margin-top": "6px",
          "font-size": "12px",
          color: "#666666",
        },
      },
      [
        _c("div", [_vm._v("直推有效团长≥5")]),
        _vm._v(" "),
        _c("div", [_vm._v("1/5")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fc" }, [_c("div", [_vm._v("29/月")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }