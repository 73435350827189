var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            title: _vm.conditionType.name,
            width: "750px",
            top: "10vh",
            "custom-class": "dialog_box",
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "div",
            { staticClass: "from_box" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "分类名称:", prop: "categoryName" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入内容" },
                            model: {
                              value: _vm.ruleForm.categoryName,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "categoryName", $$v)
                              },
                              expression: "ruleForm.categoryName",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型:", prop: "type" } },
                    [
                      [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: 1 },
                            on: { input: _vm.onRadioChange },
                            model: {
                              value: _vm.ruleForm.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "type", $$v)
                              },
                              expression: "ruleForm.type",
                            },
                          },
                          [_vm._v("自定义素材")]
                        ),
                      ],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  !(
                    _vm.conditionType.type === "editor" &&
                    _vm.ruleForm.level === 1
                  )
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "所属上级分类:", prop: "parentId" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "无" },
                              model: {
                                value: _vm.ruleForm.parentId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "parentId", $$v)
                                },
                                expression: "ruleForm.parentId",
                              },
                            },
                            _vm._l(_vm.options, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: {
                                  label: item.categoryName,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态:", prop: "enable" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.ruleForm.enable,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "enable", $$v)
                            },
                            expression: "ruleForm.enable",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "上线", value: 1 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "下线", value: 0 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序:", prop: "sort" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex" },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入内容" },
                            model: {
                              value: _vm.ruleForm.sort,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleForm, "sort", _vm._n($$v))
                              },
                              expression: "ruleForm.sort",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v("确认")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.resetForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }