var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "LittleActivityCreation" },
    [
      _c("div", { staticClass: "card frb" }, [
        _c(
          "div",
          { staticClass: "flex" },
          _vm._l(_vm.typeName, function (item) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "fc mr20 pointer",
                on: {
                  click: function ($event) {
                    return _vm.onTypeName(item.id)
                  },
                },
              },
              [
                _c("div", {
                  class: _vm.selectID === item.id ? "select_block" : "block",
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: _vm.selectID === item.id ? "select_title" : "title",
                  },
                  [_vm._v("\n          " + _vm._s(item.name) + "\n        ")]
                ),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "add_btn pointer", on: { click: _vm.onAdd } },
          [_vm._v("添加活动")]
        ),
      ]),
      _vm._v(" "),
      _c("officialEventBeltShare", { ref: "officialEventBeltShare" }),
      _vm._v(" "),
      _c("CustomActivityPopup", {
        ref: "customActivityPopup",
        on: { refresh: _vm.onRefresh },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }