import request from '@/utils/request';
// 刪除商学院
export function deleteBusinessCollege(data) {
  return request({
    url: '/portal/system/businessCollege/deleteBusinessCollege',
    method: 'post',
    data: data
  });
}
// 查询商学院
export function getBusinessCollege(params) {
  return request({
    url: '/portal/system/businessCollege/getBusinessCollege',
    method: 'get',
    params: params
  });
}

// 查询商学院列表
export function listBusinessCollege(data) {
  return request({
    url: '/portal/system/businessCollege/listBusinessCollege',
    method: 'post',
    data: data
  });
}
// 保存商学院
export function saveBusinessCollege(data) {
  return request({
    url: '/portal/system/businessCollege/saveBusinessCollege',
    method: 'post',
    data: data
  });
}
// 更新商学院
export function updateBusinessCollege(data) {
  return request({
    url: '/portal/system/businessCollege/updateBusinessCollege',
    method: 'post',
    data: data
  });
}