//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ItemActivity',
  props: {
    item: {
      type: Object,
      default: function _default() {}
    }
  },
  methods: {
    onControls: function onControls(val) {
      this.$emit('controls', {
        type: val,
        id: this.item.id
      });
    }
  }
};