var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "top-nav" },
      _vm._l(_vm.tabList, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            class: ["tab-box", _vm.activeNum === index ? "tab-box-active" : ""],
            on: {
              click: function ($event) {
                return _vm.changeType(index)
              },
            },
          },
          [_vm._v(_vm._s(item.name) + "\n    ")]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { padding: "30px" } },
      [
        _c("HomeTrim", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeNum === 0,
              expression: "activeNum === 0",
            },
          ],
          attrs: { "app-id": _vm.appId },
        }),
        _vm._v(" "),
        _c("UserCenter", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeNum === 1,
              expression: "activeNum === 1",
            },
          ],
          attrs: { "app-id": _vm.appId },
        }),
        _vm._v(" "),
        _c("BaseConfig", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeNum === 2,
              expression: "activeNum===2",
            },
          ],
          attrs: { "app-id": _vm.appId },
          on: { "change-value": _vm.updateValue },
        }),
        _vm._v(" "),
        _c("SubcommissionAllocation", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeNum === 4,
              expression: "activeNum === 4",
            },
          ],
          attrs: { "app-id": _vm.appId },
        }),
        _vm._v(" "),
        _c("HierarchicalAllocation", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeNum === 3,
              expression: "activeNum === 3",
            },
          ],
          attrs: { "app-id": _vm.appId },
        }),
        _vm._v(" "),
        _c("appDisposition", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeNum === 5,
              expression: "activeNum===5",
            },
          ],
          attrs: { "app-id": _vm.appId },
          on: { "change-value": _vm.updateValue },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }