var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "card frb" }, [
        _c("div", { staticClass: "flex" }, [
          _vm._v("\n      商品列表活动\n    "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "add_btn pointer", on: { click: _vm.onAdd } },
          [_vm._v("添加活动")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "search_criteria" }, [
        _c(
          "div",
          [
            _c("el-input", {
              attrs: { placeholder: "请输入活动名称", clearable: "" },
              model: {
                value: _vm.name,
                callback: function ($$v) {
                  _vm.name = $$v
                },
                expression: "name",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { margin: "0 10px" } },
          [
            _c(
              "el-select",
              {
                attrs: {
                  filterable: "",
                  clearable: "",
                  placeholder: "请选择状态",
                },
                on: {
                  change: function ($event) {
                    return _vm.search()
                  },
                },
                model: {
                  value: _vm.status,
                  callback: function ($$v) {
                    _vm.status = $$v
                  },
                  expression: "status",
                },
              },
              _vm._l(_vm.statusOptions, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                attrs: {
                  filterable: "",
                  clearable: "",
                  placeholder: "请选择平台",
                },
                on: {
                  change: function ($event) {
                    return _vm.search()
                  },
                },
                model: {
                  value: _vm.type,
                  callback: function ($$v) {
                    _vm.type = $$v
                  },
                  expression: "type",
                },
              },
              _vm._l(_vm.typeOptions, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "flex",
            staticStyle: { width: "fit-content", "margin-left": "10px" },
          },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.search } },
              [_vm._v("查询")]
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticClass: "ml10",
                attrs: { type: "info", plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.rest()
                  },
                },
              },
              [_vm._v("重置")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: {
                display: "flex",
                "justify-content": "flex-start",
                padding: "0 10px",
                "flex-wrap": "wrap",
              },
            },
            _vm._l(_vm.tableData, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticStyle: {
                    width: "24%",
                    "margin-bottom": "20px",
                    "margin-right": "1%",
                  },
                },
                [
                  _c(
                    "el-card",
                    {
                      attrs: {
                        "body-style": { padding: "0px" },
                        shadow: "hover",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { height: "150px" } },
                        [
                          _c("el-image", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: { src: item.picUrl, fit: "fill" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticStyle: { padding: "14px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "space-between",
                              "align-items": "center",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { "font-weight": "bold" } },
                              [_vm._v(_vm._s(item.activityName))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "center",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c("div", {
                                  staticClass: "round-box",
                                  style: {
                                    background: _vm.colorObj[item.status],
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(_vm._s(_vm.textObj[item.status])),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "both-box" }, [
                          _vm._v(
                            "\n              开始时间：" +
                              _vm._s(item.startTime) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "both-box" }, [
                          _vm._v(
                            "\n              结束时间：" +
                              _vm._s(item.endTime) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "both-box",
                            staticStyle: {
                              display: "flex",
                              "justify-content": "flex-start",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("div", {}, [_vm._v("活动链接：")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "long-text",
                                staticStyle: { flex: "1" },
                              },
                              [_vm._v(_vm._s(item.openUrl))]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "5%",
                                  "text-align": "right",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-document-copy",
                                  staticStyle: {
                                    color: "#1890FF",
                                    cursor: "pointer",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.copy(item.openUrl)
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "both-box",
                            staticStyle: {
                              display: "flex",
                              "justify-content": "space-between",
                              "align-items": "center",
                            },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.look(item)
                                      },
                                    },
                                  },
                                  [_vm._v("查看导入结果")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goods(item)
                                      },
                                    },
                                  },
                                  [_vm._v("查看商品详情")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.detail(item)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑活动")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-dropdown",
                                  { on: { command: _vm.handleCommand } },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "el-dropdown-link" },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-more",
                                          staticStyle: {
                                            color: "#1890FF",
                                            cursor: "pointer",
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown",
                                      },
                                      [
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            attrs: {
                                              command: _vm.beforeHandleCommand(
                                                "1",
                                                item
                                              ),
                                            },
                                          },
                                          [_vm._v("编辑类目")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            attrs: {
                                              command: _vm.beforeHandleCommand(
                                                "2",
                                                item
                                              ),
                                            },
                                          },
                                          [_vm._v("删除活动")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("el-empty", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tableData.length === 0,
                expression: "tableData.length===0",
              },
            ],
            attrs: { "image-size": 200 },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "pagination-container",
              staticStyle: { margin: "20px 0", "text-align": "right" },
            },
            [
              _c("el-pagination", {
                staticStyle: { display: "inline-block" },
                attrs: {
                  "current-page": _vm.page,
                  "page-sizes": [10, 20, 30, 50],
                  "page-size": _vm.limit,
                  total: _vm.total,
                  background: "",
                  layout: "total, prev, pager, next, sizes,jumper",
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("activePop", { ref: "activePop", on: { success: _vm.handActivePop } }),
      _vm._v(" "),
      _c("resultPop", { ref: "resultPop" }),
      _vm._v(" "),
      _c("categoryPop", { ref: "categoryPop" }),
      _vm._v(" "),
      _c("goodsPop", { ref: "goodsPop" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }