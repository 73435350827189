//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HomeTrim from "../appRenovation/component/homeTrim.vue";
import userCenter from "../appRenovation/component/userCenter.vue";
export default {
  name: '',
  components: {
    HomeTrim: HomeTrim,
    userCenter: userCenter
  },
  data: function data() {
    return {
      tabList: [{
        name: '首页装修',
        id: 0
      }, {
        name: '个人中心装修',
        id: 1
      }],
      activeNum: 0
    };
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    changeType: function changeType(val) {
      this.activeNum = val;
    }
  }
};